import React from "react";
import "./styles.css";

const PosNumberPad = ({ state, dispatch, actions }) => {


  const onClickPad = (digit) => {
    if (state.numberPad.length < 3) {
      dispatch({ type: actions.MANEJO_PAD, payload: '' + state.numberPad + digit });
    }
  }

  return (
    <React.StrictMode>
      <div className="ui grid h-100">
        <div className="row pad-va">
          <div className="ui huge grey label">
            {state.numberPad !== '' ? state.numberPad : 0}
          </div>
          <button onClick={() => dispatch({ type: actions.MANEJO_PAD, payload: '' })} className="ui fluid button">CE</button>
          <button onClick={() => onClickPad(1)} className="ui fluid button">1</button>
          <button onClick={() => onClickPad(2)} className="ui fluid button">2</button>
          <button onClick={() => onClickPad(3)} className="ui fluid button">3</button>
          <button onClick={() => onClickPad(4)} className="ui fluid button">4</button>
          <button onClick={() => onClickPad(5)} className="ui fluid button">5</button>
          <button onClick={() => onClickPad(6)} className="ui fluid button">6</button>
          <button onClick={() => onClickPad(7)} className="ui fluid button">7</button>
          <button onClick={() => onClickPad(8)} className="ui fluid button">8</button>
          <button onClick={() => onClickPad(9)} className="ui fluid button">9</button>
          {
            state.numberPad === ''
              ? <button onClick={() => onClickPad(0)} className="ui fluid button" disabled>0</button>
              : <button onClick={() => onClickPad(0)} className="ui fluid button">0</button>
          }
        </div>
      </div>
    </React.StrictMode>
  );
};

export { PosNumberPad };
