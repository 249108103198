// MODAL 14
import React from "react";

const ModalTotalTarjeta = ({ state, dispatch, actions, totalOrder, calcularDescuento }) => {

  const totalPagarCredito = totalOrder + calcularDescuento() - state.datosFiscales.pagaCon;

  const onCancel = () => {
    dispatch({ type: actions.CERRAR_MODAL });
    dispatch({ type: actions.MODIFICAR_DATO_FISCAL, payload: {...state.datosFiscales, pagaCon: 0} });
  };

  const onAceptInfo = () => {
    // modal nit/dui
    dispatch({ type: actions.NUMERO_MODAL, payload: 6 });
  };

  return (
    <div className="ui tiny longer modal md-disp">
      <div className="header">Total a pagar con tarjeta: {totalPagarCredito.toFixed(2)}</div>
      <div className="actions">
        <button
          className="ui positive let labeled icon button"
          onClick={() => onAceptInfo()}
        >
          SI
          <i className="check icon"></i>
        </button>
        <button
          className="ui red let labeled icon button"
          onClick={() => onCancel()}
        >
          NO
          <i className="x icon"></i>
        </button>
      </div>
    </div>
  );
};

export { ModalTotalTarjeta };
