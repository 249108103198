import React from "react";
import './style.css';

const ModalBitacora = ({closeModal, text, codigoOrden}) => {

  let textOrder = '';
  text.forEach(line => {
    textOrder += line + '\n'
  });

  return (
    <div className="ui tiny longer modal md-disp">
      <div className="header">Orden: {codigoOrden}</div>
        <div className="content modal-content">
          <textarea value={textOrder} className="modal-ta" readOnly>     
          </textarea>
        </div>
        <div className="actions">
          <button onClick={closeModal} className="large ui orange right labeled icon approve button">
            <i className="close icon"></i>
            CERRAR
          </button>
        </div>
    </div>
  );
};

export { ModalBitacora };
