import React, { useEffect } from 'react';

const FormOrdersLog = ({ filters, formFilter, handleChange, handleSubmit, limpiarFiltros }) => {

  /*useEffect(() => {
    window.$(".ui.dropdown").dropdown();
  }, []);*/

  return (
    <form className="ui form" onSubmit={handleSubmit}>
      <div className="two fields">
        <div className="field">
          <label>Fecha inicio</label>
          <input
            name="fechaOrdenInicio"
            value={formFilter.fechaOrdenInicio}
            onChange={handleChange}
            type="date"
            placeholder="Fecha inicio"
          />
        </div>
        <div className="field">
          <label>Fecha final</label>
          <input
            name="fechaOrdenFin"
            value={formFilter.fechaOrdenFin}
            onChange={handleChange}
            type="date"
            placeholder="Fecha final"
          />
        </div>
      </div>
      <div className="two fields">
        <div className="field">
          <label>Orden/Reporte</label>
          <input
            name="codigoOrden"
            value={formFilter.codigoOrden}
            onChange={handleChange}
            type="number"
          />
        </div>
        <div className="field">
          <label>Canal</label>
          <select onChange={handleChange} value={formFilter.codigoCanal} name="codigoCanal" className="drop-down">
            <option value="">Ninguno</option>
            {
              filters.canales.map((item) => {
                return (
                  <option key={item.CodigoCanal + item.Descripcion} className='dropdown-option' value={item.CodigoCanal}>{item.Descripcion}</option>
                )
              })
            }
          </select>
        </div>
      </div>
      <div className="two fields">
        <div className="field">
          <label>Forma de pago</label>
          <select
            className="drop-down"
            name="formaPago"
            value={formFilter.formaPago}
            onChange={handleChange}
          >
            <option value="">Ninguno</option>
            {filters.formasPago.map(item => (<option key={item} value={item}>{item}</option>))}
          </select>
        </div>
        <div className="field">
          <label>No. Documento</label>
          <input
            name="documentoNumero"
            value={formFilter.documentoNumero}
            onChange={handleChange}
            type="number"
          />
        </div>
      </div>
      <div className="field">
        <label>Busqueda por campo</label>
        <input
          type="text"
          name="contenidoBitacora"
          value={formFilter.contenidoBitacora}
          onChange={handleChange}
          placeholder="Buscar por texto en documento impreso"
        />
      </div>
      <button type="submit" className="large ui orange button">Aplicar filtros</button>
      <button onClick={limpiarFiltros} className="ui large brown button">Limpiar filtros</button>
    </form>
  );
};

export { FormOrdersLog }