
const FacturaPersonal = ({ datosReporte, hayOrdenes }) => {
    return (
        <div className="ui segment">
            <div className="ui middle aligned list">
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.CantidadCreditosFiscales : 0}
                    </div>
                    <div className="content">
                        <strong> Créditos Fiscales:</strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? (Math.round(datosReporte.TotalCreditosFiscales * 100) / 100).toFixed(2) : 0.00}
                    </div>
                </div>
                <hr />
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.CantidadFacturasManuales : 0}
                    </div>
                    <div className="content">
                        <strong> Facturas Manuales: </strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? (Math.round(datosReporte.TotalFacturasManuales * 100) / 100).toFixed(2) : 0.00}
                    </div>
                </div>
                <hr />
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.CantidadComidaPersonal : 0}
                    </div>
                    <div className="content">
                        <strong> Comida de Personal:</strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? (Math.round(datosReporte.TotalComidaPersonal * 100) / 100).toFixed(2) : 0.00}
                    </div>
                </div>
            </div>
        </div>
    )
}

export { FacturaPersonal }