import React from 'react';
import './style.css';

const CrearNuevaCuenta = ({ agregar }) => {
  const onAdd = () => {
    agregar();
  }

  return (
    <div className="drop-column" >

      <button className=" ui fluid inverted green btn-height button " onClick={onAdd}>
        <i className="massive icon plus m-0"></i>
      </button>

    </div>
  );
};

export { CrearNuevaCuenta }