import { valoresDefecto, ordenDescuento } from "../../../constants/Orden";
import { ordenFiscalizada } from "../../../constants/OrdenFiscalizada";
import { productoConComplemento } from "./proComplemento";
const INITIAL_STATE = {
  loading: true,
  error: undefined,
  response: {
    valoresLogin: {
      codigoEmpresa: null,
      codigoTienda: null,
      codigoCaja: null,
      codigoPuntoDeVenta: 1,//Lo devuelve el login
      codigoColaborador: null, //Login
      cierreNumero: 1, //Login, correlativo interno de Zeta
      codigoTurno: 0,//Login
    },
    otrosValores: {
      empleado: null,
      fecha: null,
      correlativoActual: null,
      abrirCaja: [true, '', 0],
      pin: null,
      nitEmpresa: null,
    },
    canales: [],
    descuentos: [],
    documentos: [],
    puntosDeVenta: [],
    formasPago: [],
    departamento: [],
    orden: null,
    menu: [],
    producto: [],
    menuArbol: []
  },
  openModal: true,
  numberModal: 5,
  orderOptions: {
    serie: "TEST", //Serie de la caja, retirar de esta tabla
    codigoCanal: 2, //Se obtiene de un modal
    documentoNumero: null, //No existen al ingresar la orden
    codigoDocumento: 1, //No existen al ingresar la orden pero al ser GT ponemos 1 para factura
    formaDePago: "EFECTIVO",//Se obtiene de un modal
    tiempoInicio: "2022-03-30 12:00:00", //debes de capturar cuando se marque el primer producto
    tiempoFinal: null, //Debería ser null
    totalFisico: 0, //Todo lo que se pague en efectivo
    totalVirtual: 0, //Todo lo que NO se pague en efectivo
    totalDescuento: 0, //Total descuentos aplicados
    totalImpuesto: 0, //Total impuestos aplicados
    codigoCliente: null, //Se obtiene de un flujo que no se ha hecho, y de prioridad baja
    documentoAnulado: null,
    dividida: 0, //0 es no, 1 es sí
    cuenta: 1, //1 por default
    tiempoCreacion: null, //enviar como null
    tiempoFacturacion: null, //enviar como null
    codigoEtapa: "CONF",
    condicion: 0,
    codigoOrden: 0,
  },
  orderItems: [],
  datosFiscales: {
    codigoOrden: 0,
    formaPago: '',
    nit: '',
    tipoIdCliente: '',
    nombreCliente: '',
    codigoEmpresa: '',
    codigoTienda: '',
    codigoCaja: '',
    codigoDocumento: '',
    pagaCon: 0,
    documentoNumero: '',
    datosCredito: null,
    datosBitcoin: null,
  },
  descuentoFuncion: {
    funcion: false,
    parametros: 0,
    objetoFuncion: "",
    valorDigitado: "0",
    referencia: "False",
    descripcionReferencia: "",
    codigoDescuento: "",
    descripcion: ""
  },
  menuValue: '8',
  complementProduct: [],//productoConComplemento,
  numberPad: '',
  arrayOrderOpcion: [],
  arrayOpcionesEliminadas: [],
  estadosComplementos: [],
  correlativo: null,
  loaderAccion: false,
  errorBuscar: undefined,
  contingencia: '',
  valorMaximoCF: 2500.00,
}

const posReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_START": //NOTA: inicia buscando todos los campos para la pantalla pos
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "FETCH_START_SUCCESS": //NOTA: toda la data se obtuvo con exito
      return {
        ...action.payload, //NOTA: response contiene todos los datos necesarios para la pantalla
        loading: false,
      };
    case "FETCH_START_ERROR": //NOTA: la data no se obtuvo por algun motivo
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "ABRIR_MODAL": //NOTA: mostramos el componente modal
      return {
        ...state,
        openModal: true,
      };
    case "CERRAR_MODAL": //NOTA: ocultamos el componente modal
      return {
        ...state,
        openModal: false,
      };
    case "NUMERO_MODAL": //NOTA: segun el valor de este, cambiamos lo que muestra el componente modal
      return {
        ...state,
        numberModal: action.payload,
        openModal: true,
      };
    case "MODIFICAR_ORDEN": //NOTA: cambiamos alguna propiedad de la orden que será insertada
      return {
        ...state,
        orderOptions: {
          ...state.orderOptions,
          ...action.payload,
        },
      };
    case "LISTA_PRODUCTOS": //NOTA: modifica los productos de la orden
      return {
        ...state,
        orderItems: action.payload,
      };
    case "MODIFICAR_DATO_FISCAL": //NOTA: cambiamos alguna propiedad de la orden que será insertada
      return {
        ...state,
        datosFiscales: action.payload
      };
    case "MODIFICAR_DESCUENTO": //NOTA: manejo de objeto funcion para los descuentos
      return {
        ...state,
        descuentoFuncion: action.payload,
      };
    case "VALOR_MENU": //NOTA: almacena el valor del menu que es mostrado en la barra superior de la pantalla
      return {
        ...state,
        menuValue: action.payload,
      };
    case "COMPLEMENTO": //REVISAR: esta parte no esta terminada, de hecho contiene bugs serios
      return {
        ...state,
        complementProduct: action.payload,
      };
    case "MANEJO_PAD": //NOTA: manejo de teclado numerico vertical en pos
      return {
        ...state,
        numberPad: action.payload,
      };
    case "CORRELATIVO":
      return {
        ...state,
        correlativo: action.payload,
      };
    case "FETCH_DATA": //NOTA: este campo sirve para el uso de llamadas a la api durante el uso del pos
      return {
        ...state,
        loaderAccion: true,
        errorBuscar: undefined,
      };
    case "FETCH_DATA_SUCCESS": //NOTA: los resultados de la peticion resultaron bien, necesita nombre de propiedad y valor
      return {
        ...state,
        loaderAccion: false,
        //[action.payload.name]: action.payload.value,
      };
    case "FETCH_DATA_ERROR": //NOTA: ocurrio un error en la busqueda del valor solicitado
      return {
        ...state,
        loaderAccion: undefined,
        errorBuscar: action.payload,
      };
    case "CONTINGENCIA":
      return {
        ...state,
        contingencia: action.payload,
      };
    //NOTA: hasta este punto son modificaciones directas a un estado
    //--------------------------------------------------------------
    case "LIMPIAR_POS":
      return {
        ...state,
        descuentoFuncion: ordenDescuento,
        menuValue: '',
        complementProduct: productoConComplemento,
        orderOptions: {
          ...state.orderOptions,
          ...INITIAL_STATE.orderOptions
        },
        orderItems: [],
        numberPad: '',
        datosFiscales: ordenFiscalizada,
        loaderAccion: false,
        openModal: true,
        numberModal: 5
      };
    case "REINICIAR_DESCUENTO":
      return {
        ...state,
        orderOptions: {
          ...state.orderOptions,
          totalDescuento: 0
        },
        orderItems: action.payload,
        //descuentoFuncion: ordenFiscalizada,
        descuentoFuncion: ordenDescuento,
      };
    case "AGREGAR_PRODUCTO_DETALLE":
      return {
        ...state,
        orderItems: action.payload.listItems,
        numberPad: '',
      };
    case "REINICIAR_MODAL_COMPLEMENTOS": // REVISAR: nuevo
      return {
        ...state,
        complementProduct: '',
      };
    case "CARGAR_COMPLEMENTOS_MODAL":
      return {
        ...state,
        complementProduct: [action.payload],
      }
    case "ACTUALIZAR_ARRAY_OPCIONES":
      return {
        ...state,
        arrayOrderOpcion: [...state.arrayOrderOpcion, action.payload],
      }
    case "ELIMINAR_OPCIONES":
      return {
        ...state,
        arrayOrderOpcion: [...action.payload],
      }
    case "ACTUALIZAR_OPS_ELIMINADAS":
      return {
        ...state,
        arrayOpcionesEliminadas: [...action.payload],
      }
    case "RESET_ARRAY_OPCIONES":
      return {
        ...state,
        arrayOrderOpcion: [],
      }
    case "CARGAR_ESTADO_COMPLEMENTOS":
      // Este estado maneja el array que contiene los estados de los complementos del producto actual 
      // El array estadosComplementos guarda el codigo de complemento de cada uno de los complementos junto con
      // un estado que puede ser "enabled" o  "disabled", que define si el botón de ese complemento está habilitado
      // o deshabilitado para hacer cambios
      return {
        ...state,
        estadosComplementos: [...action.payload],
      }
    default:
      return state;
  }
};

export { posReducer, INITIAL_STATE };