import { TecladoCompleto } from "../../TecladoCompleto";
import { FormUsuario } from "../FormUsuario";


const ModalFormulario = ({ formModificar, handleSubmit, handleChange, formData, errorInput, roles, cancelButton, activarTeclado, claseTeclado, capsLock, claseEspacioBlanco, tiendas }) => {

    return (
        <div className="ui large longer modal md-disp ">
            <div className="scrolling content">
                <FormUsuario
                    formModificar={formModificar}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    formData={formData}
                    errorInput={errorInput}
                    roles={roles}
                    cancelButton={cancelButton}
                    activarTeclado={activarTeclado}
                    tiendas={tiendas}
                />

                <div className={claseEspacioBlanco}></div>

                <TecladoCompleto handleChange={handleChange} claseActivar={claseTeclado} activarTeclado={activarTeclado} capsLock={capsLock} />
            </div>

        </div>
    );
};

export { ModalFormulario };