import http from "../http-common"

const reporteXServices = {
    reporte: {
        reporteX(codigoEmpresa, codigoTienda, codigoCaja, codigoColaborador, codigoTurno = null) {
            return http.get(`/Reporte/VentaX?codigoEmpresa=${codigoEmpresa}&codigoTienda=${codigoTienda}&codigoCaja=${codigoCaja}&codigoColaborador=${codigoColaborador}&CodigoTurno=${codigoTurno}`);
        }
    }
}

export { reporteXServices };