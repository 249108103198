import "./styles.css"

export const TarjetaUsuario = ({ usuario, eliminarColaborador, modificarColaborador, codigoColaborador }) => {
    return (
        <div className={"ui clearing" + (usuario.Estado == "ALTA" ? " segment" : " tertiary inverted red segment")}>
            <button
                onClick={() => eliminarColaborador(usuario.CodigoColaborador)}
                //className={"ui right floated" + (usuario.Estado == "ALTA" ? " button" : " disabled button")}
                className={"ui right floated" + (usuario.Estado == "ALTA" ? (usuario.CodigoColaborador !== codigoColaborador) ? " button" : " disabled button" : " disabled button")}
            >
                Deshabilitar
            </button>

            <button
                onClick={() => modificarColaborador(usuario)}
                //onClick={() => onOpenModal(0)}
                className={"ui right floated" + (usuario.Estado == "ALTA" ? (usuario.CodigoColaborador !== codigoColaborador) ? " button" : " disabled button" : " disabled button")}
            >
                Modificar
            </button>

            <div className="nombre-colaborador">
                {usuario.Nombres} {usuario.Apellidos}
            </div>

        </div>

    )
}