const ACTION_TYPES = {
  FETCH_START: "FETCH_START",
  FETCH_START_SUCCESS: "FETCH_START_SUCCESS",
  FETCH_START_ERROR: "FETCH_START_ERROR",
  CHANGE_FORM: "CHANGE_FORM",
  ABRIR_MODAL: "ABRIR_MODAL",
  CERRAR_MODAL: "CERRAR_MODAL",
  FETCH_DATA: "FETCH_DATA",
  FETCH_DATA_SUCCESS: "FETCH_DATA_SUCCESS",
  FETCH_DATA_ERROR: "FETCH_DATA_ERROR",
  DOCUMENTO_BITACORA: "DOCUMENTO_BITACORA",
  LIMPIAR_FILTROS: "LIMPIAR_FILTROS",
};

export { ACTION_TYPES };
