import React from "react";
import { useContext } from "react";
import { ProductosContext } from "../ProductosContext";
import './style.css'

const DetallesComplemento = ({ modalFuente }) => {

    const {
        state,
        seleccionarComplemento,
        editarComplementoExistente,
        cambiarEstadoComplemento,
    } = useContext(ProductosContext);

    let opcionSpan = state.compDetalle.Opciones.length;
    let restoOpciones = state.compDetalle.Opciones.slice(1);

    return (
        <>
            <div className="tabla-op">
                <table className="ui small compact celled table">
                    <tbody>
                        <tr>
                            <td>Código Complemento</td>
                            <td>{state.compDetalle.CodigoComplemento}</td>
                        </tr>
                        <tr>
                            <td>Descripción</td>
                            <td>{state.compDetalle.Descripcion}</td>
                        </tr>
                        <tr>
                            <td>Posición</td>
                            <td>{state.compDetalle.Posicion}</td>
                        </tr>
                        <tr>
                            <td>Cantidad por defecto</td>
                            <td>{state.compDetalle.CantidadPorDefecto}</td>
                        </tr>
                        <tr>
                            <td>Cantidad máxima</td>
                            <td>{state.compDetalle.CantidadMaxima}</td>
                        </tr>
                        <tr>
                            <td>Cantidad mínima</td>
                            <td>{state.compDetalle.CantidadMinima}</td>
                        </tr>
                        <tr>
                            <td rowSpan={opcionSpan}>Opciones</td>
                            {
                                (state.compDetalle.Opciones.length > 0)
                                    ? <td>{state.compDetalle.Opciones[0].Descripcion}</td>
                                    : <td> No hay opciones </td>
                            }

                        </tr>
                        {
                            restoOpciones.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item.Descripcion}</td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
            </div>
            <div className="ui two column grid">
                <div className="column pad-botones">
                    <button onClick={() => cambiarEstadoComplemento('buscando')} className='ui grey fluid icon labeled button'>
                        <i className="close icon"></i>
                        Cancelar
                    </button>
                </div>
                <div className="column pad-botones">
                    {
                        (modalFuente === 'editar')
                            ? (
                                <button onClick={() => editarComplementoExistente(state.compDetalle)} className="ui positive fluid icon labeled button">
                                    <i className="edit icon"></i>
                                    Editar
                                </button>
                            )
                            : (
                                <button onClick={() => seleccionarComplemento(state.compDetalle.CodigoComplemento)} className="ui positive fluid icon labeled button">
                                    <i className="plus icon"></i>
                                    Agregar
                                </button>
                            )
                    }

                </div>
            </div>

        </>
    )
}

export { DetallesComplemento }