import http from "../http-common";

const posServices = {
  // Agregar parametro axios para poder consumir los servicios locales si no hay conexión
  pos: {
    getAllMenus(codigoEmpresa = "GRANJEROGT", axios) {
      //return http.get(`/menu?codigoEmpresa=${codigoEmpresa}`);
      return axios.get(`/menu?codigoEmpresa=${codigoEmpresa}`);
    },
    getAllProductos(codigoEmpresa = "GRANJEROGT", axios) {
      /*return http.get(
        `/producto?codigoEmpresa=${codigoEmpresa}`
      );*/
      return axios.get(
        `/producto?codigoEmpresa=${codigoEmpresa}`
      );
    },
    getAllCanales(codigoEmpresa = "GRANJEROGT", axios) {
      //return http.get(`/canal?codigoEmpresa=${codigoEmpresa}`);
      return axios.get(`/canal?codigoEmpresa=${codigoEmpresa}`);
    },
    getAllDescuentos(codigoEmpresa = "GRANJEROGT", axios) {
      //return http.get(`/descuento?codigoEmpresa=${codigoEmpresa}`);
      return axios.get(`/descuento?codigoEmpresa=${codigoEmpresa}`);
    },
    getAllDocuments(codigoEmpresa = "GRANJEROGT", axios) {
      //return http.get(`/documento?codigoEmpresa=${codigoEmpresa}`);
      return axios.get(`/documento?codigoEmpresa=${codigoEmpresa}`);
    },
    getPuntosDeVenta(axios) {
      //return http.get('/puntodeventa');
      return axios.get('/puntodeventa');
    },
    getOrder(codigoOrden = null) {
      let baseUrl = '/orden';

      if (codigoOrden !== null) {
        baseUrl += `?codigoOrden=${codigoOrden}`;
      }

      return http.get(baseUrl);
    },
    insertNewOrder(order = null, axios = null) {
      //console.log(axios.defaults.baseURL);
      return axios.post('/Orden', order)
    },
    fiscalizarOrden(ordenFiscalizada = null, axios = null) {
      return axios.post('/Orden/FiscalizarOrden', ordenFiscalizada);
    },
    parametroDescuento(codigoEmpresa = "GRANJEROGT", nombreDescuento = null) {
      let baseUrl = `/Utilidades/Descuentos?codigoEmpresa=${codigoEmpresa}`;

      if (nombreDescuento !== null) {
        baseUrl += `&nombreDescuento${nombreDescuento}`;
      }

      return http.get(baseUrl);
    },
    getEnlaces() {
      return http.get('/Utilidades/CargarEnlace');
    },
    validarNit(nit) {
      return http.get(`/Orden/ObtenerInformacionNit?nit=${nit}`);
    },
    validarPasaporte(pasaporte) {
      return http.get(`/ValidarPasaporte?pasaporte=${pasaporte}`);
    },
    validarCui(cui) {
      return http.get(`/ValidarCUI?cui=${cui}`);
    },
    getFormasDePago(codigoEmpresa = 'GRANJEROGT', codigoTienda = 0, codigoCaja = 1, axios) {
      //return http.get(`/Utilidades/FormasPago?codigoEmpresa=${codigoEmpresa}`);
      return axios.get(`/Utilidades/FormasPago?codigoEmpresa=${codigoEmpresa}&codigoTienda=${codigoTienda}&codigoCaja=${codigoCaja}`);
    },
    getDepartamentos(codigoPais = 'GT', axios) {
      //return http.get(`/Utilidades/Departamento?codigoPais=${codigoPais}`);
      return axios.get(`/Utilidades/Departamento?codigoPais=${codigoPais}`);
    },
    getClienteByDocumentoPersonal(documentoPersonal = "") {
      return http.get(`/Cliente?documentoPersonal=${documentoPersonal}`);
    },
    getTurnoActivo(codigoEmpresa = "", codigoTienda = "", codigoCaja = "", axios) {
      return axios.get(`/Turno/TurnoActivo?codigoEmpresa=${codigoEmpresa}&codigoTienda=${codigoTienda}&codigoCaja=${codigoCaja}`);
    },
    iniciarTurno(turno = null) {
      return http.post("/Turno/IniciarTurno", turno);
    },
    iniciarTurnoServer(turno = null, server) {
      return server.post("/Turno/IniciarTurno", turno);
    },
    crearTurnoLocal(turno = null, server) {
      return server.post("/Turno/CrearTurnoLocal", turno);
    },
    anularOrden(codigoOrden = null, codigoMotivo = null, expMotivo = null, axios) {
      //return http.delete(`/orden/${codigoOrden}/${codigoMotivo}/${expMotivo}`);
      return axios.delete(`/orden/${codigoOrden}/${codigoMotivo}/${expMotivo}`);
    },
    getMaximoCF(codigoEmpresa, codigoParametro, axios) {
      //return http.get(`/Utilidades/ParametroAplicacion?CodigoEmpresa=${codigoEmpresa}&CodigoParametro=${codigoParametro}`);
      return axios.get(`/Utilidades/ParametroAplicacion?CodigoEmpresa=${codigoEmpresa}&CodigoParametro=${codigoParametro}`);
    }
  },
};

export { posServices };
