import React from "react";
import { useNavigate } from "react-router-dom";

const PermissionPage = () => {
  let navigate = useNavigate();
  return (
    <div className="container tecPadTop">
      {/* <div className="ui segment">
        <p>❌ {message} 😱</p>
      </div> */}

      <div className="ui red raised very padded text container segment">
        <div className="ui equal width grid">
          <div className="stretched row">
            <div className="six wide column">
{/* 
              <img className="ui image" src={errorIcono} />
              <h1>😱</h1> */}
            </div>

            <div className="column">
              <h1>Error</h1>
              <p>
                No tiene acceso a esta página.
              </p>

              <div className="ui centered equal width grid">
                      
                      <div className="seven wide column">
                        <button
                          onClick={() => navigate('/menu')}
                          className="ui fluid orange labeled icon button">
                          <i className="sign-out icon"></i>
                          Ir al menú
                        </button>
                      </div>
                    </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export {PermissionPage};
