import { useEffect } from "react";
import { Modal } from "../Modal";
import { FormUsuario } from "./FormUsuario";
import { ModalFormulario } from "./ModalFormulario";
import { TarjetaUsuario } from "./TarjetaUsuario";

import './styles.css'
import { TecladoCompleto } from "../TecladoCompleto";
import { useRef } from "react";

const RegistroUI = ({
    handleSubmit,
    handleChange,
    formData,
    roles,
    colaboradores,
    colaboradoresDeshabilitados,
    formModificar,
    formModData,
    handleSubmitModificar,
    handleChangeModificar,
    eliminarColaborador,
    modificarColaborador,
    cancelButton,
    errorInput, myRef,
    onOpenModal,
    numberModal,
    openModal,
    onCancelModal,
    claseTeclado,
    claseTecladoModal,
    activarTeclado,
    activarTecladoModal,
    capsLock,
    claseEspacioBlanco,
    claseEspacioBlancoModal,
    tiendas,
    codigoColaborador,
}) => {

    useEffect(() => {
        document.getElementById('input-tec');
        window.$('.ui.accordion').accordion();
        window.$('.ui.dropdown').dropdown();
        window.$('.menu .item').tab();
    }, []);

    const refBlanco = useRef();

    const scrollToBlanco = () => {
        refBlanco.current.scrollIntoView();

    }

    return (

        <>
            <div className="ui raised very padded text container segment segment-color">

                <h1>Usuarios</h1>

                <div className="ui top attached tabular menu">
                    <a className="item active" data-tab="first">Registro de usuario</a>
                    <a className="item" data-tab="second">Usuarios activos</a>
                    <a className="item" data-tab="third">Usuarios inactivos</a>
                </div>
                <div className="ui bottom attached tab segment active" data-tab="first">
                    <FormUsuario
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        formData={formData}
                        errorInput={errorInput}
                        roles={roles}
                        activarTeclado={activarTeclado}
                        scrollToBlanco={scrollToBlanco}
                    />
                </div>


                <div className="ui bottom attached tab segment" data-tab="second">
                    {
                        (colaboradores.length > 0)
                            ? (
                                colaboradores.map((item) => {
                                    return (
                                        (item.Estado === "ALTA")
                                            ? (
                                                <TarjetaUsuario key={item.CodigoColaborador}
                                                    usuario={item}
                                                    eliminarColaborador={eliminarColaborador}
                                                    modificarColaborador={modificarColaborador}
                                                    onOpenModal={onOpenModal}
                                                    codigoColaborador={codigoColaborador}
                                                />
                                            )
                                            : null
                                    )
                                })
                            )
                            : <div>NO HAY COLABORADORES ACTIVOS</div>
                    }

                    {!!openModal && (
                        <Modal>
                            {
                                numberModal === 0
                                    ? <ModalFormulario
                                        onCancelModal={onCancelModal}
                                        formModificar={formModificar}
                                        handleSubmit={handleSubmitModificar}
                                        handleChange={handleChangeModificar}
                                        formData={formModData}
                                        errorInput={errorInput}
                                        roles={roles}
                                        cancelButton={cancelButton}
                                        activarTeclado={activarTecladoModal}
                                        claseTeclado={claseTecladoModal}
                                        capsLock={capsLock}
                                        claseEspacioBlanco={claseEspacioBlancoModal}
                                        tiendas={tiendas}
                                    />
                                    : null

                            }
                        </Modal>
                    )}

                </div>

                <div className="ui bottom attached tab segment" data-tab="third">
                    {
                        (colaboradoresDeshabilitados.length > 0)
                            ? (
                                colaboradores.map((item) => {
                                    return (
                                        (item.Estado === "ALTA")
                                            ? null
                                            : (
                                                <TarjetaUsuario key={item.CodigoColaborador}
                                                    usuario={item}
                                                    eliminarColaborador={eliminarColaborador}
                                                    modificarColaborador={modificarColaborador}
                                                />
                                            )
                                    )
                                })
                            )
                            : <div>NO HAY COLABORADORES DESHABILITADOS</div>
                    }


                </div>


                <div ref={refBlanco} className={claseEspacioBlanco}></div>



            </div>

            <TecladoCompleto handleChange={handleChange} claseActivar={claseTeclado} activarTeclado={activarTeclado} capsLock={capsLock} />
        </>
    )
};

export { RegistroUI };