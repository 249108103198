// MODAL 15
import React, { useState } from 'react';
import { TecladoNumerico } from '../../TecladoNumerico';

const ModalCCF = ({ dispatch, actions }) => {
  
  const [montoDescuento, setMontoDescuento] = useState('');

  const inputHandler = (valor) => {
    if (typeof valor === "number") {
      setMontoDescuento(montoDescuento + valor);
    }  else if(valor === '!#') {
      setMontoDescuento(montoDescuento.slice(0, -1));
    } else if(valor === '!%') {
      setMontoDescuento('');
    } else {
      const valorEvento = valor.target.value;

      if (valorEvento.replace(/[^.]/g, "").length <= 1) {
        if (
          !!Number.isFinite(parseFloat(valorEvento)) ||
          valorEvento === "." ||
          valorEvento[valorEvento.length - 1] == "."
        ) {
          setMontoDescuento(valorEvento);
        }
      }
    }
  };

  const onCancelPreimpreso = () => {
    dispatch({ type: actions.CERRAR_MODAL });
  }

  const onAceptPreimpreso = () => {
    
  }

  let btnAceptarClass = 'ui positive let labeled icon button';
  if(montoDescuento === '') {
    btnAceptarClass += ' disabled';
  }

  return(
    <div className="ui tiny longer modal md-disp">
      <div className="header">Credito Fiscal No.</div>

      
        <div className="content">
        <div className="ui grid">
          <div className="row padBot">
            <div className="sixteen wide column padBot">
              <div className="ui fluid input">
                <input
                  onChange={(e) => inputHandler(e)}
                  value={montoDescuento}
                  type="text"
                />
              </div>
            </div>
          </div>

          <div className="stretched row padBotTop">
            <TecladoNumerico inputHandler={inputHandler} usarDecimal={false} />
          </div>
        </div>
      </div>
      

      <div className="actions">
        <button
          className={btnAceptarClass}
          onClick={() => onAceptPreimpreso()}
        >
          ACEPTAR
          <i className="check icon"></i>
        </button> 
        <button
          className="ui red let labeled icon button"
          onClick={() => onCancelPreimpreso()}
        >
          CANCELAR
          <i className="x icon"></i>
        </button>
      </div>
    </div>
  );
};

export {ModalCCF};