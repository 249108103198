import axios from "axios";

const posBacServices = {
  bac: {
    venta(url, data) {
      return axios.post(url, data);
    },
    anulacion(url, data) {
      return axios.post(url, data);
    },
    cierreTurno(url, data) {
      return axios.post(url, data);
    },
    cierreDia(url, data) {
      return axios.post(url, data);
    },
  },
};

export {posBacServices};
