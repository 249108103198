let ordenFiscalizada = {
    codigoOrden: 0,
    formaPago: '',
    nit: '',
    codigoEmpresa: '',
    codigoTienda: '',
    codigoCaja: '',
    codigoDocumento: '',
    pagaCon: 0,
    documentoNumero: '',
    datosCredito: null,
    datosBitcoin: null,
}

let ordenCredito = {
    CodigoOrden: 0,
    NumeroTarjeta: 'XXXXXXXXXXXXXXXX', // 
    Referencia: '0000', // ESTE CAMPO NO SE SABE SI CAMBIARA
    Autorizacion: '',
    Estado: 'ALTA', // ESTE CAMPO CAMBIARA EN EL FUTURO,
    TiempoPago: '',
}

export {ordenFiscalizada, ordenCredito};