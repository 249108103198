
const Transacciones = ({ datosReporte, hayOrdenes }) => {
    return (
        <div className="ui segment">
            <div className="ui middle aligned divided list">
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.TransaccionesReales : 0}
                    </div>
                    <div className="content">
                        <strong>Transacciones Reales:</strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.TransaccionesDevueltas : 0}
                    </div>
                    <div className="content">
                        <strong> Transacciones Devueltas: </strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.TotalTransacciones : 0}
                    </div>
                    <div className="content">
                        <strong> Total Transacciones: </strong>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Transacciones }