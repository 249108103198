import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2'

import "./styles.css"
//import api from '../../api/api';
import { RegistroUI } from './RegistroUI';
import { Loader } from '../Loader';
import { PageError } from '../PageError';
import { colaboradorServices } from '../../api/services/colaboradorServices';
import { useLocalStorage } from '../../hooks/useLocalStorage';


export const RegistroUsuario = () => {

    const myRef = useRef(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);

    const [openModal, setOpenModal] = useState(false);
    const [numberModal, setNumberModal] = useState(undefined);

    const [usuario, guardarUsuario] = useLocalStorage('LOGIN_BETA');

    const [tiendas, setTiendas] = useState([]);

    let valoresLogin = {
        codigoEmpresa: usuario.Tienda.CodigoEmpresa,
        codigoTienda: usuario.Tienda.CodigoTienda,
        codigoCaja: usuario.CorrelativoFiscal.CodigoCaja,
        codigoPuntoDeVenta: 1,//Lo devuelve el login
        codigoColaborador: usuario.Colaborador.CodigoColaborador, //Login
        cierreNumero: 1, //Login, correlativo interno de Zeta
        codigoTurno: usuario.Turno.CodigoTurno,//Login
        estadoTurno: usuario.Turno.Estado,
    };

    const codColab = valoresLogin.codigoColaborador;

    const [formData, setFormData] = useState({
        CodigoColaborador: '',
        Nombres: '',
        Apellidos: '',
        //PIN: 0,
        PIN: '',
        CodigoRol: 1,
        Clave: '',
        ClaveConfirm: '',
    });

    const [formModData, setFormModData] = useState({
        CodigoColaborador: '',
        CodigoTienda: valoresLogin.codigoTienda,
        Nombres: '',
        Apellidos: '',
        //PIN: 0,
        PIN: '',
        CodigoRol: 1,
        Clave: '',
        ClaveConfirm: '',
    });

    const [modDataSave, setModDataSave] = useState({
        CodigoColaborador: '',
        CodigoTienda: valoresLogin.codigoTienda,
        Nombres: '',
        Apellidos: '',
        //PIN: 0,
        PIN: '',
        CodigoRol: 1,
        Clave: '',
        ClaveConfirm: '',
    })

    const [colaboradores, setColaboradores] = useState([]);
    const [colaboradoresDeshabilitados, setColaboradoresDeshabilitados] = useState([]);

    const [roles, setRoles] = useState([]);

    const [formModificar, setFormModificar] = useState(false);

    const [errorPassword, setErrorPassword] = useState(undefined);
    const [errorInput, setErrorInput] = useState(undefined);

    const [claseTeclado, setClaseTeclado] = useState('keyboard-hidden');
    const [claseTecladoModal, setClaseTecladoModal] = useState('keyboard-hidden');

    const [claseEspacioBlanco, setClaseEspacioBlanco] = useState('espacio-blanco-off');
    const [claseEspacioBlancoModal, setClaseEspacioBlancoModal] = useState('espacio-blanco-modal-off');

    const [campoSeleccionado, setCampoSeleccionado] = useState(undefined);
    const [formatoSeleccionado, setFormatoSeleccionado] = useState(undefined);

    const [capsLock, setCapsLock] = useState(false);

    useEffect(() => {
        getTiendas();
        getColaboradores();
        getRoles();

        document.getElementById('input-tec');
        window.$('.ui.accordion').accordion();
        window.$('.ui.dropdown').dropdown();
        window.$('.menu .item')
            .tab();
    }, []);

    useEffect(() => {
        if (errorPassword == undefined) return;

        if (errorPassword == 'length') {
            Swal.fire({
                title: 'Error',
                text: 'La clave debe tener entre 6 y 20 caracteres',
                customClass: {
                    confirmButton: 'ui orange button'
                },
                icon: 'error',
                confirmButtonText: 'Regresar'
            })
            setErrorPassword(undefined);
            return;
        }
        else if (errorPassword == 'formato') {
            Swal.fire({
                title: 'Contraseña no aceptada',
                text: 'La clave debe tener entre 6 y 20 caracteres y al menos una letra minúscula, una letra mayúscula, un número y un caracter especial.',
                customClass: {
                    confirmButton: 'ui orange button'
                },
                icon: 'error',
                confirmButtonText: 'Regresar'
            })
            setErrorPassword(undefined);
            return;
        }

        Swal.fire({
            title: 'Error',
            text: 'Las claves no coinciden',
            customClass: {
                confirmButton: 'ui orange button'
            },
            icon: 'error',
            confirmButtonText: 'Regresar'
        })
        setErrorPassword(undefined);

    }, [errorPassword]);

    const getColaboradores = async () => {
        try {
            const respColaboradores = await colaboradorServices.colaborador.getAllColaboradores(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda);
            console.log(respColaboradores);
            if (respColaboradores.data.SUCCESS === false) {
                Swal.fire({
                    text: respColaboradores.data.MESSAGE,
                    icon: 'error',
                })
                return;
            }

            const colaboradores = respColaboradores.data.MESSAGE;
            //console.log(colaboradores);

            const colabDes = colaboradores.filter(colaborador => colaborador.Estado !== "ALTA")
            //console.log(colabDes);

            setColaboradores(colaboradores);
            setColaboradoresDeshabilitados(colabDes);

        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    }

    const getRoles = async () => {
        try {
            const respRoles = await colaboradorServices.rol.getAllRoles(valoresLogin.codigoEmpresa);
            console.log(respRoles);
            if (respRoles.data.SUCCESS === false) {
                Swal.fire({
                    text: respRoles.data.MESSAGE,
                    icon: 'error',
                })
                return;
            }

            const roles = respRoles.data.MESSAGE;

            setRoles(roles);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    }

    const getTiendas = async () => {
        try {
            const respTiendas = await colaboradorServices.tienda.getAllTiendas(valoresLogin.codigoEmpresa);
            console.log(respTiendas);
            if (respTiendas.data.SUCCESS === false) {
                Swal.fire({
                    text: respTiendas.data.MESSAGE,
                    icon: 'error',
                })
                return;
            }

            const tiendas = respTiendas.data.MESSAGE;

            setTiendas(tiendas);
            setLoading(false);

        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    }

    const handleChange = (e, formato) => {

        setErrorInput(undefined);

        let split = undefined;
        let tipo = undefined;
        let cantidadMaxima = undefined;

        let valorUsar = undefined;

        let valorActual = undefined;

        if (e.length !== undefined || typeof (e) == 'number') {

            valorActual = formData[campoSeleccionado];
            //console.log(e);

            if (e == 'backspace') {
                const valorNuevo = valorActual.slice(0, -1)
                setFormData({
                    ...formData,
                    [campoSeleccionado]: valorNuevo,
                })
                return;
            }

            if (e == 'caps') {
                setCapsLock(!capsLock);
                return;
            }

            const formatoActual = formatoSeleccionado;

            split = formatoActual.split('-');
            tipo = split[0];
            cantidadMaxima = split[1];



            valorUsar = e;
        }
        else {
            split = formato.split('-');
            tipo = split[0];
            cantidadMaxima = split[1];

            valorUsar = e.target.value;

            valorActual = e.target.value;
        }

        if (tipo == 'soloLetras') {
            if (cantidadMaxima == 'no') {
                if (!/^$|^[a-zA-Z ]+$/.test(valorUsar)) return;
            }
            else {
                const exp = new RegExp(`^$|^[a-zA-Z ]{0,${cantidadMaxima}}$`);
                if (!exp.test(valorUsar)) {
                    if (campoSeleccionado == 'Nombres') {
                        setErrorInput('nombreUsuario');
                    }
                    else if (campoSeleccionado == 'Apellidos') {
                        setErrorInput('apellidoUsuario');
                    }
                    return;
                }
                if (e.length !== undefined || typeof (e) == 'number') {
                    const expActual = new RegExp(`^$|^[a-zA-Z ]{0,${cantidadMaxima - 1}}$`);
                    if (!expActual.test(valorActual)) {
                        if (campoSeleccionado == 'Nombres') {
                            setErrorInput('nombreUsuario');
                        }
                        else if (campoSeleccionado == 'Apellidos') {
                            setErrorInput('apellidoUsuario');
                        }
                        return;
                    }
                }
            }
        }
        else if (tipo == 'numerico') {
            if (cantidadMaxima == 'no') {
                if (!/^$|^[0-9]+$/.test(valorUsar)) {
                    setErrorInput('pinUsuario');
                    return;
                }
            } else {
                const exp = new RegExp(`^[0-9]{0,${cantidadMaxima}}$`)
                if (!exp.test(valorUsar)) {
                    setErrorInput('pinUsuario');
                    return;
                }
                if (e.length !== undefined || typeof (e) == 'number') {
                    const expActual = new RegExp(`^[0-9]{0,${cantidadMaxima - 1}}$`)
                    if (!expActual.test(valorActual)) {
                        setErrorInput('pinUsuario');
                        return;
                    }
                }

            }
        }
        else if (tipo == 'alfaNumerico') {
            if (cantidadMaxima == 'no') {
                if (!/^$|^[a-zA-Z0-9]+$/.test(valorUsar)) {
                    setErrorInput('codUsuario');
                    return;
                }
            }
            else {
                const exp = new RegExp(`^[a-zA-Z0-9]{0,${cantidadMaxima}}$`);
                if (!exp.test(valorUsar)) {
                    setErrorInput('codUsuario');
                    return;
                }
                if (e.length !== undefined || typeof (e) == 'number') {
                    const expActual = new RegExp(`^[a-zA-Z0-9]{0,${cantidadMaxima}}$`);
                    if (!expActual.test(valorActual)) {
                        setErrorInput('codUsuario');
                        return;
                    }
                }
            }
        }
        else if (tipo == 'pwd') {
            const exp = new RegExp(`^[a-zA-Z0-9@$!%*#?&_]{0,${cantidadMaxima}}$`);
            if (!exp.test(valorUsar)) {
                if (campoSeleccionado == 'Clave') {
                    setErrorInput('claveUsuario');
                }
                else if (campoSeleccionado == 'ClaveConfirm') {
                    setErrorInput('claveConfUsuario');
                }
                return;
            }
        }

        if (e.length !== undefined || typeof (e) == 'number') {

            let valor = e;

            if (typeof (e) == 'string' && capsLock == true) {
                valor = e.toUpperCase();
            }

            setFormData({
                ...formData,
                [campoSeleccionado]: valorActual + valor,
            })
        }
        else {
            if (e.target.name == "CodigoRol") {
                setFormData({
                    ...formData,
                    [e.target.name]: parseInt(e.target.value),
                })
                return;
            }
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            })
        }



    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const valoresForm = Object.values(formData);

        let vacio = false;

        valoresForm.forEach(element => {
            if (element === '' || element === 0) {
                vacio = true;
            }
        });

        if (vacio == true) {
            Swal.fire({
                title: 'Error',
                text: 'Por favor llene todos los campos',
                icon: 'error',
                customClass: {
                    confirmButton: 'ui orange button'
                },
                confirmButtonText: 'Regresar'
            })
            vacio = false;
            return;
        }

        const colaborador = {
            ...formData,
            PIN: parseInt(formData.PIN),
            CodigoEmpresa: valoresLogin.codigoEmpresa,
            CodigoTienda: valoresLogin.codigoTienda,
            Estado: "ALTA",
        }

        if (formData) {

            if (colaborador.Clave != colaborador.ClaveConfirm) {
                setErrorPassword(true);
                return;
            }

            if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,20}$/.test(colaborador.Clave) || !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/.test(colaborador.ClaveConfirm)) {
                setErrorPassword('formato');
                return;
            }

            try {
                //api.postUsuario(colaborador)
                colaboradorServices.colaborador.postUsuario(colaborador)
                    .then((response) => {
                        const mensajeYaExiste = "Ya existe"

                        if (response.data.MESSAGE.includes(mensajeYaExiste)) {
                            Swal.fire({
                                text: response.data.MESSAGE,
                                icon: 'error',
                                customClass: {
                                    confirmButton: 'ui orange button'
                                },
                                confirmButtonText: 'Regresar'
                            });
                            return;
                        } else {
                            Swal.fire({
                                text: 'Usuario registrado con éxito',
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'ui orange button'
                                },
                                confirmButtonText: 'Regresar'
                            });

                            setClaseTeclado('keyboard-hidden');
                            setClaseEspacioBlanco('espacio-blanco-off');

                            setFormData({
                                CodigoColaborador: '',
                                Nombres: '',
                                Apellidos: '',
                                PIN: '',
                                CodigoRol: 1,
                                Clave: '',
                                ClaveConfirm: '',
                            });

                            getColaboradores();
                        }


                    }, (error) => {
                        console.log(error);
                    });


                //getColaboradores();
            } catch (error) {
                console.error(error);
            }

        }
    };

    const activarTeclado = (focus, e, formato) => {
        if (focus === true) {
            setClaseEspacioBlanco('espacio-blanco-activado');
            setClaseTeclado('keyboard');
            setCampoSeleccionado(e.target.name);
            setFormatoSeleccionado(formato);

        }
        else {
            setClaseEspacioBlanco('espacio-blanco-off');
            setClaseTeclado('keyboard-hidden');

        }
    }

    const activarTecladoModal = (focus, e, formato) => {
        if (focus === true) {
            setClaseTecladoModal('keyboard');
            setCampoSeleccionado(e.target.name);
            setFormatoSeleccionado(formato);
            setClaseEspacioBlancoModal('espacio-blanco-modal-activado');
        }
        else {
            setClaseTecladoModal('keyboard-hidden');
            setClaseEspacioBlancoModal('espacio-blanco-modal-off');
        }
    }

    const handleChangeModificar = (e, formato) => {

        setErrorInput(undefined);

        let split = undefined;
        let tipo = undefined;
        let cantidadMaxima = undefined;

        let valorUsar = undefined;

        let valorActual = undefined;

        if (e.length !== undefined || typeof (e) == 'number') {

            valorActual = formModData[campoSeleccionado];
            //console.log(e);

            if (e == 'backspace') {

                if (typeof (valorActual === 'number')) {
                    valorActual = valorActual.toString();
                }
                const valorNuevo = valorActual.slice(0, -1);
                console.log(valorNuevo);
                setFormModData({
                    ...formModData,
                    [campoSeleccionado]: valorNuevo,
                })
                return;
            }

            if (e == 'caps') {
                setCapsLock(!capsLock);
                return;
            }

            const formatoActual = formatoSeleccionado;

            split = formatoActual.split('-');
            tipo = split[0];
            cantidadMaxima = split[1];



            valorUsar = e;
        }
        else {
            split = formato.split('-');
            tipo = split[0];
            cantidadMaxima = split[1];

            valorUsar = e.target.value;

            valorActual = e.target.value;
        }

        if (tipo == 'soloLetras') {
            if (cantidadMaxima == 'no') {
                if (!/^$|^[a-zA-Z ]+$/.test(valorUsar)) return;
            }
            else {
                const exp = new RegExp(`^$|^[a-zA-Z ]{0,${cantidadMaxima}}$`);
                if (!exp.test(valorUsar)) {
                    if (campoSeleccionado == 'Nombres') {
                        setErrorInput('nombreUsuario');
                    }
                    else if (campoSeleccionado == 'Apellidos') {
                        setErrorInput('apellidoUsuario');
                    }
                    return;
                }
                if (e.length !== undefined || typeof (e) == 'number') {
                    const expActual = new RegExp(`^$|^[a-zA-Z ]{0,${cantidadMaxima - 1}}$`);
                    if (!expActual.test(valorActual)) {
                        if (campoSeleccionado == 'Nombres') {
                            setErrorInput('nombreUsuario');
                        }
                        else if (campoSeleccionado == 'Apellidos') {
                            setErrorInput('apellidoUsuario');
                        }
                        return;
                    }
                }
            }
        }
        else if (tipo == 'numerico') {
            if (cantidadMaxima == 'no') {
                if (!/^$|^[0-9]+$/.test(valorUsar)) {
                    setErrorInput('pinUsuario');
                    return;
                }
            } else {
                const exp = new RegExp(`^[0-9]{0,${cantidadMaxima}}$`)
                if (!exp.test(valorUsar)) {
                    setErrorInput('pinUsuario');
                    return;
                }
                if (e.length !== undefined || typeof (e) == 'number') {
                    const expActual = new RegExp(`^[0-9]{0,${cantidadMaxima - 1}}$`)
                    if (!expActual.test(valorActual)) {
                        setErrorInput('pinUsuario');
                        return;
                    }
                }

            }
        }
        else if (tipo == 'alfaNumerico') {
            if (cantidadMaxima == 'no') {
                if (!/^$|^[a-zA-Z0-9]+$/.test(valorUsar)) {
                    setErrorInput('codUsuario');
                    return;
                }
            }
            else {
                const exp = new RegExp(`^[a-zA-Z0-9]{0,${cantidadMaxima}}$`);
                if (!exp.test(valorUsar)) {
                    setErrorInput('codUsuario');
                    return;
                }
                if (e.length !== undefined || typeof (e) == 'number') {
                    const expActual = new RegExp(`^[a-zA-Z0-9]{0,${cantidadMaxima}}$`);
                    if (!expActual.test(valorActual)) {
                        setErrorInput('codUsuario');
                        return;
                    }
                }
            }
        }
        else if (tipo == 'pwd') {
            const exp = new RegExp(`^[a-zA-Z0-9@$!%*#?&_]{0,${cantidadMaxima}}$`);
            if (!exp.test(valorUsar)) {
                if (campoSeleccionado == 'Clave') {
                    setErrorInput('claveUsuario');
                }
                else if (campoSeleccionado == 'ClaveConfirm') {
                    setErrorInput('claveConfUsuario');
                }
                return;
            }
        }

        if (e.length !== undefined || typeof (e) == 'number') {

            let valor = e;

            if (typeof (e) == 'string' && capsLock == true) {
                valor = e.toUpperCase();
            }

            setFormModData({
                ...formModData,
                [campoSeleccionado]: valorActual + valor,
            })
        }
        else {
            if (e.target.name == "CodigoRol" || e.target.name === "CodigoTienda") {
                setFormModData({
                    ...formModData,
                    [e.target.name]: parseInt(e.target.value),
                })
                return;
            }
            setFormModData({
                ...formModData,
                [e.target.name]: e.target.value,
            })
        }
    };

    const handleSubmitModificar = (e) => {
        e.preventDefault();

        const valoresForm = Object.values(formModData);
        const valoresSave = Object.values(modDataSave);

        let vacio = false;
        let contIgual = 0;

        let contraMantieneIgual = true;

        if (formModData.Clave !== modDataSave.Clave) {
            contraMantieneIgual = false;
        }

        for (let i = 0; i < valoresForm.length; i++) {
            if (valoresForm[i] == valoresSave[i]) {
                contIgual = contIgual + 1;
            }

            if (valoresForm[i] === '' || valoresForm[i] === 0) {
                vacio = true;
            }
        }

        if (contIgual == 8) {
            Swal.fire({
                title: 'Sin modificaciones',
                text: 'No se han realizado modificaciones al usuario',
                icon: 'info',
                customClass: {
                    confirmButton: 'ui orange button'
                },
                confirmButtonText: 'Regresar'
            })
            return;
        }

        if (vacio == true) {
            Swal.fire({
                title: 'Error',
                text: 'Por favor llene todos los campos',
                icon: 'error',
                customClass: {
                    confirmButton: 'ui orange button'
                },
                confirmButtonText: 'Regresar'
            })
            vacio = false;
            return;
        }

        const colaborador = {
            ...formModData,
            PIN: formModData.PIN,
            CodigoEmpresa: valoresLogin.codigoEmpresa,
            //CodigoTienda: valoresLogin.codigoTienda,
            Estado: "ALTA",
        }

        console.log(colaborador);

        if (contraMantieneIgual === false) {
            if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,20}$/.test(colaborador.Clave) || !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/.test(colaborador.ClaveConfirm)) {
                setErrorPassword('formato');
                return;
            }
        }

        // nuevoClave es la clave del nuevo usuario nuevaClaveTest

        if (colaborador.Clave != colaborador.ClaveConfirm) {
            setErrorPassword(true);
            return;
        }

        Swal.fire({
            //title: `¿Desea modificar ${colaborador.CodigoColaborador} | ${colaborador.Nombres} ${colaborador.Apellidos}?`,
            title: `¿Desea modificar ${modDataSave.CodigoColaborador} | ${modDataSave.Nombres} ${modDataSave.Apellidos}?`,
            showDenyButton: true,
            customClass: {
                confirmButton: 'ui orange button',
                denyButton: 'ui grey button',
            },
            confirmButtonText: 'Modificar',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    //api.modificarColaborador(colaborador)
                    colaboradorServices.colaborador.modificarColaborador(colaborador)
                        .then((response) => {
                            const mensajeYaExiste = "Ya existe"

                            if (response.data.MESSAGE.includes(mensajeYaExiste)) {
                                Swal.fire({
                                    text: response.data.MESSAGE,
                                    icon: 'error',
                                    customClass: {
                                        confirmButton: 'ui orange button'
                                    },
                                    confirmButtonText: 'Regresar'
                                });
                                return;
                            }
                            else if (response.data.SUCCESS === false) {
                                Swal.fire({
                                    text: response.data.MESSAGE,
                                    icon: 'error',
                                    customClass: {
                                        confirmButton: 'ui orange button'
                                    },
                                    confirmButtonText: 'Regresar'
                                });
                                return;
                            }
                            else {
                                setFormModificar(false);
                                Swal.fire({
                                    title: `Se ha modificado ${colaborador.CodigoColaborador} | ${colaborador.Nombres} ${colaborador.Apellidos} con éxito`,
                                    icon: 'success',
                                    customClass: {
                                        confirmButton: 'ui orange button',
                                    },
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        getColaboradores();
                                        //window.location.reload();
                                    }
                                })

                                setFormModData({
                                    CodigoColaborador: '',
                                    Nombres: '',
                                    Apellidos: '',
                                    PIN: 0,
                                    CodigoRol: 1,
                                    Clave: '',
                                    ClaveConfirm: '',
                                });
                                setOpenModal(false);
                            }


                        }, (error) => {
                            console.log(error);
                        });



                } catch (error) {
                    console.error(error);
                }
            } else if (result.isDenied) {
                Swal.fire({
                    title: 'No se han hecho modificaciones al usuario',
                    icon: 'info',
                    confirmButtonText: 'Confirmar',
                    customClass: {
                        confirmButton: 'ui orange button'
                    },
                })
                return;
            }
        })

    }

    const eliminarColaborador = (codigoColaborador) => {
        let colaborador = colaboradores.find(c => c.CodigoColaborador == codigoColaborador)


        Swal.fire({
            title: `¿Está seguro que desea deshabilitar ${codigoColaborador} | ${colaborador.Nombres} ${colaborador.Apellidos}?`,
            showDenyButton: true,
            customClass: {
                confirmButton: 'ui orange button',
                denyButton: 'ui grey button'
            },
            confirmButtonText: 'Deshabilitar',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    colaboradorServices.colaborador.borrarColaborador(codigoColaborador, valoresLogin.codigoEmpresa)
                        .then((response) => {
                            if (response.data.SUCCESS === false) {
                                Swal.fire({
                                    title: response.data.MESSAGE,
                                    icon: 'error',
                                    customClass: {
                                        confirmButton: 'ui orange button',
                                    },
                                    confirmButtonText: 'Regresar',
                                })
                            }
                            else {
                                Swal.fire({
                                    title: `${codigoColaborador} | ${colaborador.Nombres} ${colaborador.Apellidos} deshabilitado con éxito`,
                                    confirmButtonText: 'ACEPTAR',
                                    customClass: {
                                        confirmButton: 'ui orange button'
                                    },
                                    allowOutsideClick: false,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        getColaboradores();
                                        //window.location.reload();
                                    }
                                });
                            }
                        })

                } catch (error) {
                    console.error(error);
                }
            } else if (result.isDenied) {
                Swal.fire({
                    title: 'No se ha deshabilitado el usuario',
                    icon: 'info',
                    confirmButtonText: 'Confirmar',
                    customClass: {
                        confirmButton: 'ui orange button'
                    },
                });
                return;
            }
        })
    }

    const modificarColaborador = (colaborador) => {
        setFormModificar(true);
        onOpenModal(0)
        //executeScroll();
        setModDataSave({
            CodigoColaborador: colaborador.CodigoColaborador,
            Nombres: colaborador.Nombres,
            Apellidos: colaborador.Apellidos,
            PIN: colaborador.PIN,
            CodigoRol: colaborador.CodigoRol,
            Clave: colaborador.Clave,
            ClaveConfirm: colaborador.Clave,
            CodigoTienda: valoresLogin.codigoTienda,
        })
        setFormModData({
            CodigoColaborador: colaborador.CodigoColaborador,
            Nombres: colaborador.Nombres,
            Apellidos: colaborador.Apellidos,
            PIN: colaborador.PIN,
            CodigoRol: colaborador.CodigoRol,
            Clave: colaborador.Clave,
            ClaveConfirm: colaborador.Clave,
            CodigoTienda: valoresLogin.codigoTienda,
        })

        //console.log(formModData);

    }

    const cancelButton = () => {
        setFormModificar(false);
        setOpenModal(false);
    }

    const onOpenModal = (modalOption) => {
        setOpenModal(prevState => !prevState);
        setNumberModal(modalOption);
    }

    const onCancelModal = () => {
        setOpenModal(false);
        //setFecha(undefined);
    };

    if (loading) return <Loader />

    if (error !== undefined) return <PageError message={error} source={'registro'} />

    return (
        <RegistroUI
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            handleSubmitModificar={handleSubmitModificar}
            handleChangeModificar={handleChangeModificar}
            formData={formData}
            formModData={formModData}
            roles={roles}
            tiendas={tiendas}
            colaboradores={colaboradores}
            colaboradoresDeshabilitados={colaboradoresDeshabilitados}

            formModificar={formModificar}
            eliminarColaborador={eliminarColaborador}
            modificarColaborador={modificarColaborador}
            cancelButton={cancelButton}
            errorInput={errorInput}
            myRef={myRef}
            onOpenModal={onOpenModal}
            openModal={openModal}
            onCancelModal={onCancelModal}
            numberModal={numberModal}

            claseTeclado={claseTeclado}
            claseTecladoModal={claseTecladoModal}

            activarTeclado={activarTeclado}
            activarTecladoModal={activarTecladoModal}

            capsLock={capsLock}

            claseEspacioBlanco={claseEspacioBlanco}
            claseEspacioBlancoModal={claseEspacioBlancoModal}

            codigoColaborador={codColab}
        />
    );

}