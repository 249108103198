
const VentaCanalTabla = ({ children }) => {
    return (
        <table className="ui definition small celled table">
            <thead>
                <tr>
                    <th>Canal</th>
                    <th>Cant. Transacciones</th>
                    <th>PAGO</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                {children}
            </tbody>
        </table>
    )
}

export { VentaCanalTabla }