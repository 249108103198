import React from 'react';
import './style.css';
import LogoCmi from './logo_cmi_transparente.png';
import GifCarga from './animacion_carga_transparente.gif';

const LoaderUI = () => {
  return(
    <div className="center-loader">
      <img src={GifCarga} width="200" height="200" className="gifLoader" alt="git-logo"></img>
      <div className="lds-heart"><img src={LogoCmi} width="100" height="100" alt="cmi-logo"></img></div>     
    </div>
  );
};

export { LoaderUI };