import React from "react";
import "./style.css";

const MiniLoader = () => {
  return (
    <div className="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export { MiniLoader };
