import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { LoginUi } from './LoginUi';
import { loginServices } from '../../../api/services/loginServices';
import { getFechaHoy, eliminarSesionUsuario, serverAxios } from '../../../utils/Common';
import Swal from 'sweetalert2';


import './style.css';
import { anulacionServices } from '../../../api/services/anulacionServices';

let DATOS_LOGIN = {};

const Login = () => {
  const [user, saveUser] = useLocalStorage('LOGIN_BETA');
  const [dataLogin, setDataLogin] = useState(DATOS_LOGIN);
  const [pin, setPin] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(undefined);

  const navigate = useNavigate();

  const inputHandler = (valor) => {
    if (typeof (valor) === 'number') {
      setPin(pin + valor);
    } else if (valor === '!#') {
      setPin(pin.slice(0, -1));
    } else if (valor === '!%') {
      setPin('');
    } else {
      const valorEvento = valor.target.value;
      setPin(valorEvento);
    }
  }

  const sendPostRequestPIN = async () => {
    try {
      window.$('.panelLogin').transition('fly right');
      setLoader(true);
      let loginPin = {
        codigoEmpresa: dataLogin.CodigoEmpresa,
        codigoTienda: dataLogin.CodigoTienda,
        codigoCaja: dataLogin.CodigoCaja, // puede ir nulo
        codigoColaborador: "",
        Clave: pin,
        PIN: pin,
        TipoLogin: "2",
      }

      //console.log(loginPin);

      const nuevoAxios = serverAxios('central');
      const resp = await loginServices.login.iniciarPorPin(loginPin, nuevoAxios);

      const CODIGO = resp.data.CODE;

      console.log(resp.data)

      //const empresa = resp.data.MESSAGE.Empresa.CodigoEmpresa;
      //const restaurante = resp.data.MESSAGE.CajaFiscal.CodigoTienda;
      //const caja = resp.data.MESSAGE.CajaFiscal.CodigoCaja;

      if (CODIGO == 1000) {
        console.log(resp.data.MESSAGE);

        /*const axios = serverAxios('central');
        const correlativoCentralResponse = await loginServices.login.getCorrelativo(empresa, restaurante, caja, axios);
        const nuevoAxios = serverAxios('local');
        const correlativoLocalReponse = await loginServices.login.getCorrelativo(empresa, restaurante, caja, nuevoAxios);

        const correlativoCentral = correlativoCentralResponse.data.MESSAGE.CorrelativoActual;
        const correlativoLocal = correlativoLocalReponse.data.MESSAGE.CorrelativoActual;*/

        //let nuevoCorrelativoCentral = correlativoCentral;
        // Si el correlativo está desactualizado
        /*if (correlativoLocal > correlativoCentral) {
          // Consumir un servicio que actualice el correlativo del servidor central
          // Esto servicio siempre debe apuntar a server central
          loginServices.login.actualizarCorrelativo(empresa, restaurante, caja, correlativoLocal, axios)
            .then(result => {
              if (result.data.SUCCESS === false) {
                // Indicar que no se han actualizado los correlativos
                Swal.fire({
                  title: 'Correlativo desactualizado',
                  text: 'El correlativo no está actualizado a su valor más reciente. Es posible que no se logre facturar algunas órdenes.',
                  icon: 'warning'
                })
              }
              else {
                console.log('Correlativo central desactualizado');
                //console.log(result.data.MESSAGE);
                //nuevoCorrelativoCentral = result.data.MESSAGE;
                //console.log(nuevoCorrelativoCentral);

                let nuevoUser = {
                  ...resp.data.MESSAGE,
                  CorrelativoFiscal: {
                    ...resp.data.MESSAGE.CorrelativoFiscal,
                    CorrelativoActual: result.data.MESSAGE,
                  }
                };

                //nuevoUser.CorrelativoFiscal.CorrelativoActual = correlativoCentral;
                console.log(nuevoUser);

                //saveUser({ ...resp.data.MESSAGE, FechaLogin: getFechaHoy() });
                saveUser({ ...nuevoUser, FechaLogin: getFechaHoy() });
                window.dispatchEvent(new Event("cambioStorage"));
                // guardamos la data proveniente de login
                setLoader(false);
                navigate("/menu");
              }
              // Si hubo éxito, no se debe indicar nada. 
            })
        }
        else {*/
        console.log('Correlativo correcto');
        /*console.log(nuevoCorrelativoCentral);

        let nuevoUser = {
          ...resp.data.MESSAGE,
          CorrelativoFiscal: {
            ...resp.data.MESSAGE.CorrelativoFiscal,
            CorrelativoActual: nuevoCorrelativoCentral,
          }
        };*/

        //nuevoUser.CorrelativoFiscal.CorrelativoActual = correlativoCentral;
        //console.log(nuevoUser);

        saveUser({ ...resp.data.MESSAGE, FechaLogin: getFechaHoy() });

        const nuevoUsuario = {
          ...resp.data.MESSAGE,
        }

        // Creación de objetos necesarios de localStorage para nuevos restaurantes
        const ordenLocalStorage = JSON.parse(localStorage.getItem('ORDENES'));
        // Hay que conseguir las órdenes del turno actual para ingresarlas, no se puede garantizar que esto sea 
        // igual a []
        if (ordenLocalStorage === null) {
          const respOrdenes = await anulacionServices.orden.getOrdenesPorTurno(nuevoUsuario.CajaFiscal.CodigoEmpresa, nuevoUsuario.CajaFiscal.CodigoCaja, nuevoUsuario.CajaFiscal.CodigoTienda, nuevoUsuario.Turno.CodigoTurno)
          if (respOrdenes.data.SUCCESS === false) {
            //console.log(respOrdenes.data.MESSAGE);
            if (respOrdenes.data.CODE === 1002) {
              localStorage.setItem('ORDENES', JSON.stringify([]));
            }
            else {
              console.log(respOrdenes.data.MESSAGE);
            }
          }
          else {
            const ordenesTurno = respOrdenes.data.MESSAGE;
            // En el futuro, asignar propiedad server a todas las ordenes
            localStorage.setItem('ORDENES', JSON.stringify(ordenesTurno));
          }

        }

        const contingenciaLocalStorage = JSON.parse(localStorage.getItem('CONTINGENCIA_LOCAL'));
        if (contingenciaLocalStorage === null) {
          localStorage.setItem('CONTINGENCIA_LOCAL', JSON.stringify({
            estadoCaja: 'ALTA',
            conteoContingencia: 0,
            cajaFalloTS: null
          }))
        }

        //saveUser({ ...nuevoUser, FechaLogin: getFechaHoy() });
        window.dispatchEvent(new Event("cambioStorage"));
        // guardamos la data proveniente de login
        setLoader(false);
        navigate("/menu");
        //}
      } else {
        console.log('error')
        if (CODIGO == 1004) {
          setOpenModal(true);
        } else {
          console.log(resp);
          setLoader(false);
          Swal.fire("Error", `${resp.data.MESSAGE}`, "error")
          return;
        }
        //window.$('.panelLogin').transition('fly right');
      }
    } catch (err) {
      console.error(err);
      Swal.fire("Error", `${err.message}`, "error");
      /*try {
        // No se pudo hacer login central, hay que hacerlo local
        window.$('.panelLogin').transition('fly right');
        let loginPin = {
          codigoEmpresa: dataLogin.CodigoEmpresa,
          codigoTienda: dataLogin.CodigoTienda,
          codigoCaja: dataLogin.CodigoCaja, // puede ir nulo
          codigoColaborador: "",
          Clave: pin,
          PIN: pin,
          TipoLogin: "2",
        }

        //console.log(loginPin);

        const nuevoAxios = serverAxios('local');
        const resp = await loginServices.login.iniciarPorPin(loginPin, nuevoAxios);

        const CODIGO = resp.data.CODE;

        console.log(resp.data)

        if (CODIGO == 1000) {
          console.log(resp.data.MESSAGE);
          saveUser({ ...resp.data.MESSAGE, FechaLogin: getFechaHoy() });
          window.dispatchEvent(new Event("cambioStorage"));
          // guardamos la data proveniente de login
          navigate("/menu");
        } else {
          if (CODIGO == 1004) {
            setOpenModal(true);
          } else {
            console.log(resp);
            Swal.fire("Error", `${resp.data.MESSAGE}`, "error").then(() => { window.$('.panelLogin').transition('fly right') });
          }
          //window.$('.panelLogin').transition('fly right');
        }
      } catch (error) {
        console.log(err);
        Swal.fire("Error", `${err.message}`, "error");
      }*/

    }
  };

  const fetchDataLogin = async () => {
    try {
      const nuevoAxios = serverAxios('central');
      let resDatosLogin = await loginServices.login.indentificarCaja(nuevoAxios);
      console.log(resDatosLogin);
      if (resDatosLogin.data.CODE === 1000) {
        DATOS_LOGIN = resDatosLogin.data.MESSAGE;

        setDataLogin(DATOS_LOGIN);
      } else {
        setError(resDatosLogin.data.MESSAGE);
        //console.log('error, no se obtuvieron datos del login');
      }
      setLoader(false);
    } catch (err) {
      try {
        // Cuidado con las IPs
        const nuevoAxios = serverAxios('local');
        let resDatosLogin = await loginServices.login.indentificarCaja(nuevoAxios);
        console.log(resDatosLogin);
        if (resDatosLogin.data.CODE === 1000) {
          DATOS_LOGIN = resDatosLogin.data.MESSAGE;

          setDataLogin(DATOS_LOGIN);
        } else {
          setError(resDatosLogin.data.MESSAGE);
          //console.log('error, no se obtuvieron datos del login');
        }
        setLoader(false);
      } catch (error) {
        setLoader(false);
        setError(error.message);
      }
    }
  }

  const empezarTurno = async (valorInicio = null) => {
    try {
      const resTurno = await loginServices.login.iniciarTurno(valorInicio);
      console.log(resTurno.data);
      if (resTurno.data.CODE === 1000) {
        console.log("se inicio el turno con exito");

        sendPostRequestPIN();

        setOpenModal(false);
      } else {
        Swal.fire({
          title: resTurno.data.MESSAGE,
          icon: 'warning',
          confirmButtonText: 'Entendido',
          customClass: {
            confirmButton: 'ui orange button',
          }
        });

        setOpenModal(false);
      }
    } catch (err) {
      Swal.fire("Error", `${err.message}`, "error");
    }
  }


  useEffect(() => {

    fetchDataLogin();
    eliminarSesionUsuario();
    window.dispatchEvent(new Event("cerrarSesion"));
  }, []);

  useEffect(() => {
    window.$(".ui.dropdown").dropdown();

    window.$(".menu .item").tab();

    window.$(".ui.checkbox").checkbox();
    window.$("#btnModal").on("click", function () {
      if (document.getElementById("flip-card-inner").style.transform == "") {
        document.getElementById("flip-card-inner").style.transform =
          "rotateY(180deg)";
      } else {
        document.getElementById("flip-card-inner").style.transform = "";
      }
    });
    window.$('.panelLogin').transition('horizontal flip in');
  }, []);

  return (
    <>
      <LoginUi
        inputHandler={inputHandler}
        sendPostRequestPIN={sendPostRequestPIN}
        dataLogin={dataLogin}
        openModal={openModal}
        setOpenModal={setOpenModal}
        empezarTurno={empezarTurno}
        pin={pin}
        setPin={setPin}
        loader={loader}
        error={error}
      />
    </>
  );

};

export { Login };