import React from "react"
import { ModalDetalles } from "./ModalDetalles"
import { Modal } from "../Modal"
import { ModalMiniLoader } from "./ModalMiniLoader"

import { Registro } from "./Registro"
import { Tabla } from "./Tabla"

const VentaPorDescuentoUI = ({
    reporte,
    onChangeTurno,
    turnos,
    turnoActual,
    pais,
    verDetallesDescuento,
    turnoSeleccionado,
    correlativoTurno,
    descuentoSeleccionado,
    detalleDescuento,
    openModal,
    numberModal,
    onCancelModal,
    volverVentaCanal
}) => {
    return (
        <>
            <div className="ui raised text container segment segment-color">
                <div className="ui grid">
                    <div className="four wide column">
                        Buscar por turno:
                        <select onChange={(e) => onChangeTurno(e)} name="CodigoTurno" className="ui dropdown">
                            {
                                turnos.map((item) => {
                                    return (
                                        <React.Fragment key={item.CodigoTurno}>
                                            {
                                                (item.CodigoTurno == turnoActual)
                                                    ? <option value={item.CodigoTurno}>Actual ({item.CorrelativoTurno} - {item.DiaSemana})</option>
                                                    : <option value={item.CodigoTurno}>{item.CorrelativoTurno} - {item.DiaSemana}</option>
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="eight wide column">
                        <h1>Reporte de Ventas x Descuento</h1>
                    </div>
                    <div className="four wide column">
                        <button onClick={volverVentaCanal} className="fluid ui inverted orange button">Venta por Canal</button>
                    </div>
                </div>

                <Tabla pais={pais}>
                    <Registro reporte={reporte} detalle={verDetallesDescuento} />
                </Tabla>

                {!!openModal && (
                    <Modal>

                        {numberModal === 0 ? (
                            <ModalDetalles
                                pais={pais}
                                descuentoSeleccionado={descuentoSeleccionado}
                                turnoSeleccionado={correlativoTurno}
                                onCancelModal={onCancelModal}
                                detalleDescuento={detalleDescuento}
                            />
                        ) : numberModal === 1 ? (
                            <ModalMiniLoader />
                        ) : null

                        }
                    </Modal>
                )}

            </div>

        </>
    )
}

export { VentaPorDescuentoUI }