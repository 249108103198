import React from "react";
import { useContext } from "react";
import { ProductosContext } from "../../ProductosContext";

const DetalleMenu = () => {

    const {
        state,
        agregarMenu,
        cambiarEstadoMenu,
    } = useContext(ProductosContext);

    let claseBotonAgregar = 'ui positive fluid icon labeled button';
    let textoBotonAgregar = 'Agregar';

    let productoSpan = state.menus.menuDetalle.ProductoMenu.length;
    let restoProd = state.menus.menuDetalle.ProductoMenu.slice(1);

    if (state.productoDetalles.menuProducto.indexOf(state.menus.menuDetalle) !== -1) {
        claseBotonAgregar = 'ui disabled positive fluid icon labeled button';
        textoBotonAgregar = 'Ya agregado'
    }
    else {
        claseBotonAgregar = 'ui positive fluid icon labeled button';
        textoBotonAgregar = 'Agregar'
    }

    return (
        <>
            <div className="tabla-op">
                <table className="ui small compact celled table">
                    <tbody>
                        <tr>
                            <td>Código Menú</td>
                            <td>{state.menus.menuDetalle.CodigoMenu}</td>
                        </tr>
                        <tr>
                            <td>Código Menú Padre</td>
                            <td>{state.menus.menuDetalle.CodigoMenuPadre}</td>
                        </tr>
                        <tr>
                            <td>Descripción</td>
                            <td>{state.menus.menuDetalle.Descripcion}</td>
                        </tr>
                        <tr>
                            <td>Posición</td>
                            <td>{state.menus.menuDetalle.Posicion}</td>
                        </tr>
                        <tr>
                            <td rowSpan={productoSpan}>Productos</td>
                            {
                                (state.menus.menuDetalle.ProductoMenu.length > 0)
                                    ? <td>{state.menus.menuDetalle.ProductoMenu[0].CodigoProducto}</td>
                                    : <td> No hay productos asociados a este menú </td>
                            }

                        </tr>
                        {
                            restoProd.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item.CodigoProducto}</td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
            </div>
            <div className="ui two column grid">
                <div className="column pad-botones">
                    <button onClick={() => cambiarEstadoMenu('buscando')} className='ui grey fluid icon labeled button'>
                        <i className="close icon"></i>
                        Cancelar
                    </button>
                </div>
                <div className="column pad-botones">
                    <button onClick={() => agregarMenu(state.menus.menuDetalle)} className={claseBotonAgregar}>
                        <i className="plus icon"></i>
                        {textoBotonAgregar}
                    </button>
                </div>
            </div>

        </>
    )
}

export { DetalleMenu }