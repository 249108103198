import { useRef } from 'react';

import './style.css';


const CierreForm = ({
  handleChange,
  handleSubmit,

  remesasApi,
  formExtras,

  cuentaBanco,

  formData,

  setMonedaCambio,
  setMonedaCredito,

  activarTeclado,
}) => {

  const refAgregar = useRef();

  let moneda = ''

  if (formExtras.pais === 'GT') {
    moneda = 'Q.'
  }
  else if (formExtras.pais === 'SV' || formExtras.pais === 'MX') {
    moneda = '$.'
  }
  else if (formExtras.pais === 'CR') {
    moneda = '₡.'
  }

  return (
    <form className={formExtras.estadoForm} onSubmit={handleSubmit}>
      <div className="two fields">
        <div className="field">
          <select value={formData.CodigoBanco} onChange={(e) => handleChange(e, 'no')} name="CodigoBanco" className="drop-down">
            <option value="" className='dropdown-option'>Ninguno</option>
            {
              remesasApi.bancos.map((item) => {
                return (
                  <option key={item.CodigoBanco} className='dropdown-option' value={item.CodigoBanco}>{item.Descripcion}</option>
                )
              })
            }
          </select>

        </div>

        <div className="field">
          <div className="ui labeled fluid input">
            <div className="ui label">
              Cuenta
            </div>
            <input style={{ disabled: true }} onChange={() => console.log('cuenta')} type="text" value={cuentaBanco} />
          </div>
        </div>
      </div>

      <div className="three fields">
        <div className="field">
          <label>Valor de remesa ({moneda})</label>
          <input onFocus={(e) => { activarTeclado(true, e, 'decimal-7-2'); refAgregar.current.scrollIntoView() }} onChange={(e) => handleChange(e, 'decimal-7-2')} value={formData.ValorRemesa} name="ValorRemesa" type="text" min="0" step="0.01"></input>
          {
            (formExtras.errorInput == 'valRem')
              ? <span className='errorInput'>Máximo 7 cifras y 2 decimales</span>
              : null
          }
        </div>
        <div className="field">
          <label>Número de remesa</label>
          <input onFocus={(e) => { activarTeclado(true, e, 'numerico-6'); refAgregar.current.scrollIntoView() }} onChange={(e) => handleChange(e, 'numerico-6')} value={formData.CodigoRemesa} name="CodigoRemesa" type="text" id="numRem"></input>
          {
            (formExtras.errorInput == 'numRem')
              ? <span className='errorInput'>Solo se pueden insertar números en este campo</span>
              : null
          }

        </div>
        {
          (formExtras.pais == 'SV')
            ? (
              <div className="two fields">
                <div className="field">
                  <label>Moneda de cambio</label>
                  <label className="switch">
                    <input onChange={() => setMonedaCambio(!formExtras.monedaCambio)} checked={formExtras.monedaCambio} type="checkbox" />
                    <span className="slider round cambio"></span>
                  </label>

                </div>
                <div className="field">
                  <label>Moneda de crédito</label>
                  <label className="switch">
                    <input onChange={() => setMonedaCredito(!formExtras.monedaCredito)} checked={formExtras.monedaCredito} type="checkbox" />
                    <span className="slider round credito"></span>
                  </label>


                </div>
              </div>
            )
            : (
              <div className="field">
                <label>Moneda de crédito</label>
                <label className="switch">
                  <input onChange={() => setMonedaCredito(!formExtras.monedaCredito)} checked={formExtras.monedaCredito} type="checkbox" />
                  <span className="slider round credito"></span>
                </label>


              </div>
            )
        }
      </div>
      <div className="field">
        <div className="fields">
          <div className="twelve wide field">
            <div className="ui segment">
              <div className="ui two column grid saldoText">
                <div className=" column ">
                  Saldo pendiente ({moneda}): {(Math.round(remesasApi.saldoPendiente * 100) / 100).toFixed(2)}
                </div>
                <div className="column ">
                  Saldo a favor ({moneda}): {(Math.round(remesasApi.saldoAFavor * 100) / 100).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          <div ref={refAgregar} className="four wide field">
            <button onClick={() => activarTeclado(false)} className="ui left labeled green fluid boton icon button">
              Agregar
              <i className="plus circle icon"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export { CierreForm };