import React, { useEffect } from 'react';
import './style.css';
import Swal from 'sweetalert2';

const ModalDescuento = ({ state, dispatch, actions, addProductoDetalle, reiniciarDescuento, calcularDescuento }) => {

  let gruposDescuento = []

  state.response.descuentos.map(item => (
    gruposDescuento.map(e => {
      return e.nombreGrupoDescuento
    }).indexOf(item.GrupoDescuento) < 0 ?
      gruposDescuento.push({
        nombreGrupoDescuento: item.GrupoDescuento,
        listaDescuentos: state.response.descuentos.filter(itemDescuento => itemDescuento.GrupoDescuento === item.GrupoDescuento)
      }) :
      null
  ));

  const onCancel = () => {
    dispatch({ type: actions.CERRAR_MODAL });
  }

  const onCancelDescuento = () => {
    reiniciarDescuento();
    dispatch({ type: actions.CERRAR_MODAL });
  }

  const onSelectDescuento = (descuento) => {
    console.log(descuento);

    let descuentoSeleccionado = JSON.parse(descuento.Operador);
    console.log(descuentoSeleccionado);
    let listaParametros = descuentoSeleccionado.arguments;
    console.log(listaParametros);
    let cantidadParametros = 0;

    if (!!listaParametros.includes(',')) {
      cantidadParametros = listaParametros.split(',').length;
    } else if (listaParametros !== "") {
      cantidadParametros = 1;
    } else if (listaParametros === "") {
      cantidadParametros = 0;
    }

    let descuentoProvisional = {
      ...state.descuentoFuncion,
      funcion: false,
      parametros: cantidadParametros,
      objetoFuncion: descuento.Operador,
      referencia: descuento.RequiereReferencia,
      descripcion: descuento.DescripcionPantalla,
      codigoDescuento: descuento.CodigoDescuento
    }

    console.log(descuentoProvisional);

    // Validar total de la orden antes de agregarlo
    let totalOrder = 0;
    if (state.orderItems.length > 0) {
      let auxListaSinDescuento = state.orderItems.filter((item) => item.CodigoPlu !== "DESCUENTO");
      totalOrder = auxListaSinDescuento.reduce((p, { Precio, Cantidad }) => p + Precio * Cantidad, 0);
    } else {
      totalOrder = 0;
    }

    /*const calculoDescuento = (calcularDescuento(null, descuentoProvisional) * -1).toFixed(2);
    //console.log(totalOrder);
    const totalOrden = parseFloat(totalOrder);
    console.log(calculoDescuento);
    console.log(typeof (calcularDescuento));
    const valorDescuento = parseFloat(calculoDescuento);
    console.log(totalOrden);
    console.log(valorDescuento);

    console.log(typeof (totalOrden));
    console.log(typeof (valorDescuento));

    if (totalOrden < calculoDescuento) {
      console.log('no se puede');
      Swal.fire({
        title: 'No se puede aplicar descuento'
      })
      return;
    }*/

    dispatch({ type: actions.MODIFICAR_DESCUENTO, payload: descuentoProvisional });
    if (!!descuentoSeleccionado.tipo.includes('DIGITAR')) {
      // abrir modal para seleccionar la cantidad en el descuento

      dispatch({ type: actions.NUMERO_MODAL, payload: 9 });
    } else {
      if (descuentoProvisional.referencia === 'True') {
        // escribir referencia en modal

        dispatch({ type: actions.NUMERO_MODAL, payload: 10 });
      } else {
        descuentoProvisional = {
          ...descuentoProvisional,
          funcion: true
        }
        const descuento = (calcularDescuento(null, descuentoProvisional) * -1).toFixed(2);
        const valorDescuento = parseFloat(descuento);
        const valorOrden = parseFloat(totalOrder);
        console.log(valorDescuento);
        console.log(valorOrden);
        console.log(descuento);

        if (valorOrden < valorDescuento) {
          Swal.fire({
            title: 'No se puede agregar el descuento',
            text: 'El valor del descuento es mayor al total de la orden'
          })
          return;
        }

        dispatch({ type: actions.MODIFICAR_ORDEN, payload: { ...state.orderOptions, totalDescuento: descuento } });
        addProductoDetalle({ CodigoPlu: 'DESCUENTO', descripcion: descuentoProvisional.descripcion, Precio: descuento });
        dispatch({ type: actions.MODIFICAR_DESCUENTO, payload: descuentoProvisional });
        dispatch({ type: actions.CERRAR_MODAL });
      }
    }
  };

  useEffect(() => {
    window.$('.tabular.menu .item').tab();
  }, []);


  return (
    <div className="ui tiny longer modal md-disp">
      <div className="header">
        <h1>Seleccione el descuento</h1>
      </div>
      <div className="scrolling content contenedorScroll" tab>
        <div className="ui top attached tabular menu">
          {gruposDescuento.map((item, index) => (
            <a
              className={index > 0 ? "item" : "active item"}
              key={item.nombreGrupoDescuento}
              data-tab={item.nombreGrupoDescuento}
            >
              {item.nombreGrupoDescuento}
            </a>
          ))}
        </div>

        {gruposDescuento.map((item, index) => (
          <div
            className={index > 0 ? "ui bottom attached tab segment" : "ui bottom attached active tab segment"}
            key={item.nombreGrupoDescuento + index}
            data-tab={item.nombreGrupoDescuento}
          >
            <div className="ui one column center aligned grid">
              {item.listaDescuentos.map((itemDescuento) => (
                <div className="column element-height">
                  <button
                    className="ui fluid inverted orange button btn-modal-height"
                    key={itemDescuento.CodigoDescuento + '' + itemDescuento.DescripcionPantalla}
                    style={{ marginTop: "5px", marginBottom: "5px" }}
                    onClick={() => onSelectDescuento(itemDescuento)}
                  >
                    {itemDescuento.DescripcionPantalla}
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="actions">
        {!!state.descuentoFuncion.funcion &&
          <button
            type="button"
            onClick={onCancelDescuento}
            className="ui large primary right labeled icon button"
          >
            Remover descuento
            <i className="x icon"></i>
          </button>
        }
        <button
          type="button"
          onClick={onCancel}
          className="ui large negative right labeled icon button"
        >
          Cancelar
          <i className="x icon"></i>
        </button>
      </div>
    </div>
  );
};

export { ModalDescuento };