import React from 'react'
import { FormCrearPlu } from '../FormCrearPlu'
import { FormCrearPluProducto } from '../FormCrearPluProducto/index'
import './style.css'

const TabPluProducto = () => {

    return (
        <div className="ui center aligned two column grid">
            <div className="column">
                <FormCrearPlu />
            </div>
            <div className="column">
                <FormCrearPluProducto />

            </div>
        </div>
    )
}

export { TabPluProducto }