import './style.css'

const Tabla = ({ children }) => {
    return (
        <div className="tabla-comp">
            <table className="ui small compact celled table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Complemento</th>
                        <th>Posición</th>
                        <th>Cantidad</th>
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    )
}

export { Tabla }