import React, { useState } from "react";

const useTecladoAlfanumerico = (camposFormulario) => {
  const [formData, setFormData] = useState(camposFormulario);
  const [claseTeclado, setClaseTeclado] = useState("keyboard-hidden");
  const [claseEspacioBlanco, setClaseEspacioBlanco] =
    useState("espacio-blanco-off");
  const [campoSeleccionado, setCampoSeleccionado] = useState(undefined);
  const [formatoSeleccionado, setFormatoSeleccionado] = useState(undefined);
  const [capsLock, setCapsLock] = useState(false);

  const inputHandler = (e, textoFormato) => {
    let valorTecladoMarcado = "";

    if (e.length !== undefined || typeof e === "number") {
      let inputValorActual = formData[campoSeleccionado];
      //console.log(inputValorActual);
      if (e === "backspace") {
        valorTecladoMarcado = inputValorActual.slice(0, -1);
      } else if (e === "caps") {
        setCapsLock(!capsLock);
        valorTecladoMarcado = inputValorActual;
      } else {
        //console.log(valorTecladoMarcado, e);
        valorTecladoMarcado = inputValorActual + "" + e + "";
      }
    } else {
      valorTecladoMarcado = e.target.value;
    }
    // EVALUAR LA EXPRESIÓN

    setFormData({ ...formData, [campoSeleccionado]: valorTecladoMarcado });
  };

  const activarTeclado = (mostrar, e, textoFormato) => {
    if (mostrar === true) {
      setClaseTeclado("keyboard");
      setCampoSeleccionado(e.target.name);
      setFormatoSeleccionado(textoFormato);
      setClaseEspacioBlanco("espacio-blanco-activado");
    } else {
      setClaseTeclado("keyboard-hidden");
      setClaseEspacioBlanco("espacio-blanco-off");
    }
  };

  return {
    claseTeclado,
    claseEspacioBlanco,
    formData,
    capsLock,
    activarTeclado,
    inputHandler,
    setFormData
  };
};

export { useTecladoAlfanumerico };
