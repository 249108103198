// MODAL 16
import React, { useState } from "react";
import { posServices } from "../../../api/services/posServices";
import { expresionesRegulares } from "../../../constants/ExpresionesRegulares";
import { TecladoCompleto } from "../../TecladoCompleto";
import { useTecladoAlfanumerico } from "../../../hooks/useTecladoNumerico";

let rgxNit = expresionesRegulares.verificadorNit;

const ModalNitDuiSv = ({state, dispatch, actions}) => {
  const {
    claseTeclado,
    claseEspacioBlanco,
    formData,
    capsLock,
    activarTeclado,
    inputHandler,
    setFormData
  } = useTecladoAlfanumerico({duiNitCliente: '', nombreCliente: ''});

  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState(undefined);
  const [error, setError] = useState(undefined);
  

  const buscarCliente = async (e) => {
    try {
      setLoading(true);
      setError(undefined);
      const clienteResponse = await posServices.pos.getClienteByDocumentoPersonal(formData.duiNitCliente);
      //console.log(clienteResponse);
      if(clienteResponse.data.CODE === 1000) {
        let clienteEncontrado = clienteResponse.data.MESSAGE[0];
        setFormData({...formData, nombreCliente: clienteEncontrado.Nombre});
        setCliente(clienteEncontrado);
      } else {
        setError(clienteResponse.data.MESSAGE);
      }
      setLoading(false);
    } catch(error) {
      console.error(error);
    }
  }


  const onAcept = () => {
    dispatch({ type: actions.MODIFICAR_DATO_FISCAL, payload: {
      ...state.datosFiscales,
      cliente:
        cliente !== undefined
          ? cliente
          : {
              DocumentoPersonal: formData.duiNitCliente,
              Nombre: formData.nombreCliente,
            },
    }});
    if(state.orderOptions.formaDePago === 'EFECTIVO') {
      dispatch({ type: actions.NUMERO_MODAL, payload: 8 });
    }
  };

  const onCancel = () => {
    dispatch({ type: actions.CERRAR_MODAL });
  }

  return (
    <div className="ui  modal md-disp">
      <div className="header">Datos fiscales</div>

      <div className="scrolling content">
        <div className="ui grid">
          <div className="row">
            <div className="sixteen wide column">
              {error !== undefined && <div class="ui red message">{error}</div>}
              <form className="ui form">
                <div className="ui fluid action input">
                  <input
                    onChange={(e) => inputHandler(e, "alfaNumerico-no")}
                    value={formData.duiNitCliente}
                    type="text"
                    onFocus={(e) => activarTeclado(true, e, "alfaNumerico-no")}
                    name="duiNitCliente"
                    placeholder="DUI/NIT"
                    autoFocus
                  />
                  <button
                    type="button"
                    className="ui button"
                    onClick={() => buscarCliente()}
                  >
                    Buscar
                  </button>
                </div>
                <div className="ui fluid input mt-10">
                  <input
                    onChange={(e) => inputHandler(e, "alfaNumerico-no")}
                    value={formData.nombreCliente}
                    type="text"
                    name="nombreCliente"
                    placeholder="NOMBRE CLIENTE"
                    onFocus={(e) => activarTeclado(true, e, "alfaNumerico-no")}
                  />
                </div>
              </form>

              {claseTeclado === "keyboard" &&
                <div className="mt-50">
                  <div className={claseEspacioBlanco}></div>
                  <TecladoCompleto
                    claseActivar={claseTeclado}
                    handleChange={inputHandler}
                    activarTeclado={activarTeclado}
                    capsLock={capsLock}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {(claseTeclado !== "keyboard" && formData.duiNitCliente !== '' && formData.nombreCliente !== '') && (
        <div className="actions">
          <button 
            className="ui positive right labeled icon button"
            onClick={onAcept}  
          >
              Aceptar
            <i className="x icon"></i>
          </button>
          <button 
            className="ui negative right labeled icon button"
            onClick={onCancel}  
          >
              Cancelar
            <i className="x icon"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export { ModalNitDuiSv };
