import React, { useState } from "react";
import { TecladoNumerico } from "../../TecladoNumerico";
import "./style.css";

const ModalFondoCaja = ({ dataLogin, setOpenModal, empezarTurno, pin }) => {
  const [iniciaTurno, setIniciaTurno] = useState('');

  let nuevoTurno = {
    CodigoEmpresa: dataLogin.CodigoEmpresa,
    CodigoTienda: dataLogin.CodigoTienda,
    CodigoCaja: dataLogin.CodigoCaja,
    CodigoColaboradorApertura: pin,
    MontoInicio: 0,
  }
  
  const inputHandler = (valor) => {
    if (typeof (valor) === 'number') {
      setIniciaTurno(iniciaTurno + valor);
    } else if (valor === '.') {
      if(iniciaTurno.replace(/[^.]/g, "").length < 1 && valor === '.') {
        setIniciaTurno(iniciaTurno + valor);
      }
    } else if(valor === '!#') {
      setIniciaTurno(iniciaTurno.slice(0, -1));
    }
    else {
      const valorEvento = valor.target.value;

      if(valorEvento.replace(/[^.]/g, "").length <= 1) {
        if(!!Number.isFinite(parseFloat(valorEvento)) || valorEvento === '.' || valorEvento[valorEvento.length - 1] == '.') {
          setIniciaTurno(valorEvento);
        }
      }
    }
  }
  
  return (
    <div className="ui tiny longer modal md-disp" id="panelFondoCaja">

      <div className="header">
        Ingresar su Fondo de CAJA
      </div>

      <div className="content">
        <div className="ui grid">
          <div className="row padBot">
            <div className="sixteen wide column padBot">
              <div className="ui fluid labeled input">
                <div className="ui label">
                  Q.
                </div>
                <input onChange={(e) => inputHandler(e)} value={iniciaTurno} type="text" />
              </div>
            </div>
          </div>

          <div className="stretched row padBotTop">
            <TecladoNumerico inputHandler={inputHandler} usarDecimal={true} />
          </div>
        </div>
      </div>

      <div className="actions">
        <button
          className="ui negative left labeled icon button"
          onClick={() => setOpenModal(false)}
        >
          Cancelar
          <i className="x icon"></i>
        </button>
        <button className="ui positive let labeled icon button" onClick={() => empezarTurno({...nuevoTurno, MontoInicio: iniciaTurno})}>
          Iniciar turno
          <i className="check icon"></i>
        </button>
      </div>
    </div>
  );
};

export { ModalFondoCaja };
