import React from "react";

import { MiniLoader } from "../../MiniLoader";

import "./style.css";

const ModalInsertarOrden = ({ state, dispatch, actions }) => {
  
  return (
    <div className="ui tiny longer modal md-disp">
      <div className="content">
        {!!state.loaderAccion && (
          <>
            <div className="ui centered grid m-0">
              <MiniLoader />
            </div>
            <h3 className="modal-text">insertando orden...</h3>
          </>
        )}
        {!state.loaderAccion && <h3 className="modal-text">Orden creada</h3>}
      </div>
      {!state.loaderAccion && (
        <div className="actions">
          <button
            className="ui positive let labeled icon button"
            onClick={() => dispatch({ type: actions.NUMERO_MODAL, payload: 5 }) }
          >
            ACEPTAR
            <i className="check icon"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export { ModalInsertarOrden };
