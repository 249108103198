import React from "react"
import { ColumnaIzquierda } from "./ColumnaIzquierda"
import { Transacciones } from "./Transacciones"
import { BotonesReporteX } from "./BotonesReporteX"
import { AcumuladoTiquete } from "./AcumuladoTiquete"
import { FacturaPersonal } from "./FacturaPersonal"
import { ModalVentaCanal } from "./ModalVentaCanal"

import { Modal } from "../Modal"

import './style.css'

const ReporteXUI = ({ numberModal, openModal, onCancelModal, reporte, datosReporte, activarModalVentaPorCanal, hayOrdenes, onChangeTurno, turnos, turnoActual }) => {

    console.log(turnoActual);
    return (
        <>
            <div className="ui raised text container segment segment-color">
                <div className="ui grid">
                    <div className="five wide column">
                        Buscar por turno:
                        <select onChange={(e) => onChangeTurno(e)} name="CodigoTurno" className="ui dropdown">
                            {
                                turnos.map((item) => {
                                    return (
                                        <React.Fragment key={item.CodigoTurno}>
                                            {
                                                (item.CodigoTurno == turnoActual)
                                                    ? <option value={item.CodigoTurno}>Actual ({item.CorrelativoTurno} - {item.DiaSemana})</option>
                                                    : <option value={item.CodigoTurno}>{item.CorrelativoTurno} - {item.DiaSemana}</option>
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="eight wide column">
                        <h1>Reporte de Ventas "X"</h1>
                    </div>
                    <div className="three wide column">

                    </div>
                </div>
                <div className="ui two column divided grid">
                    <div className="stretched row">
                        <div className="column">
                            <ColumnaIzquierda datosReporte={datosReporte} hayOrdenes={hayOrdenes} />
                        </div>
                        <div className="column">
                            <div className="row">
                                <AcumuladoTiquete datosReporte={datosReporte} hayOrdenes={hayOrdenes} />
                            </div>
                            <div className="row">
                                <Transacciones datosReporte={datosReporte} hayOrdenes={hayOrdenes} />
                            </div>
                            <div className="row">
                                <FacturaPersonal datosReporte={datosReporte} hayOrdenes={hayOrdenes} />
                            </div>
                            <div className="row">
                                <BotonesReporteX activarModalVentaPorCanal={activarModalVentaPorCanal} />
                            </div>

                        </div>
                    </div>
                </div>
                {!!openModal && (
                    <Modal>
                        {
                            numberModal === 0
                                ? <ModalVentaCanal
                                    onCancelModal={onCancelModal}
                                    reporte={reporte}
                                />
                                : null

                        }
                    </Modal>
                )}
            </div>

        </>
    )
}

export { ReporteXUI }