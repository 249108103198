const TablaDetalle = ({ children, pais, todasFP }) => {

    let moneda = ''
    //let pais = 'GT'

    if (pais === 'GT') {
        moneda = 'Q.'
    }
    else if (pais === 'SV' || pais === 'MX') {
        moneda = '$.'
    }
    else if (pais === 'CR') {
        moneda = '₡.'
    }

    return (
        <table className="ui celled table">
            <thead>
                <tr>
                    <th>Codigo Orden</th>
                    <th>Correlativo</th>
                    <th>Total ({moneda})</th>
                    <th>Colaborador</th>
                    {
                        (todasFP === true)
                            ? <th>Forma de Pago</th>
                            : null
                    }
                    <th>Estado</th>
                    <th>Hora</th>
                </tr>
            </thead>
            <tbody>
                {children}
            </tbody>
        </table>
    )
}

export { TablaDetalle }