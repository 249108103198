import { useRef } from 'react';
import './style.css'

const TabAnulacionInput = ({
    botonesAnular,

    handleChange,
    buscaDocumento,

    motivosDevolucion,

    formData,
    handleSubmit,

    activarTeclado
}) => {

    const refMotivo = useRef();

    return (
        <div className="ui form">

            <div className="field">
                <label>Número de Documento</label>
                <div className="fields">
                    <div className="ten wide field">
                        <input onFocus={(e) => activarTeclado(true, e, 'num')} onChange={handleChange} value={formData.CodigoFormulario} type="text" name="CodigoFormulario" placeholder="Ingrese número de documento" />
                    </div>
                    <div className="six wide field">
                        <button onClick={buscaDocumento} className={botonesAnular.claseBotonBuscarAnulacion + ' button'}>
                            <i className="search icon"></i>
                            BUSCAR
                        </button>
                    </div>
                </div>
            </div>

            <div className="field">
                <label>Motivo de Devolución</label>
                <div className="fields">
                    <div className="ten wide field">

                        <select onChange={handleChange} value={formData.CodigoMotivo} name="CodigoMotivo" className="drop-down">
                            <option value={0}>Ninguno</option>
                            {
                                motivosDevolucion.map((item) => {
                                    return (
                                        <option key={item.Condicion} value={item.Condicion}>{item.Descripcion}</option>
                                    )
                                })
                            }
                        </select>

                    </div>
                    <div className="six wide field">
                        {
                            (botonesAnular.textoAnular === 'ANULAR')
                                ? (
                                    <button onClick={handleSubmit} className={botonesAnular.claseBotonAnular + ' button'}>
                                        <i className="backward icon"></i>
                                        {botonesAnular.textoAnular}
                                    </button>
                                )
                                : (
                                    <button onClick={handleSubmit} className='ui orange left fluid labeled icon disabled button'>
                                        <i className="backward icon"></i>
                                        {botonesAnular.textoAnular}
                                    </button>
                                )
                        }

                    </div>
                </div>
            </div>

            {
                (formData.CodigoMotivo == 1)
                    ? (
                        <div ref={refMotivo} className="field">
                            <label>Especifique motivo:</label>
                            <div className="fields">
                                <div className="ten wide field">
                                    <input onFocus={(e) => { activarTeclado(true, e, 'full'); refMotivo.current.scrollIntoView() }} onChange={handleChange} value={formData.ExpMotivo} type="text" name="ExpMotivo" placeholder="Motivo" />
                                </div>
                                <div className="six wide field">

                                </div>
                            </div>
                        </div>
                    )
                    : null
            }


        </div>
    )
}

export { TabAnulacionInput };