import React from "react";
import "./style.css";

const TecladoNumerico = ({ inputHandler, usarDecimal, activarTeclado }) => {

  return (
    <div className="sixteen wide column padBot">
      <div className="ui three column grid">
        <div className="row padBot">
          <div className="column padRig">
            <button
              className="fluid ui basic button"
              onClick={() => inputHandler(1)}
            >
              {" "}
              1{" "}
            </button>
          </div>
          <div className="column padRigLef">
            <button
              className="fluid ui basic button"
              onClick={() => inputHandler(2)}
            >
              {" "}
              2{" "}
            </button>
          </div>
          <div className="column padLef">
            <button
              className="fluid ui basic button"
              onClick={() => inputHandler(3)}
            >
              {" "}
              3{" "}
            </button>
          </div>
        </div>
        <div className="row padBotTop">
          <div className="column padRig">
            <button
              className="fluid ui basic button"
              onClick={() => inputHandler(4)}
            >
              {" "}
              4{" "}
            </button>
          </div>
          <div className="column padRigLef">
            <button
              className="fluid ui basic button"
              onClick={() => inputHandler(5)}
            >
              {" "}
              5{" "}
            </button>
          </div>
          <div className="column padLef">
            <button
              className="fluid ui basic button"
              onClick={() => inputHandler(6)}
            >
              {" "}
              6{" "}
            </button>
          </div>
        </div>
        <div className="row padBotRigTop">
          <div className="column padRig">
            <button
              className="fluid ui basic button"
              onClick={() => inputHandler(7)}
            >
              {" "}
              7{" "}
            </button>
          </div>
          <div className="column padRigLef">
            <button
              className="fluid ui basic button"
              onClick={() => inputHandler(8)}
            >
              {" "}
              8{" "}
            </button>
          </div>
          <div className="column padLef">
            <button
              className="fluid ui basic button"
              onClick={() => inputHandler(9)}
            >
              {" "}
              9{" "}
            </button>
          </div>
        </div>
        <div className="row padRigTop">
          <div className="column padRig">
            {usarDecimal === true ?
              <button
                className="fluid ui basic button"
                onClick={() => inputHandler(".")}
              >
                {" "}
                .{" "}
              </button> :
              <button
                className="fluid ui teal basic button"
                onClick={() => inputHandler("!%")}
              >
                {" "}
                LIMPIAR {" "}
              </button>
            }

          </div>
          <div className="column padRigLef">
            <button
              className="fluid ui basic button"
              onClick={() => inputHandler(0)}
            >
              {" "}
              0{" "}
            </button>
          </div>
          <div className="column padLef">
            <button
              className="fluid ui teal basic button"
              onClick={() => inputHandler('!#')}
            >
              {" "}
              <i className="arrow left icon"></i>{" "}
            </button>
          </div>
        </div>
        {/*<div className="row">
          <div className="column">

          </div>
          <div className="column">
            <button onClick={() => activarTeclado(false)} type="button" className="ui left labeled red fluid icon button">
              <i className="close icon"></i>
              Cerrar
            </button>
          </div>
          <div className="column">

          </div>
        </div>*/}

      </div>
    </div>
  );
};

export { TecladoNumerico };
