import { Link } from "react-router-dom";

const BotonesReporteX = ({ activarModalVentaPorCanal }) => {
    return (
        <div className="ui segment">
            <div className="ui equal width center aligned padded grid">
                {/* <div className="row fila-boton">
                    <div className="column columna-boton">
                        <button className="fluid ui inverted orange button">Imprimir</button>
                    </div>
                    <div className="column columna-boton">
                        <button className="fluid ui inverted orange button">Venta de GAD</button>
                    </div>
                </div> */}
                <div className="row fila-boton">
                    <div className="column columna-boton">
                        <button onClick={() => activarModalVentaPorCanal(0)} className="fluid ui inverted orange button">Venta por Canal</button>
                    </div>
                    {/* <div className="column columna-boton">
                        <button className="fluid ui inverted orange button">Reporte 3PD</button>
                    </div> */}
                </div>
                <div className="row fila-boton">
                    <div className="column columna-boton">
                        <Link type="button"
                            to={{
                                pathname: `/menu`,
                            }}
                            className="fluid ui inverted orange button">
                            Salir
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )
}

export { BotonesReporteX }

