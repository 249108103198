import React from "react";
import './style.css';
import { Item } from "semantic-ui-react";

const ModalFormaPago = ({ state, dispatch, actions}) => {

  const onSelect = (formaPago) => {
    if (formaPago === "POS INTEGRADO") formaPago = "CREDITO";

    dispatch({ type: actions.MODIFICAR_ORDEN, payload: {...state.orderOptions, formaDePago: formaPago} });
    dispatch({ type: actions.CERRAR_MODAL });
  }

  return (
    <div className="ui tiny modal md-disp">
      <div className="header">
        Seleccione la forma de pago
      </div>
      <div className="content">
        <div className="ui one column center aligned grid">
          {state.response.formasPago.map(item => (
            <div className="column element-height">
              <button 
                onClick={() => onSelect(item.toUpperCase())} 
                className="ui fluid inverted orange button btn-modal-height"
                style={{ marginTop: "10px", marginBottom: "10px" }}
                key={item}
              >
                <h1>{item === "CREDITO" ? "POS INTEGRADO" : item}</h1>
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="actions">
        <div 
          className="ui negative right labeled icon button"
          onClick={() => dispatch({ type: actions.CERRAR_MODAL })}
        >
          Cancelar
          <i className="x icon"></i>
        </div>
      </div>
    </div>
  );
};

export { ModalFormaPago };