import React, { createContext, useState } from 'react';

const MenuContext = createContext();

const MenuProvider = ({ children }) => {

    const [openModal, setOpenModal] = useState(false);
    const [numberModal, setNumberModal] = useState(undefined);

    console.log('en menu');

    return (
        <MenuContext.Provider value={
            {
                openModal,
                setOpenModal,
                numberModal,
                setNumberModal,
            }
        } > {
                children
            }
        </MenuContext.Provider>
    );

};

export {
    MenuContext,
    MenuProvider
};
