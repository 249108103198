import React from 'react';

import { PosContext } from '../PosContext';
import { PosSelectedOptions } from '../PosSelectedOptions';
import { PosSelectedMenuList } from "../PosSelectedMenuList";
import { PosSelectedMenuItem } from '../PosSelectedMenuItem';
import { PosMenuTotals } from '../PosMenuTotals';
import { PosMenuButtonOptions } from '../PosMenuButtonOptions';
import { PosItemList } from '../PosItemList';
import { PosItem } from '../PosItem';
import { Modal } from '../../Modal';
import { PosNumberPad } from '../PosNumberPad';
import { Loader } from '../../Loader';
import { PageError } from '../../PageError';

import { MenuList } from '../MenuList';
import { MenuListItem } from '../MenuListItem';

import { ModalDocumento } from "../ModalDocumento";
import { ModalDescuento } from "../ModalDescuento";
import { ModalCanales } from "../ModalCanales";
import { ModalFormaPago } from "../ModalFormaPago";
import { ModalProductos } from "../ModalProductos";
import { ModalNit } from "../ModalNit";
import { ModalPagoCredito } from "../ModalPagoCredito";
import { ModalEnlaces } from "../ModalEnlaces";
import { ModalPagaCon } from "../ModalPagaCon";
import { ModalDigitarDescuento } from "../ModalDigitarDescuento";
import { ModalReferenciaDescuento } from "../ModalReferenciaDescuento";
import { ModalInsertarOrden } from "../ModalInsertarOrden";
import { ModalDatosFiscalesSv } from "../ModalDatosFiscalesSv";
import { ModalNumPreimpreso } from "../ModalNumPreimpreso";
import { ModalInfoCliente } from "../ModalInfoCliente";
import { ModalCCF } from "../ModalCCF";
import { ModalNitDuiSv } from "../ModalNitDuiSv";
import { ModalCargandoGAD } from "../ModalCargandoGAD";
import { ModalAbrirCaja } from "../ModalAbrirCaja";
import { ModalTotalTarjeta } from "../ModalTotalTarjeta";
import { ModalBuscarPlu } from "../ModalBuscarPlu";
import { ModalContingenciaManual } from '../ModalContingenciaManual';

import './style.css';
import { TecladoNumFlotante } from '../../TecladoNumFlotante';

const PosUi = () => {
  const {
    submenuList,
    childList,
    itemList,
    totalOrder,
    limpiarPos,
    reiniciarDescuento,
    flujoDeCobro,
    llamarGAD,
    regresarPadre,
    addProductoDetalle,
    onCreateOrder,
    calcularDescuento,
    finalDescuento,
    state,
    dispatch,
    ACTION_TYPES
  } = React.useContext(PosContext);

  //console.log(state.complementProduct);

  if (!!state.loading) return (<Loader />);

  if (state.error !== undefined) return (<PageError message={state.error} />);

  return (
    <React.Fragment>
      <div className="ui fluid segment ">
        <PosSelectedOptions
          state={state}
          dispatch={dispatch}
          actions={ACTION_TYPES}
          itemList={itemList}
          totalOrder={totalOrder}
        />
      </div>

      <div className="ui fluid segment">
        <div className="ui grid">
          <div className="six wide column">
            <div className="selected--items__panel">
              <PosSelectedMenuList>
                {
                  itemList.map((item, i) => (
                    <PosSelectedMenuItem
                      key={item.CodigoPlu + i}
                      state={state}
                      dispatch={dispatch}
                      actions={ACTION_TYPES}
                      selectedProduct={item}
                      itemList={itemList}
                      reiniciarDescuento={reiniciarDescuento}
                      calcularDescuento={calcularDescuento}
                    />
                  ))
                }
              </PosSelectedMenuList>
            </div>

            <PosMenuTotals
              state={state}
              dispatch={dispatch}
              actions={ACTION_TYPES}
              flujoDeCobro={flujoDeCobro}
              totalOrder={totalOrder}
              itemList={itemList}
            />
            <PosMenuButtonOptions
              dispatch={dispatch}
              actions={ACTION_TYPES}
              limpiarPos={limpiarPos}
              totalOrder={totalOrder}
              llamarGAD={llamarGAD}
            />
          </div>

          <div className="one wide column">
            <PosNumberPad
              state={state}
              dispatch={dispatch}
              actions={ACTION_TYPES}
            />
          </div>

          <div className="nine wide column items-section">
            <div className="ui sixteen wide column mt-12">
              <MenuList
                state={state}
                dispatch={dispatch}
                actions={ACTION_TYPES}
                regresarPadre={regresarPadre}
              >
                {submenuList.map(menuItem => (
                  <MenuListItem
                    key={menuItem.CodigoMenu + menuItem.CodigoMenuPadre}
                    dispatch={dispatch}
                    actions={ACTION_TYPES}
                    menu={menuItem}
                  />
                ))}
              </MenuList>
            </div>
            <PosItemList>
              {childList.map(childItem => (
                <PosItem
                  key={childItem.CodigoProducto + childItem.DescripcionPantalla}
                  itemProducto={childItem}
                  addProductoDetalle={addProductoDetalle}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                />
              ))}
            </PosItemList>
          </div>
        </div>
        {!!state.openModal && (
          <Modal>
            <>
              {state.numberModal === 0 ? (
                <ModalDocumento
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                />
              ) : state.numberModal === 1 ? (
                <ModalDescuento
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                  addProductoDetalle={addProductoDetalle}
                  reiniciarDescuento={reiniciarDescuento}
                  calcularDescuento={calcularDescuento}
                />
              ) : state.numberModal === 2 ? (
                <ModalCanales
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                />
              ) : state.numberModal === 3 ? (
                <ModalFormaPago
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                />
              ) : state.numberModal === 4 ? (
                <ModalProductos
                  productoComplemento={{
                    ...state.complementProduct[0],
                    Complementos: state.complementProduct[0].Complementos.sort((a, b) => {
                      return a.PosicionComplemento - b.PosicionComplemento;
                    })
                  }}
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                  addProductoDetalle={addProductoDetalle}
                /> //HACER:
              ) : state.numberModal === 5 ? (
                <ModalNit
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                />
              ) : state.numberModal === 6 ? (
                <ModalPagoCredito
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                  onCreateOrder={onCreateOrder}
                />
              ) : state.numberModal === 7 ? (
                null //<ModalEnlaces /> //HACER:
              ) : state.numberModal === 8 ? (
                <ModalPagaCon
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                  totalOrder={totalOrder}
                  calcularDescuento={calcularDescuento}
                  onCreateOrder={onCreateOrder}
                />
              ) : state.numberModal === 9 ? (
                <ModalDigitarDescuento
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                  addProductoDetalle={addProductoDetalle}
                  calcularDescuento={calcularDescuento}
                  totalOrder={totalOrder}
                  reiniciarDescuento={reiniciarDescuento}
                />
              ) : state.numberModal === 10 ? (
                <ModalReferenciaDescuento />
              ) : state.numberModal === 11 ? (
                <ModalInsertarOrden
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                />
              ) : state.numberModal === 12 ? (
                <ModalDatosFiscalesSv
                  state={state}
                />
              ) : state.numberModal === 13 ? (
                <ModalNumPreimpreso
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                />
              ) : state.numberModal === 14 ? (
                <ModalInfoCliente
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                />
              ) : state.numberModal === 15 ? (
                <ModalCCF
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                />
              ) : state.numberModal === 16 ? (
                <ModalNitDuiSv
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                />
              ) : state.numberModal === 17 ? (
                <ModalCargandoGAD />
              ) : state.numberModal === 18 ? (
                <ModalAbrirCaja
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                />
              ) : state.numberModal === 19 ? (
                <ModalTotalTarjeta
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                  totalOrder={totalOrder}
                  calcularDescuento={calcularDescuento}
                />
              ) : state.numberModal === 20 ? (
                <ModalBuscarPlu
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                  addProductoDetalle={addProductoDetalle}
                />
              ) : state.numberModal === 21 ? (
                <ModalContingenciaManual
                  state={state}
                  dispatch={dispatch}
                  actions={ACTION_TYPES}
                  flujoDeCobro={flujoDeCobro}
                />
              ) : null
              }
            </>
          </Modal>
        )}
      </div>

    </React.Fragment>
  );
};



export { PosUi };