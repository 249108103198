import React from 'react';

const OrderLogItem = ({ order, noFila, handleModalLog, tipo, canales }) => {

  let btnVerBitacora = 'ui big icon button ';
  if (order.CodigoEtapa === 'ANUL') {
    btnVerBitacora += 'red'
  } else {
    btnVerBitacora += 'btn-pcolor'
  }

  let param1, param2, param3 = '';

  if (tipo === 'orden') {
    param1 = order.CodigoOrden;
    param2 = order.DocumentoNumero;
    param3 = order.FormaDePago;
  } else {
    param1 = order.CodigoCierre;
    param2 = order.TipoRegistro;
    param3 = 'nose';
  }

  const fechaYHora = order.Fecha.split('T');
  const fechaCompleta = fechaYHora[0].split('-');
  const dia = fechaCompleta[2];
  const mes = fechaCompleta[1];
  const year = fechaCompleta[0];

  let canalActual = '';
  if (tipo === 'orden') {
    const canalSeleccionado = canales.filter(canal => canal.CodigoCanal === order.CodigoCanal)[0];
    //console.log(canalSeleccionado);
    canalActual = canalSeleccionado.Descripcion;
  }

  return (
    <>
      {
        (tipo !== 'orden' && order.TipoRegistro === 'TURNO')
          ? null
          : (
            <tr>
              <td>{noFila}</td>
              <td>{param1}</td>
              <td>{dia}/{mes}/{year}</td>
              <td>{param2}</td>
              {
                (tipo !== 'orden')
                  ? (
                    <td></td>
                  )
                  : (
                    <>
                      <td>{canalActual}</td>
                      <td>{param3}</td>
                    </>
                  )
              }
              <td>
                <button
                  id="mostrar"
                  className={btnVerBitacora}
                  onClick={() => handleModalLog(order)}
                >
                  <i className="eye icon"></i>
                </button>
              </td>
            </tr>
          )
      }

    </>
  );
};

export { OrderLogItem };