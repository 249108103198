// MODAL 14
import React from 'react';

const ModalInfoCliente = ({state, dispatch, actions}) => {

  const onCancel = () => {
    if(state.orderOptions.formaDePago === 'EFECTIVO') {
      dispatch({ type: actions.NUMERO_MODAL, payload: 8 });
    }
  }

  const onAceptInfo = () => {
    // modal nit/dui
    dispatch({ type: actions.NUMERO_MODAL, payload: 16 });
  }

  return(
    <div className="ui tiny longer modal md-disp">
      <div className="header">¿Desea solicitar los datos del cliente?</div>
      <div className="actions">
        <button
          className='ui positive let labeled icon button'
          onClick={() => onAceptInfo()}
        >
          SI
          <i className="check icon"></i>
        </button> 
        <button
          className="ui red let labeled icon button"
          onClick={() => onCancel()}
        >
          NO
          <i className="x icon"></i>
        </button>
      </div>
    </div>
  );
};

export {ModalInfoCliente};