import React, { useEffect } from "react";
import './style.css';

import { DetalleTotalAnulacion } from "./DetalleTotalAnulacion";
import { DetalleGeneral } from "./DetalleGeneral";
import { DetalleOrden } from "./DetalleOrden";
import { DetalleTotalFecha } from "./DetalleTotalFecha";
import { TabAnulacionInput } from "./TabAnulacionInput";
import { TabDevAnterior } from "./TabDevAnterior";
import { Modal } from "../Modal";
import { ModalMiniLoader } from "../AvalonCierres/ModalMiniLoader";
import { TecladoCompleto } from "../TecladoCompleto";
import { TecladoNumFlotante } from "../TecladoNumFlotante";

const AnulacionUI = ({

    anulacionesApi,
    valoresTeclado,
    botonesAnular,
    estadosAnulacion,
    controlAnimaciones,
    setControlAnimaciones,

    handleChangeAnulacion,
    handleSubmit,
    handleSubmitFecha,

    buscarFecha,

    buscarOrdenDiaria,

    formData,
    formDataFecha,

    handleChangeFecha,

    pais,

    setFormData,
    setFormDataFecha,

    NombreColaborador,
    ApellidoColaborador,

    numberModal,
    openModal,

    activarTeclado,


}) => {

    //console.log(controlAnimaciones.valueTabIndex);

    useEffect(() => {

        //setActivada(true);
        setControlAnimaciones({
            ...controlAnimaciones,
            activada: true,
        })

        window.$('.ui.dropdown').dropdown();
        window.$('.ui.checkbox').checkbox();
        window.$('.menu .item')
            .tab();

        window.$('.frmNumDiaAnterior').on('change', function () {
            document.getElementById("contenedor").style.animation = " expandir 0.5s forwards";
            document.getElementById("encabezado").style.animation = " expandir 0.5s forwards";
            document.getElementById("primerPanel").style.animation = " encoger 0.5s forwards";
            document.getElementById("segundoPanel").style.display = " block";
            document.getElementById("segundoPanel").style.animation = " mostrar 1s forwards";


            document.getElementById("primerPanel-fecha").style.animation = " encoger 0.5s forwards";
            document.getElementById("segundoPanel-fecha").style.display = " block";
            document.getElementById("segundoPanel-fecha").style.animation = " mostrar 1s forwards";
        });
    }, []);

    if (controlAnimaciones.expandirAnulacion == true) {
        document.getElementById("contenedor").style.animation = " expandir 0.5s forwards";
        document.getElementById("encabezado").style.animation = " expandir 0.5s forwards";

        document.getElementById("primerPanel").style.animation = " encoger 0.5s forwards";
        document.getElementById("segundoPanel").style.display = " block";
        document.getElementById("segundoPanel").style.animation = " mostrar 1s forwards";
    }

    if (controlAnimaciones.expandirFecha == true) {
        document.getElementById("contenedor").style.animation = " expandir 0.5s forwards";
        document.getElementById("encabezado").style.animation = " expandir 0.5s forwards";

        document.getElementById("primerPanel-fecha").style.animation = " encoger 0.5s forwards";
        document.getElementById("segundoPanel-fecha").style.display = " block";
        document.getElementById("segundoPanel-fecha").style.animation = " mostrar 1s forwards";
    }

    if (controlAnimaciones.activada === true) {
        if (controlAnimaciones.cerrarAnular === true) {
            //setExpandirAnulacion(false);
            setControlAnimaciones({
                ...controlAnimaciones,
                expandirAnulacion: false,
            })

            document.getElementById("contenedor").style.animation = " encoger 0.5s forwards";
            document.getElementById("encabezado").style.animation = " encoger 0.5s forwards";
            document.getElementById("primerPanel").style.animation = " encoger-inverso 0.5s forwards";
            document.getElementById("segundoPanel").style.display = " none";
        }

        if (controlAnimaciones.cerrarAnular === true) {
            //setExpandirFecha(false);
            setControlAnimaciones({
                ...controlAnimaciones,
                expandirFecha: false,
            })
            document.getElementById("contenedor").style.animation = " encoger 0.5s forwards";
            document.getElementById("encabezado").style.animation = " encoger 0.5s forwards";
            document.getElementById("primerPanel-fecha").style.animation = " encoger-inverso 0.5s forwards"
            document.getElementById("segundoPanel-fecha").style.display = " none";
        }
    }

    /*if (activada === true) {
        if (expandirAnulacion === false) {
            document.getElementById("contenedor").style.animation = " encoger 0.5s forwards";
            document.getElementById("encabezado").style.animation = " encoger 0.5s forwards";
            document.getElementById("primerPanel").style.animation = " encoger-inverso 0.5s forwards";
            document.getElementById("segundoPanel").style.display = " none";
        }

        if (expandirFecha === false) {
            document.getElementById("contenedor").style.animation = " encoger 0.5s forwards";
            document.getElementById("encabezado").style.animation = " encoger 0.5s forwards";
            document.getElementById("primerPanel-fecha").style.animation = " encoger-inverso 0.5s forwards"
            document.getElementById("segundoPanel-fecha").style.display = " none";
        }
    }*/

    const changeTabIndex = (tabNum) => {
        console.log(tabNum);
        if (tabNum !== controlAnimaciones.valueTabIndex && controlAnimaciones.expandirAnulacion === true) {

            //setExpandirAnulacion(false);
            setControlAnimaciones({
                ...controlAnimaciones,
                expandirAnulacion: false,
                valueTabIndex: tabNum,
            })

            document.getElementById("contenedor").style.animation = " encoger 0.5s forwards";
            document.getElementById("encabezado").style.animation = " encoger 0.5s forwards";
            document.getElementById("primerPanel").style.animation = " encoger-inverso 0.5s forwards";
            document.getElementById("segundoPanel").style.display = " none";

            setFormData({
                CodigoFormulario: '',
                CodigoMotivo: 0,
            });
        }
        else if (tabNum !== controlAnimaciones.valueTabIndex && controlAnimaciones.expandirFecha === true) {
            //setExpandirFecha(false);
            setControlAnimaciones({
                ...controlAnimaciones,
                expandirFecha: false,
                valueTabIndex: tabNum,
            })

            document.getElementById("contenedor").style.animation = " encoger 0.5s forwards";
            document.getElementById("encabezado").style.animation = " encoger 0.5s forwards";
            document.getElementById("primerPanel-fecha").style.animation = " encoger-inverso 0.5s forwards"
            document.getElementById("segundoPanel-fecha").style.display = " none";

            setFormDataFecha({
                Fecha: '',
                CodigoFormulario: '',
                CodigoMotivo: 0,
            });

        }
        else {
            setControlAnimaciones({
                ...controlAnimaciones,
                valueTabIndex: tabNum,
            })
        }

        //setValueTabIndex(tabNum);
    }

    return (
        <>
            <div>
                <div className="column mt-40">
                    <div className='ui center aligned segment' id="encabezado" style={{ background: "#FFF7EF", width: "50%", margin: "0 auto" }}>
                        <h1 className="ui-header">Anulaciones</h1>
                    </div>
                    <div className="ui segment" id="contenedor" style={{ background: "#FFF7EF" }}>

                        <div className="ui top attached tabular menu tabMenu">
                            <a className="item active tab1" onClick={() => changeTabIndex(1)} data-tab="first">Devolución Diaria</a>
                            {
                                (pais === 'SV')
                                    ? <a className="item tab2" onClick={() => changeTabIndex(2)} data-tab="second">Dev. día Anterior</a>
                                    : null

                            }

                        </div>

                        <div className="ui bottom attached tab segment active" data-tab="first" >

                            <div className="ui two column very relaxed grid" id="definicionColumnas">

                                <div className="column" id="primerPanel">
                                    <TabAnulacionInput
                                        botonesAnular={botonesAnular}

                                        handleChange={handleChangeAnulacion}
                                        buscaDocumento={buscarOrdenDiaria}

                                        motivosDevolucion={anulacionesApi.motivosDevolucion}

                                        formData={formData}
                                        handleSubmit={handleSubmit}

                                        activarTeclado={activarTeclado}
                                    />

                                    <DetalleTotalAnulacion
                                        controlAnimaciones={controlAnimaciones}
                                        estadosAnulacion={estadosAnulacion}
                                    />
                                </div>

                                <div className="column" id="segundoPanel">
                                    <DetalleGeneral
                                        orden={estadosAnulacion.ordenAnular}
                                        estadoOrden={estadosAnulacion.estadoOrden}
                                        NombreColaborador={NombreColaborador}
                                        ApellidoColaborador={ApellidoColaborador}
                                    />

                                    <DetalleOrden
                                        orden={estadosAnulacion.ordenAnular}
                                        estadoOrden={estadosAnulacion.estadoOrden}
                                    />


                                </div>
                            </div>
                        </div>

                        <div className="ui bottom attached tab segment" data-tab="second">

                            <div className="ui two column very relaxed grid" id="dC">

                                <div className="column" id="primerPanel-fecha">
                                    <TabDevAnterior
                                        botonesAnular={botonesAnular}


                                        motivosDevolucion={anulacionesApi.motivosDevolucion}

                                        handleChange={handleChangeFecha}
                                        formData={formDataFecha}

                                        buscarFecha={buscarFecha}
                                        handleSubmit={handleSubmitFecha}

                                        activarTeclado={activarTeclado}

                                    />

                                    <DetalleTotalFecha
                                        controlAnimaciones={controlAnimaciones}
                                        estadosAnulacion={estadosAnulacion}
                                    />
                                </div>

                                <div className="column" id="segundoPanel-fecha">
                                    <DetalleGeneral
                                        orden={estadosAnulacion.ordenAnularFecha}
                                        estadoOrden={estadosAnulacion.estadoFechaOrden}
                                        NombreColaborador={NombreColaborador}
                                        ApellidoColaborador={ApellidoColaborador}
                                    />

                                    <DetalleOrden
                                        orden={estadosAnulacion.ordenAnularFecha}
                                        estadoOrden={estadosAnulacion.estadoFechaOrden}
                                    />
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                {!!openModal && (
                    <Modal>

                        {numberModal === 0 ? (
                            <ModalMiniLoader />
                        ) : null

                        }
                    </Modal>
                )}

                <div className={valoresTeclado.claseEspacioBlanco}></div>
            </div>

            {
                (valoresTeclado.claseTeclado === 'keyboard-num')

                    ? (controlAnimaciones.valueTabIndex == 1)
                        ? (
                            <TecladoNumFlotante claseActivar={valoresTeclado.claseTeclado} inputHandler={handleChangeAnulacion} activarTeclado={activarTeclado} usarDecimal={true} />
                        )
                        : (
                            <TecladoNumFlotante claseActivar={valoresTeclado.claseTeclado} inputHandler={handleChangeFecha} activarTeclado={activarTeclado} usarDecimal={true} />
                        )
                    : (controlAnimaciones.valueTabIndex == 1)
                        ? (
                            <TecladoCompleto handleChange={handleChangeAnulacion} claseActivar={valoresTeclado.claseTeclado} activarTeclado={activarTeclado} capsLock={valoresTeclado.capsLock} />
                        )
                        : (
                            <TecladoCompleto handleChange={handleChangeFecha} claseActivar={valoresTeclado.claseTeclado} activarTeclado={activarTeclado} capsLock={valoresTeclado.capsLock} />
                        )


            }
        </>

    )

}

export { AnulacionUI }