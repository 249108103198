
const INITIAL_STATE = {
    loading: true,
    error: undefined,
    response: {
        valoresLogin: {
            codigoEmpresa: null,
            codigoTienda: null,
            codigoCaja: null,
            codigoPuntoDeVenta: 1,
            codigoColaborador: null,
            cierreNumero: 1,
            codigoTurno: null,
            Nombres: null,
            Apellidos: null,
            EstadoTurno: null,
            FechaYHora: null,
            IntentosFacturacion: null,
            EstadoCaja: null,
            TiempoContingencia: null,
        },
        productosApi: {
            productos: [],
            productList: [],
            plus: [],
            pluList: [],
            familias: [],
            areasProduccion: [],
            canales: [],
            complementos: [],
            complementoList: [],
            opciones: [],
            opcionList: [],
            menus: [],
            menuList: [],
            listOriginalMenu: [],
            todosPlus: [],
            todosPluList: [],
        }
    },
    menus: {
        menuAnterior: [],
        menuDetalle: {},
    },
    compDetalle: {},
    estados: {
        estadoPlu: 'crear',
        estadoComplemento: 'buscando',
        estadoMenu: 'buscando',
        estadoOpcion: 'buscando',
        estadoEditarComplemento: 'buscando',
        estadoEditarOpcion: 'buscando',
        estadoPrecio: 'crear',
    },
    elementoSeleccionado: {
        pluSeleccionado: '',
        complementoSeleccionado: '',
        menuSeleccionado: '',
        opcionSeleccionada: '',
    },
    formPluProducto: {
        CodigoPlu: '',
        CodigoProducto: '',
        AreaProduccion: '',
        CodigoFamilia: '',
        DescripcionPantalla: '',
        DescripcionImpresion: '',
        DescripcionProducto: '',
        HoraDesde: '',
        HoraHasta: '',
        FechaDesde: '',
        FechaHasta: '',
    },
    formComplemento: {
        CodigoComplemento: '',
        Descripcion: '',
        Posicion: '',
        CantidadDefecto: '',
        CantidadMinima: '',
        CantidadMaxima: '',
    },
    formPrecio: {
        CodigoCanal: [],
        Precio: '',
        HoraInicio: '',
        HoraFin: '',
        FechaInicio: '',
        FechaFin: '',
        TodoDia: false,
        Desayuno: false,
        AlmuerzoCena: false,
        idUnico: '',
        TodosCanales: false,
    },
    formEditarProducto: {
        CodigoPlu: '',
        CodigoProducto: '',
        DescripcionProducto: '',
        Posicion: '',
        Estado: '',
        HoraDesde: '',
        HoraHasta: '',
        FechaDesde: '',
        FechaHasta: '',
        Tutor: '',
    },
    diasSemana: {
        Lunes: false,
        Martes: false,
        Miercoles: false,
        Jueves: false,
        Viernes: false,
        Sabado: false,
        Domingo: false,
        Todos: false,
    },
    formOpcion: {
        CodigoOpcion: '',
        CodigoPlu: '',
        Descripcion: '',
        CantidadDefecto: '',
        CantidadMinima: '',
        CantidadMaxima: '',
        Posicion: '',
        ReportarPrecio: 0,
        Estado: 'ALTA',
    },
    productoDetalles: {
        productoPrecios: [],
        compProducto: [],
        menuProducto: [],
    },
    eventoPrecios: [],
    preciosAgenda: {},
    estadoTodosAnterior: '',
    canalModificarUnico: '',
    openModal: false,
    numberModal: undefined,
    errorInput: undefined,
    loaderPlus: false,

}

const productosReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_START':
            return {
                ...state,
                loading: true,
                error: false,
            };
        case 'FETCH_START_SUCCESS':
            return {
                /*...action.payload,
                loading: false,*/
                ...state,
                response: {
                    ...state.response,
                    valoresLogin: {
                        ...state.response.valoresLogin,
                        codigoEmpresa: action.payload.usuario.Tienda.CodigoEmpresa,
                        codigoTienda: action.payload.usuario.Tienda.CodigoTienda,
                        codigoCaja: action.payload.usuario.CorrelativoFiscal.CodigoCaja,
                        codigoPuntoDeVenta: 1,
                        codigoColaborador: action.payload.usuario.Colaborador.CodigoColaborador,
                        cierreNumero: 1,
                        codigoTurno: action.payload.usuario.Turno.CodigoTurno,
                        Nombres: action.payload.usuario.Colaborador.Nombres,
                        Apellidos: action.payload.usuario.Colaborador.Apellidos,
                        EstadoTurno: action.payload.usuario.Turno.Estado,
                        FechaYHora: action.payload.usuario.Turno.Fecha,
                        IntentosFacturacion: action.payload.usuario.CajaFiscal.IntentosFacturacion,
                        EstadoCaja: action.payload.usuario.EstadoCaja,
                        TiempoContingencia: action.payload.usuario.CajaFiscal.TiempoContingencia,
                    },
                    productosApi: {
                        ...state.response.productosApi,
                        productos: action.payload.todoProductos.productos,
                        productList: action.payload.todoProductos.productos,
                        plus: action.payload.todoProductos.plus,
                        pluList: action.payload.todoProductos.plus,
                        familias: action.payload.todoProductos.familias,
                        areasProduccion: action.payload.todoProductos.areasProduccion,
                        canales: action.payload.todoProductos.canales,
                        complementos: action.payload.todoProductos.complementos,
                        complementoList: action.payload.todoProductos.complementos,
                        opciones: action.payload.todoProductos.opciones,
                        opcionList: action.payload.todoProductos.opciones,
                        menus: action.payload.todoProductos.menus,
                        menuList: action.payload.todoProductos.menus,
                        listOriginalMenu: action.payload.todoProductos.menus,
                        //todosPlus: [...action.payload.todoProductos.productos, ...action.payload.todoProductos.opciones],
                        //todosPluList: [...action.payload.todoProductos.productos, ...action.payload.todoProductos.opciones],
                    }
                },
                loading: (!action.payload.loading) ? action.payload.loading : state.loading,
                error: action.payload.error,
            };
        case 'FETCH_START_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case 'ABRIR_MODAL':
            return {
                ...state,
                openModal: true,
            };
        case 'CERRAR_MODAL':
            return {
                ...state,
                openModal: false,
            };
        case 'NUMERO_MODAL':
            return {
                ...state,
                numberModal: action.payload,
                openModal: true,
            };
        case 'ACTUALIZAR_LISTA_PLUS':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        todosPlus: action.payload,
                    }
                }
            }
        case 'REINICIAR_ESTADOS_PRODUCTO':
            return {
                ...state,
                estados: {
                    ...state.estados,
                    estadoPlu: 'crear',
                    estadoComplemento: 'buscando',
                    estadoMenu: 'buscando',
                    estadoPrecio: 'crear',
                },
                productoDetalles: {
                    ...state.productoDetalles,
                    compProducto: [],
                    menuProducto: [],
                    productoPrecios: [],
                },
                formPluProducto: {
                    CodigoPlu: '',
                    CodigoProducto: '',
                    AreaProduccion: '',
                    CodigoFamilia: '',
                    DescripcionPantalla: '',
                    DescripcionImpresion: '',
                    DescripcionProducto: '',
                    HoraDesde: '',
                    HoraHasta: '',
                    FechaDesde: '',
                    FechaHasta: '',
                },
                formPrecio: {
                    CodigoCanal: '',
                    Precio: '',
                    HoraInicio: '',
                    HoraFin: '',
                    FechaInicio: '',
                    FechaFin: '',
                },
                formComplemento: {
                    CodigoComplemento: '',
                    Descripcion: '',
                    Posicion: '',
                    CantidadDefecto: '',
                    CantidadMinima: '',
                    CantidadMaxima: '',
                },
                eventoPrecios: [],
                preciosAgenda: [],
            }
        case 'ACTUALIZAR_FORM_PRODUCTO':
            return {
                ...state,
                formPluProducto: {
                    ...state.formPluProducto,
                    [action.payload.name]: action.payload.value,
                }
            }
        case 'ACTUALIZAR_FORMPROD_CODIGO':
            return {
                ...state,
                formPluProducto: {
                    ...state.formPluProducto,
                    CodigoPlu: action.payload,
                    CodigoProducto: action.payload,
                }
            }
        case 'ACTUALIZAR_ESTADO_PLU':
            return {
                ...state,
                estados: {
                    ...state.estados,
                    estadoPlu: action.payload,
                }
            }
        case 'ACTUALIZAR_PLU_SELECCIONADO':
            return {
                ...state,
                elementoSeleccionado: {
                    ...state.elementoSeleccionado,
                    pluSeleccionado: action.payload,
                }
            }
        case 'CERRAR_MODAL_CREAR':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        menus: state.response.productosApi.listOriginalMenu,
                        menuList: state.response.productosApi.listOriginalMenu,
                    },
                },
                formEditarProducto: {
                    CodigoPlu: '',
                    CodigoProducto: '',
                    DescripcionProducto: '',
                    Posicion: '',
                    Estado: 'ALTA',
                    FechaDesde: '',
                    FechaHasta: '',
                    HoraDesde: '',
                    HoraHasta: '',
                    Tutor: '',
                },
                elementoSeleccionado: {
                    ...state.elementoSeleccionado,
                    //pluSeleccionado: '',
                    complementoSeleccionado: '',
                    menuSeleccionado: '',
                },
                openModal: false,
            }
        case 'ERROR_INPUT':
            return {
                ...state,
                errorInput: action.payload,
            }
        case 'CREAR_PLUPRODUCTO':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        plus: action.payload.plu,
                        pluList: action.payload.plu,
                        productList: action.payload.prod,
                        productos: action.payload.prod,
                        complementoList: action.payload.comp,
                        complementos: action.payload.comp,
                        menuList: state.response.productosApi.listOriginalMenu,
                        menus: state.response.productosApi.listOriginalMenu,
                        todosPluList: [...action.payload.prod, ...state.response.productosApi.opcionList],
                        todosPlus: [...action.payload.prod, ...state.response.productosApi.opcionList],
                    }
                },
                elementoSeleccionado: {
                    ...state.elementoSeleccionado,
                    pluSeleccionado: '',
                    complementoSeleccionado: '',
                    menuSeleccionado: '',
                }
            }
        case 'ABRIR_MODAL_EDITAR':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        complementoList: action.payload.listadoComplementos,
                        complementos: action.payload.listadoComplementos,
                    }
                },
                formEditarProducto: {
                    CodigoPlu: action.payload.producto.CodigoPlu,
                    CodigoProducto: action.payload.producto.CodigoProducto,
                    DescripcionProducto: action.payload.producto.Producto,
                    Posicion: action.payload.producto.Posicion,
                    Estado: action.payload.producto.Estado,
                    FechaDesde: action.payload.validoDsd,
                    FechaHasta: action.payload.validoHst,
                    HoraDesde: action.payload.producto.HoraDesde,
                    HoraHasta: action.payload.producto.HoraHasta,
                    Tutor: action.payload.producto.Tutor,
                },
                productoDetalles: {
                    ...state.productoDetalles,
                    compProducto: action.payload.compAux,
                    productoPrecios: action.payload.preciosAux,
                    menuProducto: [...action.payload.producto.ListaMenus],
                },
                preciosAgenda: action.payload.preciosAg,
                eventoPrecios: action.payload.preciosTransformados,
                elementoSeleccionado: {
                    ...state.elementoSeleccionado,
                    complementoSeleccionado: '',
                },
                estados: {
                    ...state.estados,
                    estadoComplemento: 'buscando',
                    estadoPrecio: 'grafico',
                }
            }
        case 'ACTUALIZAR_FORM_EDIT_PRODUCTO':
            return {
                ...state,
                formEditarProducto: {
                    ...state.formEditarProducto,
                    [action.payload.name]: action.payload.value,
                }
            }
        case 'EDITAR_PRODUCTO':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        productList: action.payload.prod,
                        productos: action.payload.prod,
                        complementoList: action.payload.comp,
                        complementos: action.payload.comp,
                        menuList: state.response.productosApi.listOriginalMenu,
                        menus: state.response.productosApi.listOriginalMenu,
                        todosPluList: [...action.payload.prod, ...state.response.productosApi.opcionList],
                        todosPlus: [...action.payload.prod, ...state.response.productosApi.opcionList],
                    }
                },
                eventoPrecios: [],
                elementoSeleccionado: {
                    ...state.elementoSeleccionado,
                    pluSeleccionado: '',
                    complementoSeleccionado: '',
                    menuSeleccionado: '',
                },
            };
        case 'ELIMINAR_PRODUCTO':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        productList: action.payload,
                        productos: action.payload,
                        todosPluList: [...action.payload, ...state.response.productosApi.opcionList],
                        todosPlus: [...action.payload, ...state.response.productosApi.opcionList],
                    }
                }
            }
        case 'ACTUALIZAR_COMP_SELECCIONADO':
            return {
                ...state,
                elementoSeleccionado: {
                    ...state.elementoSeleccionado,
                    complementoSeleccionado: action.payload,
                }
            }
        case 'ACTUALIZAR_COMPLEMENTOS':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        complementos: action.payload,
                        //complementoList: action.payload,
                    }
                }
            }
        case 'AGREGAR_NUEVO_COMPLEMENTO':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        complementos: action.payload,
                        complementoList: action.payload,
                    }
                }
            }
        case 'ACTUALIZAR_ESTADO_COMP':
            return {
                ...state,
                estados: {
                    ...state.estados,
                    estadoComplemento: action.payload,
                }
            }
        case 'ACTUALIZAR_DETALLE_COMP':
            return {
                ...state,
                compDetalle: action.payload,
            }
        case 'ACTUALIZAR_COMP_PRODUCTO':
            return {
                ...state,
                productoDetalles: {
                    ...state.productoDetalles,
                    compProducto: action.payload,
                }
            }
        case 'ACTUALIZAR_COMP_LIST':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        complementos: action.payload.comps,
                        complementoList: action.payload.list,
                    }
                }
            }
        case 'ACTUALIZAR_FORM_COMP':
            return {
                ...state,
                formComplemento: {
                    ...state.formComplemento,
                    [action.payload.name]: action.payload.value,
                }
            }
        case 'ACTUALIZAR_COMP_OPC':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        complementos: action.payload.comp,
                        complementoList: action.payload.comp,
                        opciones: action.payload.opc,
                        opcionList: action.payload.opc,
                        todosPlus: [...state.response.productosApi.productList, ...action.payload.opc],
                        todosPluList: [...state.response.productosApi.productList, ...action.payload.opc],

                    }
                }
            }
        case 'REINICIAR_ESTADO_COMPLEMENTO':
            return {
                ...state,
                elementoSeleccionado: {
                    ...state.elementoSeleccionado,
                    complementoSeleccionado: '',
                    opcionSeleccionada: '',
                },
                estados: {
                    ...state.estados,
                    estadoComplemento: 'buscando',
                    estadoEditarComplemento: 'buscando',
                },
                formComplemento: {
                    CodigoComplemento: '',
                    Descripcion: '',
                    Posicion: '',
                    CantidadDefecto: '',
                    CantidadMinima: '',
                    CantidadMaxima: '',
                }
            }
        case 'EDITAR_COMP_EXISTENTE':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        opciones: action.payload.nuevoListado,
                        opcionList: action.payload.nuevoListado,
                    }
                },
                estados: {
                    ...state.estados,
                    estadoEditarComplemento: 'editando',
                },
                formComplemento: {
                    ...state.formComplemento,
                    CodigoComplemento: action.payload.objComp.CodigoComplemento,
                    Descripcion: action.payload.objComp.Descripcion,
                    Posicion: action.payload.objComp.Posicion,
                    CantidadDefecto: action.payload.objComp.CantidadPorDefecto,
                    CantidadMinima: action.payload.objComp.CantidadMinima,
                    CantidadMaxima: action.payload.objComp.CantidadMaxima,
                }
            }
        case 'ACTUALIZAR_ESTADO_EDITAR_COMP':
            return {
                ...state,
                estados: {
                    ...state.estados,
                    estadoEditarComplemento: action.payload,
                }
            }
        case 'OPCIONES_COMP_DETALLE':
            return {
                ...state,
                compDetalle: {
                    ...state.compDetalle,
                    Opciones: action.payload,
                }
            }
        case 'ACTUALIZAR_LISTA_OPCIONES':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        opciones: action.payload.opc,
                        opcionList: action.payload.list,
                    }
                }
            }
        case 'ACTUALIZAR_OPCION_SELECCIONADA':
            return {
                ...state,
                elementoSeleccionado: {
                    ...state.elementoSeleccionado,
                    opcionSeleccionada: action.payload,
                }
            }
        case 'ACTUALIZAR_ESTADO_OPCION':
            return {
                ...state,
                estados: {
                    ...state.estados,
                    estadoOpcion: action.payload,
                }
            }
        case 'ACTUALIZAR_OPCIONES':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        opciones: action.payload,
                    }
                }
            }
        case 'ACTUALIZAR_FORM_OPCION':
            return {
                ...state,
                formOpcion: {
                    ...state.formOpcion,
                    CodigoOpcion: state.formPluProducto.CodigoPlu,
                    CodigoPlu: state.formPluProducto.CodigoPlu,
                    [action.payload.name]: action.payload.value,
                }
            }
        case 'FORM_EDITAR_OPCION':
            return {
                ...state,
                formOpcion: {
                    CodigoOpcion: action.payload.opcion.CodigoOpcion,
                    CodigoPlu: action.payload.opcion.CodigoPlu,
                    Descripcion: action.payload.opcion.Descripcion,
                    CantidadDefecto: action.payload.opcion.CantidadPorDefecto,
                    CantidadMinima: action.payload.opcion.CantidadMinima,
                    CantidadMaxima: action.payload.opcion.CantidadMaxima,
                    Posicion: action.payload.opcion.Posicion,
                    ReportarPrecio: action.payload.opcion.ReportarPrecio,
                    Estado: action.payload.opcion.Estado,
                },
                estados: {
                    ...state.estados,
                    estadoOpcion: action.payload.estado,
                }
            }
        case 'CREAR_OPCION':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        plus: action.payload.plu,
                        pluList: action.payload.plu,
                        opciones: action.payload.opc,
                        opcionList: action.payload.opc,
                        complementos: action.payload.comp,
                        complementoList: action.payload.comp,
                        todosPluList: [...state.response.productosApi.productList, ...action.payload.opc],
                        todosPlus: [...state.response.productosApi.productList, ...action.payload.opc],
                    }
                }
            }
        case 'REINICIAR_ESTADO_OPCION':
            return {
                ...state,
                estados: {
                    ...state.estados,
                    estadoPlu: 'crear',
                    estadoComplemento: 'buscando',
                    estadoMenu: 'buscando',
                    estadoPrecio: 'crear',
                    estadoOpcion: 'crear'
                },
                elementoSeleccionado: {
                    ...state.elementoSeleccionado,
                    complementoSeleccionado: '',
                    menuSeleccionado: '',
                },
                productoDetalles: {
                    ...state.productoDetalles,
                    compProducto: [],
                    menuProducto: [],
                    productoPrecios: [],
                },
                eventoPrecios: [],
                preciosAgenda: [],
                formOpcion: {
                    CodigoOpcion: '',
                    CodigoPlu: '',
                    Descripcion: '',
                    CantidadDefecto: '',
                    CantidadMinima: '',
                    CantidadMaxima: '',
                    Posicion: '',
                    ReportarPrecio: 0,
                    Estado: 'ALTA',
                },
                formPluProducto: {
                    CodigoPlu: '',
                    CodigoProducto: '',
                    AreaProduccion: '',
                    CodigoFamilia: '',
                    DescripcionPantalla: '',
                    DescripcionImpresion: '',
                    DescripcionProducto: '',
                    HoraDesde: '',
                    HoraHasta: '',
                    FechaDesde: '',
                    FechaHasta: '',
                },
                formComplemento: {
                    CodigoComplemento: '',
                    Descripcion: '',
                    Posicion: '',
                    CantidadDefecto: '',
                    CantidadMinima: '',
                    CantidadMaxima: '',
                },
                formPrecio: {
                    CodigoCanal: '',
                    Precio: '',
                    HoraInicio: '',
                    HoraFin: '',
                    FechaInicio: '',
                    FechaFin: '',
                }

            }
        case 'MODAL_EDITAR_OPCION':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        complementos: action.payload.listadoComplementos,
                        complementoList: action.payload.listadoComplementos,
                    }
                },
                elementoSeleccionado: {
                    ...state.elementoSeleccionado,
                    complementoSeleccionado: '',
                },
                estados: {
                    ...state.estados,
                    estadoComplemento: 'buscando',
                    estadoPrecio: 'agenda',
                    estadoOpcion: 'editar',
                },
                eventoPrecios: action.payload.preciosTransformados,
                preciosAgenda: action.payload.preciosAg,
                productoDetalles: {
                    ...state.productoDetalles,
                    compProducto: action.payload.compAux,
                    productoPrecios: action.payload.preciosAux,
                },
                formOpcion: {
                    CodigoOpcion: action.payload.opcion.CodigoOpcion,
                    CodigoPlu: action.payload.opcion.CodigoPlu,
                    Descripcion: action.payload.opcion.Descripcion,
                    CantidadDefecto: action.payload.opcion.CantidadPorDefecto,
                    CantidadMinima: action.payload.opcion.CantidadMinima,
                    CantidadMaxima: action.payload.opcion.CantidadMaxima,
                    Posicion: action.payload.opcion.Posicion,
                    ReportarPrecio: action.payload.opcion.ReportarPrecio,
                    Estado: action.payload.opcion.Estado,
                },
                formPluProducto: {
                    CodigoPlu: action.payload.pluOpcion.CodigoPlu,
                    AreaProduccion: action.payload.pluOpcion.AreaProduccion,
                    CodigoFamilia: action.payload.pluOpcion.CodigoFamilia,
                    DescripcionPantalla: action.payload.pluOpcion.DescripcionPantalla,
                    DescripcionImpresion: action.payload.pluOpcion.DescripcionImpresion,
                }
            }
        case 'CREAR_NUEVA_OPCION':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        complementoList: action.payload.comp,
                        complementos: action.payload.comp,
                        opciones: action.payload.opc,
                        opcionList: action.payload.opc,
                        todosPluList: [...state.response.productosApi.productList, ...action.payload.opc],
                        todosPlus: [...state.response.productosApi.productList, ...action.payload.opc],
                    }
                },
                estados: {
                    ...state.estados,
                    estadoEditarOpcion: 'buscando',
                },
                elementoSeleccionado: {
                    ...state.elementoSeleccionado,
                    opcionSeleccionada: '',
                },
                formOpcion: {
                    CodigoOpcion: '',
                    CodigoPlu: '',
                    Descripcion: '',
                    CantidadDefecto: '',
                    CantidadMinima: '',
                    CantidadMaxima: '',
                    Posicion: '',
                    ReportarPrecio: 0,
                    Estado: 'ALTA',
                }
            }
        case 'EDITAR_ELIMINAR_OPCION':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        opciones: action.payload,
                        opcionList: action.payload,
                        todosPluList: [...state.response.productosApi.productList, ...action.payload],
                        todosPlus: [...state.productosApi.productList, ...action.paylod],
                    }
                },
            }
        case 'ACTUALIZAR_EVENTO_PRECIOS':
            return {
                ...state,
                eventoPrecios: action.payload,
            }
        case 'ACTUALIZAR_FORM_PRECIO':
            return {
                ...state,
                formPrecio: {
                    ...state.formPrecio,
                    [action.payload.name]: action.payload.value,
                }
            }
        case 'SUBMIT_PRECIO_CANALES':
            return {
                ...state,
                productoDetalles: {
                    ...state.productoDetalles,
                    productoPrecios: [...action.payload.preciosActuales],
                },
                preciosAgenda: {
                    ...action.payload.preciosGuardados,
                },
                eventoPrecios: [...action.payload.eventosActuales],
                estados: {
                    ...state.estados,
                    estadoPrecio: 'grafico',
                },
                canalModificarUnico: 'Nada',
                formPrecio: {
                    CodigoCanal: '',
                    Precio: '',
                    HoraInicio: '',
                    HoraFin: '',
                    FechaInicio: '',
                    FechaFin: '',
                },
                diasSemana: {
                    Lunes: false,
                    Martes: false,
                    Miercoles: false,
                    Jueves: false,
                    Viernes: false,
                    Sabado: false,
                    Domingo: false,
                }
            }
        case 'SUBMIT_PRECIO_UNICO':
            return {
                ...state,
                productoDetalles: {
                    ...state.productoDetalles,
                    productoPrecios: [...state.productoDetalles.productoPrecios, action.payload.precioAux],
                },
                estados: {
                    ...state.estados,
                    estadoPrecio: 'grafico',
                },
                formPrecio: {
                    CodigoCanal: '',
                    Precio: '',
                    HoraInicio: '',
                    HoraFin: '',
                    FechaInicio: '',
                    FechaFin: '',
                },
                diasSemana: {
                    Lunes: false,
                    Martes: false,
                    Miercoles: false,
                    Jueves: false,
                    Viernes: false,
                    Sabado: false,
                    Domingo: false,
                },
            }
        case 'MODIFICAR_PRECIO_UNICO':
            return {
                ...state,
                estados: {
                    ...state.estados,
                    estadoPrecio: 'editar',
                },
                diasSemana: {
                    Lunes: action.payload.lunes,
                    Martes: action.payload.martes,
                    Miercoles: action.payload.miercoles,
                    Jueves: action.payload.jueves,
                    Viernes: action.payload.viernes,
                    Sabado: action.payload.sabado,
                    Domingo: action.payload.domingo
                },
                formPrecio: {
                    CodigoCanal: action.payload.precio.CodigoCanal,
                    Precio: action.payload.precio.Precio,
                    HoraInicio: action.payload.precio.HoraInicio,
                    HoraFin: action.payload.precio.HoraFin,
                    FechaInicio: action.payload.precio.FechaInicio,
                    FechaFin: action.payload.precio.FechaFin,
                    idUnico: action.payload.precio.idUnico,
                },
                canalModificarUnico: action.payload.descripcionCanal,
            }
        case 'ACTUALIZAR_ESTADO_PRECIO':
            return {
                ...state,
                estados: {
                    ...state.estados,
                    estadoPrecio: action.payload,
                }
            }
        case 'EDITAR_PRECIO_UNICO':
            return {
                ...state,
                eventoPrecios: [...action.payload.eventosNuevos, ...action.payload.precioTransformado],
                preciosAgenda: { ...action.payload.preciosGuardados },
                productoDetalles: {
                    ...state.productoDetalles,
                    productoPrecios: [...action.payload.preciosActuales],
                },
                estados: {
                    ...state.estados,
                    estadoPrecio: 'grafico',
                },
                formPrecio: {
                    CodigoCanal: '',
                    Precio: '',
                    HoraInicio: '',
                    HoraFin: '',
                    FechaInicio: '',
                    FechaFin: '',
                },
                diasSemana: {
                    Lunes: false,
                    Martes: false,
                    Miercoles: false,
                    Jueves: false,
                    Viernes: false,
                    Sabado: false,
                    Domingo: false,
                }
            }
        case 'EDITAR_PRECIO_CANALES':
            return {
                ...state,
                eventoPrecios: [...action.payload.eventosActuales, ...action.payload.transformadosSpread, ...action.payload.guardarEventos],
                preciosAgenda: { ...action.payload.preciosGuardados },
                productoDetalles: {
                    ...state.productoDetalles,
                    productoPrecios: [...action.payload.preciosActuales],
                },
                estados: {
                    ...state.estados,
                    estadoPrecio: 'grafico',
                },
                formPrecio: {
                    CodigoCanal: '',
                    Precio: '',
                    HoraInicio: '',
                    HoraFin: '',
                    FechaInicio: '',
                    FechaFin: '',
                },
                diasSemana: {
                    Lunes: false,
                    Martes: false,
                    Miercoles: false,
                    Jueves: false,
                    Viernes: false,
                    Sabado: false,
                    Domingo: false,
                },
                canalModificarUnico: 'Nada',
            }
        case 'ELIMINAR_PRECIO':
            return {
                ...state,
                preciosAgenda: action.payload.preciosAg,
                eventoPrecios: action.payload.preciosTransformados,
                productoDetalles: {
                    ...state.productoDetalles,
                    productoPrecios: action.payload.nuevosPrecios,
                }
            }
        case 'ACTUALIZAR_ESTADO_TODOS':
            return {
                ...state,
                estadoTodosAnterior: action.payload,
            }
        case 'TODOS_CANALES':
            return {
                ...state,
                canalModificarUnico: action.payload.canal,
                estados: {
                    ...state.estados,
                    estadoPrecio: action.payload.estado,
                },
                formPrecio: {
                    ...state.formPrecio,
                    TodosCanales: action.payload.valorTodos,
                }
            }
        case 'TODOS_DIAS':
            return {
                ...state,
                diasSemana: {
                    Lunes: action.payload,
                    Martes: action.payload,
                    Miercoles: action.payload,
                    Jueves: action.payload,
                    Viernes: action.payload,
                    Sabado: action.payload,
                    Domingo: action.payload,
                    Todos: action.payload,
                }
            }
        case 'SET_DIA_SEMANA':
            return {
                ...state,
                diasSemana: {
                    ...state.diasSemana,
                    [action.payload.name]: action.payload.value,
                }
            }
        case 'SET_LUNES':
            return {
                ...state,
                diasSemana: {
                    ...state.diasSemana,
                    Lunes: !state.diasSemana.Lunes,
                }
            }
        case 'SET_MARTES':
            return {
                ...state,
                diasSemana: {
                    ...state.diasSemana,
                    Martes: !state.diasSemana.Martes,
                }
            }
        case 'SET_MIERCOLES':
            return {
                ...state,
                diasSemana: {
                    ...state.diasSemana,
                    Miercoles: !state.diasSemana.Miercoles,
                }
            }
        case 'SET_JUEVES':
            return {
                ...state,
                diasSemana: {
                    ...state.diasSemana,
                    Jueves: !state.diasSemana.Jueves,
                }
            }
        case 'SET_VIERNES':
            return {
                ...state,
                diasSemana: {
                    ...state.diasSemana,
                    Viernes: !state.diasSemana.Viernes,
                }
            }
        case 'SET_SABADO':
            return {
                ...state,
                diasSemana: {
                    ...state.diasSemana,
                    Sabado: !state.diasSemana.Sabado,
                }
            }
        case 'SET_DOMINGO':
            return {
                ...state,
                diasSemana: {
                    ...state.diasSemana,
                    Domingo: !state.diasSemana.Domingo,
                }
            }
        case 'FORM_TODO_DIA':
            return {
                ...state,
                formPrecio: {
                    ...state.formPrecio,
                    HoraInicio: (action.payload.HoraInicio) ? action.payload.HoraInicio : state.formPrecio.HoraInicio,
                    HoraFin: (action.payload.HoraFin) ? action.payload.HoraFin : state.formPrecio.HoraFin,
                    TodoDia: (action.payload.TodoDia) ? action.payload.TodoDia : state.formPrecio.TodoDia,
                    Desayuno: (action.payload.Desayuno) ? action.payload.Desayuno : state.formPrecio.Desayuno,
                    AlmuerzoCena: (action.payload.AlmuerzoCena) ? action.payload.AlmuerzoCena : state.formPrecio.AlmuerzoCena,
                }
            }
        case 'ACTUALIZAR_MENU_SELECCIONADO':
            return {
                ...state,
                elementoSeleccionado: {
                    ...state.elementoSeleccionado,
                    menuSeleccionado: action.payload,
                }

            }
        case 'ACTUALIZAR_MENUS':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.productosApi,
                        menus: action.payload,
                    }
                }
            }
        case 'ACTUALIZAR_ESTADO_MENU':
            return {
                ...state,
                estados: {
                    ...state.estados,
                    estadoMenu: action.payload,
                }
            }
        case 'SELECCIONAR_MENU_CON_HIJOS':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        menus: action.payload.childs,
                        menuList: action.payload.childs,
                    }
                },
                menus: {
                    ...state.menus,
                    menuAnterior: action.payload.combinado,
                },
            }
        case 'SELECCIONAR_MENU_FINAL':
            return {
                ...state,
                estados: {
                    ...state.estados,
                    estadoMenu: 'detalles',
                },
                menus: {
                    ...state.menus,
                    menuDetalle: action.payload,
                }
            }
        case 'REGRESAR_MENU':
            return {
                ...state,
                response: {
                    ...state.response,
                    productosApi: {
                        ...state.response.productosApi,
                        menus: action.payload.varNueva,
                        menuList: action.payload.varNueva,
                    }
                },
                menus: {
                    ...state.menus,
                    menuAnterior: action.payload.varAnterior,
                }
            }
        case 'AGREGAR_MENU':
            return {
                ...state,
                productoDetalles: {
                    ...state.productoDetalles,
                    menuProducto: [...state.productoDetalles.menuProducto, action.payload],
                },
                estados: {
                    ...state.estados,
                    estadoMenu: 'buscando',
                }
            }
        case 'ELIMINAR_MENU':
            return {
                ...state,
                productoDetalles: {
                    ...state.productoDetalles,
                    menuProducto: action.payload,
                }
            }
        case 'ACTUALIZAR_MENU_PRODUCTO':
            return {
                ...state,
                productoDetalles: {
                    ...state.productoDetalles,
                    menuProducto: action.payload,
                }
            }
        case 'REGRESAR_AL_CALENDARIO':
            return {
                ...state,
                estados: {
                    ...state.estados,
                    estadoPrecio: 'grafico',
                },
                formPrecio: {
                    CodigoCanal: '',
                    Precio: '',
                    HoraInicio: '',
                    HoraFin: '',
                    FechaInicio: '',
                    FechaFin: '',
                },
                diasSemana: {
                    Lunes: false,
                    Martes: false,
                    Miercoles: false,
                    Jueves: false,
                    Viernes: false,
                    Sabado: false,
                    Domingo: false,
                }
            }
        case 'ACTIVAR_LOADER_PLU':
            return {
                ...state,
                loaderPlus: true,
            }
        case 'DESACTIVAR_LOADER_PLU':
            return {
                ...state,
                loaderPlus: false,
            }
        default:
            return state;
    }
}

export { productosReducer, INITIAL_STATE }