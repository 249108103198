import { useEffect, useState } from "react"

import { VentaPorDescuentoUI } from "./VentaPorDescuentoUI"
import { useLocalStorage } from '../../hooks/useLocalStorage'

import { turnoServices } from '../../api/services/turnoServices';
import Swal from "sweetalert2";
import { Loader } from "../Loader";
import { PageError } from "../PageError";
import { reporteVPCServices } from "../../api/services/reporteVPCServices";
import { useNavigate } from "react-router-dom";


const VentaPorDescuento = () => {

    const [usuario, guardarUsuario] = useLocalStorage('LOGIN_BETA');

    let navigate = useNavigate();

    const [reporte, setReporte] = useState([]);

    const [openModal, setOpenModal] = useState(false);
    const [numberModal, setNumberModal] = useState(undefined);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);

    let valoresLogin = {
        codigoEmpresa: usuario.Tienda.CodigoEmpresa,
        codigoTienda: usuario.Tienda.CodigoTienda,
        codigoCaja: usuario.CorrelativoFiscal.CodigoCaja,
        codigoPuntoDeVenta: 1,//Lo devuelve el login
        codigoColaborador: usuario.Colaborador.CodigoColaborador, //Login
        cierreNumero: 1, //Login, correlativo interno de Zeta
        codigoTurno: usuario.Turno.CodigoTurno,//Login
        correlativoTurno: usuario.Turno.CorrelativoTurno,
    };

    let pais = valoresLogin.codigoEmpresa.split('O')[1];

    const turnoActual = valoresLogin.codigoTurno;

    const [turnos, setTurnos] = useState([]);
    const [turnoSeleccionado, setTurnoSeleccionado] = useState(valoresLogin.codigoTurno);
    const [correlativoTurno, setCorrelativoTurno] = useState(valoresLogin.correlativoTurno);


    const [descuentoSeleccionado, setDescuentoSeleccionado] = useState(undefined);

    const [detalleDescuento, setDetalleDescuento] = useState([]);

    useEffect(() => {
        getReporteVentaDescuento();
        makeTurnos();
    }, [])


    const getReporteVentaDescuento = async () => {
        try {
            const reporteResp = await turnoServices.reporte.reporteVentaPorDescuento(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja, valoresLogin.codigoTurno);
            if (reporteResp.data.SUCCESS === false) {
                Swal.fire({
                    icon: 'error',
                    text: reporteResp.data.MESSAGE,
                })
                return;
            }
            const reporte = reporteResp.data.MESSAGE;

            if (reporte.length === 0) {
                Swal.fire({
                    title: 'No hay órdenes con descuento en este turno',
                    icon: 'info',
                    customClass: {
                        confirmButton: 'ui orange button'
                    }
                })
            }

            setLoading(false);
            setReporte(reporte);

        } catch (error) {
            setError(error.message);
            setLoading(false);
        }

    }

    const makeTurnos = async () => {

        try {

            const turnosResp = await turnoServices.turno.listaTurnos(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja);
            if (turnosResp.data.SUCCESS === false) {
                Swal.fire({
                    icon: 'error',
                    text: turnosResp.data.MESSAGE,
                })
                return;
            }

            const turnos = turnosResp.data.MESSAGE;

            setTurnos(turnos)
        } catch (error) {
            console.error(error)
        }
    }

    const onOpenModal = (modalOption) => {
        setOpenModal(prevState => !prevState);
        setNumberModal(modalOption);
    }

    const onCancelModal = () => {
        setOpenModal(false);
    };

    const onChangeTurno = async (e) => {
        try {

            setTurnoSeleccionado(e.target.value);
            const turnoSel = turnos.filter(turno => turno.CodigoTurno == e.target.value);

            const correlativo = turnoSel[0].CorrelativoTurno;
            setCorrelativoTurno(correlativo);

            const reporteResp = await turnoServices.reporte.reporteVentaPorDescuento(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja, e.target.value);
            if (reporteResp.data.SUCCESS === false) {
                Swal.fire({
                    icon: 'error',
                    text: reporteResp.data.MESSAGE,
                })
                return;
            }
            const reporte = reporteResp.data.MESSAGE;


            if (reporte.length === 0) {
                Swal.fire({
                    title: 'No hay órdenes en este turno',
                    icon: 'info',
                    customClass: {
                        confirmButton: 'ui orange button'
                    }
                })
            }

            setReporte(reporte);

        } catch (error) {
            console.log(error);
        }
    }

    const verDetallesDescuento = async (codigoDescuento) => {

        onOpenModal(1);

        const descuentoFiltrar = reporte.filter(descuento => descuento.CodigoDescuento === codigoDescuento);
        setDescuentoSeleccionado(descuentoFiltrar[0].DescripcionDescuento);

        const detalleResp = await reporteVPCServices.reporte.reporteTurno(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja, turnoSeleccionado);
        onCancelModal();
        if (detalleResp.data.SUCCESS === false) {
            Swal.fire({
                icon: 'error',
                text: detalleResp.data.MESSAGE,
            })
            return;
        }

        const ordenesTurno = detalleResp.data.MESSAGE;

        const ordenesConDescuento = ordenesTurno.filter(orden => orden.OrdenDescuento !== null);

        const detalle = ordenesConDescuento.filter(orden => orden.OrdenDescuento.CodigoDescuento === codigoDescuento);

        setDetalleDescuento(detalle);

        onOpenModal(0);
    }

    const volverVentaCanal = () => {
        navigate('/reporte-ventaPorCanal');
    }

    if (loading) return <Loader />

    if (error !== undefined) return <PageError message={error} source={'ventaXcanal'} />

    return (
        <VentaPorDescuentoUI
            reporte={reporte}
            onChangeTurno={onChangeTurno}
            pais={pais}

            turnos={turnos}
            turnoSeleccionado={turnoSeleccionado}
            correlativoTurno={correlativoTurno}
            turnoActual={turnoActual}

            descuentoSeleccionado={descuentoSeleccionado}
            verDetallesDescuento={verDetallesDescuento}
            detalleDescuento={detalleDescuento}

            onOpenModal={onOpenModal}
            openModal={openModal}
            onCancelModal={onCancelModal}
            numberModal={numberModal}

            volverVentaCanal={volverVentaCanal}
        />
    )
}

export { VentaPorDescuento }