const DetalleOrden = ({ orden, estadoOrden }) => {
    return (
        <div className="ui segment">
            <h3>Detalle de orden</h3>

            {
                (estadoOrden === true)
                    ? (
                        <table className="ui small table">
                            {
                                (orden.OrdenDetalle === [])
                                    ? <div> NO HAY PRODUCTOS </div>
                                    : (
                                        <tbody>

                                            {

                                                orden.OrdenDetalle.map((item) => {
                                                    return (
                                                        <tr key={item.CodigoDetalle}>
                                                            <td>{item.Cantidad}</td>
                                                            <td>{item.DescripcionImpresion}</td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    )
                            }

                        </table>
                    )
                    : null
            }


        </div>
    )
}

export { DetalleOrden }