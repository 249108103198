import React, { useContext, useEffect } from "react";
import { ProductosContext } from "../ProductosContext";
import { TabComplementos } from "../TabComplementos";
import { TabGrafPrecios } from "../TabGrafPrecios";
import { TabPluOpcion } from "../TabPluOpcion";

const ModalCrearOpcion = () => {

    const {
        state,
        crearPluOpcion,
        cerrarModalOpcion,
        editarOpcionExistente,
    } = useContext(ProductosContext);

    useEffect(() => {
        window.$('.tabular.menu .item').tab();
    }, []);

    let claseBotonGuardar = 'ui positive icon labeled button';

    return (
        <div className="ui modal md-disp">
            <div className="header">
                Crear nueva opción
            </div>

            <div className="scrolling content color-contenido">

                <div className="ui top attached tabular menu">
                    <div className="active item" data-tab="first">PLU - Opción</div>
                    <div className="item" data-tab="second">Precios</div>
                    <div className="item" data-tab="third">Complementos</div>
                </div>

                <div className="ui bottom attached tab segment active tab-modal" data-tab="first">
                    <h3 className="ui center aligned header">Crear PLU</h3>
                    <TabPluOpcion />
                </div>

                <div className="ui bottom attached tab segment tab-modal" data-tab="second">
                    <TabGrafPrecios modalFuente={'crear-opcion'} />
                </div>

                <div className="ui bottom attached tab segment tab-modal" data-tab="third">
                    <h3 className="ui center aligned header">Asociar a complementos</h3>
                    <TabComplementos />
                </div>

            </div>

            <div className="actions">
                {
                    (state.estados.estadoOpcion === 'editar')
                        ? (
                            <>
                                <button onClick={cerrarModalOpcion} className="ui negative icon labeled button">
                                    <i className="close icon"></i>
                                    Cancelar
                                </button>
                                <button onClick={editarOpcionExistente} className="ui positive icon labeled button">
                                    <i className="save icon"></i>
                                    Guardar
                                </button>
                            </>
                        )
                        : (
                            <>
                                <button onClick={cerrarModalOpcion} className="ui negative icon labeled button">
                                    <i className="close icon"></i>
                                    Cancelar
                                </button>
                                <button onClick={crearPluOpcion} className={claseBotonGuardar}>
                                    <i className="save icon"></i>
                                    Guardar
                                </button>
                            </>
                        )
                }

            </div>
        </div >
    )

};

export { ModalCrearOpcion };