// MODAL 
import React, { useMemo, useState, useEffect } from "react";
import { PosItem } from "../PosItem";
import { TecladoCompleto } from "../../TecladoCompleto";
import { useTecladoAlfanumerico } from "../../../hooks/useTecladoNumerico";
import "./style.css";

const ModalBuscarPlu = ({ state, dispatch, actions, addProductoDetalle }) => {
  const {
    claseTeclado,
    claseEspacioBlanco,
    formData,
    capsLock,
    activarTeclado,
    inputHandler,
    setFormData,
  } = useTecladoAlfanumerico({ codigoPlu: "" });

  const [codigoPlu, setCodigoPlu] = useState({ codigoPlu: "" });
  const [productosCanalActual, setProductosCanalActual] = useState([]);

  const setProductos = (canal = 0) => {
    if (!state.loading && state.error === undefined) {
      // PONEMOS EL PRECIO SEGÚN EL CANAL SELECCIONADO
      let productosPrecioPantalla = [];
      state.response.producto.forEach((produc, index) => {
        if (produc.ListaPrecios.filter((item) => item.CodigoCanal === state.orderOptions.codigoCanal).length > 0) {
          produc = {
            ...produc,
            Precio: produc.ListaPrecios.filter((lprecio) => lprecio.CodigoCanal === state.orderOptions.codigoCanal)[0].Precio,
            PrecioSinIva: produc.ListaPrecios.filter((lprecio) => lprecio.CodigoCanal === state.orderOptions.codigoCanal)[0].PrecioSinIva,
          };
          productosPrecioPantalla.push(produc);
        }
      });

      if (productosPrecioPantalla.length > 0) {
        productosPrecioPantalla.sort((a, b) => {
          return a.Posicion - b.Posicion;
        });
      }

      setProductosCanalActual(productosPrecioPantalla);
    }
  };

  useEffect(() => {
    setProductos('', state.orderOptions.codigoCanal);
  }, []);

  const eliminarEvento = (e) => {
    e.preventDefault();
  };

  let btnAceptar = "ui positive right labeled icon button";
  if (formData.codigoPlu === "") {
    btnAceptar += " disabled";
  }

  const setProductosModal = (valorPlu) => {
    let menusModal = [];
    if (valorPlu !== '') {
      menusModal = productosCanalActual.filter(item => item.CodigoPlu.includes(valorPlu) || item.DescripcionPantalla.toLowerCase().includes(valorPlu.toLowerCase()));
    } else {
      menusModal = productosCanalActual;
    }
    return menusModal;
  }

  let productosModal = useMemo(() => setProductosModal(formData.codigoPlu));

  return (
    <div className="ui longer modal md-disp">

      <div className="content">
        <div className="ui ten column doubling grid m-0 itemlistm-height itemlistm-pt">
          <div className="row itemlistm-pt mt-0">
            {productosModal.map((childItem) => (
              <PosItem
                key={childItem.CodigoProducto + childItem.DescripcionPantalla}
                itemProducto={childItem}
                addProductoDetalle={addProductoDetalle}
                dispatch={dispatch}
                actions={actions}
              />
            ))}
          </div>
        </div>

        <form className="ui form mt-10" onSubmit={eliminarEvento}>
          <div className="field">
            <label>Buscar por Codigo de PLU</label>
            <input
              value={formData.codigoPlu}
              onChange={(e) => inputHandler(e, "alfaNumerico-no")}
              type="text"
              name="codigoPlu"
              placeholder="Codigo de PLU"
              onFocus={(e) => activarTeclado(true, e, "alfaNumerico-no")}
              autoFocus
            />
          </div>
        </form>
      </div>

      <div className="actions">
        <button
          onClick={() => dispatch({ type: actions.CERRAR_MODAL })}
          className="ui blue right labeled icon button"
        >
          CERRAR
          <i className="x icon"></i>
        </button>
      </div>

      <div className={claseEspacioBlanco}></div>
      <TecladoCompleto
        claseActivar={claseTeclado}
        handleChange={inputHandler}
        activarTeclado={activarTeclado}
        capsLock={capsLock}
      />
    </div>
  );
};

export { ModalBuscarPlu };
