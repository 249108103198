import { useEffect, useState } from "react"

import { VentaPorCanalUI } from "./VentaPorCanalUI"
import { useLocalStorage } from '../../hooks/useLocalStorage'

import { turnoServices } from '../../api/services/turnoServices';
import Swal from "sweetalert2";
import { reporteVPCServices } from "../../api/services/reporteVPCServices";
import { Loader } from "../Loader";
import { PageError } from "../PageError";
import { useNavigate } from "react-router-dom";


const VentaPorCanal = () => {

    const [usuario, guardarUsuario] = useLocalStorage('LOGIN_BETA');

    const [reporte, setReporte] = useState([]);
    const [formasPago, setFormasPago] = useState([]);

    const [openModal, setOpenModal] = useState(false);
    const [numberModal, setNumberModal] = useState(undefined);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);

    let navigate = useNavigate();

    let valoresLogin = {
        codigoEmpresa: usuario.Tienda.CodigoEmpresa,
        codigoTienda: usuario.Tienda.CodigoTienda,
        codigoCaja: usuario.CorrelativoFiscal.CodigoCaja,
        codigoPuntoDeVenta: 1,//Lo devuelve el login
        codigoColaborador: usuario.Colaborador.CodigoColaborador, //Login
        cierreNumero: 1, //Login, correlativo interno de Zeta
        codigoTurno: usuario.Turno.CodigoTurno,//Login
        correlativoTurno: usuario.Turno.CorrelativoTurno,
    };

    let pais = valoresLogin.codigoEmpresa.split('O')[1];

    const turnoActual = valoresLogin.codigoTurno;

    const [turnos, setTurnos] = useState([]);
    const [turnoSeleccionado, setTurnoSeleccionado] = useState(valoresLogin.codigoTurno);
    const [canalSeleccionado, setCanalSeleccionado] = useState(undefined);
    const [correlativoTurno, setCorrelativoTurno] = useState(valoresLogin.correlativoTurno);


    const [detalleCanal, setDetalleCanal] = useState([]);
    const [ordenesCanal, setOrdenesCanal] = useState([]);

    const [todasFP, setTodasFP] = useState(false);
    const [formaPagoSeleccionada, setFormaPagoSeleccionada] = useState(undefined)

    useEffect(() => {
        getFormasDePago();
        getReporteVentaCanal();
        makeTurnos();
    }, [])


    const getReporteVentaCanal = async () => {
        try {
            const reporteResp = await turnoServices.reporte.reporteVPCSinFecha(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja, valoresLogin.codigoTurno);
            console.log(reporteResp);
            if (reporteResp.data.SUCCESS === false) {
                Swal.fire({
                    icon: 'error',
                    text: reporteResp.data.MESSAGE,
                })
                return;
            }
            const reporte = reporteResp.data.MESSAGE;

            if (reporte.length === 0) {
                Swal.fire({
                    title: 'No hay órdenes en este turno',
                    icon: 'info',
                    customClass: {
                        confirmButton: 'ui orange button'
                    }
                })
            }

            setLoading(false);
            setReporte(reporte);

        } catch (error) {
            setError(error.message);
            setLoading(false);
            console.error(error);
        }

    }

    const getFormasDePago = async () => {
        try {
            //const formasPagoResp = await posServices.pos.getFormasDePago()
            const formasPagoResp = await reporteVPCServices.pos.getFormasDePago(valoresLogin.codigoEmpresa);
            console.log(formasPagoResp);
            if (formasPagoResp.data.SUCCESS === false) {
                Swal.fire({
                    icon: 'error',
                    text: formasPagoResp.data.MESSAGE,
                })
                return;
            }
            const formasPago = formasPagoResp.data.MESSAGE;

            setFormasPago(formasPago);
        } catch (error) {
            setError(error.message);
            setLoading(false);
            //console.error(error);
        }
    }

    const makeTurnos = async () => {

        try {

            const turnosResp = await turnoServices.turno.listaTurnos(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja);
            if (turnosResp.data.SUCCESS === false) {
                Swal.fire({
                    icon: 'error',
                    text: turnosResp.data.MESSAGE,
                })
                return;
            }

            const turnos = turnosResp.data.MESSAGE;

            setTurnos(turnos)
        } catch (error) {
            console.error(error)
        }

        /*const turnoActual = valoresLogin.codigoTurno;
        let arrayTurnos = [];

        for (let i = 0; i < 5; i++) {
            let element = {
                id: i,
                turno: turnoActual - i
            }

            arrayTurnos.push(element);

        }
        setTurnos(arrayTurnos)*/
    }

    const onOpenModal = (modalOption) => {
        setOpenModal(prevState => !prevState);
        setNumberModal(modalOption);
    }

    const onCancelModal = () => {
        setOpenModal(false);
        setTodasFP(false);
    };

    const onChangeTurno = async (e) => {
        try {

            setTurnoSeleccionado(e.target.value);

            const turnoSel = turnos.filter(turno => turno.CodigoTurno == e.target.value);

            const correlativo = turnoSel[0].CorrelativoTurno;
            setCorrelativoTurno(correlativo);

            const reporteResp = await turnoServices.reporte.reporteVPCSinFecha(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja, e.target.value);
            if (reporteResp.data.SUCCESS === false) {
                Swal.fire({
                    icon: 'error',
                    text: reporteResp.data.MESSAGE,
                })
                return;
            }
            const reporte = reporteResp.data.MESSAGE;


            if (reporte.length === 0) {
                Swal.fire({
                    title: 'No hay órdenes en este turno',
                    icon: 'info',
                    customClass: {
                        confirmButton: 'ui orange button'
                    }
                })
            }

            setReporte(reporte);

        } catch (error) {
            console.log(error);
        }
    }

    const verDetallesCanal = async (codigoCanal, formaPago) => {

        onOpenModal(1);

        const canalFiltrar = reporte.filter(canal => canal.CodigoCanal === codigoCanal)
        //console.log(canalFiltrar)
        setCanalSeleccionado(canalFiltrar[0].DescripcionCanal)

        const detalleResp = await reporteVPCServices.reporte.reporteCanalTurno(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja, turnoSeleccionado, codigoCanal);
        onCancelModal();
        if (detalleResp.data.SUCCESS === false) {
            Swal.fire({
                icon: 'error',
                text: detalleResp.data.MESSAGE,
            })
            return;
        }

        const detalleSinFP = detalleResp.data.MESSAGE;
        const detalle = detalleSinFP.filter(orden => orden.Orden.FormaDePago === formaPago)
        //console.log(detalle);

        setFormaPagoSeleccionada(formaPago)

        setOrdenesCanal(detalleSinFP);
        setDetalleCanal(detalle);

        onOpenModal(0);

    }

    const onChangeFormaDePago = (e) => {

        setFormaPagoSeleccionada(e.target.value);


        if (e.target.value === 'Todas') {
            setTodasFP(true);
            setDetalleCanal(ordenesCanal);
        }
        else {
            setTodasFP(false);
            const FPUpperCase = e.target.value.toUpperCase();
            const nuevoDetalle = ordenesCanal.filter(orden => orden.Orden.FormaDePago === FPUpperCase);

            if (nuevoDetalle.length === 0) {
                Swal.fire({
                    title: 'No hay órdenes con la forma de pago seleccionada',
                    icon: 'info',
                    customClass: {
                        confirmButton: 'ui orange button'
                    }
                })
            }

            setDetalleCanal(nuevoDetalle);
        }

    }

    const irVentaDescuento = () => {
        navigate('/reporte-ventaPorDescuento');
    }

    if (loading) return <Loader />

    if (error !== undefined) return <PageError message={error} source={'ventaXcanal'} />

    return (
        <VentaPorCanalUI
            reporte={reporte}
            onOpenModal={onOpenModal}
            openModal={openModal}
            onCancelModal={onCancelModal}
            numberModal={numberModal}
            onChangeTurno={onChangeTurno}
            pais={pais}
            turnos={turnos}
            detalleCanal={detalleCanal}
            verDetallesCanal={verDetallesCanal}
            turnoSeleccionado={turnoSeleccionado}
            correlativoTurno={correlativoTurno}
            canalSeleccionado={canalSeleccionado}
            formasPago={formasPago}
            onChangeFormaDePago={onChangeFormaDePago}
            todasFP={todasFP}
            formaPagoSeleccionada={formaPagoSeleccionada}
            turnoActual={turnoActual}
            irVentaDescuento={irVentaDescuento}
        />
    )
}

export { VentaPorCanal }