import { VentaCanalRegistro } from '../VentaCanalRegistro';
import { VentaCanalTabla } from '../VentaCanalTabla';
import './style.css'


const ModalVentaCanal = ({ onCancelModal, reporte }) => {

    return (
        <div className="ui modal md-disp ">

            <div className="header">Venta por Canal</div>
            <div className="content color-contenido">

                <VentaCanalTabla>
                    <VentaCanalRegistro reporte={reporte} />
                </VentaCanalTabla>


            </div>
            <div className="actions">
                <button
                    type="button"
                    onClick={onCancelModal}
                    className="ui negative right labeled icon button"
                >
                    Cancelar
                    <i className="x icon"></i>
                </button>
            </div>
        </div>
    );
};

export { ModalVentaCanal };


/**
 * 
 * 
 */