import Swal from "sweetalert2";

const SWAL_ACTION = {
  AGREGAR_OPCION: {
    title: "No es posible agregar la cantidad seleccionada de opciones.",
    icon: "warning",
    confirmButtonText: "Cerrar",
    customClass: {
      confirmButton: "ui orange button",
    },
  },
  ERROR_ANUL_ORDEN_FISC: function (msg) {
    return {
      position: "top-end",
      icon: "error",
      html: msg,
      showConfirmButton: false,
      timer: 600000,
    };
  },
  ERROR_DESC_FISC: function (msg) {
    return {
      position: "top-end",
      icon: "error",
      title: "Error",
      text: msg,
      showConfirmButton: false,
      timer: 600000,
    };
  },
  ERROR_INSERTAR_ORDEN: {
    position: "top-end",
    icon: "error",
    html: "Error al tratar de insertar la orden",
    showConfirmButton: false,
    timer: 600000,
  },
  INSERTAR_Y_FISCALIZAR: function (msg1, msg2) {
    return {
      position: "top-end",
      icon: "success",
      html: `<h2>Orden ${msg1} creada y facturada<br />Cambio: Q. ${msg2}</h2>`,
      showConfirmButton: false,
      timer: 600000,
    };
  },
  ERROR_NIT: function (msg) {
    return {
      title: msg,
      text: "¿Desea ingresar NIT nuevamente?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1bc100",
      cancelButtonColor: "#008bc2",
      confirmButtonText: "Facturar C/F",
      cancelButtonText: "Cambiar NIT",
      allowOutsideClick: false,
    };
  },
  ERROR_POS_INTEGRADO: function(msg) {
    return {
      position: "center",
      icon: "error",
      title: msg,
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "Cerrar",
      width: 800,
      customClass: "swal-height",
    }
  },
  ABRIR_TURNO: function (msg) {
    return {
      position: "center",
      icon: "warning",
      title: msg,
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "Abrir turno",
      width: 800,
      customClass: "swal-height",
    };
  },
  CERRAR_OPERACIONES: function (msg) {
    return {
      position: "center",
      icon: "warning",
      title: msg,
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "Regresar",
      width: 800,
      customClass: "swal-height",
    };
  },
  AGREGAR_PRODUCTO_GAD: {
    text: "No es posible añadir productos en Ordenes Llamar GAD",
    icon: "info",
    confirmButtonText: "Cerrar",
    customClass: {
      confirmButton: "ui orange button",
    },
  },
  ERROR_LLAMAR_GAD: function (msg) {
    return {
      text: msg,
      icon: "error",
      confirmButtonText: "Regresar",
      customClass: {
        confirmButton: "ui orange button",
      },
    }
  },
  SIN_ORDENES_GAD: {
    text: "No hay ordenes de GAD",
    icon: "info",
    confirmButtonText: "Regresar",
    customClass: {
      confirmButton: "ui orange button",
    },
  },
};

const swalFire = (payload) => {
  return Swal.fire(payload);
};

export { SWAL_ACTION, swalFire };
