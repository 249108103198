import React from "react";

const Registro = ({ menuProd, eliminarMenu }) => {
    return (
        <>
            {
                menuProd.map((item, i) => {
                    return (
                        <tr key={i}>
                            <td><button
                                onClick={() => eliminarMenu(item)}
                                className="circular centered tiny ui icon red button"
                            >
                                <i className="minus circle icon"></i>
                            </button></td>
                            <td>{item.Descripcion}</td>
                            <td>{item.DescripcionPadre}</td>
                        </tr>
                    )
                })
            }
        </>
    )
}

export { Registro };