import React, { useEffect } from 'react'

import './style.css'

const TarjetaOpcion = ({ opcion, abrirModal, eliminar }) => {


    useEffect(() => {
        window.$(".ui.accordion").accordion();
    }, []);

    let claseCard = 'card opcion';
    let claseBotonEliminar = 'ui basic red fluid icon button';

    if (opcion.Estado === 'ALTA') {
        claseCard = 'card opcion altura-card';
        claseBotonEliminar = 'ui basic red fluid icon button';
    }
    else {
        claseCard = 'card opcion-off altura-card';
        claseBotonEliminar = 'ui basic red fluid icon disabled button';
    }

    let precioLlevar = 0.0;

    const precioFiltrado = opcion.ListaPrecios.filter(precio => precio.CodigoCanal === 2)[0];
    if (precioFiltrado === undefined) {
        precioLlevar = 0.0;
    }
    else {
        precioLlevar = precioFiltrado.Precio;
    }

    return (
        <div className="ui cards">
            <div className={claseCard}>
                <div className="content">
                    <div className="header prod-desc">
                        <b>{opcion.CodigoOpcion}</b>
                    </div>
                    <div className="meta prod-desc">
                        {opcion.Descripcion}
                    </div>
                    <div className="ui divider"></div>
                    <div className="description">
                        <div>
                            <b>Precio</b>: Q. {(Math.round(precioLlevar * 100) / 100).toFixed(2)}
                        </div>
                    </div>
                    {/* <div className="description">

                        <div className="ui fluid accordion">
                            <div className="title">
                                <i className="dropdown icon"></i>
                                Complementos asociados
                            </div>
                            <div className="fluid content">
                                <div className="ui relaxed divided fluid list">
                                    {opcion.Complementos.map((item, index) => (
                                        <div className="item" key={item.CodigoComplemento}>
                                            {item.CodigoComplemento}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="tabla-precios padTabla">
                            {
                                (opcion.ListaPrecios.length > 0)
                                    ? (
                                        <table className="ui small compact celled striped table">
                                            <thead>
                                                <tr><th>Canal</th>
                                                    <th>Precio (Q.)</th>
                                                </tr></thead>
                                            <tbody>
                                                {
                                                    opcion.ListaPrecios.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td data-label="Name">{item.CodigoCanal}</td>
                                                                <td data-label="Age">{(Math.round(item.Precio * 100) / 100).toFixed(4)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    )
                                    : (
                                        <h3>No hay precios asociados a este producto</h3>
                                    )
                            }

                        </div>

                    </div> */}
                </div>
                <div className="extra content">
                    <div className="ui two buttons">
                        <button onClick={() => abrirModal(opcion)} className="ui basic blue fluid icon button">
                            <i className="edit icon"></i>
                        </button>
                        <button onClick={() => eliminar(opcion)} className={claseBotonEliminar}>
                            <i className="trash icon"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { TarjetaOpcion };
