import React, { useState, useEffect } from 'react';
import { Loader } from '../../Loader';
import { CallOrderUI } from './CallOrderUI';
import { posServices } from '../../../api/services/posServices';
import { anulacionServices } from '../../../api/services/anulacionServices';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useParams } from 'react-router-dom';
import { PageError } from '../../PageError';
import Swal from 'sweetalert2';

const CallOrder = () => {

  const [usuario, guardarUsuario] = useLocalStorage('LOGIN_BETA');

  let valoresLogin = {
    codigoEmpresa: usuario.Tienda.CodigoEmpresa,
    codigoTienda: usuario.Tienda.CodigoTienda,
    codigoCaja: usuario.CorrelativoFiscal.CodigoCaja,
    codigoPuntoDeVenta: 1,//Lo devuelve el login
    codigoColaborador: usuario.Colaborador.CodigoColaborador, //Login
    cierreNumero: 1, //Login, correlativo interno de Zeta
    codigoTurno: usuario.Turno.CodigoTurno,//Login
  };

  let { Canal } = useParams();

  const pais = valoresLogin.codigoEmpresa.split('O')[1];

  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(undefined);

  const [ordenes, setOrdenes] = useState([]);
  const [allOrders, setAllOrders] = useState([])
  const [canales, setCanales] = useState([]);
  const [canalSeleccionado, setCanalSeleccionado] = useState('LEVAR');

  const fecha = new Date;

  useEffect(() => {
    getCanales();
    getOrdenesAbiertas();
  }, []);

  const getOrdenesAbiertas = async () => {
    try {
      const dia = fecha.getDate() < 10 ? `0${fecha.getDate()}` : `${fecha.getDate()}`;
      const mes = fecha.getMonth() + 1 < 10 ? `0${fecha.getMonth() + 1}` : `${fecha.getMonth() + 1}`;
      const year = fecha.getFullYear();

      const respOrdAbiertas = await anulacionServices.orden.getOrdenesPorFecha(`${dia}${mes}${year}`, valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja);
      //const respOrdAbiertas = await anulacionServices.orden.getOrdenesPorFecha('19072022', valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja);

      if (respOrdAbiertas.data.SUCCESS === false) {
        setLoader(false);
        setError(respOrdAbiertas.data.MESSAGE);
        return;
      }

      const ordenes = respOrdAbiertas.data.MESSAGE;

      const ordenesAbiertas = ordenes.filter(orden => orden.OrdenFactura === null);

      let ordAbLlevar = []

      if (Canal !== undefined) {
        ordAbLlevar = ordenesAbiertas.filter(orden => orden.Orden.DescripcionCanal === Canal)
      }
      else {
        ordAbLlevar = ordenesAbiertas.filter(orden => orden.Orden.DescripcionCanal === "LLEVAR")
      }

      //console.log(ordAbLlevar);

      if (ordAbLlevar.length === 0) {
        Swal.fire({
          title: 'No hay órdenes abiertas',
          icon: 'info',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'ui orange button',
          }
        })
        //return;
      }

      setAllOrders(ordenes);
      setOrdenes(ordAbLlevar)
      setLoader(false);
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  }

  const getCanales = async () => {
    try {
      const respCanales = await posServices.pos.getAllCanales(valoresLogin.codigoEmpresa);

      if (respCanales.data.SUCCESS === false) {
        setLoader(false);
        setError(respCanales.data.MESSAGE);
        return;
      }

      const canales = respCanales.data.MESSAGE;

      setCanales(canales);

    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  }

  const handleChange = (e) => {
    let canalSeleccionado = '';

    if (e.target.value === 'LEVAR') canalSeleccionado = 'LLEVAR';
    else canalSeleccionado = e.target.value;

    setCanalSeleccionado(canalSeleccionado);

    const ordenesAbiertas = allOrders.filter(orden => orden.OrdenFactura === null);
    const ordenesMostrar = ordenesAbiertas.filter(orden => orden.Orden.DescripcionCanal === canalSeleccionado);

    setOrdenes(ordenesMostrar);

  }

  if (loader) return <Loader />

  if (error !== undefined) return <PageError message={error} source={'llamar-Orden'} />

  if (Canal === 'GAD') return <CallOrderUI
    titulo={'Ordenes GAD'}
    orderData={ordenes}
    canalSeleccionado={canalSeleccionado}
    handleChange={handleChange}
    fecha={fecha}
    pais={pais}
  />

  return (
    <CallOrderUI
      titulo={'Ordenes Abiertas'}
      orderData={ordenes}
      canales={canales}
      canalSeleccionado={canalSeleccionado}
      handleChange={handleChange}
      fecha={fecha}
      pais={pais}
      empresa={valoresLogin.codigoEmpresa}
    />
  );
};

export { CallOrder };