import React from "react";
import Swal from "sweetalert2";
import "./styles.css";

const PosSelectedOptions = ({ state, dispatch, actions, itemList, totalOrder }) => {

  let descuento = 0;
  //console.log(itemList);
  //console.log(state.descuentoFuncion.funcion);
  if (!!state.descuentoFuncion.funcion) {
    let ordenConDescuento = itemList.filter(
      (item) => item.CodigoPlu === "DESCUENTO"
    );
    descuento = ordenConDescuento[0].Precio;
  }

  const manejoModal = (numeroModal = 0) => {
    if (!!desactivarFormaPago && numeroModal === 3) {
      Swal.fire({
        text: "No es posible cambiar la forma de pago si el descuento es igual al total de la orden",
        icon: "info",
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "ui orange button",
        },
      });
      return;
    }
    if (!!desactivarNit && numeroModal === 5) {
      Swal.fire({
        text: "No es posible cambiar el NIT con el canal seleccionado",
        icon: "info",
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "ui orange button",
        },
      });
      return;
    }

    dispatch({ type: actions.NUMERO_MODAL, payload: numeroModal });
  }

  // NOTA: SI EL CANAL ES COMIDA DE EMPLEADO, NO SE DEBE CAMBIAR EL NIT
  let btnNit = 'ui fluid inverted icon button fh';
  let canalSeleccionado = state.response.canales.filter(
    (item) => item.CodigoCanal === state.orderOptions.codigoCanal
  )[0];
  let desactivarNit = false;
  if (canalSeleccionado.Descripcion === 'COMIDA PERSONAL') {
    desactivarNit = true;
    btnNit += ' secondary';
  } else {
    btnNit += ' orange';
  }

  let desactivarFormaPago = false;
  let bntFormaPago = 'ui fluid inverted icon button fh';
  if (descuento === totalOrder && itemList.length > 0) {
    desactivarFormaPago = true;
    bntFormaPago += ' secondary';
  } else {
    bntFormaPago += ' orange';
  }

  return (
    <div className="ui two column grid">
      <div className="ui eight wide column">
        <div className="ui center aligned three column grid">
          <div className="seven wide column">
            <h3 className="ui left aligned header">
              <i className="home icon"></i>
              <div className="content">
                Correlativo {state.correlativo}
                <div className="sub header">
                  Caja: {state.orderOptions.codigoCaja}
                </div>
                <div className="sub header">
                  Tienda: {state.orderOptions.codigoTienda}
                </div>
              </div>
            </h3>
          </div>
          <div className="nine wide column">
            <h3 className="ui left aligned header">
              <i className="settings icon"></i>
              <div className="content">
                Canal: {canalSeleccionado.Descripcion}
                <div className="sub header">
                  Forma de Pago: {state.orderOptions.formaDePago}
                </div>
                <div className="sub header">Documento: FACTURA</div>
              </div>
            </h3>
          </div>
        </div>
      </div>

      <div className="ui eight wide column" style={{ padding: "0" }}>
        <div className="ui equal width center aligned padded five column grid">
          <div
            className="row"
            style={{ padding: "0", backgroundColor: "transparent" }}
          >
            <div className="column" style={{ padding: "0", height: "6rem" }}>
              <div
                className={btnNit}
                onClick={() => manejoModal(5)}
              >
                <i className="big icon id card" style={{ fontSize: "3vw" }}></i>

                <button
                  className="ui bottom attached large label"
                  style={{ backgroundColor: "inherit", color: "inherit" }}
                >
                  NIT
                </button>
              </div>
            </div>

            <div className="column" style={{ padding: "0", height: "6rem" }}>
              <div
                onClick={() => manejoModal(2)}
                className="ui fluid inverted orange icon button fh "
              >
                <i
                  className="big icon utensils"
                  style={{ fontSize: "3vw" }}
                ></i>
                <button
                  className="ui bottom attached large label"
                  style={{ backgroundColor: "inherit", color: "inherit" }}
                >
                  CANALES
                </button>
              </div>
            </div>
            <div className="column" style={{ padding: "0", height: "6rem" }}>
              <div
                onClick={() => manejoModal(3)}
                className={bntFormaPago}
              >
                <i
                  className="big icon credit card outline"
                  style={{ fontSize: "3vw" }}
                ></i>
                <button
                  className="ui bottom attached large label"
                  style={{ backgroundColor: "inherit", color: "inherit" }}
                >
                  FORMA PAGO
                </button>
              </div>
            </div>
            <div className="column" style={{ padding: "0", height: "6rem" }}>
              <div
                onClick={() => manejoModal(0)}
                className="ui fluid inverted orange icon button fh"
              >
                <i
                  className="big icon file alternate"
                  style={{ fontSize: "3vw" }}
                ></i>

                <button
                  className="ui bottom attached large label"
                  style={{ backgroundColor: "inherit", color: "inherit" }}
                >
                  TIPO DOC.
                </button>
              </div>
            </div>
            <div className="column" style={{ padding: "0", height: "6rem" }}>
              <div
                onClick={() => manejoModal(7)}
                className="ui fluid inverted orange icon button fh"
              >
                <i className="big icon linkify" style={{ fontSize: "3vw" }}></i>

                <button
                  className="ui bottom attached large label"
                  style={{ backgroundColor: "inherit", color: "inherit" }}
                >
                  ENLACES
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { PosSelectedOptions };
