import React, { useContext, useEffect } from "react";
import { ProductosContext } from "../ProductosContext";
import { TabComplementos } from "../TabComplementos";
import { TabGrafPrecios } from "../TabGrafPrecios";
import { TabMenus } from "../TabMenus";
import { TabPluProducto } from "../TabPluProducto/index";

import './style.css'

const ModalAgregarProducto = () => {

    useEffect(() => {
        window.$('.tabular.menu .item').tab();
    }, [])

    const {
        cerrarModalCrear,
        crearPluProducto,
    } = useContext(ProductosContext);

    let claseBotonGuardar = 'ui positive icon labeled button';

    return (
        <div className="ui modal md-disp">
            <div className="header">
                Agregar Producto
            </div>
            <div className="scrolling content color-contenido">

                <div className="ui top attached tabular menu">
                    <div className="active item" data-tab="first">Producto</div>
                    <div className="item" data-tab="second">Precios</div>
                    <div className="item" data-tab="third">Complementos</div>
                    <div className="item" data-tab="fourth">Menús</div>

                </div>

                <div className="ui bottom attached tab segment active tab-modal" data-tab="first">
                    <h3 className="ui center aligned header">Crear PLU</h3>
                    <TabPluProducto />
                </div>

                <div className="ui bottom attached tab segment" data-tab="second">
                    <TabGrafPrecios />
                </div>
                <div className="ui bottom attached tab segment tab-modal" data-tab="third">
                    <h3 className="ui center aligned header">Agregar complementos</h3>
                    <TabComplementos />

                </div>
                <div className="ui bottom attached tab segment tab-modal" data-tab="fourth">
                    <TabMenus />
                </div>


            </div>
            <div className="actions">
                <button onClick={cerrarModalCrear} className="ui negative icon labeled button">
                    <i className="close icon"></i>
                    Cancelar
                </button>
                <button onClick={crearPluProducto} className={claseBotonGuardar}>
                    <i className="save icon"></i>
                    Guardar
                </button>
            </div>
        </div>
    )
}

export { ModalAgregarProducto }