import { useContext } from "react";
import { ProductosContext } from "../ProductosContext";

const FormOpcion = () => {

    const {
        state,
        changeFormOpcion,
    } = useContext(ProductosContext);

    return (
        <>
            <form className="ui form">
                <div className="field">
                    <label>Descripción</label>
                    <input onChange={(e) => changeFormOpcion(e)} value={state.formOpcion.Descripcion} name="Descripcion" type="text" />
                </div>
                <div className="three fields">
                    <div className="field">
                        <label>Cant. por defecto</label>
                        <input onChange={(e) => changeFormOpcion(e)} value={state.formOpcion.CantidadDefecto} name="CantidadDefecto" type="text" />
                    </div>
                    <div className="field">
                        <label>Cant. Mínima</label>
                        <input onChange={(e) => changeFormOpcion(e)} value={state.formOpcion.CantidadMinima} name="CantidadMinima" type="text" />
                    </div>
                    <div className="field">
                        <label>Cant. Máxima</label>
                        <input onChange={(e) => changeFormOpcion(e)} value={state.formOpcion.CantidadMaxima} name="CantidadMaxima" type="text" />
                    </div>
                </div>
                {
                    (state.estados.estadoEditarOpcion !== 'buscando')
                        ? (
                            <div className="three fields">
                                <div className="field">
                                    <label>Posición</label>
                                    <input onChange={(e) => changeFormOpcion(e)} value={state.formOpcion.Posicion} name="Posicion" type="text" />
                                </div>
                                <div className="field">
                                    <label>Reportar precio</label>
                                    <select onChange={(e) => changeFormOpcion(e)} value={state.formOpcion.ReportarPrecio} name="ReportarPrecio" className="drop-down">
                                        <option value={0} className='dropdown-option'>No</option>
                                        <option value={1} className='dropdown-option'>Sí</option>
                                    </select>
                                </div>
                                <div className="field">
                                    <label>Estado</label>
                                    <select onChange={(e) => changeFormOpcion(e)} value={state.formOpcion.Estado} name="Estado" className="drop-down">
                                        <option value={"ALTA"} className='dropdown-option'>ALTA</option>
                                        <option value={"BAJA"} className='dropdown-option'>BAJA</option>
                                    </select>
                                </div>
                            </div>
                        )
                        : (
                            <div className="two fields">
                                <div className="field">
                                    <label>Posición</label>
                                    <input onChange={(e) => changeFormOpcion(e)} value={state.formOpcion.Posicion} name="Posicion" type="text" />
                                </div>
                                <div className="field">
                                    <label>Reportar precio</label>
                                    <select onChange={(e) => changeFormOpcion(e)} value={state.formOpcion.ReportarPrecio} name="ReportarPrecio" className="drop-down">
                                        <option value={0} className='dropdown-option'>No</option>
                                        <option value={1} className='dropdown-option'>Sí</option>
                                    </select>
                                </div>
                            </div>
                        )
                }

            </form>
        </>
    )
}

export { FormOpcion }