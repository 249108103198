import React, { useContext, useState } from "react";
import { PosContext } from "../PosContext";
import { posServices } from "../../../api/services/posServices";
import { expresionesRegulares } from "../../../constants/ExpresionesRegulares";
import { TecladoCompleto } from "../../TecladoCompleto"
import "./style.css";
import Swal from "sweetalert2";

let rgxNit = expresionesRegulares.verificadorNit;

const ModalNit = ({ state, dispatch, actions }) => {

  const [documentoActivo, setDocumentoActivo] = useState({
    usarNit: false,
    usarPasaporte: false,
    usarCui: false,
  })

  const [claseBotones, setClaseBotones] = useState({
    botonGuardar: 'ui button',
    textoGuardar: 'Buscar',
    botonAceptar: 'ui large green right labeled icon button',
  })

  const [valoresTeclado, setValoresTeclado] = useState({
    claseEspacioBlanco: 'espacio-blanco-off-nit',
    claseTeclado: 'keyboard-hidden',
    capsLock: false,
    campoSeleccionado: undefined,
  })

  const [formData, setFormData] = useState({
    nombreFiscal: '',
    nuevoNit: '',
  })

  const [datoPlaceholder, setDatoPlaceholder] = useState('');
  const [nuevoNit, setNuevoNit] = useState(state.datosFiscales.nit === undefined ? '' : state.datosFiscales.nit);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(undefined);
  const [nombreFiscal, setNombreFiscal] = useState('');

  const handleNit = async (nit) => {
    let tipoIdCliente = '';
    if (documentoActivo.usarCui === true) tipoIdCliente = 'CUI';
    else if (documentoActivo.usarPasaporte === true) tipoIdCliente = 'Pasaporte';
    else tipoIdCliente = 'Nit'

    if (documentoActivo.usarPasaporte === true) {
      //if (nombreFiscal === '') {
      if (formData.nombreFiscal === '') {
        Swal.fire({
          title: 'Debe ingresar nombre',
          icon: 'warning',
        })
        return;
      }

      //let responsePasaporte = await posServices.pos.validarPasaporte(nuevoNit);
      let responsePasaporte = await posServices.pos.validarPasaporte(formData.nuevoNit);
      if (responsePasaporte.data.SUCCESS === false) {
        Swal.fire({
          title: 'El número de pasaporte ingresado no es válido',
          icon: 'error',
        })
        return;
      }
    }
    else if (documentoActivo.usarCui === true) {
      //let responseCui = await posServices.pos.validarCui(nuevoNit);
      let responseCui = await posServices.pos.validarCui(formData.nuevoNit);
      if (responseCui.data.SUCCESS === false) {
        Swal.fire({
          title: 'El número de CUI ingresado no es válido',
          icon: 'error',
        })
        return;
      }
    }
    else if (documentoActivo.usarNit === true) {
      // Validar NIT
      const responseNit = await posServices.pos.validarNit(formData.nuevoNit);
      //console.log(respuestaNit);
      /*if (respuestaNit.data.SUCCESS === false) {
        Swal.fire({
          text: 'Debe ingresar nit otra vez',
        })
        //dispatch({ type: ACTION_TYPES.CERRAR_MODAL });
        dispatch({ type: ACTION_TYPES.ABRIR_MODAL, payload: 5 });
        return;
      }*/
      if (!responseNit.data.SUCCESS) {
        setHasError(responseNit.data.MESSAGE);
        //setNombreFiscal('');
        setFormData({
          ...formData,
          nombreFiscal: '',
        })
        return;
      } else {
        //setNombreFiscal(responseNit.data.MESSAGE.NOMBRE);
        setFormData({
          ...formData,
          nombreFiscal: responseNit.data.MESSAGE.NOMBRE,
        })
      }
      setClaseBotones({
        ...claseBotones,
        botonGuardar: 'ui button',
      });
    }

    dispatch({
      type: actions.MODIFICAR_DATO_FISCAL,
      payload:
      {
        ...state.datosFiscales,
        nit: nit.replace('-', ''),
        tipoIdCliente: tipoIdCliente,
        nombreCliente: formData.nombreFiscal
      }
    });
    dispatch({ type: actions.CERRAR_MODAL });
  };

  const activarTeclado = (focus, e, tipo, source) => {

    let claseTeclado = '';
    let campoSeleccionado = '';
    let claseEspacioBlanco = '';

    if (focus === true) {
      if (source !== 'inicio') {
        campoSeleccionado = e.target.name;
      }
      claseEspacioBlanco = 'espacio-blanco-activado-nit';
      if (tipo === 'full') {
        claseTeclado = 'keyboard';
      }
      else if (tipo === 'num') {
        claseTeclado = 'keyboard-num';
      }

    }
    else {
      claseTeclado = 'keyboard-hidden';
      claseEspacioBlanco = 'espacio-blanco-off-nit'
    }

    setValoresTeclado({
      ...valoresTeclado,
      campoSeleccionado,
      claseEspacioBlanco,
      claseTeclado,
    })
  }

  const buscarNit = async (e) => {
    try {
      //e.preventDefault();
      setHasError(undefined);
      setClaseBotones({
        ...claseBotones,
        botonGuardar: 'ui loading button',
      })
      //let textNit = nuevoNit.replace('-', '');
      let textNit = formData.nuevoNit.replace('-', '');
      if (!!textNit.match(rgxNit)) {
        setLoading(true);

        let responseNit = await posServices.pos.validarNit(textNit);

        if (!responseNit.data.SUCCESS) {
          setHasError(responseNit.data.MESSAGE);
          //setNombreFiscal('');
          setFormData({
            ...formData,
            nombreFiscal: '',
          })
        } else {
          //setNombreFiscal(responseNit.data.MESSAGE.NOMBRE);
          setFormData({
            ...formData,
            nombreFiscal: responseNit.data.MESSAGE.NOMBRE,
          })
        }
        setClaseBotones({
          ...claseBotones,
          botonGuardar: 'ui button',
        });
      } else {
        //setNombreFiscal('');
        setFormData({
          ...formData,
          nombreFiscal: '',
        })
      }

      setLoading(false);
    }
    catch (error) {
      console.error(error);
      setHasError(error.message);
      setHasError(false);
    }
  }

  const formFiscal = (source) => {

    activarTeclado(true, '', 'full', 'inicio');

    const sourceAnterior = state.datosFiscales.tipoIdCliente;

    // Cuando se limpia el POS, no hay tipoIdCliente anterior
    // El flujo cambia cuando es una nueva orden y no un cambio en la misma
    if (sourceAnterior !== undefined) {
      const anteriorMayus = sourceAnterior.toUpperCase();
      const sourceMayus = source.toUpperCase();

      if (anteriorMayus !== sourceMayus) {
        dispatch({
          type: actions.MODIFICAR_DATO_FISCAL,
          payload:
          {
            ...state.datosFiscales,
            nit: '',
            tipoIdCliente: '',
            nombreCliente: ''
          }
        });

        //setNuevoNit('');
        setFormData({
          ...formData,
          nuevoNit: '',
        })
      }
    }

    if (source === 'nit') {
      setDocumentoActivo({
        usarNit: true,
        usarCui: false,
        usarPasaporte: false,
      })
      setDatoPlaceholder('Número de nit');
    }
    else if (source === 'cui') {
      setDocumentoActivo({
        usarNit: false,
        usarCui: true,
        usarPasaporte: false,
      })
      setDatoPlaceholder('CUI');
    }
    else if (source === 'pasaporte') {
      setDocumentoActivo({
        usarNit: false,
        usarCui: false,
        usarPasaporte: true,
      })
      setDatoPlaceholder('Número de pasaporte');
    }
  }

  const inputNitHandler = async (e) => {
    try {

      setClaseBotones({
        ...claseBotones,
        botonGuardar: 'ui button',
        textoGuardar: 'Buscar',
        //botonAceptar: 'ui large green right labeled icon disabled button'
      })


      // Limpiar el nombre cuando el NIT cambie
      if (documentoActivo.usarNit) setNombreFiscal('');

      let textNit = '';

      if (typeof e === 'number' || e === 'K' || e === 'S' || e === '-') {
        // utiliza el teclado numerico del modal
        textNit = nuevoNit + '' + e;
      } else if (e === '!#') {
        setNombreFiscal('');
        textNit = '';
      } else if (e === '!%') {
        textNit = nuevoNit.slice(0, -1);
      } else {
        // escribe con teclado
        textNit = e.target.value;
      }

      setNuevoNit(textNit);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  let classInputName = 'ui fluid input mt-10';
  if (!!loading) {
    classInputName += ' loading';
  }

  const inputNombreHandler = (e) => {
    setNombreFiscal(e.target.value);
  };

  const inputHandlerGeneral = (e) => {

    let valorActual = undefined;

    if (e.length !== undefined || typeof (e) == 'number') {
      // Valor actual del campo en el que se está escribiendo
      valorActual = formData[valoresTeclado.campoSeleccionado];
      //console.log(valorActual);

      // Control para eliminar un caracter (backspace)
      if (e == 'backspace' || e == '!#') {
        // Se elimina el último caracter del valor y se actualiza el estado
        const valorNuevo = valorActual.slice(0, -1)
        setFormData({
          ...formData,
          [valoresTeclado.campoSeleccionado]: valorNuevo,
        })
        /*if (valorNuevo.length === 0 && valoresTeclado.campoSeleccionado === 'CodigoFormulario') {
          setBotonesAnular({
            ...botonesAnular,
            claseBotonBuscarAnulacion,
          })
        }
        else if (valorNuevo.length === 0 && valoresTeclado.campoSeleccionado === 'ExpMotivo') {
          setBotonesAnular({
            ...botonesAnular,
            claseBotonAnular: 'ui orange left fluid labeled icon disabled'
          })
        }*/
        return;
      }

      // Control de mayúsculas
      if (e == 'caps') {
        setValoresTeclado({
          ...valoresTeclado,
          capsLock: !valoresTeclado.capsLock,
        })
        return;
      }
    }

    // Si los dos ifs son iguales, deberían estar dentro ambos
    if (e.length !== undefined || typeof (e) == 'number') {

      // e (evento) al ser un click en el teclado digital, no tiene name ni value. Por eso se trabaja directamente
      // con e
      let valor = e;

      // Si es un string (una letra) y está activado el capsLock
      if (typeof (e) == 'string' && valoresTeclado.capsLock == true) {
        // Se hace mayúscula la tecla presionada
        valor = e.toUpperCase();
      }

      setFormData({
        ...formData,
        [valoresTeclado.campoSeleccionado]: valorActual + valor,
      })


    }
    // Se está usando un teclado físico para ingresar valores
    else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });

    }
  }

  const cerrarModal = () => {
    dispatch({ type: actions.CERRAR_MODAL });

    //console.log(state.datosFiscales.tipoIdCliente); // string vacío al inicio
    if (state.datosFiscales.tipoIdCliente !== '' && state.datosFiscales.tipoIdCliente !== undefined) {
      const sourceAnterior = state.datosFiscales.tipoIdCliente;
      const anteriorMayus = sourceAnterior.toUpperCase();

      let sourceActual = '';

      const keys = Object.keys(documentoActivo);
      for (let i = 0; i < keys.length; i++) {
        if (documentoActivo[keys[i]] === true) {
          sourceActual = keys[i];
        }
      }

      const source = sourceActual.split(/(?=[A-Z])/)[1];
      const sourceMayus = source.toUpperCase();

      if (anteriorMayus !== sourceMayus) {

        dispatch({
          type: actions.MODIFICAR_DATO_FISCAL,
          payload:
          {
            ...state.datosFiscales,
            nit: '',
            tipoIdCliente: '',
            nombreCliente: ''
          }
        });
      }
    }

  }

  return (
    <div className="ui longer modal md-disp">
      {
        (!documentoActivo.usarNit && !documentoActivo.usarCui && !documentoActivo.usarPasaporte)
          ? (
            <div className="header">Datos fiscales</div>
          )
          : (documentoActivo.usarCui)
            ? <div className="header">Datos fiscales - CUI</div>
            : (documentoActivo.usarNit)
              ? <div className="header">Datos fiscales - NIT</div>
              : <div className="header">Datos fiscales - Pasaporte</div>
      }

      <div className="content">
        {(!documentoActivo.usarNit && !documentoActivo.usarCui && !documentoActivo.usarPasaporte) ? (
          <div className="ui two column center aligned grid">
            <div className="column element-height">
              <button
                className="big ui fluid inverted orange button btn-modal-height"
                style={{ marginTop: "10px", marginBottom: "10px" }}
                /*onClick={() => setUsarNit(!usarNit)}*/
                onClick={() => formFiscal('nit')}
              >
                <h1> Ingresar Nit </h1>
              </button>
            </div>

            <div className="column element-height">
              <button
                className="big ui fluid inverted orange button btn-modal-height"
                style={{ marginTop: "10px", marginBottom: "10px" }}
                /*onClick={() => setUsarCui(!usarCui)}*/
                onClick={() => formFiscal('cui')}
              >
                <h1> CUI </h1>
              </button>
            </div>

            <div className="column element-height">
              <button
                className="big ui fluid inverted orange button btn-modal-height"
                style={{ marginTop: "10px", marginBottom: "10px" }}
                /*onClick={() => setUsarPasaporte(!usarPasaporte)}*/
                onClick={() => formFiscal('pasaporte')}
              >
                <h1> Pasaporte </h1>
              </button>
            </div>

            <div className="column element-height">
              <button
                className="big ui fluid inverted orange button btn-modal-height"
                style={{ marginTop: "10px", marginBottom: "10px" }}
                onClick={() => handleNit('')}
              >
                <h1> C/F </h1>
              </button>
            </div>
          </div>
        ) : (
          <div className="ui grid">
            <div className="row">
              <div className="sixteen wide column">
                {hasError !== undefined && (
                  <div className="ui negative message">
                    <p>{hasError}
                    </p></div>
                )}
                <form className="ui form">
                  <div className="ui fluid action input">
                    <input
                      onFocus={(e) => activarTeclado(true, e, 'full', 'form')}
                      onChange={(e) => inputHandlerGeneral(e)}
                      //onChange={(e) => inputNitHandler(e)}
                      //value={nuevoNit}
                      value={formData.nuevoNit}
                      type="text"
                      placeholder={datoPlaceholder}
                      name="nuevoNit"
                      autoFocus
                    />
                    {
                      (documentoActivo.usarCui || documentoActivo.usarPasaporte)
                        ? null
                        : (
                          <button type="button"
                            className={claseBotones.botonGuardar}
                            onClick={() => buscarNit()}
                          >
                            {claseBotones.textoGuardar}
                          </button>
                        )
                    }

                  </div>
                  {
                    (documentoActivo.usarPasaporte || documentoActivo.usarCui)
                      ? (
                        <div className={classInputName}>
                          <input
                            onFocus={(e) => activarTeclado(true, e, 'full', 'form')}
                            //onChange={(e) => inputNombreHandler(e)}
                            onChange={(e) => inputHandlerGeneral(e)}
                            //value={nombreFiscal}
                            value={formData.nombreFiscal}
                            type="text"
                            placeholder="Nombre"
                            name="nombreFiscal"
                          />
                        </div>
                      )
                      : (
                        <div className={classInputName}>
                          <input
                            //onChange={(e) => inputNombreHandler(e)}
                            onFocus={(e) => activarTeclado(true, e, 'full', 'form')}
                            onChange={(e) => inputHandlerGeneral(e)}
                            //value={nombreFiscal}
                            value={formData.nombreFiscal}
                            type="text"
                            placeholder="Nombre"
                            readOnly
                            name="nombreFiscal"
                          />
                        </div>
                      )
                  }
                </form>

              </div>

              {/*
              <div className="sixteen wide column padBot mt-10">
                <div className="ui three column grid">
                  <div className="row padBot">
                    <div className="column padRig">
                      <button
                        className="fluid ui basic button"
                        onClick={() => inputNitHandler(1)}
                      >
                        {" "}
                        1{" "}
                      </button>
                    </div>
                    <div className="column padRigLef">
                      <button
                        className="fluid ui basic button"
                        onClick={() => inputNitHandler(2)}
                      >
                        {" "}
                        2{" "}
                      </button>
                    </div>
                    <div className="column padLef">
                      <button
                        className="fluid ui basic button"
                        onClick={() => inputNitHandler(3)}
                      >
                        {" "}
                        3{" "}
                      </button>
                    </div>
                  </div>
                  <div className="row padBotTop">
                    <div className="column padRig">
                      <button
                        className="fluid ui basic button"
                        onClick={() => inputNitHandler(4)}
                      >
                        {" "}
                        4{" "}
                      </button>
                    </div>
                    <div className="column padRigLef">
                      <button
                        className="fluid ui basic button"
                        onClick={() => inputNitHandler(5)}
                      >
                        {" "}
                        5{" "}
                      </button>
                    </div>
                    <div className="column padLef">
                      <button
                        className="fluid ui basic button"
                        onClick={() => inputNitHandler(6)}
                      >
                        {" "}
                        6{" "}
                      </button>
                    </div>
                  </div>
                  <div className="row padBotRigTop">
                    <div className="column padRig">
                      <button
                        className="fluid ui basic button"
                        onClick={() => inputNitHandler(7)}
                      >
                        {" "}
                        7{" "}
                      </button>
                    </div>
                    <div className="column padRigLef">
                      <button
                        className="fluid ui basic button"
                        onClick={() => inputNitHandler(8)}
                      >
                        {" "}
                        8{" "}
                      </button>
                    </div>
                    <div className="column padLef">
                      <button
                        className="fluid ui basic button"
                        onClick={() => inputNitHandler(9)}
                      >
                        {" "}
                        9{" "}
                      </button>
                    </div>
                  </div>
                  {
                    (documentoActivo.usarCui || documentoActivo.usarPasaporte)
                      ? (
                        <div className="row padBotTop">
                          <div className="column padRig">
                            <button
                              className="fluid ui teal basic button"
                              onClick={() => inputNitHandler('!#')}
                            >
                              {" "}
                              LIMPIAR{" "}
                            </button>
                          </div>
                          <div className="column padRigLef">
                            <button
                              className="fluid ui basic button"
                              onClick={() => inputNitHandler(0)}
                            >
                              {" "}
                              0{" "}
                            </button>
                          </div>
                          <div className="column padLef">
                            <button
                              className="fluid ui teal basic button"
                              onClick={() => inputNitHandler('!%')}
                            >
                              {" "}
                              BORRAR{" "}
                            </button>
                          </div>
                        </div>
                      )
                      : (
                        <div className="row padBotTop">
                          <div className="column padRig">
                            <button
                              className="fluid ui basic button"
                              onClick={() => inputNitHandler('-')}
                            >
                              {" "}
                              -{" "}
                            </button>

                          </div>
                          <div className="column padRigLef">
                            <button
                              className="fluid ui basic button"
                              onClick={() => inputNitHandler(0)}
                            >
                              {" "}
                              0{" "}
                            </button>
                          </div>
                          <div className="column padLef">
                            <button
                              className="fluid ui teal basic button"
                              onClick={() => inputNitHandler('!%')}
                            >
                              {" "}
                              BORRAR{" "}
                            </button>
                          </div>
                        </div>
                      )
                  }
                  {
                    (documentoActivo.usarCui || documentoActivo.usarPasaporte)
                      ? null
                      : (
                        <div className="row padRigTop">
                          <div className="column padRig">
                            <button
                              className="fluid ui basic button"
                              onClick={() => inputNitHandler("K")}
                            >
                              {" "}
                              K{" "}
                            </button>
                          </div>
                          <div className="column padRigLef">
                            <button
                              className="fluid ui basic button"
                              onClick={() => inputNitHandler('S')}
                            >
                              {" "}
                              S{" "}
                            </button>
                          </div>
                          <div className="column padLef">
                            <button
                              className="fluid ui teal basic button"
                              onClick={() => inputNitHandler('!#')}
                            >
                              {" "}
                              LIMPIAR{" "}
                            </button>
                          </div>
                        </div>
                      )
                  }
                </div>
              </div>
              */}
            </div>
          </div>
        )}
      </div>

      <div className="actions">
        {(!!documentoActivo.usarNit || !!documentoActivo.usarCui || !!documentoActivo.usarPasaporte) &&
          <button
            className={claseBotones.botonAceptar}
            onClick={() => handleNit(formData.nuevoNit)}
          >
            Aceptar
            <i className="check icon"></i>
          </button>
        }
        <button
          type="button"
          onClick={cerrarModal}
          className="ui large negative right labeled icon button"
        >
          Cancelar
          <i className="x icon"></i>
        </button>
      </div>
      <div className={valoresTeclado.claseEspacioBlanco}></div>
      <TecladoCompleto
        claseActivar={valoresTeclado.claseTeclado}
        handleChange={inputHandlerGeneral}
        activarTeclado={activarTeclado}
        capsLock={valoresTeclado.capsLock}
      />
    </div>
  );
};

export { ModalNit };
