export const gridNumerico = [
    { id: "1", valor: "1", size: "normal", icon: "no" },
    { id: "2", valor: "2", size: "normal", icon: "no" },
    { id: "3", valor: "3", size: "normal", icon: "no" },
    { id: "4", valor: "4", size: "normal", icon: "no" },
    { id: "5", valor: "5", size: "normal", icon: "no" },
    { id: "6", valor: "6", size: "normal", icon: "no" },
    { id: "7", valor: "7", size: "normal", icon: "no" },
    { id: "8", valor: "8", size: "normal", icon: "no" },
    { id: "9", valor: "9", size: "normal", icon: "no" },
    { id: "dot", valor: ".", size: "normal", icon: "no" },
    { id: "0", valor: "0", size: "normal", icon: "no" },
    { id: "bckspc", valor: "!#", size: "normal", icon: "backward icon" },

];