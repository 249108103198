// 18
import React, { useContext, useState } from "react";
import { PosContext } from "../PosContext";
import { TecladoNumerico } from "../../TecladoNumerico";
import { useNavigate } from "react-router-dom";
import { posServices } from "../../../api/services/posServices";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { serverAxios } from "../../../utils/Common";

const ModalAbrirCaja = ({ state, dispatch, actions }) => {

  const [usuario, guardarUsuario] = useLocalStorage('LOGIN_BETA');
  const [iniciaTurno, setIniciaTurno] = useState("");

  const [estadoBoton, setEstadoBoton] = useState('ui positive let labeled icon button');

  const { navigate } = useNavigate();

  //esto viene del contexto, de response
  let nuevoTurno = {
    CodigoEmpresa: state.response.valoresLogin.codigoEmpresa,
    CodigoTienda: state.response.valoresLogin.codigoTienda,
    CodigoCaja: state.response.valoresLogin.codigoCaja,
    CodigoColaboradorApertura: state.response.otrosValores.pin,
    MontoInicio: 0,
  };

  const empezarTurno = async (valorInicio = null) => {
    console.log('Empezando nuevo turno');
    setEstadoBoton('ui loading positive let labeled icon button');
    try {
      const axios = serverAxios('central');
      //const resTurno = await posServices.pos.iniciarTurno(valorInicio);
      const resTurno = await posServices.pos.iniciarTurnoServer(valorInicio, axios);
      console.log(resTurno);
      setEstadoBoton('ui positive let labeled icon button');
      if (resTurno.data.CODE === 1000 || !!resTurno.data.SUCCESS) {
        console.log("se inicio el turno con exito");
        // aqui debería haber una condición segun la empresa, como solo hay gt no importa y abrimos el nit
        dispatch({ type: actions.MODIFICAR_ORDEN, payload: { ...state.orderOptions, codigoTurno: resTurno.data.MESSAGE.CodigoTurno } });

        //MOVER ESTO DE AQUI
        let usuarioConTurno = {
          ...usuario,
          Turno: resTurno.data.MESSAGE
        };
        console.log(usuarioConTurno);

        localStorage.removeItem('LOGIN_BETA');
        localStorage.setItem('LOGIN_BETA', JSON.stringify(usuarioConTurno));
        window.dispatchEvent(new Event("cambioStorage"));

        // Habilitar para modo sin conexión
        /*const valorInicioLocal = {
          ...valorInicio,
          CorrelativoTurno: resTurno.data.MESSAGE.CorrelativoTurno,
        }
        console.log(valorInicioLocal);

        const nuevoAxios = serverAxios('local');
        const resTurnoLocal = await posServices.pos.crearTurnoLocal(valorInicioLocal, nuevoAxios);
        console.log(resTurnoLocal);*/
        // Cómo se va a manejar el error del turno local?

        dispatch({ type: actions.NUMERO_MODAL, payload: 5 });
        //////////////////////////
        Swal.fire({
          title: 'Turno abierto con éxito',
          icon: "success",
          confirmButtonText: "Continuar",
          customClass: {
            confirmButton: "ui orange button",
          },
        });
      } else {
        Swal.fire({
          title: resTurno.data.MESSAGE,
          icon: "warning",
          confirmButtonText: "Reintentar",
          customClass: {
            confirmButton: "ui orange button",
          },
        });
      }
    } /*catch (err) {
      try {
        // Crear el turno local, los turnos deberían estar actualizados, así que no debería haber problema
        // al crearlos
        const axios = serverAxios('local');
        //const resTurno = await posServices.pos.iniciarTurno(valorInicio);
        const resTurno = await posServices.pos.iniciarTurnoServer(valorInicio, axios);
        console.log(resTurno);
        setEstadoBoton('ui positive let labeled icon button');
        if (resTurno.data.CODE === 1000 || !!resTurno.data.SUCCESS) {
          //console.log("se inicio el turno con exito");
          // aqui debería haber una condición segun la empresa, como solo hay gt no importa y abrimos el nit
          dispatch({ type: actions.NUMERO_MODAL, payload: 5 });
          dispatch({ type: actions.MODIFICAR_ORDEN, payload: { ...state.orderOptions, codigoTurno: resTurno.data.MESSAGE.CodigoTurno } });

          //MOVER ESTO DE AQUI
          let usuarioConTurno = {
            ...usuario,
            Turno: resTurno.data.MESSAGE
          };
          console.log(usuarioConTurno);

          localStorage.removeItem('LOGIN_BETA');
          localStorage.setItem('LOGIN_BETA', JSON.stringify(usuarioConTurno));
          window.dispatchEvent(new Event("cambioStorage"));
          //////////////////////////
          Swal.fire({
            title: 'Turno abierto con éxito',
            icon: "success",
            confirmButtonText: "Continuar",
            customClass: {
              confirmButton: "ui orange button",
            },
          });
        } else {
          Swal.fire({
            title: resTurno.data.MESSAGE,
            icon: "warning",
            confirmButtonText: "Reintentar",
            customClass: {
              confirmButton: "ui orange button",
            },
          });
        }
      }*/ catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
    //
    //}
  };

  const inputHandler = (valor) => {
    if (typeof valor === "number") {
      setIniciaTurno(iniciaTurno + valor);
    } else if (valor === ".") {
      if (iniciaTurno.replace(/[^.]/g, "").length < 1 && valor === ".") {
        setIniciaTurno(iniciaTurno + valor);
      }
    } else if (valor === "!#") {
      setIniciaTurno(iniciaTurno.slice(0, -1));
    } else {
      const valorEvento = valor.target.value;

      if (valorEvento.replace(/[^.]/g, "").length <= 1) {
        if (
          !!Number.isFinite(parseFloat(valorEvento)) ||
          valorEvento === "." ||
          valorEvento[valorEvento.length - 1] == "."
        ) {
          setIniciaTurno(valorEvento);
        }
      }
    }
  };

  const handleClose = () => {
    navigate('/menu')
  }

  return (
    <div className="ui tiny longer modal md-disp">
      <div className="header">Ingresar su Fondo de CAJA</div>

      <div className="content">
        <div className="ui grid">
          <div className="row padBot">
            <div className="sixteen wide column padBot">
              <div className="ui fluid labeled input">
                <div className="ui label">Q.</div>
                <input
                  onChange={(e) => inputHandler(e)}
                  value={iniciaTurno}
                  type="text"
                />
              </div>
            </div>
          </div>

          <div className="stretched row padBotTop">
            <TecladoNumerico inputHandler={inputHandler} usarDecimal={true} />
          </div>
        </div>
      </div>

      <div className="actions">
        <Link
          className="ui negative left labeled icon button"
          to="/menu"
        >
          Regresar
          <i className="x icon"></i>
        </Link>
        <button
          className={estadoBoton}
          onClick={() =>
            empezarTurno({ ...nuevoTurno, MontoInicio: iniciaTurno })
          }
        >
          Iniciar turno
          <i className="check icon"></i>
        </button>
      </div>
    </div>
  );
};

export { ModalAbrirCaja };
