import React from "react";
import "./styles.css";

const PosItem = ({ itemProducto, addProductoDetalle, dispatch, actions }) => {

  //console.log(itemProducto);

  const compararPosicion = (a, b) => {
    if (a.PosicionComplemento < b.PosicionComplemento) return -1;
    if (a.PosicionComplemento > b.PosicionComplemento) return 1;
    return 0;
  }

  const handleClickProduct = () => {
    if (itemProducto.Complementos.length > 0) {

      const complementos = [...itemProducto.Complementos];

      complementos.sort(compararPosicion);

      let arrayComplementos = [];

      complementos.forEach(element => {
        const objComp = {
          CodigoComplemento: element.CodigoComplemento,
          EstadoBoton: 'enabled',
        }
        //console.log(objComp);
        arrayComplementos.push(objComp);
      });

      arrayComplementos.sort(compararPosicion);

      console.log(arrayComplementos);

      dispatch({ type: actions.CARGAR_ESTADO_COMPLEMENTOS, payload: arrayComplementos });
      dispatch({ type: actions.CARGAR_COMPLEMENTOS_MODAL, payload: itemProducto });
      dispatch({ type: actions.NUMERO_MODAL, payload: 4 });
    } else {
      addProductoDetalle(itemProducto);
    }

  };

  let estilo = '';
  if (itemProducto.Estilo !== null) {
    estilo = JSON.parse(`{${itemProducto.Estilo}}`);
  }

  return (
    <div className="column item-padding">
      {itemProducto.Estilo !== null ? (
        <button
          className="ui fluid button item-height prod-margin p-btn"
          onClick={handleClickProduct}
          style={estilo}
        >
          {itemProducto.DescripcionPantalla}
        </button>
      ) : (
        <button
          className="ui fluid button item-height prod-margin p-btn"
          onClick={handleClickProduct}
        >
          {itemProducto.DescripcionPantalla}
        </button>
      )}

    </div>
  );
};

export { PosItem };
