import React from "react";
import { FormCrearPlu } from "../FormCrearPlu";
import { FormOpcion } from "../FormOpcion";

const TabPluOpcion = () => {

    return (
        <div className="ui center aligned two column grid">
            <div className="column">
                <FormCrearPlu />
            </div>
            <div className="column">
                <FormOpcion modalFuente={'crear'} />
            </div>
        </div>
    )

};

export { TabPluOpcion };