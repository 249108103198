import http from "../http-common";

const colaboradorServices = {
    colaborador: {
        getAllColaboradores(codigoEmpresa = 'CAMPEROGT', codigoTienda) {
            return http.get(`/colaborador?CodigoEmpresa=${codigoEmpresa}&CodigoTienda=${codigoTienda}`);
        },
        postUsuario(colaborador = null) {
            return http.post('/colaborador', colaborador);
        },
        modificarColaborador(colaborador = null) {
            return http.put('/colaborador', colaborador);
        },
        borrarColaborador(codigoColaborador = null, codigoEmpresa = "GRANJEROGT") {
            return http.delete(`/colaborador?CodigoColaborador=${codigoColaborador}&CodigoEmpresa=${codigoEmpresa}`);
        }
    },
    rol: {
        getAllRoles(codigoEmpresa = "CAMPEROGT") {
            return http.get(`/rol?CodigoEmpresa=${codigoEmpresa}`);
        }
    },
    tienda: {
        getAllTiendas(codigoEmpresa = "GRANJEROGT") {
            return http.get(`/tienda?CodigoEmpresa=${codigoEmpresa}`)
        }
    }
}

export { colaboradorServices };