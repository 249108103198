import React from "react";
import { useContext } from "react";
import { AgendaPrecios } from "../AgendaPrecios";
import { CalendarioPrecios } from "../CalendarioPrecios";
import { ProductosContext } from "../ProductosContext";
import { FormPrecio } from "../TabPrecio/FormPrecio";

const TabGrafPrecios = ({ modalFuente }) => {

    const { state } = useContext(ProductosContext);

    return (
        <>
            {
                (modalFuente === 'crear-opcion')
                    ? (state.formOpcion.ReportarPrecio == 0)
                        ? (
                            <h3 className="ui center aligned header">
                                Esta opción no reporta precio
                            </h3>
                        )
                        : (
                            (state.estados.estadoPrecio === 'grafico')
                                ? <CalendarioPrecios />
                                : (state.estados.estadoPrecio === 'agenda')
                                    ? <AgendaPrecios />
                                    : <FormPrecio editarCrear={state.estados.estadoPrecio} />
                        )
                    : (
                        (state.estados.estadoPrecio === 'grafico')
                            ? <CalendarioPrecios />
                            : (state.estados.estadoPrecio === 'agenda')
                                ? <AgendaPrecios />
                                : <FormPrecio editarCrear={state.estados.estadoPrecio} />
                    )

            }
        </>
    )
}

export { TabGrafPrecios }