import React from "react";
import "./styles.css";

const PosMenuButtonOptions = ({
  dispatch,
  actions,
  limpiarPos,
  totalOrder,
  llamarGAD,
}) => {
  // 0 = Tipo de documento
  // 1 = Descuentos
  // 2 = Canales
  // 3 = Formas de pago

  const onOpenModal = (modalOption) => {
    dispatch({ type: actions.NUMERO_MODAL, payload: modalOption });
  };

  let btnDescuentoClass = " fluid ui inverted orange icon button button fh ";
  if (totalOrder === 0) {
    btnDescuentoClass += " disabled";
  }

  return (
    <React.StrictMode>
      <div className="ui equal width center aligned padded three column grid">
        <div
          className="row"
          style={{
            padding: "0",
            paddingTop: "1rem",
            backgroundColor: "transparent",
          }}
        >
          <div className="column" style={{ padding: "0", height: "6rem" }}>
            <div className={btnDescuentoClass} onClick={() => onOpenModal(1)}>
              <i className="big icon percent" style={{ fontSize: "3vw" }}></i>

              <div
                className="ui bottom attached large label"
                style={{ backgroundColor: "inherit", color: "inherit" }}
              >
                DESCUENTO
              </div>
            </div>
          </div>
          <div className="column" style={{ padding: "0", height: "6rem" }}>
            <div
              onClick={() => llamarGAD()}
              className="ui fluid inverted orange icon button fh"
            >
              <i
                className="big icon motorcycle"
                style={{ fontSize: "3vw" }}
              ></i>

              <div
                className="ui bottom attached large label"
                style={{ backgroundColor: "inherit", color: "inherit" }}
              >
                LLAMAR GAD
              </div>
            </div>
          </div>

          <div className="column" style={{ padding: "0", height: "6rem" }}>
            <div
              onClick={limpiarPos}
              className="ui fluid inverted red icon button fh"
            >
              <i
                className="big icon times circle"
                style={{ fontSize: "3vw" }}
              ></i>

              <div
                className="ui bottom attached large label"
                style={{ backgroundColor: "inherit", color: "inherit" }}
              >
                CANCELAR
              </div>
            </div>
          </div>

          <div className="ui equal width center aligned padded grid"></div>
        </div>
      </div>
    </React.StrictMode>
  );
};

export { PosMenuButtonOptions };
