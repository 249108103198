import http from "../http-common";

const ordersLogServices = {
  ordersLog: {
    getAllCanales(codigoEmpresa = "GRANJEROGT") {
      return http.get(`/canal?codigoEmpresa=${codigoEmpresa}`);
    },
    getFormasDePago(codigoEmpresa = "GRANJEROGT") {
      return http.get(`/Utilidades/FormasPago?codigoEmpresa=${codigoEmpresa}`);
    },
    getDocumentoBitacora(formParams) {
      /*
      {
          codigoEmpresa: '', //
          codigoTienda: 0,//
          codigoOrden: 0,  //
          codigoCanal: 0,
          contenidoBitacora: '', //
          fechaOrdenInicio: '', //
          fechaOrdenFin: '', //
          formaPago: null, //
          documentoNumero: null, //
      }
      */
      console.log(formParams);
      let urlParams = `/documentobitacora?codigoEmpresa=${formParams.codigoEmpresa}&codigoTienda=${formParams.codigoTienda}`;

      if (formParams.codigoOrden !== 0 && formParams.codigoOrden !== '') {
        urlParams += `&codigoOrden=${formParams.codigoOrden}`;
      }

      if (formParams.formaPago !== "") {
        urlParams += `&formaPago=${formParams.formaPago}`;
      }

      if (formParams.documentoNumero !== "") {
        urlParams += `&documentoNumero=${formParams.documentoNumero}`;
      }

      if (formParams.fechaOrdenInicio !== "") {
        urlParams += `&fechaOrdenInicio=${formParams.fechaOrdenInicio}`;
      }

      if (formParams.fechaOrdenFin !== "") {
        urlParams += `&fechaOrdenFin=${formParams.fechaOrdenFin}`;
      }

      if (formParams.contenidoBitacora !== "") {
        urlParams += `&contenidoBitacora=${formParams.contenidoBitacora}`;
      }

      return http.get(urlParams);
    },
    getBitacoraCompleta(formParams) {
      /*
      {
          codigoEmpresa: '', //
          codigoTienda: 0,//
          codigoOrden: 0,  //
          codigoCanal: 0,
          contenidoBitacora: '', //
          fechaOrdenInicio: '', //
          fechaOrdenFin: '', //
          formaPago: null, //
          documentoNumero: null, //
      }
      */
      //console.log(formParams);
      let urlParams = `/DocumentoBitacora/BitacoraCompleta?codigoEmpresa=${formParams.codigoEmpresa}&codigoTienda=${formParams.codigoTienda}`;

      if (formParams.codigoOrden !== 0 && formParams.codigoOrden !== '') {
        urlParams += `&codigoAmbos=${formParams.codigoOrden}`;
      }

      if (formParams.formaPago !== "") {
        urlParams += `&formaPago=${formParams.formaPago}`;
      }

      if (formParams.codigoCanal !== "") {
        urlParams += `&codigoCanal=${formParams.codigoCanal}`;
      }

      if (formParams.documentoNumero !== "") {
        urlParams += `&documentoNumero=${formParams.documentoNumero}`;
      }

      if (formParams.fechaOrdenInicio !== "") {
        urlParams += `&fechaOrdenInicio=${formParams.fechaOrdenInicio}`;
      }

      if (formParams.fechaOrdenFin !== "") {
        urlParams += `&fechaOrdenFin=${formParams.fechaOrdenFin}`;
      }

      if (formParams.contenidoBitacora !== "") {
        urlParams += `&contenidoBitacora=${formParams.contenidoBitacora}`;
      }

      return http.get(urlParams);
    },
  },
};
//https://localhost:44380/api/documentobitacora?codigoEmpresa=GRANJEROGT&codigoTienda=999&codigoOrden=&formaPago=&fechaOrdenInicio=2022-07-18&fechaOrdenFin=2022-07-22
export { ordersLogServices };