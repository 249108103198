import React, { useContext, useEffect } from "react";
import { ProductosContext } from "../../ProductosContext";
import Multiselect from "multiselect-react-dropdown";

import './style.css'

const FormPrecio = ({ editarCrear }) => {

    const {
        state,
        changeFormPrecio,
        submitFormPrecioCanales,
        todosDias,
        todoElDia,
        editarPrecioUnico,
        onSelectCanal,
        onRemoveCanal,
        editarPrecioPorCanal,
        todosCanales,
        regresarAlCalendario,
        cambiarEstadoDiaSemana,
    } = useContext(ProductosContext);

    let claseFieldCanal = 'field';
    if (editarCrear === 'editar' || editarCrear === 'todos-canales-editar' || editarCrear === 'todos-canales-crear') claseFieldCanal = 'disabled field';
    else claseFieldCanal = 'field'

    return (
        <>
            <h4 className="ui center aligned header">Agregar Precio</h4>
            <form className="ui form">
                <div className="three fields">
                    <div className={claseFieldCanal}>
                        <label>Canal(es)</label>
                        {
                            (editarCrear === 'editar' || editarCrear === 'todos-canales-editar' || editarCrear === 'todos-canales-crear')
                                ? (
                                    <input defaultValue={state.canalModificarUnico} name="Precio" type="text" />
                                )
                                : (
                                    <Multiselect
                                        options={state.response.productosApi.canales}
                                        displayValue="Descripcion"
                                        onSelect={onSelectCanal}
                                        onRemove={onRemoveCanal}
                                        placeholder="Canal(es)..."
                                    />
                                )
                        }
                    </div>
                    <div className="field">
                        <label>Precio</label>
                        <input onChange={(e) => changeFormPrecio(e)} value={state.formPrecio.Precio} name="Precio" type="text" />
                    </div>

                    {
                        (editarCrear === 'todos-canales-editar' || editarCrear === 'todos-canales-crear' || editarCrear === 'editar-canal' || editarCrear === 'crear')
                            ? (
                                <div className="field form-slider">
                                    <div className="ui toggle checkbox">
                                        <input
                                            onChange={todosCanales}
                                            checked={state.formPrecio.TodosCanales} type="checkbox" />
                                        <label>Canal: TODOS</label>
                                    </div>
                                </div>
                            )
                            : null
                    }

                </div>
                <div className="five fields">
                    <div className="field">
                        <label>Hora Inicio</label>
                        <input onChange={(e) => changeFormPrecio(e)} value={state.formPrecio.HoraInicio} name="HoraInicio" type="time" />
                    </div>
                    <div className="field">
                        <label>Hora Fin</label>
                        <input onChange={(e) => changeFormPrecio(e)} value={state.formPrecio.HoraFin} name="HoraFin" type="time" />
                    </div>
                    <div className="field form-slider">
                        <div className="ui toggle checkbox">
                            <input
                                onChange={() => todoElDia('TodoDia')}
                                checked={state.formPrecio.TodoDia} type="checkbox" />
                            <label>Horario: Todo el día</label>
                        </div>
                    </div>
                    <div className="field form-slider">
                        <div className="ui toggle checkbox">
                            <input
                                onChange={() => todoElDia('Desayuno')}
                                checked={state.formPrecio.Desayuno} type="checkbox" />
                            <label>Horario: Desayuno</label>
                        </div>
                    </div>
                    <div className="field form-slider">
                        <div className="ui toggle checkbox">
                            <input
                                onChange={() => todoElDia('AlmuerzoCena')}
                                checked={state.formPrecio.AlmuerzoCena} type="checkbox" />
                            <label>Horario: Almuerzo/Cena</label>
                        </div>
                    </div>
                </div>

                <div className="two fields">
                    <div className="field">
                        <label>Fecha Inicio</label>
                        <input onChange={(e) => changeFormPrecio(e)} value={state.formPrecio.FechaInicio} name="FechaInicio" type="date" />
                    </div>
                    <div className="field">
                        <label>Fecha Fin</label>
                        <input onChange={(e) => changeFormPrecio(e)} value={state.formPrecio.FechaFin} name="FechaFin" type="date" />
                    </div>

                </div>
                <div className="four fields">
                    <div className="field">
                        <div className="ui checkbox">
                            <input
                                onChange={() => cambiarEstadoDiaSemana('Lunes')}
                                checked={state.diasSemana.Lunes} type="checkbox" />
                            <label>Lunes</label>
                        </div>
                    </div>
                    <div className="field">
                        <div className="ui checkbox">
                            <input
                                onChange={() => cambiarEstadoDiaSemana('Martes')}
                                checked={state.diasSemana.Martes} type="checkbox" />
                            <label>Martes</label>
                        </div>
                    </div>
                    <div className="field">
                        <div className="ui checkbox">
                            <input
                                onChange={() => cambiarEstadoDiaSemana('Miercoles')}
                                checked={state.diasSemana.Miercoles} type="checkbox" />
                            <label>Miércoles</label>
                        </div>
                    </div>
                    <div className="field">
                        <div className="ui checkbox">
                            <input
                                onChange={() => cambiarEstadoDiaSemana('Jueves')}
                                checked={state.diasSemana.Jueves} type="checkbox" />
                            <label>Jueves</label>
                        </div>
                    </div>
                </div>
                <div className="four fields">
                    <div className="field">
                        <div className="ui checkbox">
                            <input
                                onChange={() => cambiarEstadoDiaSemana('Viernes')}
                                checked={state.diasSemana.Viernes} type="checkbox" />
                            <label>Viernes</label>
                        </div>
                    </div>
                    <div className="field">
                        <div className="ui checkbox">
                            <input
                                onChange={() => cambiarEstadoDiaSemana('Sabado')}
                                checked={state.diasSemana.Sabado} type="checkbox" />
                            <label>Sábado</label>
                        </div>
                    </div>
                    <div className="field">
                        <div className="ui checkbox">
                            <input
                                onChange={() => cambiarEstadoDiaSemana('Domingo')}
                                checked={state.diasSemana.Domingo} type="checkbox" />
                            <label>Domingo</label>
                        </div>
                    </div>
                    <div className="field">
                        <div className="ui checkbox">
                            <input
                                onChange={() => todosDias()}
                                checked={state.diasSemana.Todos} type="checkbox" />
                            <label>Todos los días</label>
                        </div>
                    </div>
                </div>
            </form>
            <div className="ui two column grid">
                <div className="column">
                    <button onClick={regresarAlCalendario} className="ui secondary fluid icon labeled button">
                        <i className="close icon"></i>
                        Regresar al calendario
                    </button>

                </div>
                <div className="column">
                    {
                        (editarCrear === 'editar')
                            ? (
                                <button onClick={editarPrecioUnico} className="ui blue fluid icon labeled button">
                                    <i className="edit icon"></i>
                                    Modificar precio
                                </button>
                            )
                            : (editarCrear === 'editar-canal' || editarCrear === 'todos-canales-editar')
                                ? (
                                    <button onClick={editarPrecioPorCanal} className="ui blue fluid icon labeled button">
                                        <i className="edit icon"></i>
                                        Modificar canales
                                    </button>
                                )
                                : (
                                    <button onClick={submitFormPrecioCanales} className="ui positive fluid icon labeled button">
                                        <i className="plus icon"></i>
                                        Agregar precio
                                    </button>
                                )


                    }

                </div>

            </div>

        </>
    )
}

export { FormPrecio }