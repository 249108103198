import { useRef } from 'react';
import './style.css';

const TabDevAnterior = ({
    botonesAnular,
    handleChange,

    motivosDevolucion,
    formData,
    handleSubmit,
    buscarFecha,

    activarTeclado,
}) => {

    const refMotivo = useRef();

    return (
        <div className="ui form">

            <div className="fields">
                <div className="five wide field">
                    <label>Fecha</label>
                    <input onChange={handleChange} name="Fecha" type="date" id="date-input" />

                </div>
                <div className="eleven wide field">
                    <label>Número de Documento</label>
                    <div className="fields">
                        <div className="eight wide field">
                            <input onFocus={(e) => activarTeclado(true, e, 'num')} onChange={handleChange} value={formData.CodigoFormularioFecha} type="text" name="CodigoFormularioFecha" placeholder="Ingrese número de documento" />
                        </div>
                        <div className="eight wide field">
                            <button onClick={buscarFecha} className={botonesAnular.claseBotonBuscarFecha + ' button'}>
                                <i className="search icon"></i>
                                BUSCAR
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field">
                <label>Motivo de Devolución</label>
                <div className="fields">
                    <div className="ten wide field">

                        <select onChange={handleChange}
                            value={formData.CodigoMotivoFecha}
                            name="CodigoMotivoFecha" className="drop-down">
                            <option value={0}>Ninguno</option>
                            {
                                motivosDevolucion.map((item) => {
                                    return (
                                        <option key={item.Condicion} value={item.Condicion}>{item.Descripcion}</option>
                                    )
                                })
                            }
                        </select>

                    </div>
                    <div className="six wide field">
                        {
                            (botonesAnular.textoAnularFecha === 'ANULAR')
                                ? (
                                    <button onClick={handleSubmit} className={botonesAnular.claseBotonAnularFecha + ' button'}>
                                        <i className="backward icon"></i>
                                        {botonesAnular.textoAnularFecha}
                                    </button>
                                )
                                : (
                                    <button onClick={handleSubmit} className='ui orange left fluid labeled icon disabled button'>
                                        <i className="backward icon"></i>
                                        {botonesAnular.textoAnularFecha}
                                    </button>
                                )
                        }
                    </div>
                </div>
            </div>

            {
                (formData.CodigoMotivoFecha == 1)
                    ? (
                        <div ref={refMotivo} className="field">
                            <label>Especifique motivo:</label>
                            <div className="fields">
                                <div className="ten wide field">
                                    <input onFocus={(e) => { activarTeclado(true, e, 'full'); refMotivo.current.scrollIntoView() }} onChange={handleChange} value={formData.ExpMotivoFecha} type="text" name="ExpMotivoFecha" placeholder="Motivo" />
                                </div>
                                <div className="six wide field">

                                </div>
                            </div>
                        </div>
                    )
                    : null
            }

        </div>
    )
}

export { TabDevAnterior };