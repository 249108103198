import { posServices } from '../../../api/services/posServices';
import { serverAxios } from '../../../utils/Common';
import { convertirArbolALista } from './Utilities';

const getDataPos = async (codigoEmpresa, codigoTienda, codigoCaja) => {
  try {
    const nuevoAxios = serverAxios('central');

    let codigoRetorno = 0;
    let mensajeRetorno = '';

    // Considerar el manejo del turno cuando existan desconexiones. Consumir el servicio podría hacer que los turnos
    // no sean correctos. Lo mejor, puede ser que sea manejar el turno únicamente desde el localStorage y evitar
    // consumir el servicio getTurnoActivo
    const responseHabilitarCaja = await posServices.pos.getTurnoActivo(
      codigoEmpresa,
      codigoTienda,
      codigoCaja,
      nuevoAxios
    );

    if (responseHabilitarCaja.data.SUCCESS === false) {
      codigoRetorno = 2;
      mensajeRetorno = responseHabilitarCaja.data.MESSAGE;
    }

    if (responseHabilitarCaja.data.CODE === 2000) {
      codigoRetorno = -1;
      mensajeRetorno = "Debe cerrar turno del día anterior";
    }

    if (responseHabilitarCaja.data.CODE === 1002) {
      codigoRetorno = 1;
      mensajeRetorno = "Debe abrir un turno nuevo";
    }

    // Todos los servicios deben tener el parámetro para consumir los servicios locales cuando no haya conexión
    const responseMenus = await posServices.pos.getAllMenus(codigoEmpresa, nuevoAxios);
    const menus = responseMenus.data.MESSAGE;
    if (responseMenus.data.CODE !== 1000) {
      codigoRetorno = 2;
      console.error(responseMenus.data.MESSAGE);
      mensajeRetorno = "Error en servicios de menus";
    }

    const responseProductos = await posServices.pos.getAllProductos(
      codigoEmpresa, nuevoAxios
    );
    if (responseProductos.data.CODE !== 1000) {
      codigoRetorno = 2;
      console.error(responseProductos.data.MESSAGE);
      mensajeRetorno = "Error en el servicio de productos";
    }

    const responseCanales = await posServices.pos.getAllCanales(codigoEmpresa, nuevoAxios);
    if (responseCanales.data.CODE !== 1000) {
      codigoRetorno = 2;
      console.error(responseCanales.data.MESSAGE);
      mensajeRetorno = "Error en el servicio de canales";
    }

    const responseDescuentos = await posServices.pos.getAllDescuentos(
      codigoEmpresa, nuevoAxios
    );
    if (responseDescuentos.data.CODE !== 1000) {
      codigoRetorno = 2;
      console.error(responseCanales.data.MESSAGE);
      mensajeRetorno = "Error en el servicio de descuentos";
    }

    const responseDocumentos = await posServices.pos.getAllDocuments(
      codigoEmpresa,
      nuevoAxios
    );
    if (responseDocumentos.data.CODE !== 1000) {
      codigoRetorno = 2;
      console.error(responseDocumentos.data.MESSAGE);
      mensajeRetorno = "Error en el servicio de documentos";
    }

    const responsePuntoDeVenta = await posServices.pos.getPuntosDeVenta(nuevoAxios);
    if (responsePuntoDeVenta.data.CODE !== 1000) {
      codigoRetorno = 2;
      console.error(responsePuntoDeVenta.data.MESSAGE);
      mensajeRetorno = "Error en el servicio de punto de venta";
    }

    const responseFormaPago = await posServices.pos.getFormasDePago(
      codigoEmpresa, codigoTienda, codigoCaja, nuevoAxios
    );
    if (responseFormaPago.data.CODE !== 1000) {
      codigoRetorno = 2;
      console.error(responseFormaPago.data.MESSAGE);
      mensajeRetorno = "Error en el servicio de formas de pago";
    }

    const responseDepartamento = await posServices.pos.getDepartamentos(
      codigoEmpresa.includes("GT") ? "GT" : "SV", nuevoAxios
    );
    if (responseDepartamento.data.CODE !== 1000) {
      codigoRetorno = 2;
      console.error(responseDepartamento.data.MESSAGE);
      mensajeRetorno = "Error en el servicio de departamentos";
    }

    const responseMaximoCF = await posServices.pos.getMaximoCF(
      codigoEmpresa, 'FEL-MAXIMO-CF', nuevoAxios
    );
    if (responseMaximoCF.data.SUCCESS === false) {
      codigoRetorno = 2;
      console.error(responseMaximoCF.data.MESSAGE);
      mensajeRetorno = "Error en el servicio de máximo C/F";
    }

    let listaMenus = [];
    menus.forEach((item) => {
      listaMenus = [...listaMenus, ...convertirArbolALista(item)];
    });

    let configuracionPos = {
      canales: responseCanales.data.MESSAGE,
      descuentos: responseDescuentos.data.MESSAGE,
      documentos: responseDocumentos.data.MESSAGE,
      puntosDeVenta: responsePuntoDeVenta.data.MESSAGE,
      formasPago: responseFormaPago.data.MESSAGE,
      departamento: responseDepartamento.data.MESSAGE,
      menu: listaMenus,
      producto: responseProductos.data.MESSAGE,
      menuArbol: menus,
      valorMaximoCF: responseMaximoCF.data.MESSAGE,
    };


    if (codigoRetorno === -1) {
      return [true, configuracionPos, codigoRetorno, mensajeRetorno];
    } else if (codigoRetorno === 1) {
      return [true, configuracionPos, codigoRetorno, mensajeRetorno];
    } else if (codigoRetorno === 2) {
      configuracionPos = {
        canales: [],
        descuentos: [],
        documentos: [],
        puntosDeVenta: [],
        formasPago: [],
        departamento: [],
        menu: [],
        producto: [],
        menuArbol: [],
      };
      return [false, configuracionPos, codigoRetorno, mensajeRetorno]; //ERROR: no debería mostrar nada la pantalla
    }
    console.log('Corriendo POS Central');
    return [true, configuracionPos, codigoRetorno, mensajeRetorno];
  } catch (error) {

    console.error('ERROR CENTRAL: ', error);
    const nuevoAxios = serverAxios('local');

    let codigoRetorno = 0;
    let mensajeRetorno = '';

    const responseHabilitarCaja = await posServices.pos.getTurnoActivo(
      codigoEmpresa,
      codigoTienda,
      codigoCaja,
      nuevoAxios
    );

    if (responseHabilitarCaja.data.SUCCESS === false) {
      codigoRetorno = 2;
      mensajeRetorno = responseHabilitarCaja.data.MESSAGE;
    }

    if (responseHabilitarCaja.data.CODE === 2000) {
      codigoRetorno = -1;
      mensajeRetorno = "Debe cerrar turno del día anterior";
    }

    if (responseHabilitarCaja.data.CODE === 1002) {
      codigoRetorno = 1;
      mensajeRetorno = "Debe abrir un turno nuevo";
    }

    const responseMenus = await posServices.pos.getAllMenus(codigoEmpresa, nuevoAxios);
    const menus = responseMenus.data.MESSAGE;
    if (responseMenus.data.CODE !== 1000) {
      codigoRetorno = 2;
      console.error(responseMenus.data.MESSAGE);
      mensajeRetorno = "Error en servicios de menus";
    }

    const responseProductos = await posServices.pos.getAllProductos(
      codigoEmpresa, nuevoAxios
    );
    if (responseProductos.data.CODE !== 1000) {
      codigoRetorno = 2;
      console.error(responseProductos.data.MESSAGE);
      mensajeRetorno = "Error en el servicio de productos";
    }

    const responseCanales = await posServices.pos.getAllCanales(codigoEmpresa, nuevoAxios);
    if (responseCanales.data.CODE !== 1000) {
      codigoRetorno = 2;
      console.error(responseCanales.data.MESSAGE);
      mensajeRetorno = "Error en el servicio de canales";
    }

    const responseDescuentos = await posServices.pos.getAllDescuentos(
      codigoEmpresa, nuevoAxios
    );
    if (responseDescuentos.data.CODE !== 1000) {
      codigoRetorno = 2;
      console.error(responseCanales.data.MESSAGE);
      mensajeRetorno = "Error en el servicio de descuentos";
    }

    const responseDocumentos = await posServices.pos.getAllDocuments(
      codigoEmpresa,
      nuevoAxios
    );
    if (responseDocumentos.data.CODE !== 1000) {
      codigoRetorno = 2;
      console.error(responseDocumentos.data.MESSAGE);
      mensajeRetorno = "Error en el servicio de documentos";
    }

    const responsePuntoDeVenta = await posServices.pos.getPuntosDeVenta(nuevoAxios);
    if (responsePuntoDeVenta.data.CODE !== 1000) {
      codigoRetorno = 2;
      console.error(responsePuntoDeVenta.data.MESSAGE);
      mensajeRetorno = "Error en el servicio de punto de venta";
    }

    const responseFormaPago = await posServices.pos.getFormasDePago(
      codigoEmpresa, nuevoAxios
    );
    if (responseFormaPago.data.CODE !== 1000) {
      codigoRetorno = 2;
      console.error(responseFormaPago.data.MESSAGE);
      mensajeRetorno = "Error en el servicio de formas de pago";
    }

    const responseDepartamento = await posServices.pos.getDepartamentos(
      codigoEmpresa.includes("GT") ? "GT" : "SV", nuevoAxios
    );
    if (responseDepartamento.data.CODE !== 1000) {
      codigoRetorno = 2;
      console.error(responseDepartamento.data.MESSAGE);
      mensajeRetorno = "Error en el servicio de departamentos";
    }

    const responseMaximoCF = await posServices.pos.getMaximoCF(
      codigoEmpresa, 'FEL-MAXIMO-CF', nuevoAxios
    );
    if (responseMaximoCF.data.SUCCESS === false) {
      codigoRetorno = 2;
      console.error(responseMaximoCF.data.MESSAGE);
      mensajeRetorno = "Error en el servicio de máximo C/F";
    }

    let listaMenus = [];
    menus.forEach((item) => {
      listaMenus = [...listaMenus, ...convertirArbolALista(item)];
    });

    let configuracionPos = {
      canales: responseCanales.data.MESSAGE,
      descuentos: responseDescuentos.data.MESSAGE,
      documentos: responseDocumentos.data.MESSAGE,
      puntosDeVenta: responsePuntoDeVenta.data.MESSAGE,
      formasPago: responseFormaPago.data.MESSAGE,
      departamento: responseDepartamento.data.MESSAGE,
      menu: listaMenus,
      producto: responseProductos.data.MESSAGE,
      menuArbol: menus,
      valorMaximoCF: responseMaximoCF.data.MESSAGE,
    };


    if (codigoRetorno === -1) {
      return [true, configuracionPos, codigoRetorno, mensajeRetorno];
    } else if (codigoRetorno === 1) {
      return [true, configuracionPos, codigoRetorno, mensajeRetorno];
    } else if (codigoRetorno === 2) {
      configuracionPos = {
        canales: [],
        descuentos: [],
        documentos: [],
        puntosDeVenta: [],
        formasPago: [],
        departamento: [],
        menu: [],
        producto: [],
        menuArbol: [],
      };
      return [false, configuracionPos, codigoRetorno, mensajeRetorno];
    }
    console.log('Corriendo POS local');
    return [true, configuracionPos, codigoRetorno, mensajeRetorno];

  }
}

export { getDataPos };
