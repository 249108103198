import { useEffect } from 'react';

import { CierreBotones } from '../CierreBotones';
import { CierreForm } from '../CierreForm';
import { CierreInfoUsuario } from '../CierreInfoUsuario'
import { CierreRegistroRemesa } from '../CierreRegistroRemesa';
import { CierreTablaRemesa } from '../CierreTablaRemesa';
import { Modal } from '../../Modal';
import { ModalMiniLoader } from '../ModalMiniLoader';
import { TecladoNumFlotante } from '../../TecladoNumFlotante';

import './style.css';

const CierresUI = ({
  remesasApi,

  formData,
  formExtras,
  handleSubmit,
  handleChange,
  setMonedaCredito,
  setMonedaCambio,
  cuentaBanco,
  fondoCaja,
  codigoCaja,

  deleteRow,

  cerrarTurno,
  imprimirCorte,

  numberModal,
  openModal,

  valoresTeclado,
  activarTeclado,
}) => {

  useEffect(() => {
    window.$(".ui.dropdown").dropdown();
    window.$(".ui.checkbox").checkbox();
  }, []);

  return (
    <>
      <div className="ui raised padded text container segment segment-color">
        <h1 className="header">Grabación de Remesas</h1>
        <div className="content">

          <CierreInfoUsuario
            codigoCaja={codigoCaja}
            fondoCaja={fondoCaja}
          />

          <div className="ui grid">
            <div className="sixteen wide column">
              <CierreForm
                handleChange={handleChange}
                handleSubmit={handleSubmit}

                remesasApi={remesasApi}
                formExtras={formExtras}

                cuentaBanco={cuentaBanco}

                formData={formData}

                setMonedaCredito={setMonedaCredito}
                setMonedaCambio={setMonedaCambio}

                activarTeclado={activarTeclado}
              />
            </div>
          </div>

          <CierreTablaRemesa pais={formExtras.pais}>
            <CierreRegistroRemesa
              remesasApi={remesasApi}
              deleteRow={deleteRow}
            />
          </CierreTablaRemesa>

          <div className="ui divider"></div>

          <CierreBotones
            cerrarTurno={cerrarTurno}
            imprimirCorte={imprimirCorte}
          />
        </div>


      </div>

      {!!openModal && (
        <Modal>

          {numberModal === 1 ? (
            <ModalMiniLoader />
          ) : null

          }
        </Modal>
      )}

      <div className={valoresTeclado.claseEspacioBlanco}>

      </div>

      <div className={valoresTeclado.claseTeclado}>
        <TecladoNumFlotante inputHandler={handleChange} activarTeclado={activarTeclado} usarDecimal={true} />
      </div>
    </>
  );
}

export { CierresUI }