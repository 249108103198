import { useContext } from "react"
import { ProductosContext } from "../ProductosContext"
import { TabEditarComplemento } from "../TabEditarComplemento";

const ModalEditarComplemento = () => {
    const {
        state,
        guardarComplementoEditado,
        cerrarModalComplemento,
    } = useContext(ProductosContext);

    let claseBotonGuardar = 'ui positive icon labeled button';

    if (state.estados.estadoEditarComplemento === 'buscando') claseBotonGuardar = 'ui disabled positive icon labeled button';
    else claseBotonGuardar = 'ui positive icon labeled button';

    return (
        <div className="ui modal md-disp">

            <div className="header">
                Editar Complemento
            </div>

            <div className="scrolling content color-contenido">
                <TabEditarComplemento />
            </div>

            <div className="actions">
                <button onClick={cerrarModalComplemento} className="ui negative icon labeled button">
                    <i className="close icon"></i>
                    Cancelar
                </button>
                <button onClick={guardarComplementoEditado} className={claseBotonGuardar}>
                    <i className="save icon"></i>
                    Guardar edición
                </button>
            </div>

        </div>
    )
}

export { ModalEditarComplemento }