import React from 'react';
import { Link } from 'react-router-dom';

const MenuTitulo = () => {
  return (
    <div className="ui center aligned segment">
      <div className="ui three column grid">
        <div className="two wide column">
          <Link to="/pos" type="button" className="ui orange left labeled icon approve button">
            <i className="arrow alternate circle left icon"></i>
            REGRESAR
          </Link>
        </div>
        <div className='twelve wide column'>
          <h1>División de Cuentas - Mesa No. 6</h1>
        </div>
        <div className="two wide column">
          <button className="ui orange left labeled icon approve button">
            <i className="save icon"></i>
            GUARDAR
          </button>
        </div>
      </div>
    </div>
  );
};

export { MenuTitulo };