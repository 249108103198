import React from 'react';
import { OrderList } from '../OrderList';
import './style.css';
import { Link } from 'react-router-dom';

const CallOrderUI = ({ orderData, canales, handleChange, canalSeleccionado, fecha, pais, empresa, titulo }) => {

  if (canalSeleccionado === 'LLEVAR' && empresa === 'CAMPEROGT') canalSeleccionado = 'LEVAR';

  return (
    <div className="ui grid container mt-20">
      <div className="sixteen wide column">
        <div className="ui column center aligned segment segment-color m-0">
          <div className="ui three column grid">
            <div className="column">

            </div>
            <div className="column">
              <h1 className="ui-header">{titulo}</h1>
            </div>
            <div className="column">
              {
                (titulo === 'Ordenes GAD')
                  ? (
                    <Link to={{ pathname: '/pos' }}>
                      <button
                        className="ui left labeled icon orange button">
                        <i className="arrow alternate circle left outline icon"></i>
                        REGRESAR
                      </button>
                    </Link>
                  )
                  : null
              }
            </div>
          </div>
        </div>
        <div className="ui segment segment-color m-0">

          {
            (canales !== undefined)
              ? (
                <form className='ui form'>
                  <div className="field">
                    <label>Filtrar órdenes por canal</label>
                    <select value={canalSeleccionado} onChange={(e) => handleChange(e)} name="CodigoBanco" className="ui fluid dropdown">
                      {
                        canales.map((item) => {
                          return (
                            <option key={item.CodigoCanal} value={item.Descripcion}>{item.Descripcion}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </form>
              )
              : null
          }

          {/* <form className='ui form'>
            <div className="field">
              <label>Filtrar órdenes por canal</label>
              <select value={canalSeleccionado} onChange={(e) => handleChange(e)} name="CodigoBanco" className="ui fluid dropdown">
                {
                  canales.map((item) => {
                    return (
                      <option key={item.CodigoCanal} value={item.Descripcion}>{item.Descripcion}</option>
                    )
                  })
                }
              </select>
            </div>
          </form> */}


          <div className="ui segment co-container">
            <OrderList ordenes={orderData} fecha={fecha} pais={pais} empresa={empresa} />
          </div>

        </div>
      </div>
    </div>
  );
};

export { CallOrderUI };