import React from 'react'
import {Link} from 'react-router-dom';
import AvalonLogo from '../../../img/swords.png';
import './style.css';


const Personalizar = () => {

    const [color, setColor] = React.useState('pcolor')

    const colors = ['pcolor', 'black', 'yellow', 'red', 'blue', 'green']

    const navClass = `ui stackable menu nav-avalon text-menu text-font ${color}`
  
    const renderButtons = colors => {
      return colors.map( (color, index) => {
        return ( <li key={index}
          className={'color-selector ' + color}
          onClick={() => setColor(color)}>
        </li> )
      })
    }


    return (
        <>
        <div className={navClass}>
      <div className="item">
        <img src={AvalonLogo} alt="logo" />
        <p className="p__ml">AVALON</p>
      </div>
      <div className="right menu">
        <div className="item">Usuario Admin</div>
        <Link to="/menu" className="item">MENU</Link>
        <Link to="/inicio" className="item">SALIR</Link>
      </div>
    </div>
        <div className="ui container">
            <div className='ui column center aligned segment segment-color mt-2p' id="encabezado" style={{ background: "#FFF7EF", margin: "0 auto" }}>
        <h1 className="ui-header">Personalizar cliente</h1>
      </div>
            <div className='ui segment column grid segment-color m-0'>
                <div className='ui two column centered grid'>
                    <div className='column'>
                    <div className="App">
                    <div id='area' className={color}> </div>
                    <div id='toolbox'>
                        { renderButtons(colors) }
                    </div>
                </div>
                    </div>
                </div>
            </div>
        </div>
        
        </>
        
    );
}

export { Personalizar };