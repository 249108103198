import http from "../http-common";

const bancoServices = {
    banco: {
        getAllBancos(codigoEmpresa = 'GRANJEROGT') {
            return http.get(`/banco?CodigoEmpresa=${codigoEmpresa}`);
        }
    }
}

export { bancoServices };