import React from 'react';
import { ContenedorCuentas } from '../ContenedorCuentas';
import { MenuTitulo } from '../MenuTitulo';

const DivisionUI = ({ estado, setEstado, onDragEnd, eliminarCuenta, agregarCuenta }) => {

  React.useEffect(() => {
    window.$('.ui.accordion').accordion();
  }, []);

  return (
    <div className="ui center aligned column">
      <MenuTitulo />
      <ContenedorCuentas
        estado={estado}
        setEstado={setEstado}
        onDragEnd={onDragEnd}
        eliminarCuenta={eliminarCuenta}
        agregarCuenta={agregarCuenta}
      />
    </div>
  );
};

export { DivisionUI }