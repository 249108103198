import './style.css';

const RegistroDetalle = ({ detalleDescuento }) => {
    return (
        <>
            {
                detalleDescuento.map((item) => {

                    let claseRegistro = 'normal'

                    if (item.Orden.CodigoEtapa === "ANUL") {
                        claseRegistro = 'anulado'
                    }

                    let tiempo = item.Orden.TiempoCreacion.split('T')[1];
                    let horas = tiempo.split(':')[0];
                    let minutos = tiempo.split(':')[1];

                    return (
                        <tr className={claseRegistro} key={item.Orden.CodigoOrden}>
                            <td>{item.Orden.CodigoOrden}</td>
                            <td>{item.Orden.DocumentoNumero}</td>
                            <td>{(Math.round((item.Orden.TotalFisico + item.Orden.TotalVirtual) * 100) / 100).toFixed(2)}</td>
                            <td>{item.Orden.NombreColaborador}</td>
                            <td>{item.Orden.CodigoEtapa}</td>
                            <td>{horas}:{minutos}</td>
                        </tr>
                    )
                })
            }
        </>
    )
}

export { RegistroDetalle }