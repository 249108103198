import { MiniLoader } from "../../MiniLoader";

const ModalMiniLoader = () => {
    return (
        <div className="ui tiny longer modal md-disp">
            <div className="content">
                <div className="ui centered grid m-0">
                    <MiniLoader />
                </div>
            </div>
        </div>
    );
};

export { ModalMiniLoader };