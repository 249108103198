// MODAL 6
import React, { useEffect, useState } from "react";
import { ordenCredito } from "../../../constants/OrdenFiscalizada";
import { TecladoCompleto } from "../../TecladoCompleto";
import { useTecladoAlfanumerico } from "../../../hooks/useTecladoNumerico";
import "./style.css";

const ModalContingenciaManual = ({
  state,
  dispatch,
  actions,
  flujoDeCobro,
}) => {
  const {
    claseTeclado,
    claseEspacioBlanco,
    formData,
    capsLock,
    activarTeclado,
    inputHandler,
    setFormData,
  } = useTecladoAlfanumerico({ contingencia: "" });

  useEffect(() => {
    window.$(".ui.dropdown").dropdown();
  }, []);

  const eliminarEvento = (e) => {
    e.preventDefault();
  };

  const terminarOrden = (e) => {
    let nuevoPagoTC = {
      ...ordenCredito,
      Autorizacion: formData.contingencia,
    };

    //HACER: CONTINGENCIA
    let baseContingencia = 'FE-GRANJEROGT-999-1-' + formData.contingencia;
    
    dispatch({ type: actions.CONTINGENCIA, payload: baseContingencia });
    dispatch({ type: actions.NUMERO_MODAL, payload: 11 });
    flujoDeCobro();
  };

  let btnAceptar = "ui positive right labeled icon button";
  if (formData.contingencia === "") {
    btnAceptar += " disabled";
  }

  return (
    <div className="ui large longer modal md-disp">
      <div className="header">Contingencia Manual</div>

      <div className="content">
        <form className="ui form" onSubmit={eliminarEvento}>
          <div className="field">
            <label>No. contingencia</label>
            <input
              value={formData.contingencia}
              onChange={(e) => inputHandler(e, "alfaNumerico-no")}
              type="text"
              name="contingencia"
              placeholder="No. contingencia"
              onFocus={(e) => activarTeclado(true, e, "alfaNumerico-no")}
              autoFocus
            />
          </div>
        </form>
      </div>

      <div className="actions">
        <button type="button" onClick={terminarOrden} className={btnAceptar}>
          Aceptar
          <i className="check icon"></i>
        </button>
        <button
          type="button"
          onClick={() => dispatch({ type: actions.CERRAR_MODAL })}
          className="ui negative right labeled icon button"
        >
          Cancelar
          <i className="x icon"></i>
        </button>
      </div>

      <div className={claseEspacioBlanco}></div>
      <TecladoCompleto
        claseActivar={claseTeclado}
        handleChange={inputHandler}
        activarTeclado={activarTeclado}
        capsLock={capsLock}
      />
    </div>
  );
};

export { ModalContingenciaManual };
