import { getEstadoCaja } from "../../../utils/Common"
import { remesaServices } from "../../../api/services/remesaServices";
import { bancoServices } from "../../../api/services/bancoServices";

const getRemesasBancos = async (codigoEmpresa, codigoTienda, codigoCaja, fechaYHora, intentosFacturacion) => {

    let respuesta = {};

    // Esto probablemente ya no es necesario
    let estadoCaja = getEstadoCaja();

    if (estadoCaja === 'CONT') {

        const remesas = JSON.parse(localStorage.getItem('REMESAS'));
        const bancos = JSON.parse(localStorage.getItem('BANCOS'));
        const saldos = JSON.parse(localStorage.getItem('SALDOS'));
        const pendienteAux = saldos.filter(saldo => saldo.Saldo === 'SaldoPendiente');
        const aFavorAux = saldos.filter(saldo => saldo.Saldo === 'SaldoAFavor');
        const fondoCajaAux = saldos.filter(saldo => saldo.Saldo === 'FondoCaja');

        return respuesta = {
            remesas,
            bancos,
            saldoPendiente: pendienteAux[0].Valor,
            saldoAFavor: aFavorAux[0].Valor,
            fondoCaja: fondoCajaAux[0].Valor,
            loading: false,
        }

    }
    else {
        try {
            const respRemesa = await remesaServices.remesa.getAllRemesas(codigoEmpresa, codigoTienda, codigoCaja);
            const remesas = respRemesa.data.MESSAGE.Remesas;
            const saldoPendiente = respRemesa.data.MESSAGE.TotalPendiente;
            const saldoAFavor = respRemesa.data.MESSAGE.SaldoFavor;
            const fondoCaja = respRemesa.data.MESSAGE.MontoInicio;

            console.log(respRemesa);

            if (remesas.length > 0) {
                remesas.forEach(element => {
                    element.ValorRemesa = (Math.round(element.ValorRemesa * 100) / 100).toFixed(2);
                });
            }

            // Se debe actualizar el turno? 

            localStorage.setItem('REMESAS', JSON.stringify(remesas));

            const saldos = [
                { Saldo: 'SaldoPendiente', Valor: saldoPendiente },
                { Saldo: 'SaldoAFavor', Valor: saldoAFavor },
                { Saldo: 'FondoCaja', Valor: fondoCaja },
            ];
            localStorage.setItem('SALDOS', JSON.stringify(saldos));

            // Si esto no da problema, se puede proceder a traer los bancos
            const respBanco = await bancoServices.banco.getAllBancos(codigoEmpresa);
            const bancos = respBanco.data.MESSAGE;

            localStorage.setItem('BANCOS', JSON.stringify(bancos));
            const loading = false;

            return respuesta = {
                remesas,
                saldoPendiente,
                saldoAFavor,
                fondoCaja,
                bancos,
                loading,
            }

        } catch (error) {
            /*let intentosFallidos = getConteoContingencia();

            //console.log(intentosFallidos);
            //console.log(intentosFacturacion);

            if (intentosFallidos === intentosFacturacion - 1) {
                //setEstadoCaja('CONT');
                return respuesta = {
                    estadoCaja: 'CONT',
                }
            }

            setConteoContingencia(intentosFallidos + 1);
            /*setloading(false);
            setError(error.message);*/

            return respuesta = {
                //conteoContingencia: intentosFallidos + 1,
                loading: false,
                error: error.message,
            }
        }
    }

}

const getRemesas = async (codigoEmpresa, codigoTienda, codigoCaja) => {
    let respuesta = {};
    try {
        const respRemesa = await remesaServices.remesa.getAllRemesas(codigoEmpresa, codigoTienda, codigoCaja);
        const remesas = respRemesa.data.MESSAGE.Remesas;
        const saldoPendiente = respRemesa.data.MESSAGE.TotalPendiente;
        const saldoAFavor = respRemesa.data.MESSAGE.SaldoFavor;
        const fondoCaja = respRemesa.data.MESSAGE.MontoInicio;
        console.log(remesas);

        if (remesas.length > 0) {
            remesas.forEach(element => {
                element.ValorRemesa = (Math.round(element.ValorRemesa * 100) / 100).toFixed(2);
            });
        }

        localStorage.setItem('REMESAS', JSON.stringify(remesas));

        const saldos = [
            { Saldo: 'SaldoPendiente', Valor: saldoPendiente },
            { Saldo: 'SaldoAFavor', Valor: saldoAFavor },
            { Saldo: 'FondoCaja', Valor: fondoCaja },
        ];
        localStorage.setItem('SALDOS', JSON.stringify(saldos));

        return respuesta = {
            remesas,
            saldoPendiente,
            saldoAFavor,
            fondoCaja,
        }
    } catch (error) {
        return respuesta = {
            error: error.message,
        }
    }
}

export { getRemesasBancos, getRemesas };