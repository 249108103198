import React from "react";
import { Draggable } from "react-beautiful-dnd";
import './styles.css'

export const ItemOrden = ({ task, index }) => {

    React.useEffect(() => {
        window.$('.ui.accordion').accordion();
    }, []);

    return (
        <Draggable draggableId={task.id} index={index}>
            {(provided, snapshot) => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}

                    className={snapshot.isDragging ? "column-task-dragging" : "column-task-static"}

                >
                    <div class="ui vertical accordion">
                        <div class="item">
                            <a class="fluid title">
                                <i class="dropdown icon"></i>
                                {task.content.DescripcionPantallaPadre}
                            </a>
                            <div class="fluid content">
                                <div className="ui relaxed divided fluid list">
                                    <div className="item">2 Pz Tradicionales</div>
                                    <div className="item">Pepsi</div>
                                    <div className="item">Tortilla x Pan</div>
                                    <div className="item">Agranda Pap/Bebida</div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* <div className="ui fluid accordion">
                        <div className="title" onMouseover="this.style.backgroundColor = 'E3F0F2'" onMouseout="this.style.backgroundColor = 'white'">
                            <i className="dropdown icon"></i>
                            Detalles
                        </div>
                        <div className="fluid content">
                            <div className="ui relaxed divided fluid list">
                                <div className="item">2 Pz Tradicionales</div>
                                <div className="item">Pepsi</div>
                                <div className="item">Tortilla x Pan</div>
                                <div className="item">Agranda Pap/Bebida</div>
                            </div>
                        </div>
                    </div> */}

                </div>
            )}
        </Draggable>
    )
}