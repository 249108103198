import { useRef } from "react"

export const FormUsuario = ({
    handleSubmit,
    handleChange,
    formData,
    errorInput,
    roles,
    formModificar,
    cancelButton,
    activarTeclado,
    scrollToBlanco,
    tiendas,
}) => {

    const refCodigo = useRef();
    const refTienda = useRef();
    const refPin = useRef();
    const refClave = useRef();
    const refClaveConf = useRef();
    const refNombres = useRef();
    const refApellidos = useRef();
    const refRol = useRef();

    return (
        <form className="ui big form" onSubmit={handleSubmit}>
            <div className="two fields">

                <div ref={refCodigo} className={((formModificar === true) ? "disabled " : "") + "field"}>
                    <label>Código *</label>
                    <input onChange={(e) => handleChange(e, 'alfaNumerico-no')}
                        value={formData.CodigoColaborador}
                        id="codigo" name="CodigoColaborador" type="text"
                        className="alfa-numerico"
                        onFocus={(e) => { activarTeclado(true, e, 'alfaNumerico-no'); refCodigo.current.scrollIntoView() }}
                    />
                    {
                        (errorInput == 'codUsuario')
                            ? <span className="errorInput">No se permiten caracteres especiales en este campo</span>
                            : null
                    }

                </div>
                <div ref={refPin} className="field">
                    <label>Pin *</label>
                    <input onChange={(e) => handleChange(e, 'numerico-4')}
                        value={formData.PIN}
                        name="PIN" type="text"
                        className="pin-numerico"
                        onFocus={(e) => { activarTeclado(true, e, 'numerico-4'); refPin.current.scrollIntoView() }}
                    />
                    {
                        (errorInput == 'pinUsuario')
                            ? <span className="errorInput">Solo se permiten números en este campo. Máximo 4 dígitos</span>
                            : null
                    }
                </div>
            </div>

            <div ref={refClave} className="field">
                <label>Clave *</label>
                <input onChange={(e) => handleChange(e, 'pwd-20')}
                    value={formData.Clave}
                    name="Clave" type="password"
                    className="pwd"
                    onFocus={(e) => { activarTeclado(true, e, 'pwd-20'); refClave.current.scrollIntoView() }}
                />
                {
                    (errorInput == 'claveUsuario')
                        ? <span className="errorInput">La clave no puede tener más de 20 caracteres.</span>
                        : null
                }
            </div>

            <div ref={refClaveConf} className="field">
                <label>Confirmar clave *</label>
                <input onChange={(e) => handleChange(e, 'pwd-20')}
                    value={formData.ClaveConfirm}
                    name="ClaveConfirm" type="password"
                    onFocus={(e) => { activarTeclado(true, e, 'pwd-20'); refClaveConf.current.scrollIntoView() }}
                />
                {
                    (errorInput == 'claveConfUsuario')
                        ? <span className="errorInput">La clave no puede tener más de 20 caracteres.</span>
                        : null
                }
            </div>

            <div ref={refNombres} className="field">
                <label>Nombres *</label>
                <input onChange={(e) => handleChange(e, 'soloLetras-32')}
                    value={formData.Nombres}
                    name="Nombres" type="text"
                    className="solo-letras"
                    onFocus={(e) => { activarTeclado(true, e, 'soloLetras-32'); refNombres.current.scrollIntoView() }}
                />
                {
                    (errorInput == 'nombreUsuario')
                        ? <span className="errorInput">Solo se permiten letras y espacios en este campo.</span>
                        : null
                }
            </div>

            <div ref={refApellidos} className="field">
                <label>Apellidos *</label>
                <input onChange={(e) => handleChange(e, 'soloLetras-32')}
                    value={formData.Apellidos}
                    name="Apellidos" type="text"
                    onFocus={(e) => { activarTeclado(true, e, 'soloLetras-32'); scrollToBlanco(); }}
                />
                {
                    (errorInput == 'apellidoUsuario')
                        ? <span className="errorInput">Solo se permiten letras y espacios en este campo.</span>
                        : null
                }
            </div>
            {
                (formModificar === true)
                    ? (
                        <div className="two fields">
                            <div ref={refRol} className="field">
                                <label>Rol * </label>
                                <select onClick={() => activarTeclado(false)} onChange={(e) => handleChange(e, 'no')}
                                    value={formData.CodigoRol}
                                    name="CodigoRol" className="ui fluid dropdown">
                                    {
                                        roles.map((item) => {
                                            return (
                                                <option key={item.CodigoRol} value={item.CodigoRol}>{item.Descripcion}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div ref={refTienda} className="field">
                                <label>Tienda * </label>
                                <select onClick={() => activarTeclado(false)} onChange={(e) => handleChange(e, 'no')}
                                    value={formData.CodigoTienda}
                                    name="CodigoTienda" className="ui fluid dropdown">
                                    {
                                        tiendas.map((item) => {
                                            return (
                                                <option key={item.CodigoTienda} value={item.CodigoTienda}>{item.CodigoTienda}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    )
                    : (
                        <div ref={refRol} className="field">
                            <label>Rol * </label>
                            <select onClick={() => activarTeclado(false)} onChange={(e) => handleChange(e, 'no')}
                                value={formData.CodigoRol}
                                name="CodigoRol" className="ui fluid dropdown">
                                {
                                    roles.map((item) => {
                                        return (
                                            <option key={item.CodigoRol} value={item.CodigoRol}>{item.Descripcion}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    )
            }
            {
                (formModificar == true)
                    ? (
                        <div className="ui field">
                            <button className="ui orange labeled icon button">
                                <i className="edit icon"></i>
                                Modificar
                            </button>
                            <button onClick={cancelButton} type="button" className="ui left labeled icon button">
                                <i className="close icon"></i>
                                Cancelar
                            </button>
                        </div>
                    )
                    : (
                        <div className="ui field">
                            <button className="ui orange labeled icon button">
                                <i className="address card icon"></i>
                                Registrar
                            </button>
                        </div>
                    )
            }



        </form>

    )
}