let valoresLogin = {
  codigoEmpresa: "CAMPEROGT",
  codigoTienda: 999,
  codigoCaja: 1,
  codigoPuntoDeVenta: 1,//Lo devuelve el login
  codigoColaborador: 1000, //Login
  cierreNumero: 1, //Login, correlativo interno de Zeta
  codigoTurno: 6,//Login
}


let valoresDefecto = {
  serie: "TEST", //Serie de la caja, retirar de esta tabla
  codigoCanal: 2, //Se obtiene de un modal
  documentoNumero: null, //No existen al ingresar la orden
  codigoDocumento: 1, //No existen al ingresar la orden pero al ser GT ponemos 1 para factura
  formaDePago: "EFECTIVO",//Se obtiene de un modal
  tiempoInicio: "2022-03-30 12:00:00", //debes de capturar cuando se marque el primer producto
  tiempoFinal: null, //Debería ser null
  totalFisico: 0, //Todo lo que se pague en efectivo
  totalVirtual: 0, //Todo lo que NO se pague en efectivo
  totalDescuento: 0, //Total descuentos aplicados
  totalImpuesto: 0, //Total impuestos aplicados
  codigoCliente: null, //Se obtiene de un flujo que no se ha hecho, y de prioridad baja
  documentoAnulado: null,
  dividida: 0, //0 es no, 1 es sí
  cuenta: 1, //1 por default
  tiempoCreacion: null, //enviar como null
  tiempoFacturacion: null, //enviar como null
  codigoEtapa: "CONF",
  condicion: 0
}

let ordenDescuento = {
  funcion: false,
  parametros: 0,
  objetoFuncion: "",
  valorDigitado: "0",
  referencia: "False",
  descripcionReferencia: "",
  codigoDescuento: "",
  descripcion: ""
};

const ordenContingencia = {
  CodigoOrdenFacturado: null, // no se manda
  CodigoOrdenFactura : null, // es autogenerado
  DocumentoNumero: null,//Empresa + '-' + Tienda + '-' + Caja + '-' + correlativo
  CodigoOrden: null,//Se maneja en el backend
  FolioFiscal: null,//NumeroAcceso de la contingencia
  SerieFiscal: null,//Lote de la contingencia
  Autorizacion: null,//NumeroAcceso de la contingencia
  TiempoIngreso: null,//DateTime.Now
  TiempoActualizacion: null,//DateTime.Now
  Estado: 'CONT',//CONT
  Mensaje: 'Orden en contingencia por desconexión del backend',
  Nit: '',//Nit ingresado en la orden
  Url: 'Contingencia',
  Nombre: '',
  Direccion: '',
};


export { valoresLogin, valoresDefecto, ordenDescuento, ordenContingencia };