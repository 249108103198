import axios from "axios";

const customAxios = (dynamicBaseURL) => {
  const axiosInstance = axios.create({
    baseURL: dynamicBaseURL,
    headers: {
      "Content-type": "application/json",
    },
  });

  return axiosInstance;
};

export { customAxios };
