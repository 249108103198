import http from "../http-common";

const remesaServices = {
    remesa: {
        getAllRemesas(codigoEmpresa = "GRANJEROGT", codigoTienda = 999, codigoCaja = 1) {
            return http.get(`/remesa?CodigoCaja=${codigoCaja}&CodigoEmpresa=${codigoEmpresa}&CodigoTienda=${codigoTienda}`);
            //return http.get('/remesa?CodigoCaja=1&CodigoEmpresa=GRANJEROGT&CodigoTienda=999')
        },
        postRemesa(remesa = null, axios = null) {
            return axios.post('/remesa', remesa);
        },
        borrarRemesa(codigoRemesa = null, codigoEmpresa = "GRANJEROGT", codigoTienda, codigoCaja = 1, codigoTurno, axios = null) {
            //console.log(`/remesa?CodigoEmpresa=${codigoEmpresa}&CodigoTienda=${codigoTienda}&CodioCaja=${codigoCaja}&CodigoTurno=${codigoTurno}&CodigoRemesa=${codigoRemesa}`)
            return axios.delete(`/remesa?CodigoEmpresa=${codigoEmpresa}&CodigoTienda=${codigoTienda}&CodigoCaja=${codigoCaja}&CodigoTurno=${codigoTurno}&CodigoRemesa=${codigoRemesa}`);
        },
    }
}

export { remesaServices }