export const prodData = [
    {
        "CodigoEmpresa": "CAMPEROGT",
        "Empresa": "Campero Guatemala",
        "SimboloMoneda": "Q.",
        "CodigoProducto": "PRODUCTO1",
        "Producto": "Producto 1",
        "CodigoPadre": "AGUAPURA",
        "PrecioPadre": 5,
        "CodigoCanal": 4,
        "Tutor": "p1",
        "Imagen": null,
        "DescripcionPantallaPadre": "AGUA PURA 1962",
        "DescripcionImpresionPadre": "AGUA PURA 1962",
        "EstadoPadre": "ALTA",
        "PosicionProducto": 1,
        "HoraDesde": "07:00:00",
        "HoraHasta": "22:00:00",
        "ValidoDesde": "2022-01-01T00:00:00",
        "ValidoHasta": "2025-01-01T00:00:00",
        "Complementos": []
    }, {
        "CodigoEmpresa": "CAMPEROGT",
        "Empresa": "Campero Guatemala",
        "SimboloMoneda": "Q.",
        "CodigoProducto": "MENALITA",
        "Producto": "Menú Campero 6 Alita",
        "CodigoPadre": "MENALIGT",
        "PrecioPadre": 49,
        "CodigoCanal": 4,
        "Tutor": "6 Alitas",
        "Imagen": null,
        "DescripcionPantallaPadre": "MENÚ CAMPERO 6 ALITAS 42112",
        "DescripcionImpresionPadre": "MENÚ CAMPERO 6 ALITAS",
        "EstadoPadre": "ALTA",
        "PosicionProducto": 1,
        "HoraDesde": "07:00:00",
        "HoraHasta": "23:00:00",
        "ValidoDesde": "2022-01-01T00:00:00",
        "ValidoHasta": "2025-01-01T00:00:00",
        "Complementos": [{
            "CodigoComplemento": "ALITASACOM",
            "DescripcionComplemento": "Acompañamientos Menu",
            "PosicionComplemento": 2,
            "EstadoComplemento": "ALTA",
            "CantidadPorDefectoComplemento": 2,
            "CantidadMinimaComplemento": 2,
            "CantidadMaximaComplemento": 2,
            "Opciones": [{
                "CodigoOpcion": "PURE",
                "DescripcionOpcion": "PURE DE PAPA",
                "PluOpcion": "PURE",
                "PosicionOpcion": 2,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "PURE PAPA 41348",
                "DescripcionImpresionOpcion": "PURE PAPA 41248",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "ENSREP",
                "DescripcionOpcion": "ENSALADA REPOLLO",
                "PluOpcion": "ENSREP",
                "PosicionOpcion": 3,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "ENSALADA DE REPOLLO 41066",
                "DescripcionImpresionOpcion": "ENSALADA DE REPOLLO 41066",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "PAPAMED",
                "DescripcionOpcion": "PAPAS MEDIANAS",
                "PluOpcion": "PAPAMED",
                "PosicionOpcion": 1,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "PAPAS MEDIANAS 41067",
                "DescripcionImpresionOpcion": "PAPAS MEDIANAS 41067",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "ENSLIM",
                "DescripcionOpcion": "ENSALADA CON LIMON",
                "PluOpcion": "ENSLIM",
                "PosicionOpcion": 4,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "ENSALADA CON LIMON 41408",
                "DescripcionImpresionOpcion": "ENSALADA CON LIMON 41408",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "MACQUES",
                "DescripcionOpcion": "MACARRONIX CON QUESO",
                "PluOpcion": "MACQUES",
                "PosicionOpcion": 5,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "MACARRONIX CON QUESO 44767",
                "DescripcionImpresionOpcion": "MACARRONIX CON QUESO 44767",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "DEDQUES",
                "DescripcionOpcion": "4 DEDOS DE QUESO",
                "PluOpcion": "DEDQUES",
                "PosicionOpcion": 6,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "4 DEDOS DE QUESO 44872",
                "DescripcionImpresionOpcion": "4 DEDOS DE QUESO 44872",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "PAPAGRD",
                "DescripcionOpcion": "PAPA GRANDE",
                "PluOpcion": "PAPAGRD",
                "PosicionOpcion": 7,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "PAPA GRANDE 41347",
                "DescripcionImpresionOpcion": "PAPA GRANDE 41347",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "AROCEB",
                "DescripcionOpcion": "AROS CEBOLLA",
                "PluOpcion": "AROCEB",
                "PosicionOpcion": 8,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "AROS CEBOLLA 44883",
                "DescripcionImpresionOpcion": "AROS CEBOLLA 44883",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }]
        }, {
            "CodigoComplemento": "ALITASBEBIDAS",
            "DescripcionComplemento": "Bebidas ",
            "PosicionComplemento": 3,
            "EstadoComplemento": "ALTA",
            "CantidadPorDefectoComplemento": 1,
            "CantidadMinimaComplemento": 1,
            "CantidadMaximaComplemento": 1,
            "Opciones": [{
                "CodigoOpcion": "AGUAPURA",
                "DescripcionOpcion": "AGUA PURA",
                "PluOpcion": "AGUAPURA",
                "PosicionOpcion": 1,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "AGUA PURA 1962",
                "DescripcionImpresionOpcion": "AGUA PURA 1962",
                "PrecioOpcion": 5,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "BEJAMUPG",
                "DescripcionOpcion": "BE LIGHT JAMAICA",
                "PluOpcion": "BEJAMUPG",
                "PosicionOpcion": 8,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "BE LIGHT ROSA JAMAICA 27148",
                "DescripcionImpresionOpcion": "BE LIGHT ROSA JAMAICA 27148",
                "PrecioOpcion": 2.5,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "BELIMUPG",
                "DescripcionOpcion": "BE LIGHT LIMON",
                "PluOpcion": "BELIMUPG",
                "PosicionOpcion": 9,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "BE LIGHT LIMON",
                "DescripcionImpresionOpcion": "BE LIGHT LIMON",
                "PrecioOpcion": 2.5,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "TELIMON",
                "DescripcionOpcion": "TE FRIO LIMON",
                "PluOpcion": "TELIMON",
                "PosicionOpcion": 6,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "TE FRIO LIMON 43902",
                "DescripcionImpresionOpcion": "TE FRIO LIMON 43902",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "TEDURAZ",
                "DescripcionOpcion": "TE FRIO DURAZNO",
                "PluOpcion": "TEDURAZ",
                "PosicionOpcion": 7,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "TE FRIO DURAZNO 43904",
                "DescripcionImpresionOpcion": "TE FRIO DURAZNO 43904",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "PEPSI",
                "DescripcionOpcion": "PEPSI",
                "PluOpcion": "PEPSI",
                "PosicionOpcion": 1,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "PEPSI 44464",
                "DescripcionImpresionOpcion": "PEPSI 44464",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "GRAPETTE",
                "DescripcionOpcion": "GRAPETTE",
                "PluOpcion": "GRAPETTE",
                "PosicionOpcion": 3,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "GRAPETTE 44465",
                "DescripcionImpresionOpcion": "GRAPETTE 44465",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "PEPSIBLK",
                "DescripcionOpcion": "PEPSI BLACK",
                "PluOpcion": "PEPSIBLK",
                "PosicionOpcion": 2,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "PEPSI BLACK 44627",
                "DescripcionImpresionOpcion": "PEPSI BLACK 44627",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "MIRINDA",
                "DescripcionOpcion": "MIRINDA",
                "PluOpcion": "MIRINDA",
                "PosicionOpcion": 4,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "MIRINDA 9903",
                "DescripcionImpresionOpcion": "MIRINDA 9903",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "SEVENUP",
                "DescripcionOpcion": "SEVENUP",
                "PluOpcion": "SEVENUP",
                "PosicionOpcion": 5,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "SEVENUP 9905",
                "DescripcionImpresionOpcion": "SEVEUP 9905",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }]
        }, {
            "CodigoComplemento": "ALITASSEL",
            "DescripcionComplemento": "Escoja tipo de Alitas",
            "PosicionComplemento": 1,
            "EstadoComplemento": "ALTA",
            "CantidadPorDefectoComplemento": 1,
            "CantidadMinimaComplemento": 1,
            "CantidadMaximaComplemento": 1,
            "Opciones": [{
                "CodigoOpcion": "ALEMBAC",
                "DescripcionOpcion": "ALITAS EMPANIZADAS BAÑO BARBACOA",
                "PluOpcion": "ALEMBAC",
                "PosicionOpcion": 1,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "ALITAS EMPANIZADAS BAÑADAS EN BARBACOA 41735",
                "DescripcionImpresionOpcion": "ALITAS EMPANIZADAS BAÑADAS EN BARBACOA 41735",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "ALEMBUC",
                "DescripcionOpcion": "ALITAS EMPANIZADAS BUFFALO",
                "PluOpcion": "ALEMBUC",
                "PosicionOpcion": 2,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "ALITAS EMPANIZADAS BUFFALO 41736",
                "DescripcionImpresionOpcion": "ALITAS EMPANIZADAS BUFFALO 41736",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "ALEMBAS",
                "DescripcionOpcion": "ALITAS EMPANIZADAS BAÑO APARTE BARBACOA",
                "PluOpcion": "ALEMBAS",
                "PosicionOpcion": 3,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "ALITAS EMPANIZADAS BAÑO APARTE BARBACOA 41738",
                "DescripcionImpresionOpcion": "ALITAS EMPANIZADAS BAÑO APARTE BARBACOA 41738",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "ALEMBUS",
                "DescripcionOpcion": "ALITAS EMPANIZADAS BUFFALO APARTE",
                "PluOpcion": "ALEMBUS",
                "PosicionOpcion": 4,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "ALITAS EMPANIZADAS BAÑO BUFFALO APARTE 41739",
                "DescripcionImpresionOpcion": "ALITAS EMPANIZADAS BAÑO BUFFALO APARTE 41739",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "ALEMSB",
                "DescripcionOpcion": "ALITAS EMPANIZADAS",
                "PluOpcion": "ALEMSB",
                "PosicionOpcion": 5,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "ALITAS EMPANIZADAS SIN BAÑO 41741",
                "DescripcionImpresionOpcion": "ALITAS EMPANIZADAS SIN BAÑO 41741",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "ALSEBAC",
                "DescripcionOpcion": "ALITAS SIN EMPANIZAR BARBACOA",
                "PluOpcion": "ALSEBAC",
                "PosicionOpcion": 6,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "ALITAS SIN EMPANIZAR BAÑADAS EN BARBACOA 42114",
                "DescripcionImpresionOpcion": "ALITAS SIN EMPANIZAR BAÑADAS EN BARBACOA 42114",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "ALSEBUC",
                "DescripcionOpcion": "ALITAS SIN EMPANIZAR BUFFALO",
                "PluOpcion": "ALSEBUC",
                "PosicionOpcion": 7,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "ALITAS SIN EMANIZAR BUFFALO 42115",
                "DescripcionImpresionOpcion": "ALITAS SIN EMANIZAR BUFFALO 42115",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "ALSEBAS",
                "DescripcionOpcion": "ALITAS SIN EMPANIZAR BARBACOA APARTE",
                "PluOpcion": "ALSEBAS",
                "PosicionOpcion": 8,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "ALITAS SIN EMPANIZAR BARBACOA BAÑO APARTE 42118",
                "DescripcionImpresionOpcion": "ALITAS SIN EMPANIZAR BARBACOA BAÑO APARTE 42118",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "ALSEBUS",
                "DescripcionOpcion": "ALITAS SIN EMPANIZAR BAÑO APARTE BUFFALO",
                "PluOpcion": "ALSEBUS",
                "PosicionOpcion": 9,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "ALITAS SIN EMPANIZAR BAÑO APARTE BUFALO 42119",
                "DescripcionImpresionOpcion": "ALITAS SIN EMPANIZAR BAÑO APARTE BUFALO 42119",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "ALISBSS",
                "DescripcionOpcion": "ALITAS SIN EMPANIZAR SIN SALSA",
                "PluOpcion": "ALISBSS",
                "PosicionOpcion": 10,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "ALITAS SIN EMPANIZAR SIN SALSA 42117",
                "DescripcionImpresionOpcion": "ALITAS SIN EMPANIZAR SIN SALSA 42117",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }]
        }, {
            "CodigoComplemento": "EXTRAS",
            "DescripcionComplemento": "EXTRAS",
            "PosicionComplemento": 6,
            "EstadoComplemento": "ALTA",
            "CantidadPorDefectoComplemento": 0,
            "CantidadMinimaComplemento": 0,
            "CantidadMaximaComplemento": 1,
            "Opciones": [{
                "CodigoOpcion": "HELMAN",
                "DescripcionOpcion": "HELADO MANI",
                "PluOpcion": "HELMAN",
                "PosicionOpcion": 1,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "HELADO MANI 27221",
                "DescripcionImpresionOpcion": "HELADO MANI 27221",
                "PrecioOpcion": 12,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "HELCHOCO",
                "DescripcionOpcion": "HELADO CHOCO WOW",
                "PluOpcion": "HELCHOCO",
                "PosicionOpcion": 2,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "HELADO CHOCO WOW",
                "DescripcionImpresionOpcion": "HELADO CHOCO WOW 45146",
                "PrecioOpcion": 12,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "HELCHFRE",
                "DescripcionOpcion": "HELADO CHOCO WOW CON FRESA",
                "PluOpcion": "HELCHFRE",
                "PosicionOpcion": 3,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "HELADO CHOCO WOW CON FRESA 45147",
                "DescripcionImpresionOpcion": "HELADO CHOCO WOW CON FRESA 45147",
                "PrecioOpcion": 12,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "HELCHCHO",
                "DescripcionOpcion": "HELADO CHOCO WOW CHOCOLATE",
                "PluOpcion": "HELCHCHO",
                "PosicionOpcion": 4,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "HELADO CHOCO WOW CON CHOCOLATE 45148",
                "DescripcionImpresionOpcion": "HELADO CHOCO WOW CON CHOCOLATE 45148",
                "PrecioOpcion": 12,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "HELCHCAR",
                "DescripcionOpcion": "HELADO CHOCO WOW CARAMELO",
                "PluOpcion": "HELCHCAR",
                "PosicionOpcion": 5,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "HELADO CHOCO WOW CARAMELO 45149",
                "DescripcionImpresionOpcion": "HELADO CHOCO WOW CARAMELO 45149",
                "PrecioOpcion": 12,
                "EstadoOpcion": null
            }]
        }, {
            "CodigoComplemento": "PANTORTILLA",
            "DescripcionComplemento": "Pan o Tortilla",
            "PosicionComplemento": 5,
            "EstadoComplemento": "ALTA",
            "CantidadPorDefectoComplemento": 0,
            "CantidadMinimaComplemento": 1,
            "CantidadMaximaComplemento": 1,
            "Opciones": [{
                "CodigoOpcion": "TORTILLA",
                "DescripcionOpcion": "TORTILLAS",
                "PluOpcion": "TORTILLA",
                "PosicionOpcion": 1,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "TORTILLAS 27784",
                "DescripcionImpresionOpcion": "TORTILLAS 27784",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }, {
                "CodigoOpcion": "PANBOLLO",
                "DescripcionOpcion": "PAN BOLLO",
                "PluOpcion": "PANBOLLO",
                "PosicionOpcion": 2,
                "ReportarPrecio": 1,
                "CantidadMinimaOpcion": 0,
                "CantidadMaximaOpcion": 0,
                "CantidadPorDefectoOpcion": 0,
                "DescripcionPantallaOpcion": "PAN BOLLO 41280",
                "DescripcionImpresionOpcion": "PAN BOLLO 41280",
                "PrecioOpcion": 0,
                "EstadoOpcion": null
            }]
        }]
    }]