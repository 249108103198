import { LOGIN, CONTINGENCIA_SVR_LOCAL } from "../constants";
import { PAGES } from '../constants/pages';
import md5 from 'md5';
import { URLS } from '../constants'
import { customAxios } from "../api/customAxios";

const validarSesionUsuario = () => {
  const strLogin = localStorage.getItem(LOGIN);

  const objLogin = JSON.parse(strLogin);

  if (objLogin === null || Object.keys(objLogin).length === 0) return false;

  let strFecha = getFechaHoy();
  // QUITAR LA SEGUNDA EXPRESIÓN DE ESTE IF
  if (objLogin.FechaLogin === strFecha) return true;

  else return false;
}

const validarPermisoPagina = (pathname = '') => {

  if (pathname === '') return false;

  const roles = getRolesUsuario();
  const srlzdPage = md5(pathname);

  if (roles.length === 0) return false;

  //console.table(roles);

  let bandera = false;

  if (roles.includes(PAGES[0])) return true;

  roles.forEach(item => {
    if (PAGES.includes(item) && srlzdPage.includes(item)) bandera = true;
  });

  return bandera;
}

const getRolesUsuario = () => {
  const strLogin = localStorage.getItem(LOGIN);

  const objLogin = JSON.parse(strLogin);

  const roles = objLogin.Opciones;

  if (roles.length === 0) return [];

  const nuevosRoles = [];
  let srlzdRol = '';

  roles.forEach(element => {
    srlzdRol = md5(element.CodigoOpcion);
    nuevosRoles.push(srlzdRol);
  });

  return nuevosRoles;
}

const eliminarSesionUsuario = () => {
  localStorage.removeItem(LOGIN);
}
// dd/MM/AAAA
const getFechaHoy = () => {
  let fecha = Date.now();
  let fechaHoy = new Date(fecha);

  return fechaHoy.toLocaleDateString();
}

const getConteoContingencia = () => {
  const strContFallo = localStorage.getItem(CONTINGENCIA_SVR_LOCAL);

  const objContFallo = JSON.parse(strContFallo);

  if (objContFallo === null) return -1;

  return objContFallo.conteoContingencia;
}

const setConteoContingencia = (conteo = 0) => {
  const strContFallo = localStorage.getItem(CONTINGENCIA_SVR_LOCAL);

  let objContFallo = JSON.parse(strContFallo);

  if (objContFallo === null) return false;

  objContFallo.conteoContingencia = conteo;
  //console.log(conteo);

  localStorage.setItem(CONTINGENCIA_SVR_LOCAL, JSON.stringify(objContFallo));
}

const getEstadoCaja = () => {
  const strContFallo = localStorage.getItem(CONTINGENCIA_SVR_LOCAL);

  const objContFallo = JSON.parse(strContFallo);

  if (objContFallo === null) return null;

  return objContFallo.estadoCaja;
}

const setEstadoCaja = (estado = 'ALTA') => {
  const strContFallo = localStorage.getItem(CONTINGENCIA_SVR_LOCAL);

  let objContFallo = JSON.parse(strContFallo);

  if (objContFallo === null) return false;

  objContFallo.estadoCaja = estado;

  localStorage.setItem(CONTINGENCIA_SVR_LOCAL, JSON.stringify(objContFallo));

  return true;
}

const getCajaFalloTS = () => {
  const strContFallo = localStorage.getItem(CONTINGENCIA_SVR_LOCAL);

  const objContFallo = JSON.parse(strContFallo);

  if (objContFallo === null) return null;

  return objContFallo.cajaFalloTS;
}

const setCajaFalloTS = (ts = null) => {
  const strContFallo = localStorage.getItem(CONTINGENCIA_SVR_LOCAL);

  let objContFallo = JSON.parse(strContFallo);

  if (objContFallo === null) return false;

  objContFallo.cajaFalloTS = ts;

  localStorage.setItem(CONTINGENCIA_SVR_LOCAL, JSON.stringify(objContFallo));

  return true;
}

const setDatosLogin = (data) => {
  const stringifiedItem = JSON.stringify(data);
  localStorage.setItem(LOGIN, stringifiedItem);

  return true;
}

const validarUrl = (url) => {
  const parsed = new URL(url);

  return ['https:', 'http:'].includes(parsed.protocol);
}

export const getToken = () => {
  return sessionStorage.getItem('TOKEN') || null;
}


const instanciaAxios = (estado = null) => {
  let baseURL = '';
  let estadoCaja = getEstadoCaja();
  if (estadoCaja === 'ALTA' || estado === 'ALTA') {
    baseURL = URLS.BASE_URL_AZURE;
    //baseURL = URLS.BASE_URL_DESARROLLO;
    //baseURL = URLS.BASE_URL_LOCAL;
  } else if (estadoCaja === 'CONT' || estado === 'CONT') {
    baseURL = URLS.BASE_URL_LOCAL;
  }
  return customAxios(baseURL);
}

const serverAxios = (servidor = null) => {
  let baseURL = '';
  //if (servidor === 'central' || servidor === null) baseURL = URLS.BASE_URL_DESARROLLO;
  //if (servidor === 'central' || servidor === null) baseURL = URLS.BASE_URL_LOCAL;
  if (servidor === 'central' || servidor === null) baseURL = URLS.BASE_URL_AZURE;
  else if (servidor === 'local') baseURL = URLS.BASE_URL_LOCAL;
  return customAxios(baseURL);
}

const tiempoEnContingencia = (estado = null, tiempoContingencia) => {
  if (getEstadoCaja() === 'CONT' || estado === 'CONT') {
    const millis = Date.now() - getCajaFalloTS();
    //console.log(getCajaFalloTS());
    //console.log(Date.now());
    const tiempoPasoSegundos = Math.floor(millis / 1000);
    //console.log(tiempoPasoSegundos);

    const tiempoPrueba = 9000000000;

    //if (tiempoPasoSegundos > tiempoContingencia) {
    if (tiempoPasoSegundos > tiempoPrueba) {
      // EL TIEMPO YA SE CUMPLIO, ENTONCES YA NO ESTAMOS EN CONTINGENCIA
      setEstadoCaja('ALTA')
      setCajaFalloTS(null);
      return 'ALTA';
    }

    return 'CONT';
  }

  return 'ALTA';
}

/*
estadoCaja: CONT || ALTA
conteoContingencia: 0
cajaFalloTS: Date.now() || null
*/

export {
  validarSesionUsuario,
  eliminarSesionUsuario,
  getFechaHoy,
  getEstadoCaja,
  setEstadoCaja,
  getConteoContingencia,
  setConteoContingencia,
  getCajaFalloTS,
  setCajaFalloTS,
  setDatosLogin,
  validarUrl,
  validarPermisoPagina,
  instanciaAxios,
  serverAxios,
  tiempoEnContingencia,
};