import { useContext } from "react"
import { ProductosContext } from "../ProductosContext";

const BuscarOpcion = ({ modalFuente }) => {

    const {
        state,
        changeBusquedaOpcion,
        agregarOpcionTabla,
        cambiarEstadoEditarComplemento,
    } = useContext(ProductosContext);

    return (
        <>
            <div className="ui fluid icon input input-plu">
                <input onChange={(e) => changeBusquedaOpcion(e)} value={state.elementoSeleccionado.opcionSeleccionada} type="text" placeholder="Buscar opción..." />
                <i className="search plus icon"></i>
            </div>
            <div className="ui segment plu-list">
                <h4 className="ui centered header">Listado de Opciones</h4>
                {
                    state.response.productosApi.opciones.map((item) => {
                        return (
                            <div key={item.CodigoOpcion} className="plu">
                                <button onClick={() => agregarOpcionTabla(item.CodigoOpcion)} className="ui fluid button">
                                    {item.CodigoOpcion}: {item.Descripcion ?? item.DescripcionOpcion}
                                </button>
                            </div>
                        )
                    })
                }
            </div>
            {
                (modalFuente === 'opcion')
                    ? null
                    : (
                        <button onClick={() => cambiarEstadoEditarComplemento('editando')} className="ui negative icon labeled button">
                            <i className="close icon"></i>
                            Regresar al formulario
                        </button>
                    )
            }

        </>
    )
}

export { BuscarOpcion }