import React from "react";

import { Modal } from '../../Modal';
import { ModalFondoCaja } from '../ModalFondoCaja';
import { TecladoNumerico } from "../../TecladoNumerico";
import { Loader } from '../../Loader';
import { PageError } from "../../PageError";
import CmiLogo from '../../../../img/logo_cmi_transparente.png';

const LoginUi = ({ inputHandler, sendPostRequestPIN, dataLogin, openModal, setOpenModal, empezarTurno, pin, setPin, loader, error }) => {


  if (!!loader) return (<Loader />);

  if (error !== undefined) return (<PageError message={error} source={'login'} />);

  return (
    <>
      <div className="flip-card tecPadTop panelLogin hidden">
        <div className="flip-card-inner" id="flip-card-inner">
          <div className="flip-card-front">
            <div className="ui centered grid">
              <div className="five wide computer twelve wide tablet sixteen wide mobile column">
                <div className="ui raised segment">
                  <h2 className="ui centered header text-font">
                    Inicio de sesión
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="flip-card-back">
            <div className="ui centered grid">
              <div className="five wide computer twelve wide tablet sixteen wide mobile column">
                <div className="ui raised segment">

                  <h2 className="ui center aligned icon header">
                    <img src={CmiLogo} alt="logo" height={140} width={140} style={{ margin: "0 auto" }} />
                    <div className="content">
                      Inicio de Sesión
                      <div className="sub header">Ingrese su número de PIN</div>
                    </div>
                  </h2>

                  <div className="ui grid">
                    <div className="row padBot">
                      <div className="sixteen wide column padBot">
                        <div className="ui fluid input">
                          <input
                            onChange={(e) => inputHandler(e)}
                            type="password"
                            placeholder="PIN..."
                            value={pin}
                            id="PIN"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="stretched row padBotTop">
                      <TecladoNumerico inputHandler={inputHandler} usarDecimal={false} />
                    </div>
                    <div className="sixteen wide column">
                      <div
                        className="ui positive fluid right labeled right floated icon button"
                        onClick={sendPostRequestPIN}
                      >
                        Ingresar
                        <i className="sign-in icon"></i>
                      </div>
                      {/* <div className="ui negative right labeled right floated icon button">
                        Cancelar
                        <i className="x icon"></i> 
                      </div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!!openModal && (
        <Modal>
          <ModalFondoCaja
            dataLogin={dataLogin}
            setOpenModal={setOpenModal}
            empezarTurno={empezarTurno}
            pin={pin}
          />
        </Modal>
      )}
    </>
  );
};

export { LoginUi };