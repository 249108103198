import React from "react";
import './styles.css';

const PosSelectedMenuList = (props) => {
  return (
    <div className="segment">
      {props.children}
    </div>
  );
};

export { PosSelectedMenuList };