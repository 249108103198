import { Link } from "react-router-dom"

export const MenuControlador = () => {
    return (
        <div className="ui segment column grid segment--color title-margin">
            <div className="row">
                <div className="six wide column">
                    <Link to="/pos">
                        <div className="ui orange inverted segment height-all" style={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <i className="massive icon shopping cart swingabble"></i>
                            <div className="ui orange inverted bottom left attached large label"  >
                                MARCACIÓN DE ORDENES
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="ten wide column">

                    <div className="ui equal width center aligned padded two column grid" style={{ height: "100%" }} >

                        <div className="row" style={{ marginTop: "-1rem" }}>
                            <div className="column" style={{ padding: "0" }}>
                                <Link to="/anulaciones">
                                    <div className="ui orange inverted segment height-menu text-av" style={{ marginRight: "0.4rem" }}>
                                        <i className="huge icon calendar window close tadabble"></i>
                                        <div className="ui bottom left attached orange large label"  >
                                            ANULACIÓN DE ORDENES
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="column" style={{ padding: "0" }}>
                                <Link to="/registro-ordenes" style={{ padding: "0", width: "50%" }}>
                                    <div className="ui grey inverted fluid segment height-menu text-av">
                                        <i className="huge icon search tadabble"></i>
                                        <div className="ui grey inverted bottom left attached large label" >
                                            VISOR DIGITAL
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: "-0.5rem", padding: "0" }}>
                            <div className="column" style={{ padding: "0" }}>
                                <Link to="/cierre" >
                                    <div className="ui grey inverted segment height-menu text-av" style={{ marginRight: "0.4rem" }}>
                                        <i className="huge money bill alternate outline icon"></i>
                                        <div className="ui grey inverted bottom left attached large label"  >
                                            REMESAS
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="column" style={{ padding: "0" }}>
                                <div className="ui equal width center aligned padded grid" style={{ height: "100%", padding: "0" }} >
                                    <Link to="/reporte-X" style={{ padding: "0", width: "50%" }}>
                                        <div className="ui orange inverted segment height-menu text-av" style={{ marginRight: "0.4rem" }}>
                                            <i className="huge icon file alternate outline tadabble"></i>
                                            <div className="ui orange inverted bottom left attached large label" >
                                                REPORTE X
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/reporte-ventaPorCanal" style={{ padding: "0", width: "50%" }}>
                                        <div className="ui grey inverted segment height-menu text-av">
                                            <i className="huge icon file percent tadabble"></i>
                                            <div className="ui grey inverted bottom left attached large label" >
                                                VENTA POR CANAL
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div >

        </div >
    )
}