// MODAL 12
import React, {useEffect} from 'react';

const ModalDatosFiscalesSv = ({ state }) => {

  useEffect(() => {
    window.$(".ui.dropdown").dropdown();
  }, []);

  return(
    <div className="ui tiny longer modal md-disp">
      <div className="header">
        <h1>Datos fiscales requeridos</h1>
      </div>
      <div className="content">
        <form className="ui form">
          <div className="field">
            <label>Registro</label>
            <input type="text" name="registro" />
          </div>
          <div class="two fields">
            <div class="field">
              <label>Nombre</label>
              <input type="text" name="nombre" />
            </div>
            <div class="field">
              <label>NIT</label>
              <input type="text" name="nit" />
            </div>
          </div>
          <div class="two fields">
            <div class="field">
              <label>Giro</label>
              <input type="text" name="giro" />
            </div>
            <div class="field">
              <label>Direccion</label>
              <input type="text" name="direccion" />
            </div>
          </div>
          <div class="two fields">
            <div class="field">
              <label>Departamento</label>
              <select class="ui fluid search dropdown" name="departamento">
                {state.response.departamento.map(dpto => (<option value={dpto.CodigoDepartamento}>{dpto.Descripcion}</option>))}
              </select>
            </div>
            <div class="field">
              <label>Municipio</label>
              <select class="ui fluid search dropdown" name="municipio">
                {state.response.departamento[0].Municipios.map(dpto => (<option value={dpto.CodigoDepartamento}>{dpto.Descripcion}</option>))}
              </select>
            </div>
          </div>
        </form>
      </div>
      <div className="actions">
        <button 
            className="ui positive right labeled icon button"
        >
          Aceptar
          <i className="x icon"></i>
        </button>
        <button 
          className="ui negative right labeled icon button"
        >
          Cancelar
          <i className="x icon"></i>
        </button>
      </div>
    </div>
  );
};

export { ModalDatosFiscalesSv };