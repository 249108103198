// MODAL 13
import React, {useContext, useState} from 'react';
import { PosContext } from '../PosContext';
import { TecladoNumerico } from '../../TecladoNumerico';

const ModalNumPreimpreso = ({ state, dispatch, actions }) => {
  const { setOpenModal, datosFiscales, setDatosFiscales, setNumberModal } =
    useContext(PosContext);
  
  const [numeroPreimpreso, setNumeroPreimpreso] = useState('');

  const inputHandler = (valor) => {
    if (typeof valor === "number") {
      setNumeroPreimpreso(numeroPreimpreso + valor);
    }  else if(valor === '!#') {
      setNumeroPreimpreso(numeroPreimpreso.slice(0, -1));
    } else if(valor === '!%') {
      setNumeroPreimpreso('');
    } else {
      const valorEvento = valor.target.value;

      if (valorEvento.replace(/[^.]/g, "").length <= 1) {
        if (
          !!Number.isFinite(parseFloat(valorEvento)) ||
          valorEvento === "." ||
          valorEvento[valorEvento.length - 1] == "."
        ) {
          setNumeroPreimpreso(valorEvento);
        }
      }
    }
  };

  const onCancelPreimpreso = () => {
    dispatch({ type: actions.CERRAR_MODAL });
  }

  const onAceptPreimpreso = () => {
    dispatch({ type: actions.MODIFICAR_DATO_FISCAL, payload: {...state.datosFiscales, documentoNumero: numeroPreimpreso} });
    dispatch({ type: actions.NUMERO_MODAL, payload: 14 });
  }

  let btnAceptarClass = 'ui positive let labeled icon button';
  if(numeroPreimpreso === '') {
    btnAceptarClass += ' disabled';
  }

  return(
    <div className="ui tiny longer modal md-disp">
      <div className="header">FACTURA NO.</div>

      
        <div className="content">
        <div className="ui grid">
          <div className="row padBot">
            <div className="sixteen wide column padBot">
              <div className="ui fluid input">
                <input
                  onChange={(e) => inputHandler(e)}
                  value={numeroPreimpreso}
                  type="text"
                />
              </div>
            </div>
          </div>

          <div className="stretched row padBotTop">
            <TecladoNumerico inputHandler={inputHandler} usarDecimal={false} />
          </div>
        </div>
      </div>
      

      <div className="actions">
        <button
          className={btnAceptarClass}
          onClick={() => onAceptPreimpreso()}
        >
          ACEPTAR
          <i className="check icon"></i>
        </button> 
        <button
          className="ui red let labeled icon button"
          onClick={() => onCancelPreimpreso()}
        >
          CANCELAR
          <i className="x icon"></i>
        </button>
      </div>
    </div>
  );
};

export {ModalNumPreimpreso};