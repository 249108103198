import React from 'react';
import { OrderItem } from '../OrderItem';

const OrderList = ({ ordenes, fecha, pais }) => {
  return (
    <div className='ui four column grid'>
      {
        ordenes.map((item, i) => {
          return (
            <div key={i} className="column">
              <OrderItem infoOrden={item} fecha={fecha} pais={pais} />
            </div>
          )
        })
      }
    </div>
  )
}

export { OrderList };