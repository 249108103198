import React from "react";
import './style.css'
import { gridNumerico } from './gridNumerico';

const TecladoNumFlotante = ({ activarTeclado, inputHandler, claseActivar }) => {
    return (
        <div className="keyboard">
            <div className="keyboard-keys">

                {
                    //keyLayout.map((item) => {
                    gridNumerico.map((item) => {
                        let claseBoton = ' keyboard-key-num';

                        let letra = '';

                        if (item.size === 'wide') {
                            claseBoton = ' keyboard-key-wide';
                        }
                        else if (item.size === 'extra-wide') {
                            claseBoton = ' keyboard-key-extra-wide';
                        }

                        if (item.id === 'cps') claseBoton += ' keyboard-key-activatable';


                        letra = item.valor

                        return (
                            (item.id === '3' || item.id === '6' || item.id === '9' || item.id === 'bckspc')
                                ? (
                                    (item.icon == 'no')
                                        ? (
                                            <React.Fragment key={item.id}>
                                                <button onClick={() => inputHandler(item.valor)} className={'ui button keyboard-key-num' + claseBoton}>
                                                    {item.valor}
                                                </button>
                                                <br />
                                            </React.Fragment>
                                        )
                                        : (
                                            <React.Fragment key={item.id}>
                                                <button onClick={() => inputHandler(item.valor)} className={'ui button keyboard-key-num' + claseBoton}>
                                                    <i className={item.icon}></i>
                                                </button>

                                                <br />
                                            </React.Fragment>
                                        )
                                )
                                : (
                                    (item.icon == 'no')
                                        ? (
                                            <React.Fragment key={item.id}>
                                                <button onClick={() => inputHandler(item.valor)} className={'ui button keyboard-key-num' + claseBoton}>
                                                    {letra}
                                                </button>
                                            </React.Fragment>
                                        )
                                        : (
                                            <React.Fragment key={item.id}>
                                                <button onClick={() => inputHandler(item.valor)} className={'ui button keyboard-key-num' + claseBoton}>
                                                    <i className={item.icon}></i>
                                                </button>
                                            </React.Fragment>
                                        )
                                )
                        )
                    })

                }

            </div>
            <button onClick={() => activarTeclado(false)} type="button" className="ui left labeled icon button keyboard-key keyboard-key-wide keyboard-key-close boton-cancelar">
                <i className="close icon"></i>
                Cerrar
            </button>
        </div>
    )

}

export { TecladoNumFlotante }