

const VentaCanalRegistro = ({ reporte }) => {
    return (
        <>
            {
                reporte.map((item) => {
                    return (
                        <tr key={item.CodigoCanal}>
                            <td>{item.DescripcionCanal}</td>
                            <td>{item.CantidadTransacciones}</td>
                            <td>{item.FormaPago}</td>
                            <td>{item.Total}</td>
                        </tr>
                    )
                })
            }
        </>
    )
}

export { VentaCanalRegistro }

/*        */