import React, { useContext, useState } from "react";
import { PosContext } from "../PosContext";
import { TecladoCompleto } from "../../TecladoCompleto";
import { useTecladoAlfanumerico } from "../../../hooks/useTecladoNumerico";

import './style.css';

const ModalReferenciaDescuento = () => {
  const { addProductoDetalle, calcularDescuento, descuentoFuncion, setDescuentoFuncion, setOpenModal, reiniciarDescuento } = useContext(PosContext);
  const {
    claseTeclado,
    claseEspacioBlanco,
    formData,
    capsLock,
    activarTeclado,
    inputHandler,
    orderOptions,
    setOrderOptions
  } = useTecladoAlfanumerico({ referenciaDescuento: '' });

  const onCancel = () => {
    reiniciarDescuento();
    setOpenModal(false);
  }

  const onAceptReferencia = () => {
    // debería existir una validación para aceptar el descuento si requiere verificación por parte de un 
    let descuentoProvisional = {
      ...descuentoFuncion,
      descripcionReferencia: formData.referenciaDescuento,
      funcion: true
    };
    const descuento = (calcularDescuento(null, descuentoProvisional) * -1).toFixed(2);

    setDescuentoFuncion(descuentoProvisional);
    setOrderOptions({ ...orderOptions, totalDescuento: descuento });
    addProductoDetalle({ CodigoPlu: 'DESCUENTO', descripcion: descuentoProvisional.descripcion, Precio: descuento });
    setOpenModal(false);
  }

  return (
    <div className="ui large longer modal md-disp">
      <div className="header">REFERENCIA DESCUENTO</div>

      <div className="scrolling content">
        <div className="ui grid">
          <div className="row padBot">
            <div className="sixteen wide column padBot">
              <div className="ui fluid input">
                <input
                  onChange={(e) => inputHandler(e, 'alfaNumerico-no')}
                  value={formData.referenciaDescuento}
                  type="text"
                  name="referenciaDescuento"
                  placeholder="REFERENCIA"
                  onFocus={(e) => activarTeclado(true, e, 'alfaNumerico-no')}
                  autoFocus
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {<div className="actions">
        <button
          className="ui green let labeled icon button"
          onClick={onAceptReferencia}
        >
          ACEPTAR
          <i className="check icon"></i>
        </button>
        <button
          className="ui red let labeled icon button"
          onClick={onCancel}
        >
          CANCELAR
          <i className="x icon"></i>
        </button>
      </div>}

      <div className={claseEspacioBlanco}></div>
      <TecladoCompleto
        claseActivar={claseTeclado}
        handleChange={inputHandler}
        activarTeclado={activarTeclado}
        capsLock={capsLock}
      />
    </div>
  );
};

export { ModalReferenciaDescuento };
