import { anulacionServices } from "../../api/services/anulacionServices";
import { getEstadoCaja } from "../../utils/Common";


const getAllAnulacion = async (codigoEmpresa, codigoTienda, codigoCaja, codigoTurno) => {
    let respuesta = {};

    let estadoCaja = getEstadoCaja();

    if (estadoCaja === 'CONT') {
        const ordenesDia = JSON.parse(localStorage.getItem('ORDENES'));
        const motivosDevolucion = JSON.parse(localStorage.getItem('MOTIVOS_DEV'));

        return respuesta = {
            ordenes: ordenesDia,
            motivos: motivosDevolucion,
            loading: false,
        }
    }
    else {
        try {
            // Este servicio devuelve las órdenes del turno actual
            // Solo se pueden anular las órdenes del turno actual
            const ordenesResp = await anulacionServices.orden.getOrdenesPorTurno(codigoEmpresa, codigoCaja, codigoTienda, codigoTurno);

            if (ordenesResp.data.SUCCESS === false) {
                return respuesta = {
                    mensajeFalse: ordenesResp.data.MESSAGE,
                    loading: false,
                }
            }

            let ordenes = ordenesResp.data.MESSAGE;
            ordenes = ordenes.filter(orden => orden.OrdenFactura !== null);

            const motivosResp = await anulacionServices.motivo.getAllMotivosDevolucion(codigoEmpresa);

            if (motivosResp.data.SUCCESS === false) {
                return respuesta = {
                    mensajeFalse: motivosResp.data.MESSAGE,
                    loading: false,
                }
            }

            const motivos = motivosResp.data.MESSAGE;

            localStorage.setItem('ORDENES', JSON.stringify(ordenes));
            localStorage.setItem('MOTIVOS_DEV', JSON.stringify(motivos));

            return respuesta = {
                ordenes,
                motivos,
                loading: false,
            }

        } catch (error) {
            return respuesta = {
                loading: false,
                error: error.message,
            }
        }
    }
}

const getOrdenesTurno = async (codigoEmpresa, codigoTienda, codigoCaja, codigoTurno) => {
    let respuesta = {}
    try {
        //const ordenesResp = await anulacionServices.orden.getOrdenesPorFecha(fechaHoy, codigoEmpresa, codigoTienda, codigoCaja);
        const ordenesResp = await anulacionServices.orden.getOrdenesPorTurno(codigoEmpresa, codigoCaja, codigoTienda, codigoTurno);

        if (ordenesResp.data.SUCCESS === false) {
            return respuesta = {
                mensajeFalse: ordenesResp.data.MESSAGE,
                loading: false,
            }
        }

        let ordenes = ordenesResp.data.MESSAGE;
        ordenes = ordenes.filter(orden => orden.OrdenFactura !== null);

        return respuesta = {
            ordenes,
        }
    } catch (error) {
        return respuesta = {
            error,
        }
    }
}

export { getAllAnulacion, getOrdenesTurno };
