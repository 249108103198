const Registro = ({ reporte, detalle }) => {
    return (
        <>
            {
                reporte.map((item, i) => {
                    return (
                        <tr key={i}>
                            <td>{item.DescripcionDescuento}</td>
                            <td>{item.Cantidad}</td>
                            <td>{(Math.round(item.Total * 100) / 100).toFixed(2)}</td>
                            <td>
                                <button
                                    onClick={() => detalle(item.CodigoDescuento)}
                                    className="left labeled fluid ui icon grey button"
                                >
                                    <i className="external alternate icon"></i>
                                    Detalles
                                </button>
                            </td>
                        </tr>
                    )
                })
            }
        </>
    )
}
export { Registro }
