const ACTION_TYPES = {
  FETCH_START: "FETCH_START",
  FETCH_START_SUCCESS: "FETCH_START_SUCCESS",
  FETCH_START_ERROR: "FETCH_START_ERROR",
  ABRIR_MODAL: "ABRIR_MODAL",
  CERRAR_MODAL: "CERRAR_MODAL",
  NUMERO_MODAL: "NUMERO_MODAL",
  MODIFICAR_ORDEN: "MODIFICAR_ORDEN",
  LISTA_PRODUCTOS: "LISTA_PRODUCTOS",
  MODIFICAR_DATO_FISCAL: "MODIFICAR_DATO_FISCAL",
  MODIFICAR_DESCUENTO: "MODIFICAR_DESCUENTO",
  VALOR_MENU: "VALOR_MENU",
  COMPLEMENTO: "COMPLEMENTO",
  MANEJO_PAD: "MANEJO_PAD",
  CORRELATIVO: "CORRELATIVO",
  FETCH_DATA: "FETCH_DATA",
  FETCH_DATA_SUCCESS: "FETCH_DATA_SUCCESS",
  FETCH_DATA_ERROR: "FETCH_DATA_ERROR",
  LIMPIAR_POS: "LIMPIAR_POS",
  REINICIAR_DESCUENTO: "REINICIAR_DESCUENTO",
  AGREGAR_PRODUCTO_DETALLE: "AGREGAR_PRODUCTO_DETALLE",
  CONTINGENCIA: "CONTINGENCIA",
  CARGAR_COMPLEMENTOS_MODAL: "CARGAR_COMPLEMENTOS_MODAL",
  ACTUALIZAR_ARRAY_OPCIONES: "ACTUALIZAR_ARRAY_OPCIONES",
  RESET_ARRAY_OPCIONES: "RESET_ARRAY_OPCIONES",
  ELIMINAR_OPCIONES: "ELIMINAR_OPCIONES",
  ACTUALIZAR_OPS_ELIMINADAS: "ACTUALIZAR_OPS_ELIMINADAS",
  CARGAR_ESTADO_COMPLEMENTOS: "CARGAR_ESTADO_COMPLEMENTOS",
};

export { ACTION_TYPES };