import React from 'react';
import { LoaderUI } from './LoaderUI';


const Loader = () => {
  return (
    <LoaderUI />
  );
};

export { Loader };