import React, { useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";

import { CuentaDivOrden } from "../CuentaDivOrden";
import { CrearNuevaCuenta } from '../CrearNuevaCuenta';
import initialData from "./initialData";

import './styles.css';

export const ContenedorCuentas = ({ estado, onDragEnd, agregarCuenta, eliminarCuenta }) => {
    return (
        <DragDropContext
            onDragEnd={onDragEnd}
        >
            <div className="ui segment divideContainer">
                {
                    estado.columnOrder.map((columnId, index) => {
                        const column = estado.columns[columnId];
                        const tasks = column.taskIds.map(taskId => estado.tasks[taskId]);
                        return <CuentaDivOrden key={column.id} numeroCuenta={index + 1} column={column} tasks={tasks} eliminarCuenta={eliminarCuenta} />

                    })
                }
                <CrearNuevaCuenta agregar={agregarCuenta} />
            </div>
        </DragDropContext>

    )
}
