import { useContext } from "react"
import { BuscarCrearComplemento } from "../BuscarCrearComplemento"
import { BuscarOpcion } from "../BuscarOpcion"
import { FormCrearComplemento } from "../FormCrearComplemento"
import { ProductosContext } from "../ProductosContext"
import { TablaOpciones } from "../TablaOpciones"

const TabEditarComplemento = () => {

    const { state } = useContext(ProductosContext);

    return (
        <>
            {
                (state.estados.estadoEditarComplemento === 'buscando')
                    ? (
                        <div className="ui center aligned two column grid">
                            <div className="column">
                                <h3>Crear nuevo complemento</h3>
                                <FormCrearComplemento estado={'crear-complemento'} />

                            </div>
                            <div className="column">
                                <h3>Editar complemento existente</h3>
                                <BuscarCrearComplemento modalFuente={'editar'} />
                            </div>
                        </div>
                    )
                    : (state.estados.estadoEditarComplemento === 'editando')
                        ? (
                            <div className="ui center aligned two column grid">
                                <div className="column">
                                    <FormCrearComplemento estado={'editar'} />

                                </div>
                                <div className="column">
                                    <h3>Listado de opciones</h3>
                                    <TablaOpciones compDetalle={state.compDetalle} />
                                </div>
                            </div>
                        )
                        : (state.estados.estadoEditarComplemento === 'opciones')
                            ? (
                                <div className="ui center aligned two column grid">
                                    <div className="column">
                                        <BuscarOpcion />
                                    </div>
                                    <div className="column">
                                        <h3>Listado de opciones</h3>
                                        <TablaOpciones compDetalle={state.compDetalle} />
                                    </div>
                                </div>
                            )
                            : null
            }


        </>
    )
}

export { TabEditarComplemento }