import React from 'react';
import "./styles.css"

const CierreInfoUsuario = ({ codigoCaja, fondoCaja }) => {

  const fondoInicio = (Math.round(fondoCaja * 100) / 100).toFixed(2);

  return (
    <div className="ui three column grid">
      <div className="six wide column">
        <div className="ui huge label">
          <i className='box icon'></i>
          Caja: <span className="detail">{codigoCaja} </span>
        </div>
      </div>
      <div className="ten wide column">
        <div className="ui huge label">

          <i className='money bill alternate icon'></i>
          Fondo de Caja: <span className="detail">Q.{fondoInicio} </span>
        </div>
      </div>
    </div>
  );
}

export { CierreInfoUsuario };