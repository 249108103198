import React from "react";
import { ItemOrden } from "../ItemOrden";
import { Droppable } from "react-beautiful-dnd";
import './styles.css';

export const CuentaDivOrden = ({ column, tasks, eliminarCuenta, numeroCuenta }) => {
    return (

        <div className="drop-column">
            <h3 className="titulo-cuenta">Cuenta {numeroCuenta}</h3>

            <Droppable droppableId={column.id}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        isDraggingOver={snapshot.isDraggingOver}
                        {...provided.droppableProps}

                        className={snapshot.isDraggingOver ? 'task-list-draggingOver' : 'task-list-static'}
                    >
                        {tasks.map((task, index) => <ItemOrden key={task.id} task={task} index={index} />)}
                    </div>
                )}
            </Droppable>
            <button
                type="button"
                onClick={() => eliminarCuenta(column, (numeroCuenta - 1))}
                className="ui right labeled icon bottom attached red button"
            >
                Eliminar
                <i className="x icon"></i>
            </button>
        </div>
    )

}

