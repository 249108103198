import React from "react";
import { PosContext } from "../PosContext";
import "./style.css";

const MenuList = ({ state, dispatch, actions, regresarPadre, children }) => {
  return (
    <>
      <div className="ui seven column doubling grid m-0 itemlist-height itemlist-pt">
        <div className="column item-padding">
          <button
            className="ui fluid button p-btn black mitem-height prod-margin"
            onClick={() => dispatch({ type: actions.NUMERO_MODAL, payload: 20 })}
          >
            <i className="large search icon"></i>
          </button>
        </div>
        {state.menuValue !== "" && (
          <>
            <div className="column item-padding">
              <button
                className="ui fluid button p-btn gray mitem-height prod-margin"
                onClick={() =>
                  dispatch({ type: actions.VALOR_MENU, payload: "" })
                }
              >
                <i className="large home icon"></i>
              </button>
            </div>
            <div className="column item-padding">
              <button
                className="ui fluid button p-btn gray mitem-height prod-margin"
                onClick={() => regresarPadre()}
              >
                <i className="large history icon"></i>
              </button>
            </div>
          </>
        )}

        {children}
      </div>
    </>
  );
};

export { MenuList };
