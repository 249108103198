import React from "react"
import { RegistroDetalle } from "../RegistroDetalle"
import { TablaDetalle } from "../TablaDetalle"

const ModalDetalles = ({ onCancelModal, detalleCanal, pais, turnoSeleccionado, canalSeleccionado, formasPago, onChangeFormaDePago, todasFP, formaPagoSeleccionada }) => {
    return (
        <div className="ui longer modal md-disp ">

            <div className="header">
                <div className="three column ui grid">
                    <div className="column">
                        Forma de pago:
                        <select onChange={(e) => onChangeFormaDePago(e)} value={formaPagoSeleccionada} name="FormaPago" className="drop-down">
                            {
                                formasPago.map((item, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <option value={item}>{item}</option>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="column">
                        <h1>Detalles {canalSeleccionado} - Turno: {turnoSeleccionado}</h1>
                    </div>
                    <div className="column">

                    </div>
                </div>

            </div>


            <div className="scrolling content color-contenido">

                <TablaDetalle todasFP={todasFP} pais={pais}>
                    <RegistroDetalle todasFP={todasFP} detalleCanal={detalleCanal} />
                </TablaDetalle>

            </div>
            <div className="actions">
                <button
                    type="button"
                    onClick={onCancelModal}
                    className="ui negative right labeled icon button"
                >
                    Cerrar
                    <i className="x icon"></i>
                </button>
            </div>
        </div>
    )
}

export { ModalDetalles }