import React from 'react';

const OrderLogList = ({ children, tipo }) => {

  let columna1, columna2, columna3 = '';

  if (tipo === 'orden') {
    columna1 = 'No. Orden';
    columna2 = 'No. Documento';
    columna3 = 'Forma de Pago';
  } else {
    columna1 = 'No. Cierre';
    columna2 = 'Tipo Reporte';
    columna3 = '?';
  }

  return (
    <table className="ui table">
      <thead>
        <tr>
          <th>#</th>
          <th>{columna1}</th>
          <th>Fecha</th>
          <th>{columna2}</th>
          {
            (tipo !== 'orden')
              ? (
                <th></th>
              )
              : (
                <>
                  <th>Canal</th>
                  <th>{columna3}</th>
                </>
              )
          }

          <th>Ver</th>
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
    </table>
  );
};

export { OrderLogList };