import http from "../http-common"

const turnoServices = {
    turno: {
        cierreTurno(cierre, axios = null) {
            return axios.post('/CierreFiscal', cierre)
        },
        turnoAbierto(codigoEmpresa = 'GRANJEROGT', codigoTienda = 999, codigoCaja = 1) {
            return http.get(`Turno/TurnoActivo?codigoEmpresa=${codigoEmpresa}&codigoTienda=${codigoTienda}&codigoCaja=${codigoCaja}`)
        },
        listaTurnos(codigoEmpresa = 'GRANJEROGT', codigoTienda = 999, codigoCaja = 1) {
            return http.get(`Turno/ListaTurnos?CodigoEmpresa=${codigoEmpresa}&CodigoTienda=${codigoTienda}&CodigoCaja=${codigoCaja}`)
        },
    },
    reporte: {
        reporteVentaPorCanal(fecha) {
            return http.get(`/Reporte/VentaPorCanal?fecha=${fecha}`);
            //Reporte/VentaPorCanal?fecha=12072022
        },
        reporteVPCSinFecha(codigoEmpresa, codigoTienda, codigoCaja, codigoTurno) {
            return http.get(`/Reporte/VentaPorCanal?CodigoEmpresa=${codigoEmpresa}&CodigoTienda=${codigoTienda}&CodigoCaja=${codigoCaja}&CodigoTurno=${codigoTurno}`);
        },
        reporteVentaPorDescuento(codigoEmpresa = null, codigoTienda = null, codigoCaja = null, codigoTurno = null) {
            return http.get(`/Reporte/VentaPorDescuento?CodigoEmpresa=${codigoEmpresa}&CodigoTienda=${codigoTienda}&CodigoCaja=${codigoCaja}&CodigoTurno=${codigoTurno}`);
        }
    }
}

export { turnoServices };