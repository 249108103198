import React from "react";
import { useContext } from "react";
import { ProductosContext } from "../../ProductosContext";
import { DetalleMenu } from "../DetalleMenu";

const BuscarMenu = () => {

    const {
        state,
        changeBusquedaMenu,
        seleccionarMenu,
        regresarMenu,
    } = useContext(ProductosContext);

    return (
        <>
            <div className="ui fluid icon input input-plu">
                <input onChange={(e) => changeBusquedaMenu(e)} value={state.elementoSeleccionado.menuSeleccionado} type="text" placeholder="Buscar menu..." />
                <i className="search plus icon"></i>
            </div>
            {
                (state.estados.estadoMenu === 'buscando')
                    ? (
                        <div className="ui segment plu-list">
                            <h4 className="ui centered header">Listado de Menús</h4>
                            {
                                state.response.productosApi.menus.map((item) => {
                                    return (
                                        <div key={item.node.CodigoMenu} className="plu">
                                            <button onClick={() => seleccionarMenu(item)} className="ui fluid button">
                                                {item.node.CodigoMenu}: {item.node.Descripcion}
                                            </button>
                                        </div>
                                    )
                                })

                            }
                            {
                                (state.response.productosApi.menus[0].node.CodigoMenuPadre !== "")
                                    ? (
                                        <button onClick={regresarMenu} className="ui negative fluid button">
                                            Regresar
                                        </button>
                                    )
                                    : null
                            }

                        </div>
                    )
                    : (state.estados.estadoMenu === 'detalles')
                        ? (
                            <DetalleMenu />
                        )
                        : null
            }


        </>
    )
}

export { BuscarMenu };