import React from 'react';
import { useLocation } from "react-router-dom";

import { AvalonNavMenu } from '../AvalonNavMenu';

const Layout = ({ children }) => {
  const location = useLocation();
  //console.log(location.pathname);
  return (
    <>
      {!location.pathname.includes('personalizar') && <AvalonNavMenu />}
      {children}
    </>
  );
};

export { Layout };