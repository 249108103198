import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Layout } from "../library/components/Layout";
import { Pos } from "../library/components/AvalonPos/Pos";
import { Login } from "../library/components/AvalonLogin/Login";
import { MainMenu } from "../library/components/AvalonMenu";
import { OrderLog } from "../library/components/AvalonOrdersLog/OrderLog";
import { CallOrder } from "../library/components/AvalonCallOrder/CallOrder";
import { Anulacion } from "../library/components/AvalonAnulacion";
import { Cierres } from "../library/components/AvalonCierres/Cierres";
import { DivisionCuentas } from "../library/components/AvalonCuentas/DivisionCuentas";
import { Personalizar } from "../library/components/Personalizar";
import { RegistroUsuario } from "../library/components/AvalonRegistro";
import { ReporteX } from "../library/components/AvalonReporteX";
import { VentaPorCanal } from "../library/components/AvalonVentaPorCanal";
import { VentaPorDescuento } from "../library/components/AvalonVentaDescuento";
import { PermissionPage } from "../library/pages/PermissionPage";

import { PrivateRoute } from "../library/utils/PrivateRoute";
import { PublicRoute } from "../library/utils/PublicRoute";

import "./App.css";
import { MantenimientoProductos } from "../library/components/MantenimientoProductos";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {/* RUTAS PUBLICAS */}
          <Route
            path="/inicio"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="error-acceso"
            element={
              <PublicRoute>
                <PermissionPage />
              </PublicRoute>
            }
          />
          {/* RUTAS PRIVADAS */}
          <Route
            path="/pos/"
            element={
              <PrivateRoute>
                <Pos />
              </PrivateRoute>
            }
          >
            <Route
              path=":CodigoOrden"
              element={
                <PrivateRoute>
                  <Pos />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="/registro-ordenes"
            element={
              <PrivateRoute>
                <OrderLog />
              </PrivateRoute>
            }
          />
          <Route
            path="/llamar-ordenes"
            element={
              <PrivateRoute>
                <CallOrder />
              </PrivateRoute>
            }
          >
            <Route
              path=":Canal"
              element={
                <PrivateRoute>
                  <CallOrder />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="/anulaciones"
            element={
              <PrivateRoute>
                <Anulacion />
              </PrivateRoute>
            }
          />
          <Route
            path="/cierre"
            element={
              <PrivateRoute>
                <Cierres />
              </PrivateRoute>
            }
          />
          <Route
            path="/dividir-cuenta"
            element={
              <PrivateRoute>
                <DivisionCuentas />
              </PrivateRoute>
            }
          />
          <Route
            path="/personalizar"
            element={
              <PrivateRoute>
                <Personalizar />
              </PrivateRoute>
            }
          />
          <Route
            path="/registrar-usuario"
            element={
              <PrivateRoute>
                <RegistroUsuario />
              </PrivateRoute>
            }
          />
          <Route
            path="/menu"
            element={
              <PrivateRoute>
                <MainMenu />
              </PrivateRoute>
            }
          />
          <Route
            path="/reporte-x"
            element={
              <PrivateRoute>
                <ReporteX />
              </PrivateRoute>
            }
          />
          <Route
            path="/reporte-ventaPorCanal"
            element={
              <PrivateRoute>
                <VentaPorCanal />
              </PrivateRoute>
            }
          />
          <Route
            path="/reporte-ventaPorDescuento"
            element={
              <PrivateRoute>
                <VentaPorDescuento />
              </PrivateRoute>
            }
          />
          <Route
            path="/mantenimiento-productos"
            element={
              <PrivateRoute>
                <MantenimientoProductos />
              </PrivateRoute>
            }
          />
          {/* OTROS */}
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
