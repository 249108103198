import "./styles.css"

const CierreRegistroRemesa = ({ remesasApi, deleteRow }) => {

  let claseRow = "";
  let nombreBanco = '';

  return (
    <>
      {
        remesasApi.remesas.map((item, i) => {
          //const fecha = item.TiempoRemesa.split('T')[0];
          const horas = item.TiempoRemesa.split('T')[1];
          const horaArray = horas.split(':');
          const hora = `${horaArray[0]}:${horaArray[1]}`;

          if (item.TipoRemesa === 'CAMBIO') {
            claseRow = "moneda-cambio";
          }
          else if (item.TipoRemesa === 'CREDITO') {
            claseRow = "moneda-credito";
          }
          else {
            claseRow = "normal";
          }

          let valorDosDecimales = (Math.round(item.ValorRemesa * 100) / 100).toFixed(2);

          nombreBanco = remesasApi.bancos.filter(banco => banco.CodigoBanco.includes(item.CodigoBanco))[0].Descripcion;

          return (
            <tr key={i} className={claseRow}>
              <td>
                <button
                  //onClick={() => deleteRow(item.RemesaReferencia)}
                  onClick={() => deleteRow(item.NumeroRemesa)}
                  className="circular centered tiny ui icon red button"
                >
                  <i className="minus circle icon"></i>
                </button>
              </td>
              <td>{item.NumeroRemesa}</td>
              <td>{item.CodigoColaborador}</td>
              <td>{valorDosDecimales}</td>
              <td>{hora}</td>
              <td>{nombreBanco}</td>
            </tr>
          )
        })
      }
    </>
  );
}


export { CierreRegistroRemesa }