import React from "react";
import { useContext } from "react";
import { ProductosContext } from "../ProductosContext";

const FormEditProducto = () => {

    const {
        state,
        changeEditProducto,
    } = useContext(ProductosContext);
    return (
        <form className="ui form">
            <div className="two fields">
                <div className="field">
                    <label>Descripción Producto</label>
                    <input onChange={(e) => changeEditProducto(e)} value={state.formEditarProducto.DescripcionProducto} name="DescripcionProducto" type="text" />
                </div>
                <div className="field">
                    <label>Tutor</label>
                    <input onChange={(e) => changeEditProducto(e)} value={state.formEditarProducto.Tutor} name="Tutor" type="text" />
                </div>

            </div>
            <div className="two fields">
                <div className="field">
                    <label>Posición</label>
                    <input onChange={(e) => changeEditProducto(e)} value={state.formEditarProducto.Posicion} name="Posicion" type="text" />
                </div>
                <div className="field">
                    <label>Estado</label>
                    <select onChange={(e) => changeEditProducto(e)} value={state.formEditarProducto.Estado} name="Estado" className="ui dropdown">
                        <option value="ALTA">ALTA</option>
                        <option value="BAJA">BAJA</option>
                    </select>
                </div>
            </div>
            <div className="two fields">
                <div className="field">
                    <label>Hora Desde</label>
                    <input onChange={(e) => changeEditProducto(e)} value={state.formEditarProducto.HoraDesde} name="HoraDesde" type="time" />
                </div>
                <div className="field">
                    <label>Hora Hasta</label>
                    <input onChange={(e) => changeEditProducto(e)} value={state.formEditarProducto.HoraHasta} name="HoraHasta" type="time" />
                </div>
            </div>
            <div className="two fields">

                <div className="field">
                    <label>Fecha Desde</label>
                    <input onChange={(e) => changeEditProducto(e)} value={state.formEditarProducto.FechaDesde} name="FechaDesde" type="date" />
                </div>
                <div className="field">
                    <label>Fecha Hasta</label>
                    <input onChange={(e) => changeEditProducto(e)} value={state.formEditarProducto.FechaHasta} name="FechaHasta" type="date" />
                </div>
            </div>

        </form>
    )
}

export { FormEditProducto }