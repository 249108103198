import React from "react";
import { TarjetaProducto } from "./TarjetaProducto"
import { Modal } from "../Modal"
import { ModalAgregarProducto } from "./ModalAgregarProducto"
import { ProductosContext } from "./ProductosContext"
import { useContext, useEffect } from "react"
import { ModalEditarProducto } from "./ModalEditarProducto"
import { ModalMiniLoader } from "./ModalMiniLoader"
import { ModalEditarComplemento } from "./ModalEditarComplemento";
import { ModalCrearOpcion } from "./ModalCrearOpcion";
import { TarjetaOpcion } from "./TarjetaOpcion";

const MantProdUI = () => {
    const {
        state,
        onOpenModal,
        abrirModalEditar,
        eliminarProducto,
        crearPluProdOp,
        changeBusquedaTodosPlus,
        eliminarOpcion,
        abrirModalEditarOpcion,
        todosPlus,
    } = useContext(ProductosContext);

    useEffect(() => {
        window.$('.tabular.menu .item').tab();
    }, []);

    return (
        <div className="ui segment container">
            <div className="ui three column grid">
                <div className="five wide column">
                    <div className="ui two column grid">
                        <div className="column">
                            <button onClick={crearPluProdOp} className="ui positive icon labeled button">
                                <i className="plus icon"></i>
                                AGREGAR PLU
                            </button>
                        </div>
                        <div className="column">
                            <button onClick={() => onOpenModal(3)} className="ui orange icon labeled button">
                                <i className="edit icon"></i>
                                EDITAR COMPLEMENTO
                            </button>
                        </div>
                    </div>

                </div>
                <div className="seven wide column">
                    <h1>PLUS AVALON</h1>
                </div>
                <div className="four wide column">
                    <div className="ui icon input">
                        <input value={state.elementoSeleccionado.pluSeleccionado} onChange={(e) => changeBusquedaTodosPlus(e)} placeholder="Buscar PLU..." type="text" />
                        <i className="search icon"></i>
                    </div>
                </div>
            </div>

            <div className="ui top attached tabular menu">
                <div className="active item" data-tab="first-prod">PLUS activos</div>
                <div className="item" data-tab="second-prod">PLUS inactivos</div>
            </div>

            <div className="ui bottom attached tab segment active" data-tab="first-prod">
                <div className="ui four column center aligned grid">
                    {
                        (todosPlus.length > 0)
                            ? (
                                todosPlus.map((item, index) => {
                                    if (item.Estado === 'ALTA') {
                                        return (
                                            <React.Fragment key={'Fragment' + item.CodigoPlu}>
                                                {
                                                    (item.CodigoProducto)
                                                        ? (
                                                            <div key={item.CodigoProducto} className="column">
                                                                <TarjetaProducto
                                                                    producto={item}
                                                                    abrirModal={abrirModalEditar}
                                                                    eliminar={eliminarProducto}
                                                                />
                                                            </div>
                                                        )
                                                        : (
                                                            <div key={item.CodigoOpcion} className="column">
                                                                <TarjetaOpcion
                                                                    opcion={item}
                                                                    abrirModal={abrirModalEditarOpcion}
                                                                    eliminar={eliminarOpcion}
                                                                />
                                                            </div>
                                                        )
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                })
                            )
                            : (
                                <h2>No existen productos que coincidan con la búsqueda</h2>
                            )

                    }
                </div>
            </div>

            <div className="ui bottom attached tab segment" data-tab="second-prod">
                <div className="ui four column center aligned grid">
                    {
                        (todosPlus.length > 0)
                            ? (
                                todosPlus.map((item, index) => {
                                    if (item.Estado === 'BAJA') {
                                        return (
                                            <React.Fragment key={'Fragment' + item.CodigoPlu}>
                                                {
                                                    (item.CodigoProducto)
                                                        ? (
                                                            <div key={item.CodigoProducto} className="column">
                                                                <TarjetaProducto
                                                                    producto={item}
                                                                    abrirModal={abrirModalEditar}
                                                                    eliminar={eliminarProducto}
                                                                />
                                                            </div>
                                                        )
                                                        : (
                                                            <div key={item.CodigoOpcion} className="column">
                                                                <TarjetaOpcion
                                                                    opcion={item}
                                                                    abrirModal={abrirModalEditarOpcion}
                                                                    eliminar={eliminarOpcion}
                                                                />
                                                            </div>
                                                        )
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                })
                            )
                            : (
                                <h2>No existen productos que coincidan con la búsqueda</h2>
                            )

                    }
                </div>
            </div>

            {!!state.openModal && (
                <Modal>
                    {state.numberModal === 0 ? (
                        <ModalAgregarProducto />
                    ) : state.numberModal === 1 ? (
                        <ModalEditarProducto />
                    ) : state.numberModal === 2 ? (
                        <ModalMiniLoader />
                    ) : state.numberModal === 3 ? (
                        <ModalEditarComplemento />
                    ) : state.numberModal === 4 ? (
                        <ModalCrearOpcion />
                    ) : null

                    }
                </Modal>
            )}

        </div>
    )
}

export { MantProdUI }