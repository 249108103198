//URLS PARA CONEXION API
const BASE_URL_LOCAL = 'https://localhost:44380/api';

const BASE_URL_SERVER = 'http://192.168.0.24:81/api';

//NOMBRE VARIALBES LOCAL STORAGE

const LOGIN = 'LOGIN_BETA';

const CONTINGENCIA_SVR_LOCAL = 'CONTINGENCIA_LOCAL';

const URLS = {
    BASE_URL_DESPRUEBAS: 'http://192.168.61.16/avalon/api',
    BASE_URL_PRODUCTIVO: 'http://192.168.0.24:81/api',
    BASE_URL_DESARROLLO: 'http://192.168.0.24:9002/api',
    BASE_URL_6116: 'http://192.168.61.16/avalon/api',
    BASE_URL_LOCAL: 'https://localhost:44380/api',
    BASE_URL_AZURE: 'https://alrcazapp0001.azurewebsites.net/api',
}

const ERROR = {
    FISCALIZAR: 'Ocurrió algún error al intentar fiscalizar la orden'
}

export {
    BASE_URL_LOCAL,
    BASE_URL_SERVER,
    LOGIN,
    CONTINGENCIA_SVR_LOCAL,
    URLS,
    ERROR,
};
