import React, { useContext } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MenuContext } from './MenuContext';
import { useLocalStorage } from '../../hooks/useLocalStorage'

import './style.css';
import { MenuAdmin } from './MenuAdmin';
import { MenuControlador } from './MenuControlador';

const MenuUi = () => {

    const [usuario, setUsuario] = useLocalStorage("LOGIN_BETA");

    const rolUsuario = usuario.Colaborador.CodigoRol;

    const {
        setOpenModal,
        setNumberModal,

    } = useContext(MenuContext)

    const makeSwing = () => {
        //window.$('.tadabble').transition('tada');
        window.$('.swingabble').transition('jiggle');

    }
    useEffect(() => {
        const swingabbleTimer = setInterval(makeSwing, 5000);
        window.$('.contenedorMenu').transition('fly left in');
        return () => {
            clearInterval(swingabbleTimer);
        }
    }, [])
    return (
        <div className="ui container mt-25p contenedorMenu" style={{ display: 'none' }}>
            <div className='ui column center aligned segment' id="encabezado" style={{ background: "#FFF7EF", margin: "0 auto" }}>
                <h1 className="ui-header">MENÚ PRINCIPAL</h1>
            </div>
            {
                (rolUsuario === 3 || rolUsuario === 1)
                    ? (
                        <MenuAdmin />
                    )
                    : (
                        <MenuControlador />
                    )
            }

        </div>
    );
};

export { MenuUi };