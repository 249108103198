import React, {useContext} from "react";
import { PosContext } from "../PosContext";
import './style.css';

const ModalDocumento = ({ state, dispatch, actions }) => {

  let filtrarDoc = [];
  // aqui va la condicion para pintar los documentos por pais, falta pulir
  if(true) {
    filtrarDoc = state.response.documentos.filter(item => item.Descripcion.includes("FEL") || item.Descripcion.includes("CONT"));
  }

  const onCancel = () => {
    dispatch({ type: actions.CERRAR_MODAL });
  }

  const onSelectDocument = (documento) => {
    dispatch({ type: actions.MODIFICAR_ORDEN, payload: {...state.orderOptions, codigoDocumento: documento.CodigoDocumento} });
    dispatch({ type: actions.CERRAR_MODAL });
  }

  return (
    <div className="ui tiny modal md-disp">
      <div className="header">
        Seleccione el tipo de documento
      </div>
      <div className="content">
        <div className="ui one column center aligned grid">
          {filtrarDoc.map(item => (
            <div className="column element-height">
              <button 
                className="ui fluid inverted orange button btn-modal-height"
                style={{ marginTop: "10px", marginBottom: "10px" }}
                onClick={() => onSelectDocument(item)}
                key={item.CodigoDocumento}
              >
                {item.Descripcion}  
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="actions">
        <button
          type="button"
          onClick={onCancel}
          className="ui large negative right labeled icon button"
        >
            Cancelar
            <i className="x icon"></i>
        </button>
      </div>
    </div>
  );
};

export { ModalDocumento };