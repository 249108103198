import { useContext } from "react"
import { Registro } from "./Registro"
import { Tabla } from "./Tabla"
import { ProductosContext } from "../ProductosContext"

const TablaComplementos = () => {

    const {
        state,
        eliminarComplemento,
    } = useContext(ProductosContext);

    return (
        <Tabla>
            <Registro compAgregados={state.productoDetalles.compProducto} eliminarComplemento={eliminarComplemento} />
        </Tabla>
    )
}

export { TablaComplementos }