import { useEffect, useState } from 'react';
import { turnoServices } from '../../api/services/turnoServices';
import { ReporteXUI } from './ReporteXUI'
import { Loader } from '../Loader';
import { PageError } from '../PageError';

import { useLocalStorage } from '../../hooks/useLocalStorage'

import './style.css'
import { reporteXServices } from '../../api/services/reporteXServices';
import Swal from 'sweetalert2';


const ReporteX = () => {

    const [usuario, guardarUsuario] = useLocalStorage('LOGIN_BETA');

    let valoresLogin = {
        codigoEmpresa: usuario.Tienda.CodigoEmpresa,
        codigoTienda: usuario.Tienda.CodigoTienda,
        codigoCaja: usuario.CorrelativoFiscal.CodigoCaja,
        codigoPuntoDeVenta: 1,//Lo devuelve el login
        codigoColaborador: usuario.Colaborador.CodigoColaborador, //Login
        cierreNumero: 1, //Login, correlativo interno de Zeta
        codigoTurno: usuario.Turno.CodigoTurno,//Login
        estadoTurno: usuario.Turno.Estado,
    };

    //const turnoActual = valoresLogin.codigoTurno;
    //let turnoActual = undefined;

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);

    const [turnoUsar, setTurnoUsar] = useState(valoresLogin.codigoTurno);

    const fechaCompleta = new Date();

    let dia = fechaCompleta.getDate() < 10 ? `0${fechaCompleta.getDate()}` : fechaCompleta.getDate();
    let mes = (fechaCompleta.getMonth() + 1) < 10 ? `0${fechaCompleta.getMonth() + 1}` : fechaCompleta.getMonth() + 1;

    const [openModal, setOpenModal] = useState(false);
    const [numberModal, setNumberModal] = useState(undefined);

    const [reporte, setReporte] = useState([]);

    const [datosReporte, setDatosReporte] = useState({});

    const [hayOrdenes, setHayOrdenes] = useState(false);

    const [turnos, setTurnos] = useState([]);
    const [turnoActual, setTurnoActual] = useState(undefined);

    useEffect(() => {
        getDatosReporteX();
        makeTurnos();
    }, []);

    const getDatosReporteX = async () => {
        try {

            /*if (valoresLogin.estadoTurno === "CERRADO") {
                Swal.fire({
                    title: 'No hay turno abierto',
                    icon: 'question',
                    text: '¿Desea abrir un turno?',
                    showDenyButton: true,
                    confirmButtonText: 'Sí',
                    denyButtonText: `No`,
                    customClass: {
                        confirmButton: 'ui orange button'
                    },
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/pos')
                    } else if (result.isDenied) {
                        navigate('/menu')
                    }
                });
                return;
            }*/
            const datosResp = await reporteXServices.reporte.reporteX(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja, valoresLogin.codigoColaborador);
            //console.log(datosResp);
            if (datosResp.data.SUCCESS === false) {
                if (datosResp.data.CODE === 1002) {
                    Swal.fire({
                        title: 'No se ha realizado ninguna orden',
                        icon: 'info',
                        confirmButtonText: 'OK',
                    })
                    setHayOrdenes(false);
                    setLoading(false);
                    return;
                }
                else {
                    setLoading(false);
                    setError(datosResp.data.MESSAGE.Message);
                    return;
                }
            }

            const datosReporte = datosResp.data.MESSAGE;
            setDatosReporte(datosReporte);
            setHayOrdenes(true);
            setLoading(false);

        } catch (error) {
            //console.error(error);
            setError(error.message);
            setLoading(false);
        }
    }

    const activarModalVentaPorCanal = () => {
        onOpenModal(0);
        getReporteVentaCanal();
    }

    const getReporteVentaCanal = async () => {
        try {
            const reporteResp = await turnoServices.reporte.reporteVPCSinFecha(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja, turnoUsar);
            if (reporteResp.data.SUCCESS === false) {
                console.log(reporteResp.data.MESSAGE);
                return;
            }
            const reporte = reporteResp.data.MESSAGE;

            setReporte(reporte);

        } catch (error) {
            console.log(error);
        }

    }

    const onOpenModal = (modalOption) => {
        setOpenModal(prevState => !prevState);
        setNumberModal(modalOption);
    }

    const onCancelModal = () => {
        setOpenModal(false);
    };

    const makeTurnos = async () => {
        /*const turnoActual = valoresLogin.codigoTurno;
        let arrayTurnos = [];

        for (let i = 0; i < 5; i++) {
            let element = {
                id: i,
                turno: turnoActual - i
            }

            arrayTurnos.push(element);

        }*/
        try {

            const turnosResp = await turnoServices.turno.listaTurnos(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja);
            if (turnosResp.data.SUCCESS === false) {
                Swal.fire({
                    icon: 'error',
                    text: turnosResp.data.MESSAGE,
                })
                return;
            }

            const turnos = turnosResp.data.MESSAGE;
            setTurnoActual(turnos[0].CodigoTurno);

            setTurnos(turnos)
        } catch (error) {
            console.error(error)
        }

    }

    const onChangeTurno = async (e) => {
        try {
            setTurnoUsar(e.target.value);
            const reporteResp = await reporteXServices.reporte.reporteX(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja, valoresLogin.codigoColaborador, e.target.value);
            if (reporteResp.data.SUCCESS === false) {
                Swal.fire({
                    icon: 'error',
                    text: reporteResp.data.MESSAGE,
                })
                return;
            }
            const reporte = reporteResp.data.MESSAGE;

            if (reporte.length === 0) {
                Swal.fire({
                    title: 'No hay órdenes en este turno',
                    icon: 'info',
                    customClass: {
                        confirmButton: 'ui orange button'
                    }
                })
            }

            setDatosReporte(reporte);

        } catch (error) {
            console.log(error);
        }
    }

    if (loading) return <Loader />

    if (error !== undefined) return <PageError message={error} source={'reporteX'} />

    return (
        <ReporteXUI
            openModal={openModal}
            onCancelModal={onCancelModal}
            numberModal={numberModal}
            reporte={reporte}
            datosReporte={datosReporte}
            activarModalVentaPorCanal={activarModalVentaPorCanal}
            hayOrdenes={hayOrdenes}
            turnos={turnos}
            onChangeTurno={onChangeTurno}
            turnoActual={turnoActual}
        />
    )
}

export { ReporteX }