import http from "../http-common";

const anulacionServices = {
    anulacion: {
        anularOrden(codigoOrden = null, codigoMotivo = null, expMotivo = null) {
            return http.delete(`/orden/${codigoOrden}/${codigoMotivo}/${expMotivo}`);
        }
    },
    motivo: {
        getAllMotivosDevolucion(codigoEmpresa = "CAMPEROGT") {
            return http.get(`Utilidades/MotivoAnulacion?CodigoEmpresa=${codigoEmpresa}`)
        }

    },
    orden: {
        getOrdenesPorFecha(fecha, codigoEmpresa = "CAMPEROGT", codigoTienda = null, codigoCaja = 1) {
            return http.get(`/orden?fecha=${fecha}&CodigoEmpresa=${codigoEmpresa}&CodigoTienda=${codigoTienda}&CodigoCaja=${codigoCaja}`);
        },
        getOrdenPorFechaCodigo(fecha, numeroDocumento, codigoEmpresa = "CAMPEROGT", codigoTienda = null, codigoCaja = 1) {
            return http.get(`/orden?fecha=${fecha}&DocumentoNumero=${numeroDocumento}&CodigoEmpresa=${codigoEmpresa}&CodigoTienda=${codigoTienda}&CodigoCaja=${codigoCaja}`);
        },
        getOrdenesPorTurno(codigoEmpresa, codigoCaja, codigoTienda, codigoTurno) {
            return http.get(`/orden?CodigoEmpresa=${codigoEmpresa}&CodigoCaja=${codigoCaja}&CodigoTienda=${codigoTienda}&CodigoTurno=${codigoTurno}`);
        }

    }

}
export { anulacionServices };