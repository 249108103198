import http from "../http-common";

const productoServices = {
    producto: {
        getAllProductos(codigoEmpresa, estado = "ALTA") {
            return http.get(`/Producto?CodigoEmpresa=${codigoEmpresa}&Estado=${estado}`);
        },
        agregarProducto(producto) {
            return http.post('/Producto', producto);
        },
        getAllAreasProduccion(codigoEmpresa) {
            return http.get(`/Utilidades/AreaProduccion?CodigoEmpresa=${codigoEmpresa}`);
        },
        getAllFamilias(codigoEmpresa) {
            return http.get(`/Familia?CodigoEmpresa=${codigoEmpresa}`);
        },
        getAllComplementos(codigoEmpresa) {
            return http.get(`/Complemento?CodigoEmpresa=${codigoEmpresa}`);
        },
        getAllOpciones(codigoEmpresa) {
            return http.get(`/OpcionProducto?CodigoEmpresa=${codigoEmpresa}`);
        },
        getAllMenus(codigoEmpresa) {
            return http.get(`/Menu?CodigoEmpresa=${codigoEmpresa}`);
        },
        getAllCanales(codigoEmpresa) {
            return http.get(`/canal?codigoEmpresa=${codigoEmpresa}`);
        },
        editarProducto(producto) {
            return http.put('/Producto', producto);
        },
        agregarComplemento(complemento) {
            return http.post('/Complemento', complemento);
        },
        editarComplemento(complemento) {
            return http.put('/Complemento', complemento);
        },
        agregarOpcion(opcion) {
            return http.post('/OpcionProducto', opcion);
        },
        editarOpcion(opcion) {
            return http.put('/OpcionProducto', opcion);
        },
        getComplementosSinProducto(codigoEmpresa) {
            //return http.get(`/AllComplementos?CodigoEmpresa=${codigoEmpresa}`);
            return http.get(`/Complemento/AllComplementos?CodigoEmpresa=${codigoEmpresa}`);
        }
    },
    plu: {
        getAllPlus(codigoEmpresa = null, estado = null) {
            return http.get(`/Plu?CodigoEmpresa=${codigoEmpresa}&Estado=${estado}`);
        },
        agregarPlu(plu) {
            return http.post('/Plu', plu);
        },
        agregarPrecioPlu(pluPrecio) {
            return http.post('/PluPrecio', pluPrecio);
        },
        eliminarPlu(plu) {
            return http.delete('/Plu', plu)
        }
    }
}

export { productoServices }