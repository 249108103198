import axios from 'axios';
import { URLS } from '../constants';

export default axios.create({
    //baseURL: URLS.BASE_URL_DESPRUEBAS, //61.16
    //baseURL: URLS.BASE_URL_DESARROLLO, //0.24 desarrollo
    //baseURL: URLS.BASE_URL_PRODUCTIVO, //NOTA: productivo
    //baseURL: URLS.BASE_URL_LOCAL, // desarrollo local
    baseURL: URLS.BASE_URL_AZURE,
    headers: {
        "Content-type": "application/json"
    }
});