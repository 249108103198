import './style.css';

const CierreBotones = ({ cerrarTurno, imprimirCorte }) => {
  return (
    <>
      <div className="ui four column middle aligned grid">

        <div className="seven wide column " >
          <button onClick={cerrarTurno} className="ui left labeled inverted orange huge icon button">
            Cerrar Turno
            <i className="save icon"></i>
          </button>
        </div>

        <div className="seven wide column">
          <button onClick={() => imprimirCorte('CORTE_X', 'boton')} className="ui left labeled inverted brown huge icon button">
            Corte X
            <i className="cut icon"></i>
          </button>

        </div>
      </div>

    </>
  );
}

export { CierreBotones }