import http from "../http-common"

const reporteVPCServices = {
    reporte: {
        reporteCanalTurno(codigoEmpresa, codigoTienda, codigoCaja, codigoTurno = null, codigoCanal) {
            return http.get(`/Orden?codigoEmpresa=${codigoEmpresa}&codigoTienda=${codigoTienda}&codigoCaja=${codigoCaja}&CodigoTurno=${codigoTurno}&CodigoCanal=${codigoCanal}`);
        },
        reporteTurno(codigoEmpresa, codigoTienda, codigoCaja, codigoTurno) {
            return http.get(`/Orden?codigoEmpresa=${codigoEmpresa}&codigoTienda=${codigoTienda}&codigoCaja=${codigoCaja}&CodigoTurno=${codigoTurno}`);
        },
        //}/Orden?codigoTurno=50&codigoCanal=1
    },
    pos: {
        getFormasDePago(codigoEmpresa = null) {
            return http.get(`/Utilidades/FormasPago?codigoEmpresa=${codigoEmpresa}`);
        },
    }

}

export { reporteVPCServices };