const INITIAL_STATE = {
  loading: true,
  filtros: { canales: [], formasPago: [] },
  error: false,
  bitacora: {
    Orden: [],
    Cierre: [],
  },
  //bitacora: [],
  buscar: undefined,
  errorBuscar: undefined,
  abrirModal: false,
  formFiltros: {
    //codigoEmpresa: infoLogin.Tienda.CodigoEmpresa,
    //codigoTienda: infoLogin.Tienda.CodigoTienda,
    //codigoEmpresa: 'GRANJEROGT',
    //codigoTienda: 999,
    codigoOrden: 0,
    codigoCanal: "",
    contenidoBitacora: "",
    fechaOrdenInicio: "",
    fechaOrdenFin: "",
    formaPago: "",
    documentoNumero: "",
  },
  documentoBitacora: undefined,
};

const bitacoraReducer = (state, action) => {

  switch (action.type) {
    case "FETCH_START":
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case "FETCH_START_SUCCESS":
      return {
        ...state,
        loading: false,
        filtros: action.payload,
      };
    case "FETCH_START_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CHANGE_FORM":
      return {
        ...state,
        formFiltros: {
          ...state.formFiltros,
          [action.payload.name]: action.payload.value,
        },
      };
    case "ABRIR_MODAL":
      return {
        ...state,
        abrirModal: true,
      };
    case "CERRAR_MODAL":
      return {
        ...state,
        abrirModal: false,
      };
    case "FETCH_DATA":
      return {
        ...state,
        buscar: true,
        errorBuscar: undefined,
      };
    case "FETCH_DATA_SUCCESS":
      return {
        ...state,
        buscar: false,
        bitacora: action.payload,
      };
    case "FETCH_DATA_ERROR":
      return {
        ...state,
        buscar: undefined,
        errorBuscar: action.payload,
      };
    case "DOCUMENTO_BITACORA":
      return {
        ...state,
        documentoBitacora: action.payload
      };
    case "LIMPIAR_FILTROS":
      return {
        ...state,
        formFiltros: {
          codigoOrden: 0,
          codigoCanal: "",
          contenidoBitacora: "",
          fechaOrdenInicio: "",
          fechaOrdenFin: "",
          formaPago: "",
          documentoNumero: "",
        },
        bitacora: {
          Orden: [],
          Cierre: [],
        },
        buscar: undefined,
      }
    default:
      return state;
  }
};


export { INITIAL_STATE, bitacoraReducer };