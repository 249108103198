
const AcumuladoTiquete = ({ datosReporte, hayOrdenes }) => {
    return (
        <div className="ui segment">
            <div className="ui middle aligned divided list">
                <div className="item">
                    <div className="right floated content">
                        {(Math.round(datosReporte.AcumuladoGeneral * 100) / 100).toFixed(2)}
                    </div>
                    <div className="content">
                        <strong> Acumulado General:</strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(Math.round(datosReporte.AcumuladoNeto * 100) / 100).toFixed(2)}
                    </div>
                    <div className="content">
                        <strong> Acumulado Neto: </strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.TiqueteInicial : 0}
                    </div>
                    <div className="content">
                        <strong> Tiquete Inicial: </strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.TiqueteFinal : 0}
                    </div>
                    <div className="content">
                        <strong> Tiquete Final: </strong>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { AcumuladoTiquete }