import React from "react";
import "./style.css";

const MenuListItem = ({ dispatch, actions, menu }) => {
  return (
    <div className="column item-padding">
      <button
        className="ui fluid button orange mitem-height p-btn prod-margin"
        onClick={() =>
          dispatch({ type: actions.VALOR_MENU, payload: menu.CodigoMenu })
        }
      >
        {menu.Descripcion}
      </button>
    </div>
  );
};

export { MenuListItem };
