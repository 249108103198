import React, { useContext } from "react";
import { ProductosContext } from "../../ProductosContext";
import { Registro } from "./Registro";
import { Tabla } from "./Tabla";

const TablaMenus = () => {

    const {
        state,
        eliminarMenu,
    } = useContext(ProductosContext);

    return (
        <Tabla>
            <Registro
                menuProd={state.productoDetalles.menuProducto}
                eliminarMenu={eliminarMenu}
            />
        </Tabla>
    )
}

export { TablaMenus };