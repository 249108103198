
const ColumnaIzquierda = ({ datosReporte, hayOrdenes }) => {
    return (
        <div className="ui segment">
            <div className="ui middle aligned relaxed list">
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.CantidadGravadas : 0}
                    </div>
                    <div className="content">
                        <strong>Total Gravadas:</strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? (Math.round(datosReporte.TotalGravadas * 100) / 100).toFixed(2) : 0.00}
                    </div>
                </div>
                <hr />
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? (Math.round(datosReporte.VentaNeta * 100) / 100).toFixed(2) : 0.00}
                    </div>
                    <div className="content">
                        <strong>Venta Neta:</strong>
                    </div>
                </div>
                <hr />
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.CantidadDomicilio : 0}
                    </div>
                    <div className="content">
                        <strong>Total Domicilio:</strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? (Math.round(datosReporte.TotalDomicilio * 100) / 100).toFixed(2) : 0.00}
                    </div>
                </div>
                <hr />
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.CantidadPospaxDomicilio : 0}
                    </div>
                    <div className="content">
                        <strong> POSPAX Domicilio:</strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? (Math.round(datosReporte.TotalPospaxDomicilio * 100) / 100).toFixed(2) : 0.00}
                    </div>
                </div>
                <hr />
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.CantidadDevolucionesDomicilio : 0}
                    </div>
                    <div className="content">
                        <strong> Dev. Domicilio:</strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? (Math.round(datosReporte.TotalDevolucionesDomicilio * 100) / 100).toFixed(2) : 0.00}
                    </div>
                </div>
                <hr />
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.CantidadEfectivo : 0}
                    </div>
                    <div className="content">
                        <strong>Total Efectivo:</strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? (Math.round(datosReporte.TotalEfectivo * 100) / 100).toFixed(2) : 0.00}
                    </div>
                </div>
                <hr />
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.CantidadOtrasFormasPago : 0}
                    </div>
                    <div className="content">
                        <strong> Otras Formas de Pago:</strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? (Math.round(datosReporte.TotalOtrasFormasPago * 100) / 100).toFixed(2) : 0.00}
                    </div>
                </div>
                <hr />
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.TotalArticulos : 0}
                    </div>
                    <div className="content">
                        <strong> Total Artículos:</strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.TotalArticulosVendidos : 0}
                    </div>
                    <div className="content">
                        <p> Artículos Vendidos:</p>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.TotalArticulosDevueltos : 0}
                    </div>
                    <div className="content">
                        <p> Artículos Devueltos:</p>
                    </div>
                </div>
                <hr />
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.CantidadDevoluciones : 0}
                    </div>
                    <div className="content">
                        <strong> Total Devoluciones:</strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? (Math.round(datosReporte.TotalDevoluciones * 100) / 100).toFixed(2) : 0.00}
                    </div>
                </div>
                <hr />
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? datosReporte.CantidadDescuentos : 0}
                    </div>
                    <div className="content">
                        <strong> Total Descuentos:</strong>
                    </div>
                </div>
                <div className="item">
                    <div className="right floated content">
                        {(hayOrdenes) ? (Math.round(datosReporte.TotalDescuentos * 100) / 100).toFixed(2) : 0.00}
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ColumnaIzquierda }