import React from "react";
import {  Navigate, useLocation } from "react-router-dom";
import { validarSesionUsuario, validarPermisoPagina } from "./Common";

//TODO   -> b7b1e314614cf326c6e2b6eba1540682
//CIERRE -> 3702274bb077f6d589c1ecc7fc2b0ddc
//MENU   -> 3ed53fbeb1eab0443561b68ca0c0b5cf
//POS    -> 1903f54cd46d54aa3200a4508c948db0

const PrivateRoute = ({ children }) => {
  const location = useLocation();

  const auth = validarSesionUsuario();
  const permission = validarPermisoPagina(location.pathname.replace('/', '').toUpperCase());
  //console.log(permission);
  
  if(!permission) return <Navigate to='/error-acceso' />

  return auth ? children : <Navigate to="/inicio" />
};

export { PrivateRoute };
