const Registro = ({ reporte, verDetallesCanal }) => {
    return (
        <>
            {
                reporte.map((item, i) => {
                    return (
                        <tr key={i}>
                            <td>{item.DescripcionCanal}</td>
                            <td>{item.CantidadTransacciones}</td>
                            <td>{item.FormaPago}</td>
                            <td>{(Math.round(item.Total * 100) / 100).toFixed(2)}</td>
                            <td>
                                <button
                                    onClick={() => verDetallesCanal(item.CodigoCanal, item.FormaPago)}
                                    className="left labeled fluid ui icon grey button"
                                >
                                    <i className="external alternate icon"></i>
                                    Detalles
                                </button>
                            </td>
                        </tr>
                    )
                })
            }
        </>
    )
}
export { Registro }
