import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CierresUI } from './CierresUI';
import { Loader } from '../../Loader';
import { PageError } from '../../PageError';
import { remesaServices } from '../../../api/services/remesaServices';
import { turnoServices } from '../../../api/services/turnoServices';
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { getRemesas, getRemesasBancos } from './serviciosCierre';
import { getConteoContingencia, getEstadoCaja, setConteoContingencia, setEstadoCaja, instanciaAxios, tiempoEnContingencia, serverAxios } from '../../../utils/Common';

import Swal from 'sweetalert2';

import './style.css'

const Cierres = () => {

  const [usuario, guardarUsuario] = useLocalStorage('LOGIN_BETA');

  let valoresLogin = {
    codigoEmpresa: usuario.Tienda.CodigoEmpresa,
    codigoTienda: usuario.Tienda.CodigoTienda,
    codigoCaja: usuario.CorrelativoFiscal.CodigoCaja,
    codigoPuntoDeVenta: 1,
    codigoColaborador: usuario.Colaborador.CodigoColaborador,
    cierreNumero: 1,
    codigoTurno: usuario.Turno.CodigoTurno,
    Nombres: usuario.Colaborador.Nombres,
    Apellidos: usuario.Colaborador.Apellidos,
    EstadoTurno: usuario.Turno.Estado,
    FechaYHora: usuario.Turno.Fecha,
    IntentosFacturacion: usuario.CajaFiscal.IntentosFacturacion,
    EstadoCaja: usuario.EstadoCaja,
    TiempoContingencia: usuario.CajaFiscal.TiempoContingencia,
  };

  let navigate = useNavigate();

  const [loading, setloading] = useState(true);
  const [error, setError] = useState(undefined);

  const [remesasApi, setRemesasApi] = useState({
    remesas: [],
    bancos: [],
    saldoPendiente: 0,
    saldoAFavor: 0,
    fondoCaja: 0,
  })

  const [formExtras, setFormExtras] = useState({
    monedaCambio: false,
    monedaCredito: false,
    campoSeleccionado: undefined,
    formatoSeleccionado: undefined,
    errorInput: undefined,
    pais: valoresLogin.codigoEmpresa.split('O')[1],
    estadoForm: 'ui big form'
  });

  const [valoresTeclado, setValoresTeclado] = useState({
    claseTeclado: 'keyboard-hidden',
    claseEspacioBlanco: 'espacio-blanco-off',
    capsLock: false,
  })

  const fechaYHora = new Date();

  const [openModal, setOpenModal] = useState(false);
  const [numberModal, setNumberModal] = useState(undefined);

  const [formData, setFormData] = useState({
    CodigoColaborador: '',
    CodigoTienda: '',
    CodigoBanco: '',
    CodigoRemesa: '',
    ValorRemesa: '',
    VieneDeBackend: 0,
  });

  let cuentaBanco = null;

  if (loading === false) {
    // Se busca la cuenta del banco dependiendo del banco seleccionado en el dropdown
    if (formData.CodigoBanco !== '') {
      cuentaBanco = remesasApi.bancos.filter(banco => banco.CodigoBanco.includes(formData.CodigoBanco))[0].NumeroCuenta;
    }
    else {
      cuentaBanco = '0000';
    }
  }

  useEffect(async () => {
    let estadoCaja = getEstadoCaja();
    if (estadoCaja === 'CONT') {

      const fechaArray = valoresLogin.FechaYHora.split('T')[0];
      const fechaSeparada = fechaArray.split('-');

      // Si el turno está cerrado, se dispara una alerta para hacer corte z o para regresar al menú
      if (valoresLogin.EstadoTurno === 'CERRADO') {
        Swal.fire({
          title: 'No hay turno abierto',
          icon: 'question',
          text: `¿Desea realizar el CORTE Z del ${fechaSeparada[2]}/${fechaSeparada[1]}/${fechaSeparada[0]}?`,
          showDenyButton: true,
          confirmButtonText: 'Realizar CORTE Z',
          denyButtonText: 'Volver al menú',
          customClass: {
            confirmButton: 'ui orange button',
            denyButton: 'ui grey button',
          },
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            imprimirCorte('CORTE_Z', 'swal');
          } else if (result.isDenied) {
            navigate('/menu');
          }
        });
        return;
      }

      setloading(false);

      // Se cargan las remesas, saldos y bancos almacenados en localstorage
      // Es importante que al cerrar un turno se elimine todo lo correspondiente para que no se tomen datos
      // de un turno anterior al estar en contingencia
      const remesas = JSON.parse(localStorage.getItem('REMESAS'));
      const saldos = JSON.parse(localStorage.getItem('SALDOS'));
      const pendienteAux = saldos.filter(saldo => saldo.Saldo === 'SaldoPendiente');
      const aFavorAux = saldos.filter(saldo => saldo.Saldo === 'SaldoAFavor');
      const fondoCajaAux = saldos.filter(saldo => saldo.Saldo === 'FondoCaja');
      const bancos = JSON.parse(localStorage.getItem('BANCOS'));

      setRemesasApi({
        ...remesasApi,
        remesas,
        bancos,
        saldoAFavor: aFavorAux[0].Valor,
        saldoPendiente: pendienteAux[0].Valor,
        fondoCaja: fondoCajaAux[0].Valor,
      })
      setloading(false);
    }
    else { // Si no estamos en contingencia
      try {

        // Se consume el servicio para saber si hay turno abierto
        const respTurno = await turnoServices.turno.turnoAbierto(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja);

        const fechaArray = valoresLogin.FechaYHora.split('T')[0];
        const fechaSeparada = fechaArray.split('-');

        const fechaZ = new Date(valoresLogin.FechaYHora);
        const timeZ = fechaZ.getDate();

        const fechaActual = new Date();
        const timeActual = fechaActual.getDate();

        // Si no se cerró el turno o no se hizo el corte Z del día anterior, se muestra una alerta para hacerlo
        // También, al cerrarse un turno del día se muestra esta alerta para hacer un corte x o un corte z
        // Si el turno que se acaba de cerrar es del día actual, se muestra un mensaje en color rojo dentro de
        // la alerta, que indica que al realizar el corte Z, la caja se cerrará porque se tomará como que ya se 
        // hizo el cierre del día
        // Si el turno es del día anterior, no aparece este mensaje
        let mensajeOperacion = '<p class="color-texto">Si usted realiza el CORTE Z en este momento, ya no podrá operar en esta caja hasta el día de mañana.</p>';

        if (timeZ !== timeActual) mensajeOperacion = '';
        else mensajeOperacion = '<p class="color-texto">Si usted realiza el CORTE Z en este momento, ya no podrá operar en esta caja hasta el día de mañana.</p>';

        if (respTurno.data.MESSAGE === "No existe turno activo") {
          Swal.fire({
            title: 'No hay turno abierto',
            icon: 'question',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Realizar CORTE Z',
            denyButtonText: 'Realizar CORTE X',
            cancelButtonText: 'Salir',
            html:
              `<p>¿Desea realizar el CORTE Z del ${fechaSeparada[2]}/${fechaSeparada[1]}/${fechaSeparada[0]}?</p>` +
              `${mensajeOperacion}`,
            customClass: {
              cancelButton: 'ui grey button',
              confirmButton: 'ui orange button',
              denyButton: 'ui yellow button',
            },
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              imprimirCorte('CORTE_Z', 'swal');
            } else if (result.isDenied) {
              imprimirCorte('CORTE_X', 'swal');
            }
            else {
              navigate('/menu');
            }
          });
          return;
        }

        // Se consumen los servicios para toda la información de remesas y bancos
        const todoCierres = await getRemesasBancos(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja, valoresLogin.FechaYHora, valoresLogin.IntentosFacturacion);
        console.log(todoCierres);
        if (Object.hasOwn(todoCierres, 'remesas')) {
          setRemesasApi({
            ...remesasApi,
            remesas: todoCierres.remesas,
            bancos: todoCierres.bancos,
            saldoAFavor: todoCierres.saldoAFavor,
            saldoPendiente: todoCierres.saldoPendiente,
            fondoCaja: todoCierres.fondoCaja,
          })
          setloading(todoCierres.loading);
        }
        // Si se obtuvo un error de los servicios, se empieza a realizar el proceso para entrar en contingencia
        else if (Object.hasOwn(todoCierres, 'error')) {
          let intentosFallidos = getConteoContingencia();

          setConteoContingencia(intentosFallidos + 1);
          setloading(false);
          //setError(error.message);
          setError(error);

          if (intentosFallidos === valoresLogin.IntentosFacturacion - 1) {
            setEstadoCaja('CONT');
          }
        }

      } catch (error) {
        // Si hubo error, se realiza el proceso para entrar en contingencia
        console.log(error);
        setloading(false);
        setError(error);
        /*let intentosFallidos = getConteoContingencia();
        setConteoContingencia(intentosFallidos + 1);
        setloading(false);
        //setError(error.message);
        if (intentosFallidos === valoresLogin.IntentosFacturacion - 1) {
          setEstadoCaja('CONT');
          return;
        }*/
      }
    }


  }, []);

  // Handler formulario remesa
  const handleChange = (e, formato) => {
    // Se reinicia el errorInput
    setFormExtras({
      ...formExtras,
      errorInput: undefined,
    })

    let split = undefined;
    let tipo = undefined;
    let cantidadMaxima = undefined;

    let valorUsar = undefined;

    let valorActual = undefined;

    let cantidadDecimales = 0;


    // Si e no tiene longitud o si es de tipo número, es porque se usó el teclado digital y no el físico
    if (e.length !== undefined || typeof (e) == 'number') {

      // Valor del campo en el que se ingresó datos
      valorActual = formData[formExtras.campoSeleccionado];
      // Si el valor de e es !#, se presionó la tecla DELETE
      if (e == '!#') {
        // Se elimina el último caracter y se actualiza el valor
        const valorNuevo = valorActual.slice(0, -1)
        setFormData({
          ...formData,
          [formExtras.campoSeleccionado]: valorNuevo,
        })
        return;
      }

      // Si se selecciona la tecla CAPS, se activa la función para que todas las teclas se ingresen en
      // todo mayúsculas o minúsculas
      if (e == 'caps') {
        setValoresTeclado({
          ...valoresTeclado,
          capsLock: !valoresTeclado.capsLock,
        })
        return;
      }

      // Formato dicta el tipo de campo en el que se está escribiendo. 
      // El formato es de tipo: tipo-cantidadMaxima-decimales (decimales solo para números)
      // Se usa para el control de los campos, limitando cantidad de caracteres ingresada y cuáles son permitidos
      const formatoActual = formExtras.formatoSeleccionado;

      // Se divide el formato en tipo y cantidadMaxima
      split = formatoActual.split('-');
      tipo = split[0];
      cantidadMaxima = split[1];

      // Valor a utilizar
      // Al no ser un evento natural de js/react, e no tiene value ni name. e en este caso es directamente el
      // valor presionado en el teclado digital
      valorUsar = e;
    }
    else { // Se está usando el teclado físico
      split = formato.split('-');
      tipo = split[0];
      cantidadMaxima = split[1];

      // Reinicio de la cantidad de decimales
      cantidadDecimales = 0;

      // Si el formato trae 3 casillas, es porque vienen decimales
      if (split.length > 2) {
        cantidadDecimales = split[2];
      }

      // El valor de la tecla presionada en el teclado físico
      valorUsar = e.target.value;

      valorActual = e.target.value;
    }

    // MANEJO DE ERRORES AL INGRESAR DATOS AL FORMULARIO

    // Si el campo solo permite números
    if (tipo === 'numerico') {
      // Es posible que los campos no tengan límite de caracteres
      if (cantidadMaxima === 'no') {
        // Se valida si son solo números con una expresión regular
        if (!/^$|^[0-9]+$/.test(valorUsar)) {
          // Si no se cumple la expresión, se muestra un mensaje de error
          setFormExtras({
            ...formExtras,
            errorInput: 'numRem',
          });
          return;
        }
      }
      else { // Si hay un límite de caracteres
        // Se genera la expresión regular con el límite y se evalúa
        const exp = new RegExp(`^[0-9]{0,${cantidadMaxima}}$`);
        if (!exp.test(valorUsar)) {
          setFormExtras({
            ...formExtras,
            errorInput: 'numRem',
          });
          return;
        }
        // La validación de arriba no funciona para el uso del teclado digital, porque sirve solo para el
        // valor que se está intentando agregar
        // Cuando se usa el teclado digital es necesario usar todo el valor del campo para evaluar
        if (e.length !== undefined || typeof (e) == 'number') {
          const expActual = new RegExp(`^[0-9]{0,${cantidadMaxima - 1}}$`);
          if (!expActual.test(valorActual)) { // Se usa todo el valor del campo, en lugar de solo el valor ingresado
            setFormExtras({
              ...formExtras,
              errorInput: 'numRem',
            });
            return;
          }
        }
      }
    }
    // Si el campo permite números y puntos decimales
    else if (tipo === 'decimal') {
      // Esta expresión regular valida la cantidad de números antes y después del punto decimal
      const exp = new RegExp(`^([0-9]{0,${cantidadMaxima}})([\\.]{1}[0-9]{0,${cantidadDecimales}})?$`)
      if (!exp.test(valorUsar)) {
        setFormExtras({
          ...formExtras,
          errorInput: 'valRem',
        });
        return;
      }
      if (e.length !== undefined || typeof (e) == 'number') {
        const expActual = new RegExp(`^([0-9]{0,${cantidadMaxima}})([\\.]{1}[0-9]{0,${cantidadDecimales + 1}})?$`)
        if (!expActual.test(valorActual)) {
          setFormExtras({
            ...formExtras,
            errorInput: 'valRem',
          });
          return;
        }
      }
    }

    // Si se está usando el teclado digital
    if (e.length !== undefined || typeof (e) == 'number') {

      let valor = e;

      // Si el valor a ingresar es string y está activado el caps
      if (typeof (e) == 'string' && valoresTeclado.capsLock == true) {
        // Se hace mayúscula
        valor = e.toUpperCase();
      }

      // Se coloca el valor en el campo actualmente seleccionado
      setFormData({
        ...formData,
        [formExtras.campoSeleccionado]: valorActual + valor,
      })
    }
    else { // Si se usa el teclado físico
      // Si el campo afectado es CodigoBanco, se actualiza la cuenta de banco
      if (e.target.name === "CodigoBanco") {
        if (e.target.value !== '') {
          cuentaBanco = remesasApi.bancos.filter(banco => banco.CodigoBanco.includes(e.target.value))[0].NumeroCuenta;
        }
      }
      // Se actualiza el estado
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      })

    }
  }
  // Función para activar/desactivar el teclado digital
  const activarTeclado = (focus, e, formato) => {
    if (focus === true) {
      setValoresTeclado({
        ...valoresTeclado,
        claseTeclado: 'keyboard-num',
        claseEspacioBlanco: 'espacio-blanco-activado-c',
      })

      setFormExtras({
        ...formExtras,
        campoSeleccionado: e.target.name,
        formatoSeleccionado: formato,
      })
    }
    else {
      setValoresTeclado({
        ...valoresTeclado,
        claseTeclado: 'keyboard-hidden',
        claseEspacioBlanco: 'espacio-blanco-off',
      })
    }
  }

  const handleSubmit = (e) => {
    // Para evitar que el formulario se envíe al presionar enter. Esto se puede cambiar pero no es tan necesario
    // porque en las máquinas casi no se usa teclado
    e.preventDefault();

    // Variables para formar la fecha y hora de la remesa
    let mes = ((fechaYHora.getMonth() + 1) < 10) ? `0${fechaYHora.getMonth() + 1}` : fechaYHora.getMonth() + 1;
    let dia = (fechaYHora.getDate() < 10) ? `0${fechaYHora.getDate()}` : fechaYHora.getDate();
    let hora = (fechaYHora.getHours() < 10) ? `0${fechaYHora.getHours()}` : fechaYHora.getHours();
    let minutos = (fechaYHora.getMinutes() < 10) ? `0${fechaYHora.getMinutes()}` : fechaYHora.getMinutes();

    let remesaSubmit = {
      CodigoTienda: valoresLogin.codigoTienda,
      CodigoEmpresa: valoresLogin.codigoEmpresa,
      CodigoCajaFiscal: valoresLogin.codigoCaja,
      CodigoColaborador: valoresLogin.codigoColaborador,
      CodigoBanco: formData.CodigoBanco,
      ValorRemesa: parseFloat(formData.ValorRemesa),
      Turno: valoresLogin.codigoTurno,
      TipoRemesa: '',
      RemesaReferencia: null,
      EstadoSincronizacion: null,
      NumeroRemesa: formData.CodigoRemesa,
      TiempoRemesa: `${fechaYHora.getFullYear()}-${mes}-${dia}T${hora}:${minutos}:${fechaYHora.getSeconds()}`,
    }

    // Validación de formulario lleno
    const valoresForm = Object.values(formData);
    const llavesForm = Object.keys(formData);

    let vacio = false;

    for (let i = 0; i < valoresForm.length; i++) {
      if (llavesForm[i] === 'CodigoColaborador' || llavesForm[i] === 'VieneDeBackend' || llavesForm[i] === 'CodigoTienda') {
        //console.log('Nada');
      }
      else {
        if (valoresForm[i] === '' || valoresForm[i] === 0) {
          vacio = true;
        }
      }
    }

    if (vacio === true) {
      Swal.fire({
        title: 'Campos vacíos',
        text: 'Por favor llene todos los campos',
        icon: 'warning',
        customClass: {
          confirmButton: 'ui orange button'
        },
        confirmButtonText: 'Regresar'
      })
      vacio = false;
      return;
    }

    // Agregar el tipo de remesa al objeto
    if (formExtras.monedaCambio === true) {
      remesaSubmit = {
        ...remesaSubmit,
        TipoRemesa: 'CAMBIO'
      }
      handleChange(0, 'no');
    }
    else if (formExtras.monedaCredito === true) {
      remesaSubmit = {
        ...remesaSubmit,
        TipoRemesa: 'CREDITO'
      }
    }
    else {
      remesaSubmit = {
        ...remesaSubmit,
        TipoRemesa: 'EFECTIVO'
      }
    }

    // Coloca el formulario en estado de loading para mostrar que se está tratando de agregar la remesa
    setFormExtras({
      ...formExtras,
      estadoForm: 'ui loading big form',
    })

    // El tipo de caja (ALTA o CONT) define el servidor
    //const tipoCaja = tiempoEnContingencia(getEstadoCaja(), valoresLogin.TiempoContingencia);
    //console.log(tipoCaja);
    //const axios = instanciaAxios(tipoCaja);

    // Para almacenar los saldos y hacer solo una actualización de estado al final
    let saldoAFavor = 0;
    let saldoPendiente = 0;

    //remesaSubmit.RemesaReferencia = null;

    const axios = serverAxios('central');

    console.log(remesaSubmit);
    // Consumo de servicio
    remesaServices.remesa.postRemesa(remesaSubmit, axios)
      .then((response) => {

        // Esto todavía es posible?
        // Es posible que se esté duplicando el número de remesa, lo que causa un error único
        const mensajeExiste = 'duplicate';

        if (response.data.SUCCESS === false) {
          if (response.data.CODE === 9999) {
            console.log(response.data);
            //const rutaError = response.data.MESSAGE.InnerException.Errors[0].message;
            const rutaError = response.data.MESSAGE;
            // Si el error incluye la palabra 'duplicate', es porque se está duplicando el número de remesa
            if (rutaError.includes(mensajeExiste)) {
              Swal.fire({
                text: 'Ya existe una remesa con ese número',
                icon: 'error',
                customClass: {
                  confirmButton: 'ui orange button'
                },
                confirmButtonText: 'Regresar'
              });
              setFormExtras({
                ...formExtras,
                estadoForm: 'ui big form',
              })
              return;
            }
            else {
              Swal.fire({
                text: 'ERROR',
                icon: 'error',
                customClass: {
                  confirmButton: 'ui orange button'
                },
                confirmButtonText: 'Regresar'
              });
              setFormExtras({
                ...formExtras,
                estadoForm: 'ui big form',
              })
              return;
            }
          }
          else { // Si es cualquier otro error, se muestra el error completo en la alerta
            Swal.fire({
              title: 'No se pudo ingresar la remesa',
              text: response.data.MESSAGE,
              icon: 'error',
              customClass: {
                confirmButton: 'ui orange button'
              },
              confirmButtonText: 'Regresar'
            });
            setFormExtras({
              ...formExtras,
              estadoForm: 'ui big form',
            })
            return;
          }
        }
        else { // Si fue exitosa la creación de la remesa
          // Si la remesa es de tipo CAMBIO o CREDITO
          if (remesaSubmit.TipoRemesa !== 'EFECTIVO') {
            // Se suma directamente su valor a saldo a favor, no afecta a saldo pendiente
            const saldoAF = parseFloat(remesasApi.saldoAFavor) + parseFloat(formData.ValorRemesa);
            saldoAFavor = saldoAF;
          }
          else { // Si es EFECTIVO
            // Si hay saldo pendiente
            if (remesasApi.saldoPendiente > 0) {
              // Si el valor de la remesa a agregar es mayor al saldo pendiente
              if (remesasApi.saldoPendiente < formData.ValorRemesa) {
                // El saldo pendiente queda en cero y el restante pasa a ser saldo a favor
                const resta = parseFloat(remesasApi.saldoPendiente) - parseFloat(formData.ValorRemesa);
                const sumaAFavor = Math.abs(resta);
                const saldoAFAux = parseFloat(remesasApi.saldoAFavor) + parseFloat(sumaAFavor);

                saldoAFavor = saldoAFAux;
                saldoPendiente = 0;


                const saldoAux = [
                  { Saldo: 'SaldoPendiente', Valor: 0 },
                  { Saldo: 'SaldoAFavor', Valor: saldoAFAux },
                  { Saldo: 'FondoCaja', Valor: remesasApi.fondoCaja }
                ];
                localStorage.setItem('SALDOS', JSON.stringify(saldoAux));
              }
              else { // Si son iguales o el pendiente es mayor al valor de la remesa
                // Solo se resta el valor de la remesa del saldo pendiente
                const saldoPen = parseFloat(remesasApi.saldoPendiente) - parseFloat(formData.ValorRemesa);
                saldoPendiente = saldoPen;
                const saldos = [
                  { Saldo: 'SaldoPendiente', Valor: saldoPen },
                  { Saldo: 'SaldoAFavor', Valor: remesasApi.saldoAFavor },
                  { Saldo: 'FondoCaja', Valor: remesasApi.fondoCaja }
                ];
                localStorage.setItem('SALDOS', JSON.stringify(saldos));
              }

            } // Si el saldo pendiente es 0
            else if (remesasApi.saldoPendiente == 0) {
              // El valor de la remesa pasa directamente a ser saldo a favor
              const saldoAF = parseFloat(remesasApi.saldoAFavor) + parseFloat(formData.ValorRemesa);
              saldoAFavor = saldoAF;

              const saldos = [
                { Saldo: 'SaldoPendiente', Valor: remesasApi.saldoPendiente },
                { Saldo: 'SaldoAFavor', Valor: saldoAF },
                { Saldo: 'FondoCaja', Valor: remesasApi.fondoCaja }
              ];
              localStorage.setItem('SALDOS', JSON.stringify(saldos));
            }
          }

          // Codigo de la remesa que devuelve el servicio, útil para la tabla
          let CodigoRemesa = response.data.MESSAGE.CodigoRemesa;

          setFormData({
            ...formData,
            CodigoBanco: '',
            CodigoRemesa: '',
            ValorRemesa: '',
            VieneDeBackend: 0,
          })

          // Se agrega el código a la remesa y se actualiza el estado
          remesaSubmit = {
            ...remesaSubmit,
            CodigoRemesa: CodigoRemesa
          }

          let nuevasRemesas = [...remesasApi.remesas, remesaSubmit];
          setRemesasApi({
            ...remesasApi,
            remesas: nuevasRemesas,
            saldoAFavor,
            saldoPendiente,
          });
          localStorage.setItem('REMESAS', JSON.stringify([...remesasApi.remesas, remesaSubmit]));

          setFormExtras({
            ...formExtras,
            estadoForm: 'ui big form'
          })
        }
      }, (error) => {
        onCancelModal();
        console.error(error);

        // Se muestra el mensaje de error en la pantalla
        Swal.fire({
          title: 'Error',
          icon: 'error',
          text: 'No se pudo conectar con el servidor. Intente nuevamente.'
        })

        setFormExtras({
          ...formExtras,
          estadoForm: 'ui big form',
        })

        const estadoCaja = getEstadoCaja();

        // Si la caja está en ALTA, es necesario aumentar los intentosFallidos y hacer todo el proceso para
        // que la caja entre en contingencia
        if (estadoCaja === 'ALTA') {

          let intentosFallidos = getConteoContingencia();
          setConteoContingencia(intentosFallidos + 1);

          if (intentosFallidos === valoresLogin.IntentosFacturacion - 1) {
            setEstadoCaja('CONT');
            return;
          }

        }
      });
  }

  // Función para borrar una remesa
  const deleteRow = (remesa) => {

    // Lista auxiliar de remesas
    const listaAux = [...remesasApi.remesas];
    // Index de la remesa que se quiere eliminar
    //const indexRemesa = listaAux.findIndex(item => item.RemesaReferencia === remesa);
    const indexRemesa = listaAux.findIndex(item => item.NumeroRemesa === remesa);
    // Remesa completa a eliminar
    const remesaEliminar = listaAux[indexRemesa];

    let saldoPendiente = 0;
    let saldoAFavor = 0;

    onOpenModal(1);

    // El tipo de caja (ALTA o CONT) define el servidor
    const tipoCaja = tiempoEnContingencia(getEstadoCaja(), valoresLogin.TiempoContingencia);
    const axios = instanciaAxios(tipoCaja);
    remesaServices.remesa.borrarRemesa(listaAux[indexRemesa].CodigoRemesa, valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja, valoresLogin.codigoTurno, axios)
      .then((response) => {

        if (response.data.SUCCESS === false) {
          onCancelModal();
          Swal.fire({
            title: 'No se pudo eliminar la remesa',
            text: response.data.MESSAGE,
            icon: 'error',
            customClass: {
              confirmButton: 'ui orange button'
            },
            confirmButtonText: 'Regresar'
          });
          return;
        }
        else {
          onCancelModal();

          // Si el saldo a favor es mayor a 0 y el saldo pendiente es menor o igual a 0
          if (remesasApi.saldoAFavor > 0 && (remesasApi.saldoPendiente < 0 || remesasApi.saldoPendiente == 0)) {
            // Si el saldo a favor es menor al valor de la remesa a eliminar
            if (remesasApi.saldoAFavor < remesaEliminar.ValorRemesa) {

              // Hay saldo a favor, entonces al eliminar una remesa es necesario restar el valor de la remesa al
              // saldo a favor. Como el valor de la remesa a eliminar es mayor al saldo a favor, se pierde todo este
              // saldo y pasa a ser saldo pendiente 
              const resta = parseFloat(remesasApi.saldoAFavor) - parseFloat(remesaEliminar.ValorRemesa);
              const positivo = Math.abs(resta);

              saldoAFavor = 0;
              saldoPendiente = positivo;

              const saldoAux = [
                { Saldo: 'SaldoPendiente', Valor: positivo },
                { Saldo: 'SaldoAFavor', Valor: 0 },
                { Saldo: 'FondoCaja', Valor: remesasApi.fondoCaja }
              ];
              localStorage.setItem('SALDOS', JSON.stringify(saldoAux))
            }
            else { // Si el valor de la remesa no es mayor al saldo a favor
              // Solo se resta el valor de la remesa al saldo a favor
              const saldoAF = parseFloat(remesasApi.saldoAFavor) - parseFloat(remesaEliminar.ValorRemesa);
              saldoAFavor = saldoAF;

              const saldos = [
                { Saldo: 'SaldoPendiente', Valor: remesasApi.saldoPendiente },
                { Saldo: 'SaldoAFavor', Valor: saldoAF },
                { Saldo: 'FondoCaja', Valor: remesasApi.fondoCaja }
              ];
              localStorage.setItem('SALDOS', JSON.stringify(saldos));
            }
          }
          else {
            // Al eliminar una remesa, se suma al saldo pendiente
            const saldoPend = parseFloat(remesasApi.saldoPendiente) + parseFloat(remesaEliminar.ValorRemesa);
            saldoPendiente = saldoPend;

            const saldos = [
              { Saldo: 'SaldoPendiente', Valor: saldoPend },
              { Saldo: 'SaldoAFavor', Valor: remesasApi.saldoAFavor },
              { Saldo: 'FondoCaja', Valor: remesasApi.fondoCaja }
            ];
            localStorage.setItem('SALDOS', JSON.stringify(saldos));
          }

          // Se elimina la remesa de la lista y se actualiza el estado
          listaAux.splice(indexRemesa, 1);

          localStorage.setItem('REMESAS', JSON.stringify([...listaAux]));
          setRemesasApi({
            ...remesasApi,
            saldoAFavor,
            saldoPendiente,
            remesas: [...listaAux],
          })
        }
      }, (error) => {
        onCancelModal();
        console.error(error);

        // Se muestra el mensaje de error en la pantalla
        Swal.fire({
          title: 'Error',
          icon: 'error',
          text: 'No se pudo conectar con el servidor. Intente nuevamente.'
        })

        const estadoCaja = getEstadoCaja();

        // Si la caja está en ALTA, es necesario aumentar los intentosFallidos y hacer todo el proceso para
        // que la caja entre en contingencia
        if (estadoCaja === 'ALTA') {

          let intentosFallidos = getConteoContingencia();
          setConteoContingencia(intentosFallidos + 1);

          if (intentosFallidos === valoresLogin.IntentosFacturacion - 1) {
            setEstadoCaja('CONT');
            return;
          }

        }
      });
  }

  // Función para cerrar turno
  const cerrarTurno = () => {
    // Si queda saldo pendiente, no se puede realizar el cierre
    if (remesasApi.saldoPendiente !== 0) {
      Swal.fire({
        title: 'No se puede cerrar el turno',
        text: 'Aún queda saldo pendiente.',
        customClass: {
          confirmButton: 'ui orange button'
        },
        icon: 'error',
        confirmButtonText: 'Regresar'
      })
      return;
    }

    // MiniLoader
    onOpenModal(1);

    // El tipo de este "cierre" siempre es TURNO
    const cierreReq = {
      CodigoEmpresa: valoresLogin.codigoEmpresa,
      CodigoTienda: valoresLogin.codigoTienda,
      CodigoCaja: valoresLogin.codigoCaja,
      CodigoColaborador: valoresLogin.codigoColaborador,
      CodigoTurno: valoresLogin.codigoTurno,
      TipoCierre: "TURNO",
    }

    // Estado de la caja para saber a qué servidor apuntar
    const tipoCaja = tiempoEnContingencia(getEstadoCaja(), valoresLogin.TiempoContingencia);
    const axios = instanciaAxios(tipoCaja);
    turnoServices.turno.cierreTurno(cierreReq, axios)
      .then((response) => {

        onCancelModal();

        // No se pudo cerrar el turno
        if (response.data.SUCCESS === false) {
          Swal.fire({
            title: 'No ha podido cerrar el turno',
            text: JSON.stringify(response.data.MESSAGE),
            icon: 'error',
            customClass: {
              confirmButton: 'ui orange button'
            },
            confirmButtonText: 'Regresar'
          });
          return;
        }
        else {

          // Se actualiza el localStorage para indicar que el turno actual se cerró
          let dataUsuario = {
            ...usuario,
            Turno: {
              ...usuario.Turno,
              Estado: "CERRADO",
            }
          }

          guardarUsuario(dataUsuario);

          localStorage.setItem('ORDENES', JSON.stringify([]));

          Swal.fire({
            title: 'Turno cerrado',
            text: 'Se ha cerrado el turno con éxito',
            icon: 'success',
            customClass: {
              confirmButton: 'ui orange button'
            },
            confirmButtonText: 'OK'
          }).then(async (result) => {
            if (result.isConfirmed) validarTurno();
          });

        }
      }, (error) => {
        // Se muestra el error en la consola
        console.error(error);

        // Se muestra el mensaje de error en la pantalla
        setError('No se pudo conectar con el servidor.');
        setloading(false);

        const estadoCaja = getEstadoCaja();

        // Si la caja está en ALTA, es necesario aumentar los intentosFallidos y hacer todo el proceso para
        // que la caja entre en contingencia
        if (estadoCaja === 'ALTA') {

          let intentosFallidos = getConteoContingencia();
          setConteoContingencia(intentosFallidos + 1);

          if (intentosFallidos === valoresLogin.IntentosFacturacion - 1) {
            setEstadoCaja('CONT');
            return;
          }

        }
      });
  }

  // Función para realizar un corte X o un corte Z
  const imprimirCorte = (tipo, source) => {

    // Se muestra el miniLoader
    onOpenModal(1);

    // Objeto con la información del cierre
    const cierreReq = {
      CodigoEmpresa: valoresLogin.codigoEmpresa,
      CodigoTienda: valoresLogin.codigoTienda,
      CodigoCaja: valoresLogin.codigoCaja,
      CodigoColaborador: valoresLogin.codigoColaborador,
      CodigoTurno: valoresLogin.codigoTurno,
      TipoCierre: tipo,
    }

    // Se obtiene el tipo de caja (ALTA o CONT)
    const tipoCaja = tiempoEnContingencia(getEstadoCaja(), valoresLogin.TiempoContingencia);
    // Se crea la instancia de axios dependiendo de si se está o no en contigencia, ya que eso cambia el servidor
    // al que se apunta para consumir los servicios
    const axios = instanciaAxios(tipoCaja);
    // Consumo del servicio (cierreTurno también sirve para los cortes)
    turnoServices.turno.cierreTurno(cierreReq, axios)
      // Si hubo respuesta
      .then((response) => {

        // Se cierra el miniLoader
        onCancelModal();

        // Si no se pudo realizar el corte
        if (response.data.SUCCESS === false) {
          // Se muestra una alerta
          Swal.fire({
            title: `No se pudo realizar el ${tipo}`,
            text: response.data.MESSAGE,
            icon: 'error',
            customClass: {
              confirmButton: 'ui orange button'
            },
            confirmButtonText: 'Regresar'
          }).then(async (result) => {
            if (result.isConfirmed) {
              // Si al seleccionar regresar era tipo z
              if (tipo === 'CORTE_Z') {
                // Se regresa al menú
                navigate('/menu');
              }
              // Tipo X
              else if (tipo === 'CORTE_X') {
                // Se cargan las remesas nuevamente
                const todoRemesas = await getRemesas();
                setRemesasApi({
                  ...remesasApi,
                  remesas: todoRemesas.remesas,
                  saldoPendiente: todoRemesas.saldoPendiente,
                  saldoAFavor: todoRemesas.saldoAFavor,
                  fondoCaja: todoRemesas.fondoCaja,
                })
              }
            }
          });
        }
        else {
          Swal.fire({
            title: `${tipo} realizado con éxito`,
            icon: 'success',
            customClass: {
              confirmButton: 'ui orange button'
            },
            confirmButtonText: 'Regresar'
          }).then(async (result) => {
            if (result.isConfirmed) {
              if (tipo === 'CORTE_Z') {
                navigate('/menu');
              }
              else if (tipo === 'CORTE_X') {
                if (source === 'swal') {
                  validarTurno();
                }
                else {
                  const todoRemesas = await getRemesas();
                  setRemesasApi({
                    ...remesasApi,
                    remesas: todoRemesas.remesas,
                    saldoPendiente: todoRemesas.saldoPendiente,
                    saldoAFavor: todoRemesas.saldoAFavor,
                    fondoCaja: todoRemesas.fondoCaja,
                  })
                }
              }
            }
          });
        }
      }, (error) => { // En el caso de que no se pueda consumir el servicio
        // Se muestra el error en la consola
        console.error(error);

        // Se muestra el mensaje de error en la pantalla
        setError('No se pudo conectar con el servidor.');
        setloading(false);

        const estadoCaja = getEstadoCaja();

        // Si la caja está en ALTA, es necesario aumentar los intentosFallidos y hacer todo el proceso para
        // que la caja entre en contingencia
        if (estadoCaja === 'ALTA') {

          let intentosFallidos = getConteoContingencia();
          setConteoContingencia(intentosFallidos + 1);

          if (intentosFallidos === valoresLogin.IntentosFacturacion - 1) {
            setEstadoCaja('CONT');
            return;
          }

        }

      });
  }

  const validarTurno = async () => {
    // Se consume el servicio para saber si hay turno abierto
    try {
      const respTurno = await turnoServices.turno.turnoAbierto(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja);

      const fechaArray = valoresLogin.FechaYHora.split('T')[0];
      const fechaSeparada = fechaArray.split('-');

      const fechaZ = new Date(valoresLogin.FechaYHora);
      const timeZ = fechaZ.getDate();

      const fechaActual = new Date();
      const timeActual = fechaActual.getDate();

      // Si no se cerró el turno o no se hizo el corte Z del día anterior, se muestra una alerta para hacerlo
      // También, al cerrarse un turno del día se muestra esta alerta para hacer un corte x o un corte z
      // Si el turno que se acaba de cerrar es del día actual, se muestra un mensaje en color rojo dentro de
      // la alerta, que indica que al realizar el corte Z, la caja se cerrará porque se tomará como que ya se 
      // hizo el cierre del día
      // Si el turno es del día anterior, no aparece este mensaje
      let mensajeOperacion = '<p class="color-texto">Si usted realiza el CORTE Z en este momento, ya no podrá operar en esta caja hasta el día de mañana.</p>';

      if (timeZ !== timeActual) mensajeOperacion = '';
      else mensajeOperacion = '<p class="color-texto">Si usted realiza el CORTE Z en este momento, ya no podrá operar en esta caja hasta el día de mañana.</p>';

      if (respTurno.data.MESSAGE === "No existe turno activo") {
        Swal.fire({
          title: 'No hay turno abierto',
          icon: 'question',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Realizar CORTE Z',
          denyButtonText: 'Realizar CORTE X',
          cancelButtonText: 'Salir',
          html:
            `<p>¿Desea realizar el CORTE Z del ${fechaSeparada[2]}/${fechaSeparada[1]}/${fechaSeparada[0]}?</p>` +
            `${mensajeOperacion}`,
          customClass: {
            cancelButton: 'ui grey button',
            confirmButton: 'ui orange button',
            denyButton: 'ui yellow button',
          },
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            imprimirCorte('CORTE_Z', 'swal');
          } else if (result.isDenied) {
            imprimirCorte('CORTE_X', 'swal');
          }
          else {
            navigate('/menu');
          }
        });
        return;
      }
    } catch (error) {
      console.error(error);
      setloading(false);
      setError(error);
    }

  }

  const activarMonedaCambio = (monedaCambio) => {
    setFormExtras({
      ...formExtras,
      monedaCambio: monedaCambio,
      monedaCredito: false,
    })

  }

  const activarMonedaCredito = (monedaCredito) => {
    setFormExtras({
      ...formExtras,
      monedaCambio: false,
      monedaCredito: monedaCredito,
    })
  }

  const onOpenModal = (modalOption) => {
    setOpenModal(prevState => !prevState);
    setNumberModal(modalOption);
  }

  const onCancelModal = () => {
    setOpenModal(false);
  };

  if (loading) return <Loader />

  if (error !== undefined) return <PageError message={error.message} source={'cierres'} />

  return (
    <CierresUI
      remesasApi={remesasApi}

      formData={formData}
      formExtras={formExtras}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      setMonedaCambio={activarMonedaCambio}
      setMonedaCredito={activarMonedaCredito}
      cuentaBanco={cuentaBanco}
      fondoCaja={remesasApi.fondoCaja}
      codigoCaja={valoresLogin.codigoCaja}

      deleteRow={deleteRow}

      cerrarTurno={cerrarTurno}
      imprimirCorte={imprimirCorte}

      numberModal={numberModal}
      openModal={openModal}

      valoresTeclado={valoresTeclado}
      activarTeclado={activarTeclado}
    />
  );

}

export { Cierres }