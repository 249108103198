import React from "react"
import { BuscarCrearComplemento } from "../BuscarCrearComplemento"
import { TablaComplementos } from "../TablaComplementos"

const TabComplementos = () => {
    return (
        <div className="ui center aligned two column grid">
            <div className="column">
                <BuscarCrearComplemento estado={'crear'} />

            </div>
            <div className="column">
                <TablaComplementos />
            </div>

        </div>
    )
}

export { TabComplementos }