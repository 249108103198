import { prodData } from "./prodData";


const initialData = {
    tasks: {
        'task-1': { id: 'task-1', content: prodData[0] },
        'task-2': { id: 'task-2', content: prodData[1] },
    },
    columns: {
        'column-1': { id: 'column-1', title: 'Cuenta 1', taskIds: ['task-1', 'task-2'] },
        'column-2': { id: 'column-2', title: 'Cuenta 2', taskIds: [] },
    },
    columnOrder: ['column-1', 'column-2'],
}

export default initialData;