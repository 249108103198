import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const OrderItem = ({ infoOrden, fecha, pais }) => {

  const orden = infoOrden.Orden;

  const horaActual = fecha.getHours();
  const minActual = fecha.getMinutes();

  const tiempo = orden.TiempoCreacion.split('T');
  const tiempoInicio = tiempo[1].split('.');
  const tiempoInHoras = tiempoInicio[0].split(':')[0];
  const tiempoInMin = tiempoInicio[0].split(':')[1];

  const inicio = new Date('2022', '05', '29', tiempoInHoras, tiempoInMin);

  let tiempoHoras = horaActual - inicio.getHours();
  let tiempoMinutos = minActual - inicio.getMinutes();

  if (parseInt(tiempoHoras) < 0) {
    tiempoHoras = 24 - Math.abs(tiempoHoras);
  }

  if (parseInt(tiempoMinutos) < 0) {
    tiempoMinutos = 60 - Math.abs(tiempoMinutos);
  }

  let moneda = ''

  if (String(pais) === 'SV' || String(pais) === 'MX') moneda = '$.'
  else if (String(pais) === 'GT') moneda = 'Q.'
  else if (String(pais) === 'CR') moneda = '₡.'

  const link = `/pos/${orden.CodigoOrden}`;

  return (
    <Link to={link} state={infoOrden}>
      <div className="ui card">
        <div className="content">
          <h3>Orden: {orden.CodigoOrden}</h3>

          <p>Canal: {orden.DescripcionCanal}</p>
          <p>Total: {moneda} {(Math.round(orden.TotalFisico * 100) / 100).toFixed(2)}</p>
          <p>Tiempo: {tiempoHoras}:{tiempoMinutos}</p>
        </div>


        <button
          className="ui right labeled icon bottom attached orange button">
          <i className="chevron circle right icon"></i>
          LLAMAR ORDEN
        </button>
      </div>
    </Link>
  );
};

export { OrderItem };