import http from "../http-common";
import axios from "axios";
import qs from 'qs';

const loginServices = {
  login: {
    indentificarCaja(axios) {
      return axios.get("/Seguridad/IdentificarCaja");
    },
    iniciarTurno(turno = null) {
      return http.post("/Turno/IniciarTurno", turno);
    },
    getCorrelativo(codigoEmpresa, codigoTienda, codigoCaja, axios) {
      return axios.get(`/Correlativo?codigoEmpresa=${codigoEmpresa}&codigoTienda=${codigoTienda}&codigoCaja=${codigoCaja}`)
    },
    actualizarCorrelativo(codigoEmpresa, codigoTienda, codigoCaja, correlativoLocal, axios) {
      return axios.put(`/Utilidades/ActualizarCorrelativoCentral?codigoEmpresa=${codigoEmpresa}
      &codigoTienda=${codigoTienda}&codigoCaja=${codigoCaja}&correlativoLocal=${correlativoLocal}`);
    },
    iniciarPorPin(objLogin = null, axios) {
      return axios.post("/Seguridad/Login", objLogin);
    },
    loginPinUrlEncoded(objLogin = null) {
      const config = {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      }

      return http.post("/Seguridad/Login", qs.stringify(objLogin), config);
    }
  },
};

export { loginServices };
