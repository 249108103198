import './style.css'
import { gridTeclado } from './gridTeclado';
import React from 'react';

const TecladoCompleto = ({ handleChange, claseActivar, activarTeclado, capsLock }) => {
    return (
        <div className={claseActivar}>
            <div className="keyboard-keys">

                {
                    //keyLayout.map((item) => {
                    gridTeclado.map((item) => {
                        let claseBoton = ' keyboard-key';

                        let letra = '';

                        if (item.size === 'wide') {
                            claseBoton = ' keyboard-key-wide';
                        }
                        else if (item.size === 'extra-wide') {
                            claseBoton = ' keyboard-key-extra-wide';
                        }

                        if (item.id === 'cps') claseBoton += ' keyboard-key-activatable';


                        if (capsLock === true && item.id === 'cps') {
                            claseBoton += ' keyboard-key-active';
                        }

                        if (capsLock === true && item.valor.length === 1 && typeof (item.valor) === 'string') {
                            letra = item.valor.toUpperCase();
                        }
                        else {
                            letra = item.valor;
                        }

                        return (
                            (item.id === 'bckspc' || item.id === 'ampersand' || item.id === 'ntr' || item.id === 'exmark')
                                ? (
                                    (item.icon == 'no')
                                        ? (
                                            <React.Fragment key={item.id}>
                                                <button onClick={() => handleChange(item.valor)} className={'ui button keyboard-key' + claseBoton}>
                                                    {item.valor}
                                                </button>
                                                <br />
                                            </React.Fragment>
                                        )
                                        : (
                                            <React.Fragment key={item.id}>
                                                <button onClick={() => handleChange(item.valor)} className={'ui button keyboard-key' + claseBoton}>
                                                    <i className={item.icon}></i>
                                                </button>

                                                <br />
                                            </React.Fragment>
                                        )
                                )
                                : (
                                    (item.icon == 'no')
                                        ? (
                                            <React.Fragment key={item.id}>
                                                <button onClick={() => handleChange(!!capsLock ? item.valor.toUpperCase() : item.valor.toLowerCase())} className={'ui button keyboard-key' + claseBoton}>
                                                    {letra}
                                                </button>
                                            </React.Fragment>
                                        )
                                        : (
                                            <React.Fragment key={item.id}>
                                                <button onClick={() => handleChange(item.valor)} className={'ui button keyboard-key' + claseBoton}>
                                                    <i className={item.icon}></i>
                                                </button>
                                            </React.Fragment>
                                        )
                                )
                        )
                    })

                }
                <button onClick={() => activarTeclado(false)} type="button" className="ui left labeled icon button keyboard-key keyboard-key-wide keyboard-key-close">
                    <i className="close icon"></i>
                    Cerrar
                </button>
            </div>

        </div>
    )
}

export { TecladoCompleto };