import React, { useContext } from 'react'
import { ProductosContext } from '../ProductosContext'
import './style.css'

const FormCrearPluProducto = () => {

    const {
        state,
        changeFormPluProducto,
    } = useContext(ProductosContext)

    const onSubmitForm = (e) => {
        e.preventDefault();
    }

    return (
        <>
            <form className="ui form" onSubmit={onSubmitForm}>
                <div className="field">
                    <label>Descripción </label>
                    <input onChange={(e) => changeFormPluProducto(e)} value={state.formPluProducto.DescripcionProducto} name="DescripcionProducto" type="text" />
                </div>
                <div className="two fields">
                    <div className="field">
                        <label>Hora desde</label>
                        <input onChange={(e) => changeFormPluProducto(e)} value={state.formPluProducto.HoraDesde} name="HoraDesde" type="time" />
                    </div>
                    <div className="field">
                        <label>Hora hasta</label>
                        <input onChange={(e) => changeFormPluProducto(e)} value={state.formPluProducto.HoraHasta} name="HoraHasta" type="time" />
                    </div>
                </div>
                <div className="two fields">
                    <div className="field">
                        <label>Fecha desde</label>
                        <input onChange={(e) => changeFormPluProducto(e)} value={state.formPluProducto.FechaDesde} name="FechaDesde" type="date" />
                    </div>
                    <div className="field">
                        <label>Fecha hasta</label>
                        <input onChange={(e) => changeFormPluProducto(e)} value={state.formPluProducto.FechaHasta} name="FechaHasta" type="date" />
                    </div>
                </div>
            </form>
        </>
    )
}

export { FormCrearPluProducto }