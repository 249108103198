const DetalleTotalFecha = ({ controlAnimaciones, estadosAnulacion }) => {

    if (controlAnimaciones.activada === true) {
        if (controlAnimaciones.expandirFecha === true) {
            document.getElementById("detalleTotales-fecha").style.animation = " mostrar 1s forwards";
            document.getElementById("detalleTotales-fecha").style.display = " block";
        }
        else {
            document.getElementById("detalleTotales-fecha").style.display = " none";
        }
    }

    return (
        <div className="ui segment" id="detalleTotales-fecha">
            {
                (estadosAnulacion.estadoFechaOrden == true)
                    ? (

                        <div className="ui middle aligned divided list">

                            <div className="item">
                                <div className="right floated content">
                                    <div className="content">

                                    </div>
                                </div>
                                <div className="content">
                                    <h1>Detalle Totales</h1>
                                </div>
                            </div>
                            <div className="item">
                                <div className="right floated content">
                                    <div className="content">
                                        <h3>{(Math.round(estadosAnulacion.ordenAnularFecha.Orden.TotalFisico * 100) / 100).toFixed(2)}</h3>
                                    </div>
                                </div>
                                <div className="content">
                                    <h3>Valor Efectivo</h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="right floated content">
                                    <div className="content">
                                        <h3>0.00</h3>
                                    </div>
                                </div>
                                <div className="content">
                                    <h3>Valor Credito</h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="right floated content">
                                    <div className="content">
                                        <h3>{(Math.round(estadosAnulacion.ordenAnularFecha.Orden.TotalDescuento * 100) / 100).toFixed(2)}</h3>
                                    </div>
                                </div>
                                <div className="content">
                                    <h3>Valor Descuentos</h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="right floated content">
                                    <div className="content">
                                        <h3>{(Math.round((estadosAnulacion.ordenAnularFecha.Orden.TotalFisico - estadosAnulacion.ordenAnularFecha.Orden.TotalDescuento) * 100) / 100).toFixed(2)}</h3>
                                    </div>
                                </div>
                                <div className="content">
                                    <h3>Venta Total</h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="right floated content">
                                    <div className="content">
                                        <h3>0.00</h3>
                                    </div>
                                </div>
                                <div className="content">
                                    <h3>Valor Propina</h3>
                                </div>
                            </div>
                            {/* <div className="item">
                                <div className="right floated content">
                                    <div className="content">
                                        <h3>0</h3>
                                    </div>
                                </div>
                                <div className="content">
                                    <h3>Tiquete devuelto</h3>
                                </div>
                            </div> */}

                        </div>

                    )
                    : null
            }
        </div>
    )
}

export { DetalleTotalFecha }