import React, { useState } from 'react';
import { SWAL_ACTION, swalFire } from '../../../constants/sweetAlert';

import './style.css';

const ModalProductos = ({ productoComplemento, state, dispatch, actions, addProductoDetalle }) => {

    const [comp, setComp] = useState(0);
    const [complemento, setComplemento] = useState(0);
    const [padComp, setPadComp] = useState('');
    const [listaComplemento, setListaComplemento] = useState([]);

    //console.log(productoComplemento);
    const complementoActual = productoComplemento.Complementos[complemento];
    const opciones = complementoActual.Opciones;

    let valorPad = (state.numberPad === '' || state.numberPad === 0) ? 1 : state.numberPad;

    const conteoComplemento = {};
    //listaComplemento.forEach((x) => { conteoComplemento[x.id] = ((conteoComplemento[x.id] || 0) + 1) * x.Cantidad });
    listaComplemento.forEach((x) => { conteoComplemento[x.id] = (conteoComplemento[x.id] || 0) + (1 * x.Cantidad) });

    const nuevoMinimoActual = (complementoActual.CantidadMinimaComplemento === 0) ? valorPad : complementoActual.CantidadMinimaComplemento * parseInt(valorPad);
    const nuevoMaximoActual = complementoActual.CantidadMaximaComplemento * parseInt(valorPad);

    //let arrayOrderOpcion = [];

    let btnOpcion = 'ui fluid button item-height p-btn prod-margin';
    if ((nuevoMinimoActual >= 0 &&
        (nuevoMaximoActual - (conteoComplemento[complementoActual.CodigoComplemento] || 0)) === 0) &&
        ((conteoComplemento[complementoActual.CodigoComplemento] || 0) <= nuevoMaximoActual)) {
        btnOpcion += ' disabled';
    }

    //console.log(productoComplemento.Complementos);

    const onClickPad = (digit) => {
        if (padComp.length < 3) {
            setPadComp('' + padComp + digit);
        }
    }

    const onAddOpcion = (opcion = null) => {
        //const conteo = {};
        //console.log('onAddOpcion', opcion);
        //arrayOrderOpcion.push(opcion);

        let opcionesEliminadas = state.arrayOpcionesEliminadas;
        let opcionesActuales = state.arrayOrderOpcion;

        //console.log(opcion);
        //console.log(complemento);

        const opcionState = {
            CodigoEmpresa: productoComplemento.CodigoEmpresa,
            CodigoPlu: opcion.CodigoOpcion,
            Cantidad: padComp === '' ? 1 : parseInt(padComp),
            Precio: opcion.PrecioOpcion,
            CategoriaItem: 2,
            Secuencia: 0,
            Descripcion: opcion.DescripcionOpcion,
            //Posicion: complemento,
            Posicion: opcion.PosicionOpcion,
            id: complementoActual.CodigoComplemento,
            codigoColor: complemento,
        }

        // Cómo se muestren las opciones en el modal no importa
        // Este código es importante para poder ordenar los productos al crearlos
        // Solo afecta al state.arrayOrderOpcion para poder desplegar los productos con sus opciones correspondientes
        if (opcionesEliminadas.length > 0) {
            // id dicta el código de complemento 
            // Necesito saber el codigo de complemento o el de plu? Debería ser el codigo de complemento
            // para poder asociarlo directamente a sus espacios correspondientes no? 
            //console.log(opcion);
            const eliminadosFilter = opcionesEliminadas.filter(item => item.CodigoComplemento === opcionState.id);
            //console.log(eliminadosFilter);
            if (eliminadosFilter.length > 0) {
                // Si coincide y hay elementos elimindos, la nueva opción seleccionada se puede agregar
                // al espacio que dejó la opción eliminada

                // Se agrega la nueva opción en el index de la primera eliminda del complemento, sin borrar nada
                //opcionesActuales.splice(eliminadosFilter[0].Index, 0, opcionState);

                // Debido a que el array de opciones ya no elimina las opciones eliminadas, no es necesario
                // hacer un splice para añadir la opción en la casilla correspondiente, solo hay que cambiar
                // el valor de esa casilla
                const indiceActual = eliminadosFilter[0].Index;
                opcionesActuales[indiceActual] = opcionState;

                //console.log(opcionesActuales);

                //console.log(opcionesActuales);

                // Ahora hay que eliminar la opción eliminado del array de eliminadas xd 
                const indexEliminada = opcionesEliminadas.findIndex(item => item.Index === eliminadosFilter[0].Index);
                opcionesEliminadas.splice(indexEliminada, 1);

                // Actualizar el estado
                dispatch({ type: actions.ELIMINAR_OPCIONES, payload: opcionesActuales });
                dispatch({ type: actions.ACTUALIZAR_OPS_ELIMINADAS, payload: opcionesEliminadas });


            } else { // La opción pertenece a un complemento sin actualizar
                dispatch({ type: actions.ACTUALIZAR_ARRAY_OPCIONES, payload: opcionState });
            }



        }
        else {
            dispatch({ type: actions.ACTUALIZAR_ARRAY_OPCIONES, payload: opcionState });
        }

        //console.log(arrayOrderOpcion);

        //listaComplemento.forEach((x) => {conteo[x.Posicion] = (conteo[x.Posicion] || 0) + 1});

        //console.log(conteo);

        let listaAux = [...listaComplemento];

        // Si todavía no estaba agregada la opción al listado 
        if (listaAux.find(element => element.CodigoPlu === opcion.CodigoOpcion) === undefined) {
            const nuevoComplemento = {
                CodigoEmpresa: productoComplemento.CodigoEmpresa,
                CodigoPlu: opcion.CodigoOpcion,
                Cantidad: padComp === '' ? 1 : parseInt(padComp),
                Precio: opcion.PrecioOpcion,
                CategoriaItem: 2,
                Secuencia: 0,
                Descripcion: opcion.DescripcionOpcion,
                //Posicion: complemento,
                Posicion: opcion.PosicionOpcion,
                id: complementoActual.CodigoComplemento,
                codigoColor: complemento,
            }
            listaAux.push(nuevoComplemento);
        } else {
            listaAux = listaAux.map((item) =>
                item.CodigoPlu === opcion.CodigoOpcion ? { ...item, Cantidad: padComp === '' ? item.Cantidad + 1 : item.Cantidad + parseInt(padComp) } : item
            );
        }

        const conteoAux = {};
        listaAux.forEach((x) => {
            //conteoAux[x.id] = ((conteoAux[x.id] || 0) + 1) * x.Cantidad;
            conteoAux[x.id] = (conteoAux[x.id] || 0) + (1 * x.Cantidad);
            //console.log(conteoAux[x.id]);
        })

        const estadoComps = [...state.estadosComplementos];
        //console.log(conteoAux[complementoActual.CodigoComplemento]);

        if (((nuevoMaximoActual - (conteoAux[complementoActual.CodigoComplemento] || 0)) >= 0)) {
            setListaComplemento(listaAux);
            if (((nuevoMaximoActual - (conteoAux[complementoActual.CodigoComplemento] || 0)) === 0)) {
                //console.log(productoComplemento.Complementos.length, complemento);
                //console.log(complementoActual.CodigoComplemento);
                //console.log(estadoComps);
                //console.log(state.estadosComplementos);
                //let compEstadoActual = estadoComps.filter(item => item.CodigoComplemento === complementoActual.CodigoComplemento)[0];
                let indexEstadoActual = estadoComps.findIndex(item => item.CodigoComplemento === complementoActual.CodigoComplemento)
                console.log(indexEstadoActual);
                estadoComps[indexEstadoActual].EstadoBoton = 'disabled';
                dispatch({ type: actions.CARGAR_ESTADO_COMPLEMENTOS, payload: estadoComps });
                //console.log(state.estadosComplementos()
                setComplemento(complemento === productoComplemento.Complementos.length - 1 ? complemento : complemento + 1);
            }
        } else {
            swalFire(SWAL_ACTION.AGREGAR_OPCION);
        }

        setPadComp('');
    }

    const onDeleteItem = (codigoPlu = '') => {
        const auxLista = [...listaComplemento];

        const estadosComps = [...state.estadosComplementos];
        const auxStateOps = [...state.arrayOrderOpcion];
        const indexEliminados = getMultipleIndexes(auxStateOps, codigoPlu);
        let arrayEliminados = [...state.arrayOpcionesEliminadas]; // Este array probablemente tenga que ser en el estado

        let complementoCodigo = '';
        indexEliminados.forEach(element => {
            const objetoEliminado = {
                CodigoPlu: auxStateOps[element].CodigoPlu,
                CodigoComplemento: auxStateOps[element].id,
                Index: element,
            }
            complementoCodigo = auxStateOps[element].id;
            arrayEliminados.push(objetoEliminado);
        });

        // Al borrar una opción de la lista, se tiene que volver a habilitar el botón para seleccionar complementos
        // para eso, hay que hacer dispatch otra vez para actuaizar el estado a enabled
        const indexEstadoComp = estadosComps.findIndex(item => item.CodigoComplemento === complementoCodigo);
        estadosComps[indexEstadoComp].EstadoBoton = 'enabled';

        dispatch({ type: actions.CARGAR_ESTADO_COMPLEMENTOS, payload: estadosComps });

        // Las opciones elimindas quedan guardadas en una nueva variable de estado llamada arrayOpcionesEliminadas
        dispatch({ type: actions.ACTUALIZAR_OPS_ELIMINADAS, payload: arrayEliminados });
        // Es importante guardar las opciones elimindas para saber a qué complemento y a qué posición pertenecen
        // para poder agregarlas después en el lugar correspondiente

        setListaComplemento(auxLista.filter(item => item.CodigoPlu !== codigoPlu));
    }

    const getMultipleIndexes = (array, el) => {
        // array es el array en el que se quiere buscar
        // el es el elemento con el que se quiere comparar 
        let idxs = [];
        for (let i = array.length - 1; i >= 0; i--) {
            if (array[i].CodigoPlu === el) {
                idxs.unshift(i);
            }
        }
        return idxs;
    };

    const insertarComplementos = () => {
        addProductoDetalle({ ...productoComplemento, opciones: listaComplemento })
    }

    const cerrarModal = () => {
        dispatch({ type: actions.RESET_ARRAY_OPCIONES });
        dispatch({ type: actions.CERRAR_MODAL });
    }

    const complementoColorPicker = (i) => {
        let color = '';
        switch (i) {
            case 0: // red
                return color = "ui fluid button red item-height p-btn prod-margin"
            case 1: // orange
                return color = "ui fluid button orange item-height p-btn prod-margin"
            case 2: // yellow
                return color = "ui fluid button yellow item-height p-btn prod-margin"
            case 3: // olive
                return color = "ui fluid button olive item-height p-btn prod-margin"
            case 4: // green
                return color = "ui fluid button green item-height p-btn prod-margin"
            case 5: // teal
                return color = "ui fluid button teal item-height p-btn prod-margin"
            case 6: // blue
                return color = "ui fluid button blue item-height p-btn prod-margin"
            case 7: // violet
                return color = "ui fluid button violet item-height p-btn prod-margin"

            default:
                return color = "ui fluid button grey item-height p-btn prod-margin"
        }
    }

    const opcionColorPicker = (i) => {
        let color = '';
        switch (i) {
            case 0:
                return color = "ui fluid red segment margin-BotTop"
            case 1:
                return color = "ui fluid orange segment margin-BotTop"
            case 2:
                return color = "ui fluid yellow segment margin-BotTop"
            case 3:
                return color = "ui fluid olive segment margin-BotTop"
            case 4:
                return color = "ui fluid green segment margin-BotTop"
            case 5:
                return color = "ui fluid teal segment margin-BotTop"
            case 6:
                return color = "ui fluid blue segment margin-BotTop"
            case 7:
                return color = "ui fluid violet segment margin-BotTop"

            default:
                return color = "ui fluid segment margin-BotTop"
        }
    }

    return (
        <div className="ui large modal md-disp">
            <div className="header">OPCIONES DE MENÚ: {productoComplemento.Producto}</div>

            <div className="content ctn-h">

                <div className="ui grid">
                    <div className="row">
                        <div className="six wide column" style={{ color: 'black' }}>
                            <div className="segment opcion-panel">
                                {listaComplemento.map(item => {
                                    let claseSegmento = ''
                                    claseSegmento = opcionColorPicker(item.codigoColor)
                                    return (
                                        <div className={claseSegmento} key={item.CodigoPlu + item.Descripcion}>
                                            <div className="ui grid">
                                                <div className="one wide column">{item.Cantidad}</div>
                                                <div className="seven wide column">
                                                    {item.Descripcion}
                                                </div>
                                                <div className="two wide column txt-ae">
                                                    {(item.Precio * item.Cantidad).toFixed(2)}
                                                </div>
                                                <div className="five wide column a-el">
                                                    <div className="large ui icon buttons">
                                                        <button
                                                            className="ui black basic button"
                                                            onClick={() => onDeleteItem(item.CodigoPlu)}
                                                        >
                                                            <i className="trash icon"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="one wide column">
                            <div className="ui grid h-100">
                                <div className="row pad-va p-0">
                                    <div className="ui huge grey label">
                                        {padComp !== '' ? padComp : 0}
                                    </div>
                                    <button onClick={() => setPadComp('')} className="ui fluid button">CE</button>
                                    <button onClick={() => onClickPad(1)} className="ui fluid button">1</button>
                                    <button onClick={() => onClickPad(2)} className="ui fluid button">2</button>
                                    <button onClick={() => onClickPad(3)} className="ui fluid button">3</button>
                                    <button onClick={() => onClickPad(4)} className="ui fluid button">4</button>
                                    <button onClick={() => onClickPad(5)} className="ui fluid button">5</button>
                                    <button onClick={() => onClickPad(6)} className="ui fluid button">6</button>
                                    <button onClick={() => onClickPad(7)} className="ui fluid button">7</button>
                                    <button onClick={() => onClickPad(8)} className="ui fluid button">8</button>
                                    <button onClick={() => onClickPad(9)} className="ui fluid button">9</button>
                                    <button onClick={() => onClickPad(9)} className="ui fluid button">0</button>
                                    {
                                        padComp === ''
                                            ? <button onClick={() => onClickPad(0)} className="ui fluid button" disabled>0</button>
                                            : <button onClick={() => onClickPad(0)} className="ui fluid button">0</button>
                                    }
                                </div>
                            </div>
                        </div>
                        {<div className="nine wide column">
                            <div className="ui five column doubling grid opcion-panel">
                                {
                                    productoComplemento.Complementos.map((comp, i) => {
                                        let color = '';
                                        color = complementoColorPicker(i);
                                        //if (state.estadosComplementos[i].EstadoBoton === 'disabled') color += ' disabled';
                                        if (state.estadosComplementos[i].EstadoBoton === 'disabled') color += ' complemento-dis';
                                        return (
                                            <div className="column item-padding" key={comp.CodigoComplemento}>
                                                <button
                                                    className={color}
                                                    onClick={() => setComplemento(i)}
                                                >
                                                    {comp.DescripcionComplemento} ({(comp.CantidadMaximaComplemento * valorPad) - (conteoComplemento[comp.CodigoComplemento] || 0)})
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="ui five column doubling grid">
                                {opciones.map((comp, i) => (
                                    <div className="column item-padding" key={comp.CodigoOpcion}>
                                        <button
                                            className={btnOpcion}
                                            onClick={() => onAddOpcion(comp)}
                                        >
                                            {comp.DescripcionOpcion}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </div>
                </div>

            </div>

            <div className="actions">
                <button
                    className="ui orange labeled icon button"
                    onClick={insertarComplementos}
                >
                    <i className="check icon"></i>
                    ACEPTAR
                </button>
                <button
                    className="ui cancel button"
                    onClick={cerrarModal}
                >
                    <i className="close icon"></i>
                    CANCELAR
                </button>
            </div>
        </div>
    );
};

export { ModalProductos };
