import React from "react"
import { MantProdUI } from "./MantProdUI"
import { ProductosProvider } from "./ProductosContext"

const MantenimientoProductos = () => {
    return (
        <ProductosProvider>
            <MantProdUI />
        </ProductosProvider>
    )
}

export { MantenimientoProductos }