import React from 'react';
import Swal from 'sweetalert2';
import './styles.css';

const PosMenuTotals = ({ state, dispatch, actions, flujoDeCobro, totalOrder, itemList }) => {

  let descuento = 0;
  let ordenConDescuento = itemList.filter(item => item.CodigoPlu === 'DESCUENTO');

  if (ordenConDescuento.length > 0) {
    descuento = ordenConDescuento[0].Precio;
  }

  const onCobrarCliente = () => {

    const cuentaActual = parseFloat((totalOrder + descuento).toFixed(2));
    const valorMaximo = parseFloat((Math.round(state.valorMaximoCF * 100) / 100).toFixed(2));

    //console.log(cuentaActual >= valorMaximo);

    if ((state.datosFiscales.nit === '' || state.datosFiscales.nit === null) && (cuentaActual >= valorMaximo)) { // Si el nit es CF
      Swal.fire({
        title: 'No se puede hacer el cobro con C/F',
        text: `El valor de la cuenta es mayor a Q${valorMaximo}. Debe ingresar un NIT, Pasaporte o CUI para realizar el cobro.`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'Cancelar',
        confirmButtonText: 'Ingresar documento',
        customClass: {
          confirmButton: 'ui orange button',
          denyButton: 'ui grey button',
        }
      }).then(result => {
        if (result.isConfirmed) { // Mostrar modal de id otra vez
          // Se puede hacer un estado extra para dominar el estado del botón C/F y no permitir que se seleccione
          // Pero al seleccionarlo, no hay problema porque la validación no lo deja pasar a cobro
          dispatch({ type: actions.NUMERO_MODAL, payload: 5 });
        }
      })
      return;
    }

    let documento = state.response.documentos.filter((doc) => state.orderOptions.codigoDocumento === doc.CodigoDocumento)[0];
    console.log(documento);
    //NOTA: SI ES CONTINGENCIA ES NECESARIO ABRIR EL MODAL PARA INGRESARLA MANUALMENTE
    if (documento.Descripcion.includes('CONT')) {
      dispatch({ type: actions.NUMERO_MODAL, payload: 21 });
    } else {
      flujoDeCobro();
    }
  };

  let btnInsertarClass = 'fluid ui green button btn-height';
  // validación para poder realizar el cobro de una orden
  if (!!state.loaderAccion || state.orderItems.length === 0 || (state.orderItems.length > 0 && totalOrder === 0 && descuento === 0)) {
    btnInsertarClass += ' disabled';
  }

  return (
    <div className="ui grid">
      <div className="eleven wide column">
        <div className="ui segment m--0 p--0">
          <h3 className="mt--20 p-0">Subtotal: <span className="float--r">{totalOrder.toFixed(2)}</span></h3>
          <h3 className="m-0 p-0">Descuento: <span className="float--r">{descuento !== 0 ? descuento.toFixed(2) : "0.00"}</span></h3>
          <h2 className="m-0 p-0">Total: <span className="float--r">Q{(totalOrder + descuento).toFixed(2)}</span></h2>
        </div>
      </div>
      <div className="five wide column">
        <button onClick={onCobrarCliente} className={btnInsertarClass}>
          <i className="fitted money bill alternate outline big icon"></i>
        </button>
      </div>
    </div>
  );
};

export { PosMenuTotals };