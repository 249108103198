
const CierreTablaRemesa = ({ children, pais }) => {

  let moneda = ''

  if (pais === 'GT') {
    moneda = 'Q.'
  }
  else if (pais === 'SV' || pais === 'MX') {
    moneda = '$.'
  }
  else if (pais === 'CR') {
    moneda = '₡.'
  }

  return (
    <table className="ui definition small celled table">
      <thead>
        <tr>
          <th>Acciones</th>
          <th>Remesa</th>
          <th>Colaborador</th>
          <th>Valor ({moneda})</th>
          <th>Hora</th>
          <th>Banco</th>
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
    </table>
  );
}

export { CierreTablaRemesa }