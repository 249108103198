import React from 'react';
import { useNavigate } from 'react-router-dom';
import errorIcono from '../../../img/iconoError.png';
import './style.css'

const PageError = ({ message, source }) => {

  let navigate = useNavigate();

  return (
    <div className="container tecPadTop">
      {/* <div className="ui segment">
        <p>❌ {message} 😱</p>
      </div> */}

      <div className="ui red raised very padded text container segment">
        <div className="ui equal width grid">
          <div className="stretched row">
            <div className="six wide column">

              <img className="ui image" src={errorIcono} />
              {/* <h1>😱</h1> */}
            </div>

            <div className="column">
              <h1>No se puede cargar la página</h1>
              <p>
                Póngase en contacto con su administrador o recarge la página
              </p>
              <p className='error-p'>
                Error: {message}
              </p>

              {
                (source === 'login')
                  ? (
                    <div className="ui centered equal width grid">
                      <div className="three wide column">

                      </div>
                      <div className="ten wide column">
                        <button
                          onClick={() => window.location.reload()}
                          className="ui fluid orange labeled icon button">
                          <i className="redo icon"></i>
                          Recargar página
                        </button>
                      </div>
                      <div className="three wide column">

                      </div>
                    </div>
                  )
                  : (
                    <div className="ui centered equal width grid">
                      <div className="seven wide column">
                        <button
                          onClick={() => window.location.reload()}
                          className="ui fluid orange labeled icon button">
                          <i className="redo icon"></i>
                          Recargar
                        </button>
                      </div>
                      <div className="two wide column">

                      </div>
                      <div className="seven wide column">
                        <button
                          onClick={() => navigate('/menu')}
                          className="ui fluid orange labeled icon button">
                          <i className="sign-out icon"></i>
                          Ir al menú
                        </button>
                      </div>
                    </div>
                  )
              }


            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { PageError };