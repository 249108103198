import React, { useContext, useState } from 'react';
import { PosContext } from '../PosContext';
import { TecladoNumerico } from '../../TecladoNumerico';
import { expresionesRegulares } from '../../../constants/ExpresionesRegulares';

const ModalDigitarDescuento = ({
  state,
  dispatch,
  actions,
  addProductoDetalle,
  calcularDescuento,
  totalOrder,
  reiniciarDescuento,
}) => {
  const [montoDescuento, setMontoDescuento] = useState("");

  const inputHandler = (valor) => {
    if (typeof valor === "number") {
      setMontoDescuento(montoDescuento + valor);
    } else if (valor === ".") {
      if (
        state.descuentoFuncion.descuentoDigitado.replace(/[^.]/g, "").length <
        1 &&
        valor === "."
      ) {
        setMontoDescuento(montoDescuento + valor);
      }
    } else if (valor === "!#") {
      setMontoDescuento(montoDescuento.slice(0, -1));
    } else {
      const valorEvento = valor.target.value;

      if (valorEvento.replace(/[^.]/g, "").length <= 1) {
        if (
          !!Number.isFinite(parseFloat(valorEvento)) ||
          valorEvento === "." ||
          valorEvento[valorEvento.length - 1] == "."
        ) {
          setMontoDescuento(valorEvento);
        }
      }
    }
  };

  const onCancelDescuento = () => {
    reiniciarDescuento();
    dispatch({ type: actions.CERRAR_MODAL });
  };

  const onAceptDescuento = () => {
    let descuentoProvisional = {
      ...state.descuentoFuncion,
      valorDigitado: montoDescuento,
    };
    if (state.descuentoFuncion.referencia === true) {
      // pedimos la referencia en el modal de referencia descuento
      //console.log(montoDescuento);
      dispatch({
        type: actions.MODIFICAR_DESCUENTO,
        payload: descuentoProvisional,
      });
      dispatch({ type: actions.NUMERO_MODAL, payload: 10 });
    } else {
      //el descuento no pide referencia, por lo tanto se agrega el descuento
      descuentoProvisional = {
        ...descuentoProvisional,
        funcion: true,
      };
      const descuento = (
        calcularDescuento(null, descuentoProvisional) * -1
      ).toFixed(2);

      addProductoDetalle({
        CodigoPlu: "DESCUENTO",
        descripcion: descuentoProvisional.descripcion,
        Precio: descuento,
      });
      dispatch({
        type: actions.MODIFICAR_DESCUENTO,
        payload: descuentoProvisional,
      });
      dispatch({
        type: actions.MODIFICAR_ORDEN,
        payload: { ...state.orderOptions, totalDescuento: descuento },
      });
      dispatch({ type: actions.CERRAR_MODAL });
    }
  };

  let btnAceptarClass = "ui positive let labeled icon button";
  if (
    calcularDescuento(montoDescuento) * -1 > totalOrder ||
    montoDescuento === "" ||
    expresionesRegulares.inicioCeros.test(montoDescuento)
  ) {
    btnAceptarClass += " disabled";
  }

  return (
    <div className="ui tiny longer modal md-disp">
      <div className="header">DIGITAR DESCUENTO</div>

      <div className="content">
        <div className="ui grid">
          <div className="row padBot">
            <div className="sixteen wide column padBot">
              <div className="ui fluid input">
                <input
                  onChange={(e) => inputHandler(e)}
                  value={montoDescuento}
                  type="text"
                  placeholder="VALOR DESCUENTO"
                />
              </div>
            </div>
          </div>

          <div className="stretched row padBotTop">
            <TecladoNumerico inputHandler={inputHandler} usarDecimal={true} />
          </div>
        </div>
      </div>

      <div className="actions">
        <button className={btnAceptarClass} onClick={() => onAceptDescuento()}>
          ACEPTAR
          <i className="check icon"></i>
        </button>
        <button
          className="ui red let labeled icon button"
          onClick={() => onCancelDescuento()}
        >
          CANCELAR
          <i className="x icon"></i>
        </button>
      </div>
    </div>
  );
};

export { ModalDigitarDescuento };