import React, { useEffect } from "react";
import { ordenDescuento } from '../../../constants/Orden';
import "./styles.css";

const PosSelectedMenuItem = ({ state, dispatch, actions, selectedProduct, itemList, reiniciarDescuento }) => {

  useEffect(() => {
    window.$(".ui.accordion").accordion();
  }, []);

  const onDeleteItem = (plu, pluOriginal) => {
    // Logica para complementos
    // Si se usa el PLU, se borran todos los productos del mismo plu aunque tengan distintas opciones (sean
    // tarjetas distintas)
    //let newOrderList = itemList.filter((item) => !plu.includes(item.CodigoPlu));
    //console.log(pluOriginal);
    //console.log(itemList);
    let newOrderList = itemList.filter((item) => !plu.includes(item.idUnico));

    dispatch({ type: actions.LISTA_PRODUCTOS, payload: newOrderList });

    if (pluOriginal === 'DESCUENTO') {
      dispatch({ type: actions.MODIFICAR_DESCUENTO, payload: ordenDescuento });
      reiniciarDescuento();
    }
  };

  const onAdd = (plu) => {
    const newList = itemList.map((item) =>
      item.CodigoPlu === plu ? { ...item, Cantidad: item.Cantidad + 1 } : item
    );

    dispatch({ type: actions.LISTA_PRODUCTOS, payload: newList });
  };

  const onMinus = (plu) => {
    const newList = itemList.map((item) =>
      item.CodigoPlu === plu ? { ...item, Cantidad: item.Cantidad - 1 } : item
    );

    //newList.filter(item => item.Cantidad > 0);

    dispatch({ type: actions.LISTA_PRODUCTOS, payload: newList.filter(item => item.Cantidad > 0) });
  };

  //console.log(selectedProduct);
  return (
    <div className="ui fluid segment margin-BotTop">
      <div className="ui grid">
        <div className="one wide column">{selectedProduct.Cantidad}</div>
        <div className="seven wide column pitem-wb">
          {selectedProduct.DescripcionProducto ?? selectedProduct.DescripcionPantalla}
        </div>
        {/*<div className="three wide column txt-ae">
            {selectedProduct.Precio}
  </div>*/}
        <div className="two wide column txt-ae">
          {(selectedProduct.Precio * selectedProduct.Cantidad).toFixed(2)}
        </div>
        <div className="five wide column a-el">
          <div className="large ui icon buttons">
            <button
              className="ui black basic button"
              onClick={() => onDeleteItem(selectedProduct.idUnico, selectedProduct.CodigoPlu)}
            //onClick={() => onDeleteItem(selectedProduct.CodigoPlu)}
            >
              <i className="trash icon"></i>
            </button>
            {/*selectedProduct.CodigoPlu !== 'DESCUENTO' && (
                  <>
                    <button 
                      className="ui red basic button"
                      onClick={() => onMinus(selectedProduct.CodigoPlu)}
                    >
                        <i className="minus icon"></i>
                    </button>
                    <button 
                      className="ui green basic button"
                      onClick={() => onAdd(selectedProduct.CodigoPlu)}
                    >
                        <i className="plus icon"></i>
                    </button>
                    </>
                )*/}
          </div>
        </div>
      </div>
      {selectedProduct.opciones && (
        <div className="ui fluid accordion">
          <div className="title">
            <i className="dropdown icon"></i>
            Detalles
          </div>
          <div className="fluid content">
            <div className="ui relaxed divided fluid list">
              {selectedProduct.opciones.map((item, index) => (
                <div className="item" key={item.CodigoPlu + index}>
                  {item.Cantidad} | {item.Descripcion} | {item.Precio}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { PosSelectedMenuItem };