import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import './styles.css';
import AvalonLogo from '../../../img/swords.png';
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { eliminarSesionUsuario } from "../../utils/Common"

const AvalonNavMenu = () => {

  const [usuario, guardarUsuario] = useLocalStorage('LOGIN_BETA');

  const [user, setUser] = useState(usuario);

  let usuarioActualizado = '';

  window.addEventListener('cambioStorage', (e) => {
    e.stopPropagation();
    usuarioActualizado = JSON.parse(localStorage.getItem('LOGIN_BETA'));
    setUser(usuarioActualizado);
  })

  window.addEventListener('cerrarSesion', () => {
    logout();
  })

  const logout = () => {
    eliminarSesionUsuario();
    setUser(null)
  }

  let fechaTurno = '';
  let dia, mes, year = '';

  if (user !== null) {
    if (Object.keys(user).length > 0) {
      let fechaStorage = user.Turno.Fecha.split('T');
      fechaTurno = fechaStorage[0];
      let fechaDividida = fechaTurno.split('-');
      dia = fechaDividida[2];
      mes = fechaDividida[1];
      year = fechaDividida[0];
    }
  }



  return (
    <div className="ui stackable menu nav-avalon text-menu pcolor text-font">
      <div className="item">
        <img src={AvalonLogo} alt="logo" />
        <p className="p__ml">SAREST AVALON</p>
      </div>
      <div className="center menu">
        {
          (user === null || user === undefined || Object.keys(user).length === 0)
            ? null
            : (
              <>
                <div className="item">
                  <i className="calendar alternate icon"></i>
                  {dia}/{mes}/{year}
                  <span className="ui orange circular big label">T {user.Turno.CorrelativoTurno}</span>
                </div>
                {/* <div className="item">Turno {user.Turno.CorrelativoTurno}  
                
                {dia}/{mes}/{fecha.getFullYear()}
                </div> */}

              </>
            )
        }

        {/* <div className="item"><i class="calendar alternate icon"></i> {dia}/{mes}/{fecha.getFullYear()}</div>
        <div id="hora" name="hora" className="item"><i className="clock outline icon"></i> {new Date().toLocaleString([], { hour: '2-digit', minute: '2-digit' })} </div> */}
      </div>

      <div className="right menu">
        {
          (user === null || user === undefined || Object.keys(user).length === 0)
            ? null
            : (
              <>
                <div className="item">

                  {user.Colaborador.Nombres} {user.Colaborador.Apellidos}</div>
                <Link to="/menu" className="item">MENU</Link>
                <Link to="/inicio" onClick={logout} className="item">SALIR</Link>
              </>
            )
        }

      </div>
    </div>
  );
};

export { AvalonNavMenu };