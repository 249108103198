import React, { useEffect } from "react";
import { TabPrecio } from "../TabPrecio"
import { TabComplementos } from "../TabComplementos"
import { FormEditProducto } from "../FormEditProducto";
import { useContext } from "react";
import { ProductosContext } from "../ProductosContext";
import { TabMenus } from "../TabMenus";
import { TabGrafPrecios } from "../TabGrafPrecios"

const ModalEditarProducto = () => {
    useEffect(() => {
        window.$('.tabular.menu .item').tab();
    }, [])

    const {
        state,
        guardarProductoEditado,
        cerrarModalCrear,
    } = useContext(ProductosContext);

    return (
        <div className="ui modal md-disp">
            <div className="header">
                Editar producto - {state.formEditarProducto.CodigoProducto}
            </div>
            <div className="scrolling content color-contenido">
                <div className="ui top attached tabular menu">
                    <div className="active item" data-tab="first">Producto</div>
                    <div className="item" data-tab="second">Precios</div>
                    <div className="item" data-tab="third">Complementos</div>
                    <div className="item" data-tab="fourth">Menús</div>

                </div>

                <div className="ui bottom attached tab segment active tab-modal" data-tab="first">
                    <FormEditProducto />
                </div>

                <div className="ui bottom attached tab segment" data-tab="second">
                    <TabGrafPrecios />
                </div>
                <div className="ui bottom attached tab segment tab-modal" data-tab="third">
                    <h3 className="ui center aligned header">Editar complementos</h3>
                    <TabComplementos />
                </div>

                <div className="ui bottom attached tab segment tab-modal" data-tab="fourth">
                    <TabMenus />
                </div>


            </div>
            <div className="actions">
                <button onClick={cerrarModalCrear} className="ui negative icon labeled button">
                    <i className="close icon"></i>
                    Cancelar
                </button>
                <button onClick={guardarProductoEditado} className="ui positive icon labeled button">
                    <i className="save icon"></i>
                    Guardar
                </button>
            </div>
        </div>
    )
}

export { ModalEditarProducto }