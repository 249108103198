import React from 'react';
import { MenuProvider } from './MenuContext';
import { MenuUi } from './MenuUi';

import './style.css';


const MainMenu = () => {
  
  return (
    <MenuProvider>
      <MenuUi />
    </MenuProvider>
  );
};

export { MainMenu };