import React, {
  createContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { posServices } from "../../../api/services/posServices";

import { buscarMenuProductos, buscarNodoEnArbol } from "./Utilities";

import { useLocalStorage } from "../../../hooks/useLocalStorage";

import { anulacionServices } from "../../../api/services/anulacionServices";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { ordenContingencia } from '../../../constants/Orden';

//NOTA: nuevos imports para mejora de context
import { posReducer, INITIAL_STATE } from "./posReducer";
import { ACTION_TYPES } from "./posConstants";
import { getDataPos } from "./services";
import {
  getFechaHoy,
  getEstadoCaja,
  setEstadoCaja,
  getConteoContingencia,
  setConteoContingencia,
  getCajaFalloTS,
  setCajaFalloTS,
  setDatosLogin,
  serverAxios
} from "../../../utils/Common";

import { swalFire, SWAL_ACTION } from "../../../constants/sweetAlert";
import { customAxios } from '../../../api/customAxios';
import { URLS, ERROR } from '../../../constants';
import Swal from "sweetalert2";
import { el } from "date-fns/locale";
import { loginServices } from "../../../api/services/loginServices";
import { posBacServices } from "../../../api/services/posBacServices";

const PosContext = createContext();

const PosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(posReducer, INITIAL_STATE);
  const [usuario, setUsuario] = useLocalStorage("LOGIN_BETA");

  let navigate = useNavigate();
  let { CodigoOrden } = useParams();
  let location = useLocation();

  const getConfiguracionPos = async () => {
    try {
      dispatch({ type: ACTION_TYPES.FETCH_START });
      //OBSERVACION: datos obtenidos de la pantalla de
      let datosLoginUsuario = {
        codigoEmpresa: usuario.Tienda.CodigoEmpresa,
        codigoTienda: usuario.Tienda.CodigoTienda,
        codigoCaja: usuario.CorrelativoFiscal.CodigoCaja,
        codigoPuntoDeVenta: 1, //Lo devuelve el login
        codigoColaborador: usuario.Colaborador.CodigoColaborador, //Login
        cierreNumero: 1, //Login, correlativo interno de Zeta
        codigoTurno: 0, //Login
      };

      let otrosValores = {
        empleado: `${usuario.Colaborador.Nombres} ${usuario.Colaborador.Apellidos}`,
        fecha: getFechaHoy(),
        pin: usuario.Colaborador.PIN,
        nitEmpresa: usuario.Empresa.NIT,
        intentosFacturacion: usuario.CajaFiscal.IntentosFacturacion,
        estadoCaja: usuario.EstadoCaja,
        tiempoContingencia: usuario.CajaFiscal.TiempoContingencia,
      };

      let modalInicial = INITIAL_STATE.numberModal;

      //OBSERVACION: llamadas a api para funcionamiento de pantalla
      // [0]: true o false
      // [1]: respuesta del metodo
      // [2]: codigo del metodo, 0 es que todo salio bien,
      const responseServices = await getDataPos(
        datosLoginUsuario.codigoEmpresa,
        datosLoginUsuario.codigoTienda,
        datosLoginUsuario.codigoCaja
      );
      //console.log(responseServices);
      let data = null;
      if (
        !!responseServices[0] &&
        (responseServices[2] === 0 ||
          responseServices[2] === -1 ||
          responseServices[2] === 1)
      ) {
        //NOTA: todo salio bien y todos los servicios respondieron
        data = responseServices[1];
      } else if (!responseServices[0] && responseServices[2] === 2) {
        //NOTA: falla en la api o en algun servicio en especifico
        dispatch({
          type: ACTION_TYPES.FETCH_START_ERROR,
          payload: responseServices[1],
        });
        return;
      } else {
        //NOTA: algun error no controlado
        dispatch({
          type: ACTION_TYPES.FETCH_START_ERROR,
          payload: responseServices[1],
        });
        return;
      }

      //OBSERVACION: verificamos url para ver si se obtiene una orden
      let ordenLlamarGad = null;
      let itemsIniciales = [];
      let nitInicial = "";
      let formaDePagoActual = INITIAL_STATE.orderOptions.formaDePago;
      if (location.state !== null && CodigoOrden) {
        //NOTA: como se cumple la condicion, entonces recibimos la orden
        ordenLlamarGad = location.state;

        //NOTA: Al obtener todos los datos necesarios ahora configuramos estados iniciales
        formaDePagoActual = ordenLlamarGad.Orden.FormaDePago; //orderOptions
        nitInicial = ordenLlamarGad.Orden.NIT; //datosFiscales
        itemsIniciales = ordenLlamarGad.OrdenDetalle; //orderItems
      }

      //OBSERVACION: preparamos el estado para la pantalla
      let estadoCargaInicial = INITIAL_STATE;

      const valorCF = responseServices[1].valorMaximoCF[0].Valor;
      const maximoCF = parseFloat(valorCF);
      console.log(usuario.CorrelativoFiscal.CorrelativoActual)
      estadoCargaInicial = {
        ...INITIAL_STATE,
        response: {
          valoresLogin: datosLoginUsuario,
          otrosValores: otrosValores,
          orden: ordenLlamarGad,
          ...data,
        },
        numberModal: 5,
        orderOptions: {
          ...INITIAL_STATE.orderOptions,
          formaDePago: formaDePagoActual,
          codigoEmpresa: usuario.Tienda.CodigoEmpresa,
          codigoTienda: usuario.Tienda.CodigoTienda,
          codigoCaja: usuario.CorrelativoFiscal.CodigoCaja,
          codigoPuntoDeVenta: 1, //Lo devuelve el login
          codigoColaborador: usuario.Colaborador.CodigoColaborador, //Login
        },
        orderItems: itemsIniciales,
        datosFiscales: {
          ...INITIAL_STATE.datosFiscales,
          nit: nitInicial,
        },
        correlativo: usuario.CorrelativoFiscal.CorrelativoActual,
        //correlativo: usuario.CorrelativoFiscal.CorrelativoActual ?? 990,
        valorMaximoCF: maximoCF,
      };
      console.log(estadoCargaInicial.correlativo);

      dispatch({
        type: ACTION_TYPES.FETCH_START_SUCCESS,
        payload: estadoCargaInicial,
      });

      if (!!responseServices[0] && responseServices[2] === 1) {
        //NOTA: se debe abrir turno nuevo
        swalFire(SWAL_ACTION.ABRIR_TURNO(responseServices[3]))
          .then((result) => {
            if (!!result.isConfirmed) {
              dispatch({ type: ACTION_TYPES.NUMERO_MODAL, payload: 18 });
            }
          })
      } else if (!!responseServices[0] && responseServices[2] === -1) {
        //NOTA:  se deben cerrar operaciones anteriores
        swalFire(SWAL_ACTION.CERRAR_OPERACIONES(responseServices[3]))
          .then((result) => {
            if (!!result.isConfirmed) {
              navigate("/menu");
            }
          });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: ACTION_TYPES.FETCH_START_ERROR,
        payload: error.message,
      });
    }
  };

  useEffect(() => {
    (async () => {
      console.log("carga pos");
      await getConfiguracionPos();
    })();
  }, []);

  //este estado maneja el correlativo
  /*useEffect(() => {
    return () => {
      console.log('detruir pos')
      let correlativoGuardar = 0;
      if (state.correlativo === null) {
        correlativoGuardar = usuario.CorrelativoFiscal.CorrelativoActual;
      }
      else {
        correlativoGuardar = state.correlativo;
      }
      //let correlativoGuardar = state.correlativo;
      console.log(state.correlativo)
      console.log(correlativoGuardar);
      let infoTurno = JSON.parse(localStorage.getItem("LOGIN_BETA"));
      //console.log(correlativoGuardar);
      let nuevaDataLogin = {
        ...usuario,
        CorrelativoFiscal: {
          ...usuario.CorrelativoFiscal,
          CorrelativoActual: correlativoGuardar,
        },
        Turno: {
          ...infoTurno.Turno,
        },
      };
      console.log(nuevaDataLogin);
      setUsuario(nuevaDataLogin);
    };
  }, [state.correlativo]);*/

  //REVISAR:
  const onCreateOrder = async (pagaCon = 0, datosCredito = null, nitCf = false) => {
    //onOpenModal(8);
    dispatch({ type: ACTION_TYPES.FETCH_DATA });
    ////console.log(order);
    let listaPlus = [];
    let contador = 1;
    state.orderItems.forEach((item) => {
      item.Secuencia = contador;
      contador++;
      listaPlus.push(item);
      if (item.Complementos) {
        item.Complementos.forEach((comp) => {
          comp.Secuencia = contador;
          contador++;
          listaPlus.push(comp);
        });
      }
    });

    /*console.log(state.datosFiscales);

    if (state.datosFiscales.tipoIdCliente === 'Nit') {
      const respuestaNit = await posServices.pos.validarNit(state.datosFiscales.nit);
      console.log(respuestaNit);
      if (respuestaNit.data.SUCCESS === false) {
        Swal.fire({
          text: 'Debe ingresar nit otra vez',
        })
        //dispatch({ type: ACTION_TYPES.CERRAR_MODAL });
        dispatch({ type: ACTION_TYPES.ABRIR_MODAL, payload: 5 });
        return;
      }
    }*/

    // SI LA FORMA DE PAGO ES POS INTEGRADO, HACEMOS EL COBRO ANTES DE ARMAR LA ORDEN Y FISCALIZARLA
    if(state.orderOptions.formaDePago.toUpperCase() === "CREDITO"){
      const posIntegradoObj = {
        parameters: `transactionType:${"SALE"};terminalId:${"RVIRTUAL"};invoice:${state.correlativo};accountNumber:;expirationDate:;avsEntry:;address:;postalCode:;otpCode:;otpPinCode:;totalAmount:${0.10};taxAmount:${0};tipAmount:${0};keepCardDevice:OFF`
      };
      const responsePosIntegrado = await posBacServices.bac.venta('http://localhost:8000/CSPIntegrationServices/Interop/rest/runTransaction', posIntegradoObj);
  
      if(responsePosIntegrado.status !== 200) {
        // MENSAJE DE ERROR YA QUE NO SE PUDO COMPLETAR EL PAGO
        swalFire(SWAL_ACTION.ERROR_POS_INTEGRADO("ERROR DE COMUNICACIÓN CON POS INTEGRADO"));
        dispatch({ type: ACTION_TYPES.CERRAR_MODAL });
        return;
      }
  
      const objPosIntegrado = JSON.parse(responsePosIntegrado.data.runTransactionResult);
      console.info(objPosIntegrado);
  
      if(objPosIntegrado.responseCode === "00" || objPosIntegrado.responseCodeDescription === '') {
        datosCredito = {
          ...datosCredito,
          CodigoOrden: state.orderOptions,
          NumeroTarjeta: objPosIntegrado.maskedCardNumber,
          Referencia: objPosIntegrado.referenceNumber,
          Autorizacion: objPosIntegrado.authorizationNumber,
          Estado: 'ALTA'
        }
      } else {
        // MENSAJE DE ERROR, HUBO ALGÚN ERROR AL REALIZAR EL COBRO
        swalFire(SWAL_ACTION.ERROR_POS_INTEGRADO(objPosIntegrado.responseCodeDescription));
        dispatch({ type: ACTION_TYPES.CERRAR_MODAL });
        return;
      }
    }


    const ordenInsertadaFiscalizada = await armarOrdenFiscalizar(listaPlus, pagaCon, datosCredito, nitCf);
    //console.log(ordenInsertadaFiscalizada);

    let cambio = 0;
    if (state.orderOptions.formaDePago.toUpperCase() === "EFECTIVO") {
      cambio = (pagaCon - (state.orderOptions.totalFisico)).toFixed(2);
    } else if (state.orderOptions.formaDePago.toUpperCase() === "TC" ||
    state.orderOptions.formaDePago.toUpperCase() === "CREDITO") {
      cambio = "0.00";
    }

    if (ordenInsertadaFiscalizada[3] === 0) {
      //TODO SALIO BIEN EN EL FLUJO
      dispatch({ action: ACTION_TYPES.MODIFICAR_ORDEN, payload: ordenInsertadaFiscalizada[2] });
      swalFire(SWAL_ACTION.INSERTAR_Y_FISCALIZAR(ordenInsertadaFiscalizada[2].codigoOrden, cambio));
    }/* else if (!!ordenInsertadaFiscalizada[0] && !ordenInsertadaFiscalizada[1] && ordenInsertadaFiscalizada[3] === 2) {
      //REVISAR: solo insertada, error en el nit 
      //console.log('nop 3');
      Swal.fire({
        position: "top-end",
        icon: "error",
        html: ordenInsertadaFiscalizada[2],
        showConfirmButton: false,
        timer: 600000,
      });
    } */
    else if (ordenInsertadaFiscalizada[3] === 1) {
      //SE INSERTO BIEN PERO EL NIT ESTA MALO
      swalFire(SWAL_ACTION.ERROR_NIT(ordenInsertadaFiscalizada[2]))
        .then((result) => {
          if (result.isConfirmed) {
            onCreateOrder(pagaCon, null, true);
          } else {
            dispatch({ action: ACTION_TYPES.NUMERO_MODAL, payload: 5 });
            dispatch({ action: ACTION_TYPES.FETCH_DATA_SUCCESS });
          }
        })
      return;
    } else if (ordenInsertadaFiscalizada[3] === 3) {
      //ERROR AL FISCALIZAR Y SE INTENTA REVERTIR LA ORDEN
      swalFire(SWAL_ACTION.ERROR_ANUL_ORDEN_FISC(ordenInsertadaFiscalizada[2]));
    } else if (ordenInsertadaFiscalizada[3] === -3) {
      // ERROR AL TRATAR DE INSERTAR LA ORDEN
      // AQUI DEBERÍA REVERTIRSE EL PAGO SI ES CON POS INTEGRADO
      dispatch({ action: ACTION_TYPES.MODIFICAR_ORDEN, payload: ordenInsertadaFiscalizada[2] });
      swalFire(SWAL_ACTION.ERROR_INSERTAR_ORDEN);
      navigate("/pos");
    } else if (ordenInsertadaFiscalizada[3] === -2) {
      //ERROR DESCONOCIDO AL FISCALIZAR LA ORDEN
      swalFire(SWAL_ACTION.ERROR_DESC_FISC(ordenInsertadaFiscalizada[2]));
    }
    limpiarPos();
  };
  //REVISAR:
  // Se van a seguir asignando contingencias desde el frontEnd?
  // Es probable que no
  const asignarContingencia = (orden = null) => {
    try {
      // obtenemos las contingencias del LS
      let contingencias = usuario.Contingencias;

      if (contingencias.length <= 0 && state.contingencia === '') {
        return [false, null];
      }

      let contingenciaActual = contingencias[0];
      let fecha = new Date();

      //console.log('dentro contigencia');

      let ordenFactura = {
        ...ordenContingencia,
        DocumentoNumero: `${state.response.valoresLogin.codigoEmpresa}-${state.response.valoresLogin.codigoTienda}-${state.response.valoresLogin.codigoCaja}-${state.correlativo}`,
        FolioFiscal: contingenciaActual.NumeroAcceso,
        SerieFiscal: contingenciaActual.Lote + "-" + state.correlativo,
        Autorizacion: contingenciaActual.NumeroAcceso,
        TiempoIngreso: fecha,
        TiempoActualizacion: fecha,
        Nit: state.datosFiscales.nit,
      };

      let actualizarContingenciaUsuario = usuario;
      actualizarContingenciaUsuario = {
        ...actualizarContingenciaUsuario,
        Contingencias: contingencias.filter(item => item.NumeroAcceso !== contingenciaActual.NumeroAcceso),
      }

      setDatosLogin(actualizarContingenciaUsuario);

      let ordenConContingencia = {
        ...orden,
        ordenFactura: ordenFactura,
      }

      return [true, ordenConContingencia];
    } catch (error) {
      console.error(error);
      return [false, null];
    }
  };
  //REVISAR:
  const insertarOrden = async (orden = null, origen = false) => {
    //console.log('Orden insertar: ', orden);
    const usuarioLocal = JSON.parse(localStorage.getItem('LOGIN_BETA'));
    //const turnoLocalStorage = usuarioLocal.Turno;
    try {
      // Se va a intentar insertar las órdenes en server central
      const nuevoAxios = serverAxios('central');
      //const nuevoAxios = serverAxios('local');

      // Ordenes realizadas en este turno, almacenadas en localStorage
      let ordenesLS = JSON.parse(localStorage.getItem('ORDENES'));

      //#region Ordenes realizadas sin conexión
      // Antes de insertar la orden en central, es necesario verificar cuántas órdenes se hicieron mientras
      // no había conexión. Es importante indicarle al backend de esas órdenes, para que tome en cuenta las
      // órdenes que debe actualizar

      /*
      // Se extiende la propiedad multiIndexOf para los arrays. Esta propiedad sirve para encontrar todos los
      // índices de las órdenes que se encuentran, en este caso, en determinado server
      Array.prototype.multiIndexOf = function (server) {
        let indxs = [];
        for (let i = this.length - 1; i >= 0; i--) {
          if (this[i].server === server) {
            indxs.unshift(i);
          }
        }
        return indxs;
      }

      // Solo realizar la revisión si ya existen órdenes en el turno
      if (ordenesLS.length > 0) {
        // Array de índices en los que hay órdenes insertadas en el server central
        let indexes = ordenesLS.multiIndexOf('Central');
        // Indice de la última orden que se hizo en el server central
        let indexOrdenFinalCentral = indexes[indexes.length - 1];
        // Indice de la última orden realizada
        let indexOrdenFinal = ordenesLS.length - 1;
        // Variable que indica cuántas órdenes se insertaron en el server local antes de la que se está insertando
        // actualmente
        let espacioOrdenesLocales = indexOrdenFinal - indexOrdenFinalCentral;

        /* EXPLICACION:
        ** Supongamos que localStorage se mira así: ['central', 'central, 'local', 'local']
        ** La propiedad multiIndexOf devuelve el array con los índices [0, 1] para server central
        ** La longitud del array es 4, se le resta 1 para obtener el último índice: 3
        ** Resta: 3-1 = 2. 2 espacios que se deben reservar para las órdenes que se realizaron en el local.
        ** Conicide con las órdenes con 'local' en localStorage.
        ** Ahora, se logra insertar esta orden en central. lS: ['central', 'central, 'local', 'local', 'central']
        ** Mismo procedimiento. Array de índices: [0, 1, 4]. Longitud - 1: 4
        ** Resta = 0. No nuevas órdenes realizadas en server local, no se necesitan "reservar" espacios
        

        // Nueva propiedad de la orden que se utiliza en el backend para "reservar" espacios para las órdenes
        // que están en BD local
        orden.MientrasDesconexion = espacioOrdenesLocales;
        //#endregion
      }*/

      // Se intenta insertar la orden en server central
      //console.log(orden);
      const ordenInsertar = {
        ...orden,
        Orden: {
          ...orden.Orden,
          TurnoReferencia: orden.Orden.codigoTurno,
        },
        CorrelativoTurno: usuarioLocal.Turno.CorrelativoTurno,
        MontoInicio: usuarioLocal.Turno.MontoInicio,
      }

      console.log(ordenInsertar);
      //const responseOrden = await posServices.pos.insertNewOrder(orden, nuevoAxios);
      const responseOrden = await posServices.pos.insertNewOrder(ordenInsertar, nuevoAxios);

      // Si la orden fue insertada correctamente
      if (responseOrden.data.SUCCESS === true) {
        // Se crea un nuevo objeto de orden para guardar en localStorage
        // Se agrega el código de orden que devuelve el backend, y la propiedad server: 'Central'
        const nuevaOrdenLS = {
          ...orden,
          Orden: {
            ...orden.Orden,
            codigoOrden: responseOrden.data.MESSAGE.Orden,
          },
          server: 'Central',
        };

        // Se agrega la orden al localStorage
        ordenesLS.push(nuevaOrdenLS);
        localStorage.setItem('ORDENES', JSON.stringify(ordenesLS));

        // Ya no se usa el conteoContingencia
        //if (!origen) setConteoContingencia(0);

        // Se retorna true y la respuesta del servicio
        return [true, responseOrden];
      }

      // Si no hubo éxito al intentar insertar, pero no fue por un problema de conexión, se retorna el error
      return [false, responseOrden.data.MESSAGE];

    } catch (error) {
      console.error('ERROR EN CENTRAL: ', error);
      // Solo se debe realizar el trycatch si el error es de conexión. Si es por otro tipo de error, se debe
      // levantar el error y mostrar al usuario.
      try {
        // Insertar orden en BD local
        const nuevoAxios = serverAxios('local');
        // Al no poder conectarse con el server central, se debe insertar la orden la BD local
        // Se debe colocar en estado PEND esa orden para poder sincronizarla después en el server central
        // Las órdenes que van a la BD local necesitan dos cosas: estado = PEND y el DocumentoNumero.
        // A diferencia del código de orden, el DocumentoNumero no se puede repetir y debe ser único para
        // todas las órdenes del restaurante, sin importar el server. Para mantener el orden, se debe enviar
        // este valor, el correlativo actual.

        // Cuidado con el correlativo actual, fuente de problemas

        // En esta orden hay que modificar el envio del turno, agregando la propiedad TurnoReferencia
        // para que el BE sepa que se necesita crear el nuevo turno
        // O, en su defecto, aceptar todas las órdenes que vengan con ese turno, indicando que se ha creado
        // uno nuevo solo en local. 
        const ordenLocal = {
          ...orden,
          Orden: {
            ...orden.Orden,
            EstadoSincronizacion: 'PEND',
            DocumentoNumero: state.correlativo,
            TurnoReferencia: orden.Orden.codigoTurno,
          },
          CorrelativoTurno: usuarioLocal.Turno.CorrelativoTurno,
          MontoInicio: usuarioLocal.Turno.MontoInicio,
        }
        console.log(ordenLocal);
        // Insertar orden en servidor local
        const responseOrden = await posServices.pos.insertNewOrder(ordenLocal, nuevoAxios);
        console.log(responseOrden);
        // El manejo de la respuesta es igual porque los servers son iguales, misma respuesta
        if (responseOrden.data.SUCCESS === true) {
          // Si la orden fue insertada correctamente, se puede agregar al localStorage
          const nuevaOrdenLS = {
            ...orden,
            Orden: {
              ...orden.Orden,
              codigoOrden: responseOrden.data.MESSAGE.Orden,
            },
            server: 'Local',
          };

          let ordenesLS = JSON.parse(localStorage.getItem('ORDENES'));
          ordenesLS.push(nuevaOrdenLS);
          localStorage.setItem('ORDENES', JSON.stringify(ordenesLS));

          //if (!origen) setConteoContingencia(0);
          console.log('regresando');
          return [true, responseOrden];
        }

        return [false, responseOrden.data.MESSAGE];

      } catch (error) {
        // Catch del consumo de servicio local
        console.error('Fallo al insertar orden localmente: ', error);
        // Si esto falla, solo se puede retornar un error al usuario indicando que no se pudo insertar la orden
        return [false, 'No se pudo insertar la orden'];
      }
      /*console.log(error);

      let auxFallo = getConteoContingencia() + 1;
      setConteoContingencia(auxFallo);

      if (auxFallo >= state.response.otrosValores.intentosFacturacion) {
        setEstadoCaja('CONT');
        setCajaFalloTS(Date.now());
      }

      return [false, error.message];*/
    }
  };
  //REVISAR:
  const fiscalizarOrden = async (orden = null, origen = false, ordenCompleta = null) => {
    // Para fiscalizar la orden, existen varios casos
    // Antes de "intentar" fiscalizar como tal, debe de validarse que la orden exista en el servidor
    // en el que se está intentando fiscalizar.

    // Esto se debe a que es posible que se recupere o pierda la conexión entre la inserción de la orden
    // y su fiscalización/anulación

    // Caso 1:
    // Todo se hace con conexión. Se aplica el flujo normal

    // Caso 2:
    // Todo se hace sin conexión. Se va al catch de la función para usar el flujo normal pero en local

    // Caso 3:
    // La orden se hizo sin conexión, pero se recupera la conexión para la fiscalización
    // Servicio debe ir a buscar la orden en server central. No la va a encontrar, entonces se require
    // de una respuesta "especial" que indique que la debe ir a buscar al local
    // Fiscaliza localmente y listo
    // ICARO debe sincronizar la orden cuando le toque

    // Caso 4:
    // La orden se hizo con conexión, pero se pierde la conexión al momento de fiscalizar
    // Se va al catch de la función
    // Se busca la orden en el localStorage. Esta orden debería tener la propiedad Server en 'Central'
    // Se inserta la orden en la BD local. Se fiscaliza localmente
    // Todo listo

    try {
      console.log('Fiscalizar central');
      // Si la orden que se acaba de insertar, se insertó localmente
      // es mejor correr el flujo de fiscalizar en local de una, sin intentar fiscalizar en central
      const ordenesLS = JSON.parse(localStorage.getItem('ORDENES'));
      console.log(ordenesLS);
      let ordenEnLs = ordenesLS.filter(ordenLs => ordenLs.Orden.codigoOrden === orden.codigoOrden)[0];
      console.log(ordenEnLs);
      //const indexOrdenLs = ordenesLS.findIndex(ordenI => ordenI.Orden.codigoOrden === orden.codigoOrden);

      if (ordenEnLs.server === 'Local') {
        // Fiscalizar de forma local la orden
        console.log('Orden local, fiscalizar local');
        const nuevoAxiosLocal = serverAxios('local');
        // Al fiscalizar la orden de forma local, es necesario enviar el correlativo para que 
        // esté todo actualizado. 
        orden.DocumentoNumero = state.correlativo;
        const responseFiscalizar = await posServices.pos.fiscalizarOrden(orden, nuevoAxiosLocal);
        console.log(responseFiscalizar);
        // Control de la respuesta de la fiscalización en local
        if (responseFiscalizar.data.CODE === 1000) {
          //COMO LA ORDEN SE FISCALIZO ENTONCES SUMAMOS CORRELATIVO
          dispatch({ type: ACTION_TYPES.CORRELATIVO, payload: state.correlativo + 1 })
          // El correlativo aumentado en este caso fue el local

          //let correlativoGuardar = state.correlativo;
          console.log(state.correlativo)
          //console.log(correlativoGuardar);
          const correlativoGuardar = state.correlativo + 1;
          let infoTurno = JSON.parse(localStorage.getItem("LOGIN_BETA"));
          //console.log(correlativoGuardar);
          let nuevaDataLogin = {
            ...usuario,
            CorrelativoFiscal: {
              ...usuario.CorrelativoFiscal,
              CorrelativoActual: correlativoGuardar,
            },
            Turno: {
              ...infoTurno.Turno,
            },
          };
          console.log(nuevaDataLogin);
          setUsuario(nuevaDataLogin);
          // ACTUALIZAR CORRELATIVO EN LS
          if (!origen) setConteoContingencia(0);
          return [true, responseFiscalizar, 0];
        } else if (responseFiscalizar.data.CODE === 2003) {
          return [true, responseFiscalizar, 1]
        }

        // La orden se anuló porque no se pudo fiscalizar
        //OBSERVACION: COMO LA ORDEN NO PUDO SER FISCALIZADA, ENTONCES LA ANULAMOS
        const resAnularOrden = await posServices.pos.anularOrden(orden.codigoOrden, 0, '', nuevoAxios);
        if (!!resAnularOrden.data.SUCCESS) {
          return [false, responseFiscalizar, 2];
        } else {
          return [false, responseFiscalizar, -1];
        }
      }

      //#region Ordenes realizadas sin conexión
      // Antes de insertar la orden en central, es necesario verificar cuántas órdenes se hicieron mientras
      // no había conexión. Es importante indicarle al backend de esas órdenes, para que tome en cuenta las
      // órdenes que debe actualizar

      // Se extiende la propiedad multiIndexOf para los arrays. Esta propiedad sirve para encontrar todos los
      // índices de las órdenes que se encuentran, en este caso, en determinado server
      Array.prototype.multiIndexOf = function (server) {
        let indxs = [];
        for (let i = this.length - 1; i >= 0; i--) {
          if (this[i].server === server) {
            indxs.unshift(i);
          }
        }
        return indxs;
      }

      let ordenesSinNueva = [...ordenesLS];
      //console.log(ordenesSinNueva);
      //ordenesSinNueva = ordenesSinNueva.pop();
      ordenesSinNueva.pop();

      // Solo realizar la revisión si ya existen órdenes en el turno
      console.log(ordenesSinNueva);
      if (ordenesSinNueva.length > 0) {
        // Array de índices en los que hay órdenes insertadas en el server central
        //let indexes = ordenesLS.multiIndexOf('Central');
        let indexes = ordenesSinNueva.multiIndexOf('Central');
        console.log(indexes);

        // Si no se han hecho órdenes en el central, solo local, hay que hacer que MientrasDesconexion sea
        // todas las ordenes hechas en local
        if (indexes.length === 0) {
          let ordenesLocales = ordenesSinNueva.filter(ord => ord.server === 'Local');
          console.log(ordenesLocales);

          orden.mientrasDesconexion = ordenesLocales.length;
          orden.correlativoLocalActual = state.correlativo;
          console.log(ordenesLocales.length);
          console.log(state.correlativo);
        }
        else {
          // Indice de la última orden que se hizo en el server central
          let indexOrdenFinalCentral = indexes[indexes.length - 1];
          console.log(indexOrdenFinalCentral);
          // Indice de la última orden realizada
          //let indexOrdenFinal = ordenesLS.length - 1;
          let indexOrdenFinal = ordenesSinNueva.length - 1;
          console.log(indexOrdenFinal);
          // Variable que indica cuántas órdenes se insertaron en el server local antes de la que se está insertando
          // actualmente
          let espacioOrdenesLocales = indexOrdenFinal - indexOrdenFinalCentral;

          // EXPLICACION:
          // Supongamos que localStorage se mira así: ['central', 'central, 'local', 'local']
          // La propiedad multiIndexOf devuelve el array con los índices [0, 1] para server central
          // La longitud del array es 4, se le resta 1 para obtener el último índice: 3
          // Resta: 3-1 = 2. 2 espacios que se deben reservar para las órdenes que se realizaron en el local.
          // Conicide con las órdenes con 'local' en localStorage.
          // Ahora, se logra insertar esta orden en central. lS: ['central', 'central, 'local', 'local', 'central']
          // Mismo procedimiento. Array de índices: [0, 1, 4]. Longitud - 1: 4
          // Resta = 0. No nuevas órdenes realizadas en server local, no se necesitan "reservar" espacios


          // Nueva propiedad de la orden que se utiliza en el backend para "reservar" espacios para las órdenes
          // que están en BD local
          console.log(espacioOrdenesLocales);
          console.log(state.correlativo);
          orden.mientrasDesconexion = espacioOrdenesLocales;
          orden.correlativoLocalActual = state.correlativo;
        }
        //#endregion
      }

      const nuevoAxios = serverAxios('central');
      //const nuevoAxios = serverAxios('local');

      console.log(orden);
      const responseFiscalizar = await posServices.pos.fiscalizarOrden(orden, nuevoAxios);

      // El servicio fiscalizarOrden debe regresar un código especial en caso que no encuentre la orden en
      // el server central, el 3000 por ejemplo
      // Tiene que buscar la referencia, no el código. Porque el código probablemente ya exista

      //let responseFiscalizarLocal = '';

      if (responseFiscalizar.data.CODE === 1000) {
        //COMO LA ORDEN SE FISCALIZO ENTONCES SUMAMOS CORRELATIVO
        dispatch({ type: ACTION_TYPES.CORRELATIVO, payload: state.correlativo + 1 })
        // Se fiscalizó en central, hay que actualizar el correlativo local
        console.log(state.correlativo)
        //console.log(correlativoGuardar);
        const correlativoGuardar = state.correlativo + 1;

        // Actualizar correlativo local para estar siempre lo más actualizado posible en caso de problemas.
        // Revisar después de presentación
        /*const axiosCorrelativoLocal = serverAxios('local');
        loginServices.login.actualizarCorrelativo(usuario.Tienda.CodigoEmpresa, usuario.Tienda.CodigoTienda,
          usuario.CorrelativoFiscal.CodigoCaja, correlativoGuardar, axiosCorrelativoLocal)
          .then(result => {
            console.log(result.data)
          })*/

        let infoTurno = JSON.parse(localStorage.getItem("LOGIN_BETA"));
        console.log(correlativoGuardar);
        let nuevaDataLogin = {
          ...usuario,
          CorrelativoFiscal: {
            ...usuario.CorrelativoFiscal,
            CorrelativoActual: correlativoGuardar,
          },
          Turno: {
            ...infoTurno.Turno,
          },
        };
        console.log(nuevaDataLogin);
        setUsuario(nuevaDataLogin);
        // ACTUALIZAR CORRELATIVO EN LS
        if (!origen) setConteoContingencia(0);
        return [true, responseFiscalizar, 0];
      } else if (responseFiscalizar.data.CODE === 2003) {
        return [true, responseFiscalizar, 1]
      }
      // Esto deja de existir por el momento
      // Porque ya no se valida aquí que la orden no esté en el central
      /*else if (responseFiscalizar.data.CODE === 3000) {
        // Este código indica que la referencia de la orden, no se encontró en el server central
        // Se tiene que intentar la fiscalización en el server local
        const axiosLocal = serverAxios('local');
        const responseFiscalizarLocal = await posServices.pos.fiscalizarOrden(orden, axiosLocal);

        if (responseFiscalizarLocal.data.CODE === 1000) {
          dispatch({ type: ACTION_TYPES.CORRELATIVO, payload: state.correlativo + 1 })
          if (!origen) setConteoContingencia(0);
          return [true, responseFiscalizarLocal, 0];
        }
        else if (responseFiscalizarLocal.data.CODE === 2003) {
          return [true, responseFiscalizarLocal, 1]
        }
        // Este else evita que se intente anular la orden en el server central en caso que no se pueda
        // fiscalizar
        else {
          const resAnularOrden = await posServices.pos.anularOrden(orden.codigoOrden, 0, '', axiosLocal);
          if (!!resAnularOrden.data.SUCCESS) {
            return [false, responseFiscalizar, 2];
          } else {
            return [false, responseFiscalizar, -1];
          }
        }
      }*/

      //OBSERVACION: COMO LA ORDEN NO PUDO SER FISCALIZADA, ENTONCES LA ANULAMOS
      const resAnularOrden = await posServices.pos.anularOrden(orden.codigoOrden, 0, '', nuevoAxios);
      if (!!resAnularOrden.data.SUCCESS) {
        return [false, responseFiscalizar, 2];
      } else {
        return [false, responseFiscalizar, -1];
      }
    } catch (error) {

      try {
        // Se va a intentar consumir los servicios del server local
        const nuevoAxios = serverAxios('local');

        // Primero, se debe buscar la orden en el localStorage para saber en qué server fue insertada
        // Array con las ordenes almacenadas en localStorage
        const ordenesLS = JSON.parse(localStorage.getItem('ORDENES'));
        let ordenEnLs = ordenesLS.filter(ordenLs => ordenLs.Orden.codigoOrden === orden.codigoOrden)[0];
        const indexOrdenLs = ordenesLS.findIndex(ordenI => ordenI.Orden.codigoOrden === orden.codigoOrden);

        // Si la orden se insertó en el server central, se debe insertar la orden en la BD local para poder
        // fiscalizarla localmente
        if (ordenEnLs.server === 'Central') {
          // Insertar orden en server local
          // La orden tiene que ir con una etapa que indique que ya está insertada en el central,
          // para que ICARO no la trate de sincronizar cuando pase

          //ordenEnLs.Orden.CodigoEtapa = 'SNYC';

          // Enviar la orden pura no debería de dar problema
          //const responseOrden = await posServices.pos.insertNewOrder(ordenEnLs, nuevoAxios);
          const ordenInsertarLocal = {
            ...ordenCompleta,
            Orden: {
              ...ordenCompleta.Orden,
              OrdenReferencia: ordenEnLs.Orden.codigoOrden,
            }
          }
          const responseOrden = await posServices.pos.insertNewOrder(ordenInsertarLocal, nuevoAxios);
          console.log(responseOrden);
          // No es necesario almacenar la orden otra vez en el localStorage porque ya está ahí
          // Si no se pudo insertar, no se continua con la operación
          if (responseOrden.data.SUCCESS === false) {
            console.log(responseOrden.data.MESSAGE);
            return;
          }

          // Hay que cambiar el código de orden para que intente fiscalizar la orden local
          // respuestaInsertar es un objeto que únicamente trae el código de Orden como propiedad Orden
          const respuestaInsertar = responseOrden.data.MESSAGE;
          console.log(respuestaInsertar);
          //ordenEnLs.codigoOrden = respuestaInsertar.Orden;
          orden.codigoOrden = respuestaInsertar.Orden;

          // Cambiar el estado del server a local para que no existan problemas con el MientrasDesconexion
          // Actualizar localStorage
          ordenesLS[indexOrdenLs] = {
            ...ordenEnLs,
            Orden: {
              ...ordenEnLs.Orden,
              OrdenReferencia: respuestaInsertar.Orden,
            },
            server: 'Local',
          }
          console.log(ordenesLS[indexOrdenLs]);
          localStorage.setItem('ORDENES', JSON.stringify(ordenesLS));
        }
        //console.log('Orden Local, fiscalizar localmente');
        // Si está en el local, se puede proceder a fiscalizar sin problema
        //console.log(ordenEnLs.Orden);
        // Enviar orden, no la orden del localStorage. Esto porque son distintas y el backend está preparado
        // para recibir orden, no ordenLS
        //const responseFiscalizar = await posServices.pos.fiscalizarOrden(ordenEnLs.Orden, nuevoAxios);

        // Se trata de fiscalizar la orden localmente con el nuevo codigoOrden que regresó el backend local
        console.log(orden);
        orden.DocumentoNumero = state.correlativo;
        const responseFiscalizar = await posServices.pos.fiscalizarOrden(orden, nuevoAxios);
        console.log(responseFiscalizar);
        // Control de la respuesta de la fiscalización en local
        if (responseFiscalizar.data.CODE === 1000) {
          //COMO LA ORDEN SE FISCALIZO ENTONCES SUMAMOS CORRELATIVO
          // Se inserta local, no hay problemas
          dispatch({ type: ACTION_TYPES.CORRELATIVO, payload: state.correlativo + 1 })
          console.log(state.correlativo)
          //console.log(correlativoGuardar);
          const correlativoGuardar = state.correlativo + 1;
          let infoTurno = JSON.parse(localStorage.getItem("LOGIN_BETA"));
          //console.log(correlativoGuardar);
          let nuevaDataLogin = {
            ...usuario,
            CorrelativoFiscal: {
              ...usuario.CorrelativoFiscal,
              CorrelativoActual: correlativoGuardar,
            },
            Turno: {
              ...infoTurno.Turno,
            },
          };
          console.log(nuevaDataLogin);
          setUsuario(nuevaDataLogin);
          // ACTUALIZAR CORRELATIVO EN LS
          if (!origen) setConteoContingencia(0);
          return [true, responseFiscalizar, 0];
        } else if (responseFiscalizar.data.CODE === 2003) {
          return [true, responseFiscalizar, 1]
        }

        // La orden se anuló porque no se pudo fiscalizar
        //OBSERVACION: COMO LA ORDEN NO PUDO SER FISCALIZADA, ENTONCES LA ANULAMOS
        const resAnularOrden = await posServices.pos.anularOrden(orden.codigoOrden, 0, '', nuevoAxios);
        if (!!resAnularOrden.data.SUCCESS) {
          return [false, responseFiscalizar, 2];
        } else {
          return [false, responseFiscalizar, -1];
        }
      } catch (error) {
        // Problema con server local, no se puede hacer nada
        console.error(error);
      }
      /*console.error(error);

      let auxFallo = getConteoContingencia() + 1;
      setConteoContingencia(auxFallo);

      if (auxFallo >= state.response.otrosValores.intentosFacturacion) {
        setEstadoCaja('CONT');
        setCajaFalloTS(Date.now());
      }*/

      //return [false, error.message, -2];
    }
  };
  //REVISAR:
  /*const instanciaAxios = (estado = null) => {
    let baseURL = '';
    let estadoCaja = getEstadoCaja();
    if (estadoCaja === 'ALTA' || estado == 'ALTA') {
      //baseURL = URLS.BASE_URL_DESARROLLO;
      //baseURL = URLS.BASE_URL_AZURE;
      baseURL = URLS.BASE_URL_LOCAL;
    } else if (estadoCaja === 'CONT' || estado == 'CONT') {
      baseURL = URLS.BASE_URL_LOCAL;
    }
    console.log(baseURL);
    return customAxios(baseURL);
  }*/
  //REVISAR:
  /*const tiempoEnContingencia = (estado = null) => {
    if (getEstadoCaja() === 'CONT' || estado === 'CONT') {
      const millis = Date.now() - getCajaFalloTS();
      console.log(millis);
      const tiempoPasoSegundos = Math.floor(millis / 1000);
      console.log(tiempoPasoSegundos);
      if (tiempoPasoSegundos > state.response.otrosValores.tiempoContingencia) {
        // EL TIEMPO YA SE CUMPLIO, ENTONCES YA NO ESTAMOS EN CONGINGENCIA
        setEstadoCaja('ALTA')
        setCajaFalloTS(null);
        return 'ALTA';
      }
      console.log('hola');
      return 'CONT';
    }

    return 'ALTA';
  }*/
  //TERMINADO:
  const armarOrdenFiscalizar = async (orderItems, pagaCon = 0, datosCredito = null, nitCf = false) => {
    console.log(state.correlativo);
    // VALIDAMOS SI ESTAMOS EN ESTADO DE CONTINGENCIA PARA VER SI CUMPLIMOS CON EL TIEMPO
    //const tipoCaja = tiempoEnContingencia();

    // LEEMOS EL ESTADO ACTUAL DE LA CAJA PARA DETERMINAR LA URL E INSTANCIA DE AXIOS
    //let axios = instanciaAxios(tipoCaja);
    //console.log(axios);
    // codigoProceso 0 -> fallo total, 1 -> flujo normal, 2 flujo -> local
    //let codigoProceso = tipoCaja === 'ALTA' ? 1 : 2;
    //NOTA: ARMANDO LA ORDEN
    let totalFinal = totalOrder.toFixed(2);

    const valorDescuentoFinal = calcularDescuento();

    let configuracionOrden = state.orderOptions;
    console.log(configuracionOrden);

    let objSesion = JSON.parse(localStorage.getItem("LOGIN_BETA"));
    configuracionOrden.codigoTurno = objSesion.Turno.CodigoTurno;

    //VALIDAMOS EL "PAGA CON" CON EL TOTAL DE LA ORDEN SI LA ORDEN TIENE FORMA DE PAGO DIVIDIDA
    if (configuracionOrden.formaDePago === "DIVIDIDA" && state.datosFiscales.pagaCon >= totalOrder - valorDescuentoFinal) {
      configuracionOrden.formaDePago = "EFECTIVO";
    }
    //VALIDAMOS TOTALES DE LA ORDEN SEGUN LA FORMA DE PAGO
    if (configuracionOrden.formaDePago === "EFECTIVO") {
      configuracionOrden.totalFisico = totalFinal;
    } else if (configuracionOrden.formaDePago === "TC" || configuracionOrden.formaDePago === "CREDITO") {
      configuracionOrden.totalVirtual = totalFinal;
    } else if (configuracionOrden.formaDePago === "DIVIDIDA") {
      configuracionOrden.totalFisico = state.datosFiscales.pagaCon.toFixed(2);
      configuracionOrden.totalVirtual = (totalOrder - state.datosFiscales.pagaCon - valorDescuentoFinal).toFixed(2);
    }

    //NOTA: asignamos el correlativo actual en la orden
    //configuracionOrden.documentoNumero = state.correlativo;

    //NOTA: si la caja ya se encuentra en cont entonces asignamos etapa y condicion
    // Esto pasa a estar en el catch del servicio para insertar órdenes, ya que la caja ya no tendrá estados
    /*if (tipoCaja === 'CONT') {
      configuracionOrden.codigoEtapa = 'PEND';
      configuracionOrden.condicion = 1;
    }*/

    let order = {
      Orden: configuracionOrden,
      ordenDetalle: orderItems,
    };

    // Creación de la propiedad para modo sin conexión, NULL por defecto
    order.Orden.EstadoSincronizacion = null;
    //VALIDAMOS EL DESCUENTO EN LA ORDEN, SI EXISTE
    if (state.descuentoFuncion.funcion === true) {
      ////console.log('hay descuento');
      let detalleSinDescuento = orderItems.filter((item) => item.CodigoPlu !== "DESCUENTO");

      order.ordenDetalle = detalleSinDescuento;
      order.Orden.totalDescuento = (valorDescuentoFinal * -1).toFixed(2);
      order = {
        ...order,
        ordenDescuento: {
          CodigoOrden: null,
          CodigoDescuento: state.descuentoFuncion.codigoDescuento,
          ReferenciaDescuento: state.descuentoFuncion.descripcionReferencia ?? null,
          CodigoEmpresa: configuracionOrden.codigoEmpresa,
        },
      }


      if (configuracionOrden.formaDePago === "EFECTIVO") {
        order.Orden.totalFisico = (parseFloat(order.Orden.totalFisico) + parseFloat(valorDescuentoFinal)).toFixed(2);

      } else if (configuracionOrden.formaDePago === "TC" || configuracionOrden.formaDePago === "CREDITO") {
        order.Orden.totalVirtual = (parseFloat(order.Orden.totalVirtual) + parseFloat(valorDescuentoFinal)).toFixed(2);
      } else if (configuracionOrden.formaDePago === "DIVIDIDA") {
        order.Orden.totalFisico = state.datosFiscales.pagaCon.toFixed(2);
        order.Orden.totalVirtual = (parseFloat(totalFinal) + parseFloat(valorDescuentoFinal) - state.datosFiscales.pagaCon).toFixed(2);

      }
    }
    console.log(order);

    let nuevaOrdenFiscalizada = {}

    nuevaOrdenFiscalizada = {
      ...state.datosFiscales,
      formaPago: configuracionOrden.formaDePago,
      codigoEmpresa: configuracionOrden.codigoEmpresa,
      codigoTienda: configuracionOrden.codigoTienda,
      codigoCaja: configuracionOrden.codigoCaja,
      codigoDocumento: configuracionOrden.codigoDocumento,
      tipoIdCliente: state.datosFiscales.tipoIdCliente,
      nombreCliente: state.datosFiscales.nombreCliente, // esto tiene que venir del formulario
      direccionCliente: 'GUATEMALA', // Siempre se usa GUATEMALA?
      nit: state.datosFiscales.nit,
      pagaCon: pagaCon.toFixed(2),
      datosCredito: datosCredito === null ? null : datosCredito,
      // el documentoNumero/Correlativo se debe enviar sin importar si está en conectado o no
      //documentoNumero: state.correlativo
    };
    console.log(nuevaOrdenFiscalizada);
    if (nitCf) nuevaOrdenFiscalizada.nit = '';

    try {
      //OBSERVACION: ESTE FLUJO ES PARA ORDENES YA INSERTADAS.
      if (state.response.orden !== null || configuracionOrden.codigoOrden) {
        configuracionOrden.codigoOrden = state.response.orden === null
          ? configuracionOrden.codigoOrden
          : state.response.orden.Orden.CodigoOrden;

        nuevaOrdenFiscalizada.codigoOrden = configuracionOrden.codigoOrden;

        if (configuracionOrden.formaDePago.toUpperCase() === "TC" || configuracionOrden.formaDePago.toUpperCase() === "CREDITO") {
          nuevaOrdenFiscalizada.datosCredito.CodigoOrden = configuracionOrden.codigoOrden;

        }

        //const soloFiscalizaOrden = await posServices.pos.fiscalizarOrden(nuevaOrdenFiscalizada, axios, false);
        //const soloFiscalizaOrden = await fiscalizarOrden(nuevaOrdenFiscalizada, axios, false);
        const soloFiscalizaOrden = await fiscalizarOrden(nuevaOrdenFiscalizada, false);
        /*if (soloFiscalizaOrden.data.CODE === 1000 && !!soloFiscalizaOrden.data.SUCCESS) {
          const ordenFactura = soloFiscalizaOrden.data.MESSAGE;

          configuracionOrden.documentoNumero = ordenFactura.DocumentoNumero;

          dispatch({ type: ACTION_TYPES.CORRELATIVO, payload: nuevoCorrelativo });
          // hasta este punto se inserto y se fiscalizo
          return [false, true, configuracionOrden];
        }*/
        //console.log(soloFiscalizaOrden);
        if (soloFiscalizaOrden[1].data.CODE === 2003 && !soloFiscalizaOrden[1].data.SUCCESS) {
          //NOTA: ERROR EN EL NIT ENVIADO
          dispatch({ action: ACTION_TYPES.MODIFICAR_ORDEN, payload: configuracionOrden });
          return [true, false, soloFiscalizaOrden[1].data.MESSAGE, 1];

        } else if (soloFiscalizaOrden[1].data.CODE === 1000 && !!soloFiscalizaOrden[1].data.SUCCESS) {
          //NOTA: SE FISCALIZO BIEN LA ORDEN, TERMINA 
          const ordenFactura = soloFiscalizaOrden[1].data.MESSAGE;

          configuracionOrden.documentoNumero = ordenFactura.DocumentoNumero;

          return [true, true, configuracionOrden, 0];
        } else if (soloFiscalizaOrden[2] === 2) {
          return [true, false, `Ocurrio un error con la orden ${configuracionOrden.codigoOrden}, la orden fue revertida.`, 3];
        } else if (soloFiscalizaOrden[2] === -1) {
          return [true, false, `Ocurrio un error con la orden ${configuracionOrden.codigoOrden}, la orden no fue revertida.`, 3];
        } else if (soloFiscalizaOrden[1].data.CODE === -2) {
          //NOTA: OCURRIO ALGÚN ERROR AL FISCALIZAR LA ORDEN
          return [true, false, ERROR.FISCALIZAR, -2];
        }
      }
      //------------------------------------------------------

      //OBSERVACION: asignamos contingecia manual si es el caso
      // Tratar de no asignar contingencias desde el front por el momento
      /*
      console.log(state.contingencia);
      if (state.contingencia !== '') {
        order = asignarContingencia(order)[1];
        console.log(order.ordenFactura);
      }*/

      //const resOrder = await insertarOrden(order, axios, false);
      //debugger;
      const resOrder = await insertarOrden(order, false);
      console.log(resOrder);

      //OBSERVACION: FLUJO SI FALLA INSERTAR ORDEN
      /*if(!resOrder[0]) {
        codigoProceso = 2;
        // pend 1
        configuracionOrden.codigoEtapa = 'PEND';
        configuracionOrden.condicion = 1;
        order = {
          ...order,
          Orden: configuracionOrden,
        };

        axios = instanciaAxios('CONT');

        return [false, false, resOrder[1]]
      }*/
      //debugger;
      if (!!resOrder[0]) {
        // se inserto bien la orden
        const codigoOrden = resOrder[1].data.MESSAGE.Orden;

        configuracionOrden.codigoOrden = codigoOrden;

        // ya que se inserto la orden ahora la fiscalizamos
        nuevaOrdenFiscalizada.codigoOrden = codigoOrden;


        if (configuracionOrden.formaDePago.toUpperCase() === "TC" || configuracionOrden.formaDePago.toUpperCase() === "CREDITO") {
          nuevaOrdenFiscalizada.datosCredito.CodigoOrden = codigoOrden;
        }
        //console.log(nuevaOrdenFiscalizada.tipoIdCliente);
        //const fiscalizarOrdenResponse = await fiscalizarOrden(nuevaOrdenFiscalizada, axios, false);
        const fiscalizarOrdenResponse = await fiscalizarOrden(nuevaOrdenFiscalizada, false, order);
        console.log(fiscalizarOrdenResponse);

        /*if(!fiscalizarOrdenResponse[0] && !!resOrder[0]){
          codigoProceso = 2;
          // pend cond 2
          configuracionOrden.codigoEtapa = 'PEND';
          configuracionOrden.condicion = 2;

          axios = instanciaAxios('CONT');

          return [true, false, configuracionOrden];
        }*/

        /*if(!resOrder[0] || !fiscalizarOrdenResponse[0]) {
          const nuevaOrdenContingencia = asignarContingencia(configuracionOrden);
          order = {
            ...order,
            ...nuevaOrdenContingencia[1],
          }

          const auxOrder = await insertarOrden(order, axios, true);
          //NOTA: HASTA ESTE PUNTO NO SE INSERTO NADA, todo fallo
          if(!auxOrder[0]) {

          }
          //ESTA PARTE ESTABA MALA EN LA OTRA RAMA
          const auxCodigoOrden = auxOrder[1].data.MESSAGE.Orden;
          configuracionOrden.codigoOrden = auxCodigoOrden;

          const auxFiscalizar = await fiscalizarOrden(nuevaOrdenFiscalizada, axios, true);
          //
          return [true, true, configuracionOrden, 1]
        }*/

        if (fiscalizarOrdenResponse[2] === 1) {
          //NOTA: ERROR EN EL NIT ENVIADO
          dispatch({ action: ACTION_TYPES.MODIFICAR_ORDEN, payload: configuracionOrden });
          return [true, false, fiscalizarOrdenResponse[1].data.MESSAGE, 1];

        } else if (fiscalizarOrdenResponse[2] === 0) {
          //NOTA: SE FISCALIZO BIEN LA ORDEN, TERMINA 
          const ordenFactura = fiscalizarOrdenResponse[1].data.MESSAGE;
          configuracionOrden.documentoNumero = ordenFactura.DocumentoNumero;

          return [true, true, configuracionOrden, 0];
        } else if (fiscalizarOrdenResponse[2] === 2) {
          return [true, false, `Ocurrio un error con la orden ${configuracionOrden.codigoOrden}, la orden fue revertida.`, 3];
        } else if (fiscalizarOrdenResponse[2] === -1) {
          return [true, false, `Ocurrio un error con la orden ${configuracionOrden.codigoOrden}, la orden no fue revertida.`, 3];
        } else if (fiscalizarOrdenResponse[2] === -2) {
          //NOTA: OCURRIO ALGÚN ERROR AL FISCALIZAR LA ORDEN
          return [true, false, ERROR.FISCALIZAR, 2];
        }

        //ESTE CODIGO ES INALCANZABLE, SE QUEDA COMENTADO DE MOMENTO
        //return [true, false, configuracionOrden, 1];
      } else {
        return [false, false, resOrder.data.MESSAGE, -3];
      }
    } catch (error) {
      //let ordenFallida = { orden: order, fiscal: nuevaOrdenFiscalizada };
      //guardarOrden(ordenFallida);
      console.error(error);
      return [false, false, error, -4];
    }
  };
  //TERMINADO:
  const regresarPadre = () => {
    let menuActual = state.response.menu.filter(
      (item) => item.CodigoMenu === state.menuValue
    )[0];
    dispatch({
      type: ACTION_TYPES.VALOR_MENU,
      payload: menuActual.CodigoMenuPadre,
    });
  };
  //TERMINADO:
  const limpiarPos = () => {
    dispatch({ type: ACTION_TYPES.LIMPIAR_POS });
  };
  //TERMINADO:
  const calcularDescuento = (valorDigitado = null, funcionProvisional = null) => {
    let totalDescuento = 0;
    let auxDescuento =
      funcionProvisional === null ? state.descuentoFuncion : funcionProvisional;

    if (!!auxDescuento.funcion || valorDigitado !== null) {
      let objetoFuncion = JSON.parse(auxDescuento.objetoFuncion);
      let nuevaFuncionDescuento = undefined;

      if (objetoFuncion.tipo.includes("FIJO")) {
        if (auxDescuento.parametros === 1) {
          // el descuento fijo es introducido por el usuario
          nuevaFuncionDescuento = new Function(
            objetoFuncion.arguments,
            objetoFuncion.body
          );

          if (valorDigitado === null) {
            totalDescuento = nuevaFuncionDescuento(
              parseFloat(auxDescuento.valorDigitado)
            );
          } else {
            ////console.log('-' + valorDigitado.length + '-');
            if (valorDigitado === null || valorDigitado === 0)
              valorDigitado = 0;
            totalDescuento = nuevaFuncionDescuento(parseFloat(valorDigitado));
          }
        } else {
          // el descuento ya esta configurado
          ////console.log('hola');
          nuevaFuncionDescuento = new Function(objetoFuncion.body);

          totalDescuento = nuevaFuncionDescuento();
          ////console.log(totalDescuento);
        }
      } else if (objetoFuncion.tipo.includes("PORCENTAJE")) {
        if (auxDescuento.parametros === 2) {
          // el porcentaje es introducido por el usuario
          nuevaFuncionDescuento = new Function(
            objetoFuncion.arguments,
            objetoFuncion.body
          );

          if (valorDigitado === null) {
            totalDescuento = nuevaFuncionDescuento(
              totalOrder,
              parseFloat(auxDescuento.valorDigitado)
            );
          } else {
            if (valorDigitado === null || valorDigitado === 0)
              valorDigitado = 0;
            totalDescuento = nuevaFuncionDescuento(
              totalOrder,
              parseFloat(valorDigitado)
            );
          }
        } else {
          // el porcentaje ya esta configurado, faltaría ingresar el monto total de la orden
          nuevaFuncionDescuento = new Function(
            objetoFuncion.arguments,
            objetoFuncion.body
          );

          totalDescuento = nuevaFuncionDescuento(totalOrder);
        }
      }
    }

    return totalDescuento;
  };
  //TERMINADO:
  const reiniciarDescuento = () => {
    const detalleSinDescuento = state.orderItems.filter(
      (item) => item.CodigoPlu !== "DESCUENTO"
    );

    /*setOrderOptions({ ...orderOptions, totalDescuento: 0 });
    setOrderItems(detalleSinDescuento);
    setDescuentoFuncion(ordenFiscalizada);*/
    console.log(detalleSinDescuento);
    dispatch({
      type: ACTION_TYPES.REINICIAR_DESCUENTO,
      payload: detalleSinDescuento,
    });
  };
  //TERMINADO: crear dispatch agregar producto y limpiar pad,
  const addProductoDetalle = (itemProducto) => {

    // Valor actual marcado en el numberPad
    const valorPad = (state.numberPad === '' || state.numberPad === 0) ? 1 : state.numberPad;

    let listaProductosActualizada = state.orderItems;
    if (state.response.orden !== null && CodigoOrden !== undefined) {
      swalFire(SWAL_ACTION.AGREGAR_PRODUCTO_GAD);
      return;
    }
    console.log(itemProducto);
    if (itemProducto.CodigoPlu !== "DESCUENTO") {

      // Array que contiene los arrays de cada producto a crear
      // No tiene uso si el valor del numberPad es 1, solo se usa para múltiples productos creados a la vez
      let opcionesPorPad = [];

      // Lógica para ordenar las opciones seleccionadas por producto a agregar

      // No es necesario realizar esto si el numberPad es 1 y si no hay opciones seleccionadas
      if ((valorPad > 0 || valorPad !== '') && state.arrayOrderOpcion.length > 0) {

        // Por cada producto a crear (el numberPad indica la cantidad de productos a crear)
        for (let m = 0; m < parseInt(valorPad); m++) {

          let arrayOpcionUnProd = []; // Array para todas las opciones de un único producto

          // Por cada complemento que el producto contenga
          itemProducto.Complementos.forEach(element => {
            const maximoComp = element.CantidadMaximaComplemento; // CantidadMaxima del complemento actual

            if (maximoComp > 1) {
              // Se filtran todas las opciones que pertenezcan a este complemento
              const opcionAgregarArrayFull = state.arrayOrderOpcion.filter(item => item.id === element.CodigoComplemento);

              /* El código siguiente solo sirve para cuando el máximo de un complemento es 2 o 3
              * En el caso de que fuera más (poco probable pero posible), las series dejan de funcionar
              * La nueva lógica es usar [0, maximoComp] cuando m = 0
              * [maximoComp * m, (maximoComp * m) + maximoComp] cuando m > 0
              * Esta nueva lógica siempre garantiza empezar la serie bien desde m = 1, cumpliendo con el máximo
              * por producto
              // Serie 2n hasta 2n+k que toma las opciones correspondientes al número máximo permitido 
              const botSlicePar = 2 * m;
              const topSlicePar = (2 * m) + maximoComp;

              // Serie 2n+1 hasta (2n+1) + k para cuando el máximo sea un valor impar
              const botSliceOdd = (2 * m) + 1; // Esto siempre da 3 en la primera iteración, no es cierto
              const topSliceOdd = ((2 * m) + 1) + maximoComp;
              */

              const botSlice = maximoComp * m;
              const topSlice = (maximoComp * m) + maximoComp;

              let arrayPorProd = []; // Array para las opciones del complemento actual

              if (m === 0) arrayPorProd = opcionAgregarArrayFull.slice(0, maximoComp);
              else arrayPorProd = opcionAgregarArrayFull.slice(botSlice, topSlice);

              /*
              // Si el máximo del complemento es par
              // Se usan los limites de la serie par
              if (maximoComp % 2 === 0) arrayPorProd = opcionAgregarArrayFull.slice(botSlicePar, topSlicePar);
              // Si el máximo es impar
              else {
                // Se usan los límites pares cuando es la primera iteración 
                if (m === 0) arrayPorProd = opcionAgregarArrayFull.slice(0, topSlicePar);
                // Y los impares para m > 1 
                else {
                  console.log(botSliceOdd);
                  console.log(topSliceOdd);
                  arrayPorProd = opcionAgregarArrayFull.slice(botSliceOdd, topSliceOdd);
                }
              }
              */

              /*if (maximoComp % 2 === 0) { // Si el maximo es par
                arrayPorProd = opcionAgregarArrayFull.slice(botSlicePar, topSlicePar);
              }
              else {
                if (m === 0) {
                  arrayPorProd = opcionAgregarArrayFull.slice(0, topSlicePar);
                }
                else {
                  arrayPorProd = opcionAgregarArrayFull.slice(botSliceOdd, topSliceOdd);

                }

              }*/

              // En este caso, es posible que se repita una opción seleccionada
              // Por ejemplo, en el menú súper campero se puede repetir la misma receta hasta 3 veces

              let codigosOpcion = []; // Array para almacenar los códigos de cada opción seleccionada

              // Se agregan los CodigoPlu de cada opción al array 
              arrayPorProd.forEach(element => {
                codigosOpcion.push(element.CodigoPlu);
              });

              const counts = {}; // Objeto para almacenar la información de cuántas veces se repite una misma opción

              for (const num of codigosOpcion) {
                counts[num] = counts[num] ? counts[num] + 1 : 1;
              }

              let nuevoArrayOpciones = []; // Array para guardar las opciones y sus cantidades actualizadas

              // Por cada CodigoPlu de opción seleccioanda
              for (let p = 0; p < Object.keys(counts).length; p++) {
                // Se toma una opción y se cambia el valor de la cantidad al valor actual de veces que está
                let opcionActual = arrayPorProd.filter(item => item.CodigoPlu === Object.keys(counts)[p])[0];
                opcionActual.Cantidad = counts[opcionActual.CodigoPlu];
                nuevoArrayOpciones.push(opcionActual);
              }

              //arrayOpcionUnProd.push(...arrayPorProd);

              // Se agrega a las opciones del producto
              arrayOpcionUnProd.push(...nuevoArrayOpciones);

            }
            else { // Si el máximo del complemento es 1
              // Solo se obtiene la opción de la iteración actual relacionada al complemento
              const opcionAgregar = state.arrayOrderOpcion.filter(item => item.id === element.CodigoComplemento)[m];
              arrayOpcionUnProd.push(opcionAgregar);
            }

          });

          // Se agrega el array de opciones de cada producto a la variable
          opcionesPorPad.push(arrayOpcionUnProd);
        }
      }

      //console.log('opciones:', opcionesPorPad);

      // Si el valor del pad es mayor a 1 (múltiples productos al mismo tiempo)
      if (valorPad > 1) {

        // Si los productos a crear contienen opciones
        if (opcionesPorPad.length > 0) {

          let nuevosProductos = []; // Array para ir almacenando los nuevos productos que se van a crear

          // Por cada nuevo producto
          for (let n = 0; n < valorPad; n++) {

            let banderaNuevo = false; // Bandera que indica si el producto por crear ya se encuentra en la 
            // lista por crear al final del for

            const nuevaDescripcion = itemProducto.DescripcionPantalla.replaceAll('+', '');

            // El producto a crear con el array de opciones correspondiente
            const newItem = {
              CodigoEmpresa: itemProducto.CodigoEmpresa,
              CodigoPlu: itemProducto.CodigoPlu,
              Cantidad: 1,//state.numberPad === "" ? 1 : parseInt(state.numberPad),
              Precio: itemProducto.Precio.toFixed(2),
              CategoriaItem: 1,
              //DescripcionProducto: itemProducto.DescripcionPantalla,
              DescripcionProducto: nuevaDescripcion,
              Secuencia: 0,
              ComplementosNecesarios: itemProducto.Complementos,
              idUnico: uuidv4(),
              opciones: opcionesPorPad[n], // opciones correspodientes a este producto
            };

            //console.log(newItem);

            let banderaFaltan = false;

            // Como es por item, es posible que no sea necesario multiplicar la cantidad mínima por el valor
            // del numberpad
            let compAux = newItem.ComplementosNecesarios;
            let opAux = newItem.opciones;

            compAux.forEach(element => {
              let cont = 0;
              opAux.forEach(el => {
                if (el === undefined) {
                  Swal.fire({
                    title: 'No se puede agregar el producto a la orden',
                    text: 'Faltan complementos por agregar',
                    icon: 'error'
                  })
                  banderaFaltan = true;
                }
                else if (el.id === element.CodigoComplemento) {
                  cont += el.Cantidad;
                }
              });
              if (element.CantidadMinimaComplemento !== 0) {
                if (cont !== element.CantidadMinimaComplemento) {
                  Swal.fire({
                    title: 'No se puede agregar el producto a la orden',
                    text: 'Faltan complementos por agregar',
                    icon: 'error'
                  })
                  banderaFaltan = true;
                }
              }
            });

            if (banderaFaltan) return;

            // Es necesario hacer dos verificaciones de PLU existente
            // Hay que verificar si ya ese encuentra en la lista de productos agregados
            const pluExiste = state.orderItems.findIndex((item) => item.CodigoPlu === newItem.CodigoPlu);

            // O si está en el listado de productos que se están creando actualmente
            // Lo anterior siempre se va a cumplir, hay que verificar si las opciones son las mismas para
            // hacer una suma a la cantidad total o si se crea un nuevo producto
            const existeNuevo = nuevosProductos.findIndex((item) => item.CodigoPlu === newItem.CodigoPlu);
            if (existeNuevo > -1) { // Situación que siempre se da, puede que no sea necesario evaluar
              // Si sirve, para saber el index
              const opIg = opcionesIguales(nuevosProductos[existeNuevo].opciones, newItem.opciones);
              //console.log(opIg);
              if (opIg) {
                nuevosProductos[existeNuevo].Cantidad += 1;
                banderaNuevo = true;
              }

            }

            // Si el producto existe en el nuevo listado que se está creando, ya no es necesario compararlo
            // con un plu existente porque el primer producto igual que se agregó ya lo hizo, solo es una copia
            // que suma a la cantidad del original
            if (!banderaNuevo) {

              if (pluExiste > -1) { // Si el plu ya existe
                // Hay que probar las opciones
                // Aquí tiene que haber un for de indexes, puede que sean distintos todos
                // Es decir, el item al que estoy apuntando, no necesariamente es el mismo que quiero
                // pluExiste solo nos indica que ya existe pero no indica cuál es el que queremos afectar

                const indxPos = getMultipleIndexes(state.orderItems, newItem.CodigoPlu);
                //console.log(indxPos);
                let arrayIguales = [];

                indxPos.forEach(index => {

                  const opIg = opcionesIguales(state.orderItems[index].opciones, newItem.opciones);
                  if (opIg) { // Si ese plu con las opciones ya existe
                    state.orderItems[index].Cantidad += 1;
                    arrayIguales.push('1');
                    //itemList[pluExiste].Cantidad += 1;
                  }
                  else { // Si no, es un producto nuevo
                    //nuevosProductos.push(newItem);
                    // Si no coincide con ninguna, se puede esperar para agregar fuera del 
                    arrayIguales.push('0');
                  }
                });

                if (!arrayIguales.includes('1')) {
                  nuevosProductos.push(newItem);
                }
              }
              else {
                // Si el plu no existe, solo se agrega a la nueva lista de productos
                nuevosProductos.push(newItem);
              }
            }
            //console.log(nuevosProductos);

          }
          //console.log(nuevosProductos);
          listaProductosActualizada = [...itemList, ...nuevosProductos];

          //console.log(listaProductosActualizada);

          dispatch({ type: ACTION_TYPES.RESET_ARRAY_OPCIONES });
          dispatch({ type: ACTION_TYPES.LISTA_PRODUCTOS, payload: listaProductosActualizada });
          dispatch({ type: ACTION_TYPES.MANEJO_PAD, payload: '' });
          dispatch({ type: ACTION_TYPES.CERRAR_MODAL });

        }
        else { // Si los productos nuevos no tienen opciones

          const nuevaDescripcion = itemProducto.DescripcionPantalla.replaceAll('+', ' ');

          // Nuevo producto
          const newItem = {
            CodigoEmpresa: itemProducto.CodigoEmpresa,
            CodigoPlu: itemProducto.CodigoPlu,
            Cantidad: 1,//state.numberPad === "" ? 1 : parseInt(state.numberPad),
            Precio: itemProducto.Precio.toFixed(2),
            CategoriaItem: 1,
            //DescripcionProducto: itemProducto.DescripcionPantalla,
            DescripcionProducto: nuevaDescripcion,
            Secuencia: 0,
            ComplementosNecesarios: itemProducto.Complementos,
            idUnico: uuidv4(),
          };

          // Se verifica si el plu del producto a crear ya existe en el listado de productos agregados
          const pluExiste = state.orderItems.findIndex((item) => item.CodigoPlu === newItem.CodigoPlu);

          if (pluExiste > -1) { // Si ya existe, quiere decir que es un producto duplicado

            const newList = [...itemList]; // Listado actual de productos agregados

            // Se busca en cada producto agregado el plu que haga match
            itemList.forEach(element => {

              if (element.CodigoPlu === newItem.CodigoPlu) {
                // Index del producto en el listado
                const i = newList.findIndex((item) => item.CodigoPlu === newItem.CodigoPlu);
                // Se actualiza la cantidad tomando la cantidad actual + el valor del numberPad
                newList[i] = {
                  ...element,
                  Cantidad: element.Cantidad + parseInt(state.numberPad),
                }
              }
            });
            // Se actualiza la lista con la cantidad nueva
            listaProductosActualizada = newList;
          }
          else { // Si no existe, es un nuevo producto
            // La cantidad del producto es el valor del numberPad
            newItem.Cantidad = parseInt(state.numberPad);
            // Se agrega a a lista de productos
            listaProductosActualizada = [...itemList, newItem];
          }
          dispatch({ type: ACTION_TYPES.LISTA_PRODUCTOS, payload: listaProductosActualizada });
          dispatch({ type: ACTION_TYPES.MANEJO_PAD, payload: '' });
        }
      }
      // Si el valor del numberPad es igual a 1 (o '')
      else {

        const nuevaDescripcion = itemProducto.DescripcionPantalla.replaceAll('+', ' ');

        // Nuevo producto
        const newItem = {
          CodigoEmpresa: itemProducto.CodigoEmpresa,
          CodigoPlu: itemProducto.CodigoPlu,
          Cantidad: 1,//state.numberPad === "" ? 1 : parseInt(state.numberPad),
          Precio: itemProducto.Precio.toFixed(2),
          CategoriaItem: 1,
          //DescripcionProducto: itemProducto.DescripcionPantalla,
          DescripcionProducto: nuevaDescripcion,
          Secuencia: 0,
          ComplementosNecesarios: itemProducto.Complementos,
          idUnico: uuidv4(),
        };

        // Si el producto a agregar tiene opciones
        if (itemProducto.opciones !== undefined && itemProducto.opciones !== null) {
          newItem.opciones = itemProducto.opciones; // Se agrega la propiedad al nuevo producto
        }
        else { // Esto ya no ocurre
          newItem.Cantidad = state.numberPad === "" ? 1 : parseInt(state.numberPad);
        }

        // Lógica para evitar que se agregue el producto si no tiene todos sus complementos
        // Buscar en los ComplementosNecesarios del nuevo producto si las opciones hacen match con las cantidades
        // minimas de los complementos para ver si falta alguna opción por agregar
        let banderaFaltan = false;

        // Si el producto no tiene opciones, no aplica esta lógica
        if (newItem.opciones !== undefined && newItem.opciones !== null) {
          let compAux = newItem.ComplementosNecesarios;
          let opAux = newItem.opciones;

          compAux.forEach(element => {
            let cont = 0;
            opAux.forEach(el => {
              if (el.id === element.CodigoComplemento) {
                cont += el.Cantidad;
              }
            });
            if (element.CantidadMinimaComplemento !== 0) {
              if (cont !== element.CantidadMinimaComplemento) {
                Swal.fire({
                  title: 'No se puede agregar el producto a la orden',
                  text: 'Faltan complementos por agregar',
                  icon: 'error'
                })
                banderaFaltan = true;
              }
            }
          });
        }
        // Si faltan complementos (opciones) por agregar, no se agrega el producto y se deja el modal abierto
        if (banderaFaltan) return;

        // buscamos el plu esta marcado
        const pluExiste = state.orderItems.findIndex((item) => item.CodigoPlu === newItem.CodigoPlu);

        // Se busca si el plu ya se encuentra en la lista de productos seleccionados
        if (pluExiste > -1) {
          //console.log('dentro');
          const newList = [...itemList]; // La nueva lista de productos que se va a manejar
          // Contiene al inicio la lista de productos ya seleccionados

          // PRIMERO, se verifica si el producto tiene complementos (propiedad opciones)
          if (newItem.opciones !== undefined && newItem.opciones !== null) { // Tiene complementos
            // Si el producto tiene complementos, existen dos posibilidades
            // 1) El producto tiene las mismas opciones seleccionadas que uno de los productos ya en la lista
            // 2) El producto tiene opciones no seleccionadas (es como un producto nuevo)

            let listaIguales = []; // Listado para almacenar los productos con los que haga match el newItem

            // Es necesario verificar que el listado de opciones sea igual aunque no estén en el mismo orden
            // La función opcionesIguales() se encarga de ordenar las opciones por la posición
            itemList.forEach((element, k) => {
              if (element.opciones !== undefined && element.opciones !== null) { // No interesan los elementos que no tengan opciones

                if (element.CodigoPlu === newItem.CodigoPlu) { // Si el PLU de element es igual al del producto a agregar

                  // La función opcionesIguales(producto1, producto2) evalúa si las opciones de dos productos son iguales
                  const opIg = opcionesIguales(element.opciones, newItem.opciones);
                  if (opIg) { // Si las opciones son iguales

                    // Este findIndex no funciona porque va a por el index del primer producto con el que se encuentre
                    // que haga match con el PLU, no necesariamente va a ser el producto correcto
                    //const i = newList.findIndex((item) => item.CodigoPlu === newItem.CodigoPlu);

                    // Tiene que ser el index del elemento en el que estamos actualmente
                    // Este indice es k en el callback

                    // Se añade el elemento y el indice con el que hizo match a la lista de iguales
                    listaIguales.push({
                      producto: element,
                      index: k,
                    })
                    //console.log('Estoy aqui: ', k);
                  }
                  // Deberia haber un else? Al parecer no
                }
              }
            });

            if (listaIguales.length > 0) { // Si hay elementos en la listaIguales
              // Solo debería haber un elemento en la lista (solo debe hacer match con un producto)
              const indice = listaIguales[0].index;
              newList[indice] = {
                ...listaIguales[0].producto,
                Cantidad: listaIguales[0].producto.Cantidad + newItem.Cantidad,
                opciones: newItem.opciones
              }
            }
            else { // Si no hubo matches, quiere decir que es un producto nuevo
              newList.push({
                ...newItem,
              })
            }
          }
          else { // No tiene complementos
            // Si el producto no tiene complementos, solo es necesario aumentar la cantidad de este producto en 
            // la lista de productos seleccionados
            itemList.forEach(element => {
              if (element.CodigoPlu === newItem.CodigoPlu) {
                const i = newList.findIndex((item) => item.CodigoPlu === newItem.CodigoPlu);

                newList[i] = {
                  ...element,
                  Cantidad: element.Cantidad + newItem.Cantidad,
                }

              }
            });
          }
          listaProductosActualizada = newList;

        } else { // Si el plu todavía no existe en la lista, solo se agrega a esta
          listaProductosActualizada = [...itemList, newItem];
        }
        dispatch({ type: ACTION_TYPES.RESET_ARRAY_OPCIONES });
        dispatch({ type: ACTION_TYPES.LISTA_PRODUCTOS, payload: listaProductosActualizada });
        dispatch({ type: ACTION_TYPES.MANEJO_PAD, payload: '' });
        dispatch({ type: ACTION_TYPES.CERRAR_MODAL });
      }
      //setNumberPad('');

    } else {
      let auxItems = state.orderItems.filter(
        (item) => item.CodigoPlu !== "DESCUENTO"
      );

      const newItemDescuento = {
        //CodigoEmpresa: state.response.valoresLogin.CodigoEmpresa,
        CodigoEmpresa: usuario.Tienda.CodigoEmpresa,
        CodigoPlu: itemProducto.CodigoPlu,
        Cantidad: 1,
        Precio: itemProducto.Precio * -1,
        CategoriaItem: 1,
        DescripcionProducto: itemProducto.descripcion,
        Secuencia: 0,
        idUnico: uuidv4(),
      };
      console.log(newItemDescuento);

      listaProductosActualizada = [...auxItems, newItemDescuento];
      dispatch({ type: ACTION_TYPES.LISTA_PRODUCTOS, payload: listaProductosActualizada });
    }

  };


  const opcionesIguales = (opcn_1, opcn_2) => {
    if (opcn_1 === opcn_2) return true;
    if (opcn_1 == null || opcn_2 == null) return false;
    if (opcn_1.length !== opcn_2.length) return false;

    // Sort de las opciones
    opcn_1.sort((a, b) => a.Posicion - b.Posicion);
    opcn_2.sort((a, b) => a.Posicion - b.Posicion);

    const resultIgual = _.isEqual(opcn_1, opcn_2)
    return resultIgual;
    //console.log(resultIgual);

  }

  const getMultipleIndexes = (array, el) => {
    let idxs = [];
    for (let i = array.length - 1; i >= 0; i--) {
      if (array[i].CodigoPlu === el) {
        idxs.unshift(i);
      }
    }
    return idxs;
  };


  //TERMINADO:
  const flujoDeCobro = () => {
    let formaPagoActual = state.orderOptions.formaDePago.toUpperCase();

    if (state.orderOptions.codigoEmpresa.includes("GT")) {
      if (formaPagoActual === "EFECTIVO" || totalOrder === finalDescuento * -1) {
        dispatch({
          type: ACTION_TYPES.MODIFICAR_ORDEN,
          payload: { formaDePago: "EFECTIVO" },
        });
        dispatch({ type: ACTION_TYPES.NUMERO_MODAL, payload: 8 });
      } else if (formaPagoActual === "TC") {
        dispatch({ type: ACTION_TYPES.NUMERO_MODAL, payload: 6 });
      } else if(formaPagoActual === "CREDITO") {
        // ESTO DEBE QUEDAR AQUI? 
        dispatch({ type: ACTION_TYPES.NUMERO_MODAL, payload: 11 });
        onCreateOrder(0, null);
      } else if (formaPagoActual === "DIVIDIDA") {
        dispatch({ type: ACTION_TYPES.NUMERO_MODAL, payload: 8 });
      }
    } else if (state.orderOptions.codigoEmpresa.includes("SV")) {
      let documento = state.response.documentos.filter((doc) => state.orderOptions.documento === doc.CodigoDocumento)[0];
      if (documento.Descripcion === "FACTURA SV") {
        dispatch({ type: ACTION_TYPES.NUMERO_MODAL, payload: 13 });
      } else if (documento.Descripcion === "COMPROBANTE CREDITO FISCAL") {
        dispatch({ type: ACTION_TYPES.NUMERO_MODAL, payload: 15 });
      } else if (documento.Descripcion === "FACTURA EXENTA") {
      } else {
        dispatch({ type: ACTION_TYPES.NUMERO_MODAL, payload: 8 });
      }
    }
  };
  //TERMINADO:
  const llamarGAD = async () => {
    try {
      dispatch({ type: ACTION_TYPES.NUMERO_MODAL, action: 17 });

      const fecha = new Date();
      let dia = fecha.getDate() < 10 ? `0${fecha.getDate()}` : fecha.getDate();
      let mes =
        fecha.getMonth() + 1 < 10
          ? `0${fecha.getMonth() + 1}`
          : fecha.getMonth() + 1;

      const ordenResp = await anulacionServices.orden.getOrdenesPorFecha(
        `${dia}${mes}${fecha.getFullYear()}`,
        state.orderOptions.codigoEmpresa,
        state.orderOptions.codigoTienda,
        state.orderOptions.codigoCaja
      );
      dispatch({ type: ACTION_TYPES.CERRAR_MODAL });
      if (ordenResp.data.SUCCESS === false) {
        swalFire(SWAL_ACTION.ERROR_LLAMAR_GAD(ordenResp.data.MESSAGE));
      }

      const ordenes = ordenResp.data.MESSAGE;
      const ordGAD = ordenes.filter((orden) => orden.Orden.CodigoCanal === 4);

      if (ordGAD.length > 0) {
        navigate("/llamar-ordenes/GAD");
      } else {
        swalFire(SWAL_ACTION.SIN_ORDENES_GAD);
      }
    } catch (error) {
      console.error(error);
    }
  };
  //TERMINADO:
  // SELECCION DE MENUS Y PRODUCTOS AL NAVEGAR EN MENU
  const setMenusPantalla = (valorMenu = "") => {
    let menusPantalla = [];
    if (valorMenu !== "") {
      // combos
      menusPantalla = state.response.menu.filter(
        (item) => item.CodigoMenuPadre === valorMenu
      );

      if (menusPantalla.length === 0) {
        menusPantalla = state.response.menu.filter(
          (item) => item.CodigoMenu === valorMenu
        );
      }
    } else {
      menusPantalla = state.response.menu.filter(
        (item) => item.CodigoMenuPadre === ""
      );
    }

    if (menusPantalla.length > 0) {
      menusPantalla.sort((a, b) => {
        return a.Posicion - b.Posicion;
      });
    }

    return menusPantalla;
  };
  //TERMINADO:
  const setProductosPantalla = (valorMenu = "", canal = 0) => {
    if (!state.loading && state.error === undefined) {
      let productosPantalla = [];
      if (valorMenu !== "") {
        // combos

        let nodoMenu = null;
        for (let i = 0; i < state.response.menuArbol.length; i++) {
          nodoMenu = buscarNodoEnArbol(state.response.menuArbol[i], valorMenu);
          if (nodoMenu !== null) break;
        }

        let listaMenuProducto = [...new Set(buscarMenuProductos(nodoMenu))];

        productosPantalla = [
          ...state.response.producto.filter((p) =>
            listaMenuProducto.some((pMenu) => p.CodigoProducto === pMenu.CodigoProducto)
          ),
        ];
      } else {
        productosPantalla = [...state.response.producto];
      }

      // PONEMOS EL PRECIO SEGÚN EL CANAL SELECCIONADO
      let productosPrecioPantalla = [];
      productosPantalla.forEach((produc, index) => {
        if (produc.ListaPrecios.filter((item) => item.CodigoCanal === canal).length > 0) {
          produc = {
            ...produc,
            Precio: produc.ListaPrecios.filter(
              (lprecio) => lprecio.CodigoCanal === canal
            )[0].Precio,
            PrecioSinIva: produc.ListaPrecios.filter(
              (lprecio) => lprecio.CodigoCanal === canal
            )[0].PrecioSinIva,
          };

          productosPrecioPantalla.push(produc);
        }
      });

      if (productosPrecioPantalla.length > 0) {
        productosPrecioPantalla.sort((a, b) => {
          return a.Posicion - b.Posicion;
        });
      }

      return productosPrecioPantalla;
    }
  };

  let submenuList = useMemo(() => setMenusPantalla(state.menuValue));
  let childList = useMemo(() => setProductosPantalla(state.menuValue, state.orderOptions.codigoCanal));

  // CALCULO TOTAL DE LA ORDEN
  let totalOrder = 0;
  {
    if (state.orderItems.length > 0) {
      let auxListaSinDescuento = state.orderItems.filter((item) => item.CodigoPlu !== "DESCUENTO");
      totalOrder = auxListaSinDescuento.reduce((p, { Precio, Cantidad }) => p + Precio * Cantidad, 0);
    } else {
      totalOrder = 0;
    }
  }

  let finalDescuento = calcularDescuento();//state.orderOptions.totalDescuento;
  // listado de productos seleccionados
  let itemList = state.orderItems.map(item =>
    item.CodigoPlu === 'DESCUENTO' ?
      { ...item, Precio: (finalDescuento) } :
      item
  );

  return (
    <PosContext.Provider
      value={{
        armarOrdenFiscalizar,
        calcularDescuento, // si
        onCreateOrder, // si
        limpiarPos, // si
        regresarPadre, // si
        reiniciarDescuento, //si
        addProductoDetalle, // si
        flujoDeCobro, // si
        llamarGAD,
        submenuList, // si
        childList, // si
        totalOrder, // si
        finalDescuento,
        itemList,
        state,
        dispatch,
        ACTION_TYPES,
      }}
    >
      {children}
    </PosContext.Provider>
  );
};

export { PosContext, PosProvider };

/*
armarOrdenFiscalizar,
calcularDescuento, // si
onCreateOrder, // si
limpiarPos, // si
regresarPadre, // si
reiniciarDescuento, //si
addProductoDetalle, // si
flujoDeCobro, // si
llamarGAD,
calcularDescuento, // si
onCreateOrder, // si
limpiarPos, // si
regresarPadre, // si
reiniciarDescuento, //si
addProductoDetalle, // si
flujo*/