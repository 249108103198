import React from 'react';
import { useRef } from 'react';
import './styles.css';

const PosItemList = ({ children }) => {

  const ref = useRef();

  const scrollUpDown = (offset) => {
    ref.current.scrollTop += offset;
  }

  return (
    <>
      <div ref={ref} className="ui six column doubling grid m-0 itemlist-height itemlist-pt">
        <div className="row itemlist-pt mt-0">
          { children }
        </div>
      </div>

    </>
  );
}

export { PosItemList };