import Swal from "sweetalert2";
import { posServices } from "../../../api/services/posServices";
import { productoServices } from "../../../api/services/productoServices";

const getAllElementos = async (empresa) => {

    let everythingProductos = {
        productos: [],
        plus: [],
        areasProduccion: [],
        familias: [],
        canales: [],
        complementos: [],
        opciones: [],
        menus: [],
        success: true,
        error: undefined,
    };

    try {
        const respProductos = await productoServices.producto.getAllProductos(empresa, 'TODOS');
        if (respProductos.data.SUCCESS === false) {
            Swal.fire({
                text: 'Productos: ' + respProductos.data.MESSAGE,
                icon: 'error',
            })
            everythingProductos.success = false;
            everythingProductos.error = respProductos.data.MESSAGE;
            return;
        }

        const productos = respProductos.data.MESSAGE;

        const respPlus = await productoServices.plu.getAllPlus(empresa, 'ALTA');
        if (respPlus.data.SUCCESS === false) {
            Swal.fire({
                text: 'Plus: ' + respPlus.data.MESSAGE,
                icon: 'error',
            })
            everythingProductos.success = false;
            everythingProductos.error = respPlus.data.MESSAGE;
            return;
        }

        const plus = respPlus.data.MESSAGE;

        const respFamilias = await productoServices.producto.getAllFamilias(empresa);
        if (respFamilias.data.SUCCESS === false) {
            Swal.fire({
                text: 'Familias: ' + respFamilias.data.MESSAGE,
                icon: 'error',
            })
            everythingProductos.success = false;
            everythingProductos.error = respFamilias.data.MESSAGE;
            return;
        }

        const familias = respFamilias.data.MESSAGE;

        const respAP = await productoServices.producto.getAllAreasProduccion(empresa);
        if (respAP.data.SUCCESS === false) {
            Swal.fire({
                text: 'AreasProduccion: ' + respAP.data.MESSAGE,
                icon: 'error',
            })
            everythingProductos.success = false;
            everythingProductos.error = respAP.data.MESSAGE;
            return;
        }

        const areasProduccion = respAP.data.MESSAGE;

        const respComp = await productoServices.producto.getComplementosSinProducto(empresa);
        if (respComp.data.SUCCESS === false) {
            Swal.fire({
                text: 'Complementos: ' + respComp.data.MESSAGE,
                icon: 'error',
            })
            everythingProductos.success = false;
            everythingProductos.error = respComp.data.MESSAGE;
            return;
        }

        const complementos = respComp.data.MESSAGE;

        const respOp = await productoServices.producto.getAllOpciones(empresa);
        if (respOp.data.SUCCESS === false) {
            Swal.fire({
                text: 'Opciones: ' + respOp.data.MESSAGE,
                icon: 'error',
            })
            everythingProductos.success = false;
            everythingProductos.error = respOp.data.MESSAGE;
            return;
        }

        const opciones = respOp.data.MESSAGE;

        const respCanal = await productoServices.producto.getAllCanales(empresa);
        if (respCanal.data.SUCCESS === false) {
            Swal.fire({
                text: 'Canales: ' + respCanal.data.MESSAGE,
                icon: 'error',
            })
            everythingProductos.success = false;
            everythingProductos.error = respCanal.data.MESSAGE;
            return;
        }

        const canales = respCanal.data.MESSAGE;

        const respMenu = await productoServices.producto.getAllMenus(empresa);
        if (respMenu.data.SUCCESS === false) {
            Swal.fire({
                text: 'Menus: ' + respMenu.data.MESSAGE,
                icon: 'error',
            });
            everythingProductos.success = false;
            everythingProductos.error = respMenu.data.MESSAGE;
            return;
        }

        const menus = respMenu.data.MESSAGE;


        everythingProductos = {
            productos: productos,
            plus: plus,
            areasProduccion: areasProduccion,
            familias: familias,
            canales: canales,
            complementos: complementos,
            opciones: opciones,
            menus: menus,
            success: true,
            error: undefined,
        };

        return everythingProductos;


    } catch (error) {
        console.error(error);
        return {
            success: false,
            error: error,
        }
    }

}

const getProductos = async (empresa) => {
    try {
        const respProductos = await productoServices.producto.getAllProductos(empresa, 'TODOS');
        if (respProductos.data.SUCCESS === false) {
            Swal.fire({
                text: 'Productos: ' + respProductos.data.MESSAGE,
                icon: 'error',
            })
            return;
        }

        const productos = respProductos.data.MESSAGE;
        return productos;

    } catch (error) {
        console.error(error);
    }
}

const getPlus = async (empresa) => {
    try {
        const respPlus = await productoServices.plu.getAllPlus(empresa, 'ALTA');
        if (respPlus.data.SUCCESS === false) {
            Swal.fire({
                text: 'Plus: ' + respPlus.data.MESSAGE,
                icon: 'error',
            })
            return;
        }

        const plus = respPlus.data.MESSAGE;
        return plus;

    } catch (error) {
        console.error(error);
    }
}

const getFamilias = async (empresa) => {
    try {
        const respFamilias = await productoServices.producto.getAllFamilias(empresa);
        if (respFamilias.data.SUCCESS === false) {
            Swal.fire({
                text: 'Familias: ' + respFamilias.data.MESSAGE,
                icon: 'error',
            })
            return;
        }

        const familias = respFamilias.data.MESSAGE;
        return familias;

    } catch (error) {
        console.error(error);
    }
}

const getAreasProduccion = async (empresa) => {
    try {
        const respAP = await productoServices.producto.getAllAreasProduccion(empresa);
        if (respAP.data.SUCCESS === false) {
            Swal.fire({
                text: 'AreasProduccion: ' + respAP.data.MESSAGE,
                icon: 'error',
            })
            return;
        }

        const areasProduccion = respAP.data.MESSAGE;
        return areasProduccion;

    } catch (error) {
        console.error(error);
    }
}

const getComplementos = async (empresa) => {
    try {
        const respComp = await productoServices.producto.getComplementosSinProducto(empresa);
        if (respComp.data.SUCCESS === false) {
            Swal.fire({
                text: 'Complementos: ' + respComp.data.MESSAGE,
                icon: 'error',
            })
            return;
        }

        const complementos = respComp.data.MESSAGE;
        return complementos;

    } catch (error) {
        console.error(error);
    }
}

const getOpciones = async (empresa) => {
    try {
        const respOp = await productoServices.producto.getAllOpciones(empresa);
        if (respOp.data.SUCCESS === false) {
            Swal.fire({
                text: 'Opciones: ' + respOp.data.MESSAGE,
                icon: 'error',
            })
            return;
        }

        const opciones = respOp.data.MESSAGE;
        return opciones;

    } catch (error) {
        console.error(error);
    }
}

const getCanales = async (empresa) => {
    try {
        const respCanal = await productoServices.producto.getAllCanales(empresa);
        if (respCanal.data.SUCCESS === false) {
            Swal.fire({
                text: 'Canales: ' + respCanal.data.MESSAGE,
                icon: 'error',
            })
            return;
        }

        const canales = respCanal.data.MESSAGE;
        return canales;

    } catch (error) {
        console.error(error);
    }
}

const getMenus = async (empresa) => {
    try {
        const respMenu = await productoServices.producto.getAllMenus(empresa);
        if (respMenu.data.SUCCESS === false) {
            Swal.fire({
                text: 'Menus: ' + respMenu.data.MESSAGE,
                icon: 'error',
            });
            return;
        }

        const menus = respMenu.data.MESSAGE;
        return menus;

    } catch (error) {
        console.error(error);
    }
}

export { getAllElementos, getProductos, getPlus, getMenus, getComplementos, getOpciones, getCanales, getFamilias, getAreasProduccion }