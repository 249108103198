import React, { createContext, useReducer, useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid"
import Swal from "sweetalert2";

import { PageError } from "../../PageError";
import { Loader } from "../../Loader"
import { productoServices } from "../../../api/services/productoServices";
import { getAllElementos, getComplementos, getOpciones, getPlus, getProductos } from "./serviciosProductos";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { INITIAL_STATE, productosReducer } from "./productosReducer";

const ProductosContext = createContext();

const ProductosProvider = ({ children }) => {

    const [state, dispatch] = useReducer(productosReducer, INITIAL_STATE);
    const [usuario, guardarUsuario] = useLocalStorage('LOGIN_BETA');

    //const [error, setError] = useState(undefined);

    const valoresLogin = {
        codigoEmpresa: usuario.Tienda.CodigoEmpresa,
        codigoTienda: usuario.Tienda.CodigoTienda,
        codigoCaja: usuario.CorrelativoFiscal.CodigoCaja,
        codigoPuntoDeVenta: 1,
        codigoColaborador: usuario.Colaborador.CodigoColaborador,
        cierreNumero: 1,
        codigoTurno: usuario.Turno.CodigoTurno,
        Nombres: usuario.Colaborador.Nombres,
        Apellidos: usuario.Colaborador.Apellidos,
        EstadoTurno: usuario.Turno.Estado,
        FechaYHora: usuario.Turno.Fecha,
        IntentosFacturacion: usuario.CajaFiscal.IntentosFacturacion,
        EstadoCaja: usuario.EstadoCaja,
        TiempoContingencia: usuario.CajaFiscal.TiempoContingencia,
    };

    useEffect(async () => {

        // Estado inicial de la aplicación, mostrar loader
        dispatch({ type: 'FETCH_START' });

        // Se consumen los servicios de la api para traer toda la información necesaria
        //console.time('CargaDatos')
        const todoProductos = await getAllElementos(valoresLogin.codigoEmpresa);
        //console.timeEnd('CargaDatos')

        // Payload a cargarse en el estado
        let payload = {
            usuario,
            todoProductos,
            loading: true,
            error: undefined,
        }

        //console.log(todoProductos,)

        // Si se cargó toda la información correctamente, se quita el loader y se muestra la pantalla
        if (todoProductos.success === true) {
            payload.loading = false;
            dispatch({ type: 'FETCH_START_SUCCESS', payload });
        }
        else {
            payload.loading = false;
            payload.error = todoProductos.error;
            dispatch({ type: 'FETCH_START_ERROR', payload });
        }
        //console.log('State cargado');

        // Carga de datos en el estado


    }, []);

    const crearListaPlus = (elementoSeleccionado) => {
        //console.log(elementoSeleccionado);
        if (elementoSeleccionado !== '') {
            return [...state.response.productosApi.productos.filter(producto => producto.CodigoProducto.toUpperCase().includes(elementoSeleccionado.toString().toUpperCase()) || producto.Producto.toUpperCase().includes(elementoSeleccionado.toString().toUpperCase())),
            ...state.response.productosApi.opciones.filter(opcion => opcion.CodigoOpcion.toUpperCase().includes(elementoSeleccionado.toString().toUpperCase()) || opcion.Descripcion.toUpperCase().includes(elementoSeleccionado.toString().toUpperCase()))]
        }
        return [...state.response.productosApi.productos, ...state.response.productosApi.opciones];
    }

    /*const separarPluAltaBaja = () => {
        let pluList = crearListaPlus();
        let pluAlta = pluList.filter(item => item.Estado === 'ALTA');
        let pluBaja = pluList.filter(item => item.Estado === 'BAJA');
        return [pluAlta, pluBaja];
    }*/

    let todosPlus = useMemo(() => crearListaPlus(state.elementoSeleccionado.pluSeleccionado));
    //let [plusDeAlta, plusDeBaja] = useMemo(() => separarPluAltaBaja());



    // Función que muestra una alerta para decidir si crear un producto o una opción
    const crearPluProdOp = () => {
        // Alerta Swal
        Swal.fire({
            title: 'Seleccione una',
            icon: 'question',
            text: `¿Desea crear un producto o una opción de complemento?`,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Crear Producto',
            denyButtonText: 'Crear Opción',
            cancelButtonText: 'Cancelar',
            customClass: {
                confirmButton: 'ui orange button',
                denyButton: 'ui yellow button',
                cancelButton: 'ui grey button',
            },
            allowOutsideClick: true,
            backdrop: true,
        }).then((result) => {
            // Si se presiona el botón para Confirm (Crear Producto), se abre el modal para crear productos
            if (result.isConfirmed) {
                dispatch({ type: 'NUMERO_MODAL', payload: 0 });
                // Si se presiona el botón Deny (Crear Opción), se abre el modal para crear opciones
            } else if (result.isDenied) {
                dispatch({ type: 'NUMERO_MODAL', payload: 4 });
            }
        });
    }

    /*******************************************************************************************/
    /**************************** PLUS Y PRODUCTOS *********************************************/
    /*******************************************************************************************/

    // Función asociada al buscador que se encuentra en la pantalla principal, actualiza el listado de PLUs
    // que se muestra dependiendo del valor ingresado en el buscador
    const changeBusquedaTodosPlus = (e) => {
        dispatch({ type: 'ACTUALIZAR_PLU_SELECCIONADO', payload: e.target.value })
    }

    // Función para reiniciar los estados de productos
    const reiniciarEstadosProducto = () => {
        // Para crear, editar y eliminar productos, se utilizan formularios y estados. Para que, al terminar
        // una operación con productos todo quede limpio, se reinician todos los valores pertinentes
        dispatch({ type: 'REINICIAR_ESTADOS_PRODUCTO' });
    };

    // Función para cerrar el modal de creación de productos
    const cerrarModalCrear = async () => {
        // Es necesarior reiniciar todos los formularios y estados para que, al abrir el modal nuevamente,
        // todo esté desde cero
        reiniciarEstadosProducto();
        dispatch({ type: 'CERRAR_MODAL_CREAR' });
    };

    // Handler del cambio de los campos del formulario para plus y productos
    const changeFormPluProducto = (e) => {

        // Al haber un cambio en el CodigoPlu, no se muestra el mensaje de error
        dispatch({ type: 'ERROR_INPUT', payload: undefined });

        // Si el valor que cambió fue el CodigoPlu
        if (e.target.name === 'CodigoPlu') {
            // Se revisa si cumple con la expresión regular: solo letras y números, no más de 8 caracteres
            if (!/^[a-zA-Z0-9_]{0,8}$/.test(e.target.value)) {
                // Se muestra un mensaje de error en el modal indicando que no se está cumpliendo la regla
                // El valor no se actualiza
                dispatch({ type: 'ERROR_INPUT', payload: 'codigoPlu' });
                return;
            }

            // Si cumple, se actualiza el valor
            dispatch({ type: 'ACTUALIZAR_FORMPROD_CODIGO', payload: e.target.value });
        }
        else {
            // Se actualizan los valores que se hayan cambiado
            const payloadForm = {
                name: e.target.name,
                value: e.target.value,
            }
            dispatch({ type: 'ACTUALIZAR_FORM_PRODUCTO', payload: payloadForm })
        }
    }

    // Creación plu y producto
    // El botón de guardar sirve para guardar todo el producto, desde el plu y sus datos hasta los complementos y precios
    const crearPluProducto = async () => {

        // Se necesita agregar las horas en formato "00:00:00" y las fechas en formato "01-01-0001T00:00:00"
        const horadsd = state.formPluProducto.HoraDesde + ':00';
        const horahst = state.formPluProducto.HoraHasta + ':00';
        const fechadsd = state.formPluProducto.FechaDesde + 'T00:00:00';
        const fechahst = state.formPluProducto.FechaHasta + 'T00:00:00';

        // Objeto con toda la información del nuevo PLU
        const nuevoPlu = {
            CodigoEmpresa: valoresLogin.codigoEmpresa,
            CodigoPlu: state.formPluProducto.CodigoPlu,
            AreaProduccion: state.formPluProducto.AreaProduccion,
            CodigoFamilia: state.formPluProducto.CodigoFamilia,
            DescripcionImpresion: state.formPluProducto.DescripcionImpresion,
            DescripcionPantalla: state.formPluProducto.DescripcionPantalla,
            Estado: 'ALTA',
        }

        // Campos y valores del nuevoPlu, para poder saber si uno de los campos está vacío y saber exactamente
        // cuál es
        const valoresPlu = Object.values(nuevoPlu);
        const keysPlu = Object.keys(nuevoPlu);

        // Se busca si hay campos que no tengan información
        for (let k = 0; k < keysPlu.length; k++) {
            if (valoresPlu[k] === '') {
                Swal.fire({
                    title: 'No se puede crear el producto',
                    text: `${keysPlu[k]} no puede ser un valor vacío o ninguno`,
                    icon: 'warning',
                    confirmButtonText: 'Regresar',
                    customClass: {
                        confirmButton: 'ui orange button',
                    }
                });
                return;
            }
        }

        // Complementos agregados en el modal
        const complementosProducto = [...state.productoDetalles.compProducto];

        // Objeto con toda la información del nuevo producto
        const nuevoProducto = {
            CodigoEmpresa: valoresLogin.codigoEmpresa,
            CodigoProducto: state.formPluProducto.CodigoProducto,
            CodigoPlu: state.formPluProducto.CodigoPlu,
            Tutor: state.formPluProducto.CodigoPlu,
            Imagen: '',
            Descripcion: state.formPluProducto.DescripcionProducto,
            Estado: 'ALTA',
            Posicion: 1,
            HoraDesde: horadsd,
            HoraHasta: horahst,
            ValidoDesde: fechadsd,
            ValidoHasta: fechahst,
            listaMenus: state.productoDetalles.menuProducto,
            listaComplementos: complementosProducto,
        }

        // Se busca si hay campos vacíos en el producto
        const valoresProducto = Object.values(nuevoProducto);
        const keysProducto = Object.keys(nuevoProducto);

        for (let j = 0; j < keysProducto.length; j++) {
            if (valoresProducto[j] === '' && keysProducto[j] != 'Imagen') {
                Swal.fire({
                    title: 'No se puede crear el producto',
                    text: `${keysProducto[j]} no puede ser un valor vacío o ninguno`,
                    icon: 'warning',
                    confirmButtonText: 'Regresar',
                    customClass: {
                        confirmButton: 'ui orange button',
                    }
                });
                return;
            }
        }

        // Precios agregados en el modal
        const preciosAux = [...state.productoDetalles.productoPrecios];

        // Se necesita al menos un precio para poder agregar un producto
        if (preciosAux.length === 0) {
            Swal.fire({
                title: 'No se puede crear producto sin precio',
                text: 'Debe agregar al menos un precio al producto para poder crearlo',
                icon: 'warning',
                confirmButtonText: 'Regresar',
                customClass: {
                    confirmButton: 'ui orange button',
                }
            });
            return;
        }

        // Luego de hacer las validaciones, si procede a cerrar el modal y se abre el mini loader
        dispatch({ type: 'CERRAR_MODAL' });
        dispatch({ type: 'NUMERO_MODAL', payload: 2 });

        // Consumo de servicio para agregar Plu
        productoServices.plu.agregarPlu(nuevoPlu)
            .then((response) => {
                if (response.data.SUCCESS === false) { // Si no se pudo crear el Plu
                    dispatch({ type: 'CERRAR_MODAL' });
                    Swal.fire({
                        title: 'No se ha podido agregar el PLU',
                        text: response.data.MESSAGE,
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        customClass: {
                            confirmButton: 'ui orange button'
                        },
                    })
                    return;
                }
                // Si el Plu se pudo crear, se procede a crear el producto (los productos dependen de los Plus)
                else if (response.data.SUCCESS === true) {
                    productoServices.producto.agregarProducto(nuevoProducto)
                        .then((response) => {
                            if (response.data.SUCCESS === false) { // Si no se puede crear el producto
                                dispatch({ type: 'CERRAR_MODAL' });
                                Swal.fire({
                                    title: 'No se ha podido agregar el producto',
                                    text: response.data.MESSAGE,
                                    icon: 'error',
                                    confirmButtonText: 'Cerrar',
                                    customClass: {
                                        confirmButton: 'ui orange button'
                                    },
                                })
                                // Si el producto no se pudo crear, hay que eliminar el nuevo plu creado
                                // para poder utilizarlo de nuevo en otro intento de creación
                                productoServices.plu.eliminarPlu(nuevoPlu);
                                return;
                            }
                            // Si se creó el producto, se procede a aregar sus precios
                            else if (response.data.SUCCESS === true) {
                                productoServices.plu.agregarPrecioPlu(preciosAux)
                                    .then(async (result) => {
                                        // Es posible que se pueda crear el producto pero no los precios
                                        // Esta validación indica al usuario que no se pudieron agregar los precios
                                        if (result.data.SUCCESS === false) {
                                            dispatch({ type: 'CERRAR_MODAL' });
                                            Swal.fire({
                                                title: 'Se ha creado el producto pero no se han actualizado sus precios',
                                                text: result.data.MESSAGE,
                                                icon: 'error',
                                                confirmButtonText: 'Cerrar',
                                                customClass: {
                                                    confirmButton: 'ui orange button'
                                                },
                                            });
                                            // Se obtiene todo el listado de plus, productos y complementos
                                            const plu = await getPlus(valoresLogin.codigoEmpresa);
                                            const prod = await getProductos(valoresLogin.codigoEmpresa);
                                            const comp = await getComplementos(valoresLogin.codigoEmpresa);

                                            const payload = {
                                                plu,
                                                prod,
                                                comp,
                                            }

                                            // Se actualiza el estado general y se reinician los estados
                                            dispatch({ type: 'CREAR_PLUPRODUCTO', payload })
                                            reiniciarEstadosProducto();
                                        }
                                        // Hubo éxito en la creación de plu, producto y precios
                                        else {
                                            dispatch({ type: 'CERRAR_MODAL' });
                                            Swal.fire({
                                                title: 'PLU creado con éxito',
                                                text: 'Se han agregado los precios correspondientes',
                                                icon: 'success',
                                                confirmButtonText: 'Cerrar',
                                                customClass: {
                                                    confirmButton: 'ui orange button'
                                                },
                                            });

                                            // Actualización del estado
                                            const plu = await getPlus(valoresLogin.codigoEmpresa);
                                            const prod = await getProductos(valoresLogin.codigoEmpresa);
                                            const comp = await getComplementos(valoresLogin.codigoEmpresa);

                                            const payload = {
                                                plu,
                                                prod,
                                                comp,
                                            }

                                            dispatch({ type: 'CREAR_PLUPRODUCTO', payload })
                                            reiniciarEstadosProducto();

                                        }
                                    }, (error) => {
                                        console.error(error);
                                    })
                            }
                        }, (error) => {
                            console.error(error);
                        }
                        )
                }
            }, (error) => {
                console.error(error);
            }
            )
    }

    // Función para abrir el modal de edición de productos, con los datos ya cargados del producto a editar
    const abrirModalEditar = (producto) => {
        dispatch({ type: 'NUMERO_MODAL', payload: 1 });

        let preciosAux = [...producto.ListaPrecios]; // Array de los precios del producto a editar
        let preciosTransformados = []; // Array para almacenar los precios transformados

        // No se necesita la hora de la fecha para los precios, por eso se elimina ese pedazo del string
        // de fechas de cada uno de los precios
        preciosAux.forEach(element => {
            const fechaIn = element.FechaInicio.split('T')[0];
            const fechaF = element.FechaFin.split('T')[0];

            element.FechaInicio = fechaIn;
            element.FechaFin = fechaF;
            element.idUnico = uuidv4(); // Se agrega un Id único a cada precio

        });

        // Para mostrar los precios en el calendario, se deben "transformar" a eventos que el calendario acepte
        preciosAux.forEach(element => {
            let precioTransformado = transformarArray(element); // Se transforma el precio
            preciosTransformados.push(...precioTransformado); // Se agrega al array de precios transformados
        });

        // Objeto para almacenar los precios que se muestran en la agenda
        // El objeto tiene un array por día de la semana
        const preciosAg = {
            Lunes: [],
            Martes: [],
            Miercoles: [],
            Jueves: [],
            Viernes: [],
            Sabado: [],
            Domingo: [],
        }

        // Se agregan los precios a preciosAg
        preciosAux.forEach(element => {
            // Los días en los que se encuentra disponible un precio están guardados como un string de números
            // Cada número indica un día: 1 -> lunes, 2 -> martes, ... 7 -> domingo. Un precio con el string
            // '1357' está disponible lunes, miércoles, viernes y domingo
            // Para agregar el precio a cada array del objeto que lo requiere, se usa un ciclo for de 1 a 7
            for (let j = 1; j < 8; j++) {
                // Si el string de días incluye el día actual de la iteración
                if (element.DiasSemana.includes(j)) {
                    // Se obtiene la descripción del canal utilizando el código de canal del precio
                    element.DescripcionCanal = state.response.productosApi.canales.filter(can => can.CodigoCanal === element.CodigoCanal)[0].Descripcion;
                    // Se agrega a el día actual de la iteración el precio
                    // El array de valores empieza en 0, por eso el índice tiene que ser j-1
                    Object.values(preciosAg)[j - 1].push(element);
                }
            }
        })

        // Lista de complementos existentes
        let listadoComplementos = [...state.response.productosApi.complementoList];

        // Array auxiliar para almacenar complementos
        // Cuál es el punto de cargar el valor de productoDetalles.compProducto en la variable, si siempre al
        // abrir el modal va a estar vacío
        let compAux = [];

        // Por cada complemento del producto
        producto.Complementos.forEach(element => {
            compAux.push(element); // Se agrega el complemento a la variable auxiliar
            // Al agregar complementos al producto, siempre se muestra el listado de complementos existente para
            // poder agregarlos al producto
            // Debido a que, al editar un producto, éste ya puede tener complementos. Se deben eliminar los 
            // complementos ya agregados del listado general, para que no se puedan agregar otra vez
            listadoComplementos = listadoComplementos.filter(comp => comp.CodigoComplemento !== element.CodigoComplemento);
        });

        // Al igual que con las fechas de los precios, no es necesaria la parte horaria de la fecha para los 
        // productos
        const validoDsd = producto.ValidoDesde.split('T')[0];
        const validoHst = producto.ValidoHasta.split('T')[0];

        const payload = {
            producto,
            validoDsd,
            validoHst,
            preciosAg,
            preciosTransformados,
            compAux,
            preciosAux,
            listadoComplementos,
        }

        // Se abre el modal con los estados cargados
        dispatch({ type: 'ABRIR_MODAL_EDITAR', payload });
    }

    // Handler para formulario de edición de productos
    const changeEditProducto = (e) => {

        const payload = {
            name: e.target.name,
            value: e.target.value,
        }

        dispatch({ type: 'ACTUALIZAR_FORM_EDIT_PRODUCTO', payload })
    }

    // Guardar los cambios del producto editado en BD
    const guardarProductoEditado = async () => {

        // Precios ingresados en el modal
        const preciosAux = [...state.productoDetalles.productoPrecios];

        // Si no hay precios, no se puede editar el producto
        if (preciosAux.length === 0) {
            Swal.fire({
                title: 'No se puede actualizar el producto',
                text: 'Debe agregar al menos un precio al producto para poder actualizarlo',
                icon: 'warning',
                confirmButtonText: 'Regresar',
                customClass: {
                    confirmButton: 'ui orange button',
                }
            });
            return;
        }

        // Complementos y menús en el modal
        let complementosProducto = [...state.productoDetalles.compProducto];
        let menusProducto = [...state.productoDetalles.menuProducto];

        //console.log(state.formEditarProducto);

        const codigoPluProducto = (state.formEditarProducto.CodigoPlu === undefined) ? state.formEditarProducto.CodigoProducto : state.formEditarProducto.CodigoPlu;

        // Objeto con toda la información del producto editado
        const productoEditado = {
            CodigoEmpresa: valoresLogin.codigoEmpresa,
            CodigoProducto: state.formEditarProducto.CodigoProducto,
            //CodigoPlu: state.formEditarProducto.CodigoPlu,
            CodigoPlu: codigoPluProducto,
            Descripcion: state.formEditarProducto.DescripcionProducto,
            HoraDesde: state.formEditarProducto.HoraDesde,
            HoraHasta: state.formEditarProducto.HoraHasta,
            Estado: state.formEditarProducto.Estado,
            Imagen: '',
            Posicion: state.formEditarProducto.Posicion,
            Tutor: state.formEditarProducto.Tutor,
            ValidoDesde: state.formEditarProducto.FechaDesde,
            ValidoHasta: state.formEditarProducto.FechaHasta,
            listaComplementos: complementosProducto,
            listaMenus: menusProducto,
        }

        //console.log(productoEditado);

        // Llaves y valores del objeto 
        const valoresProducto = Object.values(productoEditado);
        const keysProducto = Object.keys(productoEditado);

        // Si hay un valor/campo que este vacío, no se permite editar el producto
        for (let j = 0; j < keysProducto.length; j++) {
            if (valoresProducto[j] === '' && keysProducto[j] != 'Imagen') {
                Swal.fire({
                    title: 'No se puede editar el producto',
                    text: `${keysProducto[j]} no puede ser un valor vacío o ninguno`,
                    icon: 'warning',
                    confirmButtonText: 'Regresar',
                    customClass: {
                        confirmButton: 'ui orange button',
                    }
                });
                return;
            }
        }

        // Se cierra el modal y se muestra el mini loader
        dispatch({ type: 'CERRAR_MODAL' });
        dispatch({ type: 'NUMERO_MODAL', payload: 2 });

        // Se consume el servicio para editar productos
        productoServices.producto.editarProducto(productoEditado)
            .then((result) => {
                if (result.data.SUCCESS === true) {
                    // Si el producto se pudo editar, se procede a editar los precios (a veces no cambian)
                    productoServices.plu.agregarPrecioPlu(preciosAux)
                        .then(async (result) => {
                            if (result.data.SUCCESS === true) {
                                // Si los precios se actualizaron con éxito, se actualiza el estado de la pantalla
                                dispatch({ type: 'CERRAR_MODAL' });
                                Swal.fire({
                                    title: 'Producto y precios actualizados con éxito',
                                    icon: 'success',
                                    customClass: {
                                        confirmButton: 'ui orange button'
                                    }
                                })
                                const prod = await getProductos(valoresLogin.codigoEmpresa);
                                // Durante el proceso de añadir complementos, se pueden crear nuevos
                                // Por eso es importante actualizar los complementos también
                                const comp = await getComplementos(valoresLogin.codigoEmpresa);

                                const payload = {
                                    prod,
                                    comp,
                                }
                                dispatch({ type: 'EDITAR_PRODUCTO', payload });

                                reiniciarEstadosProducto();
                            }
                            else {
                                dispatch({ type: 'CERRAR_MODAL' });
                                Swal.fire({
                                    title: 'No se actualizó el producto',
                                    text: result.data.MESSAGE,
                                    icon: 'error',
                                    customClass: {
                                        confirmButton: 'ui orange button'
                                    }
                                })
                            }

                        }, (error) => {
                            console.error(error);
                        })
                }
                else {
                    dispatch({ type: 'CERRAR_MODAL' });
                    Swal.fire({
                        title: 'No se actualizó el producto',
                        text: result.data.MESSAGE,
                        icon: 'error',
                        customClass: {
                            confirmButton: 'ui orange button'
                        }
                    })
                }
            }, (error) => {
                console.error(error);
            })
    }

    // Función para dar de baja un producto, no se elimina de la BD
    const eliminarProducto = async (producto) => {
        // Objeto con la información del producto a dar de baja
        const productoBaja = {
            CodigoEmpresa: valoresLogin.codigoEmpresa,
            CodigoProducto: producto.CodigoProducto,
            CodigoPlu: producto.CodigoPlu,
            Descripcion: producto.Producto,
            HoraDesde: producto.HoraDesde,
            HoraHasta: producto.HoraHasta,
            Estado: 'BAJA',
            Imagen: '',
            Posicion: producto.Posicion,
            Tutor: producto.Tutor,
            ValidoDesde: producto.FechaDesde,
            ValidoHasta: producto.FechaHasta,
            listaComplementos: producto.Complementos,
            listaMenus: producto.ListaMenus,
        }

        // Alerta para confirmar que el usuario quiere dar de baja el producto
        Swal.fire({
            title: 'Dar producto de baja',
            icon: 'question',
            text: `¿Desea dar de baja el producto ${productoBaja.CodigoProducto}?`,
            showCancelButton: true,
            confirmButtonText: 'Dar de baja',
            cancelButtonText: 'Regresar',
            customClass: {
                confirmButton: 'ui orange button',
            },
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                // Se consume el servicio para editar producto, cambiando únicamente el estado a BAJA
                productoServices.producto.editarProducto(productoBaja)
                    .then(async (result) => {
                        if (result.data.SUCCESS === true) {
                            Swal.fire({
                                title: 'Producto dado de baja con éxito.',
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'ui orange button',
                                }
                            });
                            const prod = await getProductos(valoresLogin.codigoEmpresa);
                            dispatch({ type: 'ELIMINAR_PRODUCTO', payload: prod });
                        }
                        else {
                            Swal.fire({
                                title: 'No se ha podido dar de baja el producto.',
                                text: result.data.MESSAGE,
                                icon: 'success',
                            });
                        }
                    }, (error) => {
                        console.error(error);
                    })
            }
        });
    }

    /*************************************************************************************************** */


    /*******************************************************************************************/
    /******************************** COMPLEMENTOS *********************************************/
    /*******************************************************************************************/

    // Handler de busqueda de complementos
    const changeBusquedaComplemento = (e) => {

        // Actualización del valor del buscador
        dispatch({ type: 'ACTUALIZAR_COMP_SELECCIONADO', payload: e.target.value });

        // Si el buscador está vacío, se colocan todos los complementos existentes en la lista
        if (e.target.value === '') {
            dispatch({ type: 'ACTUALIZAR_COMPLEMENTOS', payload: state.response.productosApi.complementoList });
        }
        else {
            let listaAux = []; // Lista para almacenar los matches
            let listaTrues = []; // Lista pra cambiar estado de la tab de complementos

            // Se buscan los complementos que hagan match con el valor del buscador, buscando en su 
            // descripción o código
            state.response.productosApi.complementoList.forEach(element => {
                if (element.Descripcion.toString().toUpperCase().includes(e.target.value.toUpperCase())) {
                    // Se agrega el elemento a la lista auxiliar
                    listaAux.push(element)
                    // Se agrega un 'si' a la lista de verdaderos, para poder afectar el estado de la tab
                    listaTrues.push('si');
                }
                else if (element.CodigoComplemento.toString().toUpperCase().includes(e.target.value.toUpperCase())) {
                    listaAux.push(element)
                    listaTrues.push('si');
                }
                // Se añade un 'no' si el complemento no hace match
                else listaTrues.push('no');
            });

            // Si listaTrues incluye al menos un 'si', quiere decir que hay al menos un match, por lo que la tab
            // se debe quedar en 'buscando', que es el estado que muestra el listado de complementos disponible
            // Cuando el valor del buscador es vacío, el estado debe ser 'buscando', por eso es necesario validar
            // que su valor no sea vacío
            if (listaTrues.includes('si') && e.target.value !== '') {
                dispatch({ type: 'ACTUALIZAR_ESTADO_COMP', payload: 'buscando' });
            }
            // Si no hay matches, se muestra el estado 'no-encontrado'
            else dispatch({ type: 'ACTUALIZAR_ESTADO_COMP', payload: 'no-encontrado' });

            // Se actualiza el listado de complementos
            dispatch({ type: 'ACTUALIZAR_COMPLEMENTOS', payload: listaAux });
        }
    }

    // Botón para mostrar los detalles de un complemento
    const mostrarDetallesComp = (codigoComplemento) => {
        // Se obtiene el complemento a mostrar del listado general de complementos
        const complementoNuevo = state.response.productosApi.complementoList.filter(comp => comp.CodigoComplemento === codigoComplemento)[0];
        // Se muestran los detalles del complemento en lugar del listado
        dispatch({ type: 'ACTUALIZAR_DETALLE_COMP', payload: complementoNuevo });
        dispatch({ type: 'ACTUALIZAR_ESTADO_COMP', payload: 'detalles' });
    }

    // Botón para seleccionar un complemento del listado
    const seleccionarComplemento = (codigoComplemento) => {
        // Se selecciona el complemento completo a agregar
        const complementoNuevo = state.response.productosApi.complementoList.filter(comp => comp.CodigoComplemento === codigoComplemento)[0];

        // Se agrega a los complementos del producto
        dispatch({ type: 'ACTUALIZAR_COMP_PRODUCTO', payload: [...state.productoDetalles.compProducto, complementoNuevo] })

        // Se elimina el complemento agregado del listado de complementos disponible
        const nuevoListado = state.response.productosApi.complementoList.filter(comp => comp.CodigoComplemento !== codigoComplemento);

        const payload = {
            comps: nuevoListado,
            list: nuevoListado,
        }

        dispatch({ type: 'ACTUALIZAR_COMP_LIST', payload });
        dispatch({ type: 'ACTUALIZAR_ESTADO_COMP', payload: 'buscando' });
        dispatch({ type: 'ACTUALIZAR_COMP_SELECCIONADO', payload: '' });

    }

    // Función para mostrar el formulario de creación de complemento
    const agregarComplemento = () => {
        dispatch({ type: 'ACTUALIZAR_ESTADO_COMP', payload: 'crear' });
    }

    // Handler del formulario de complementos
    const changeFormComplemento = (e) => {

        const payload = {
            name: e.target.name,
            value: e.target.value,
        }

        dispatch({ type: 'ACTUALIZAR_FORM_COMP', payload });
    }

    // Cerrar el formulario para crear complemento y volver a mostrar el listado de disponibles
    const cerrarFormComplemento = () => {
        dispatch({ type: 'ACTUALIZAR_ESTADO_COMP', payload: 'buscando' });
        dispatch({ type: 'ACTUALIZAR_COMP_SELECCIONADO', payload: '' });
        dispatch({ type: 'ACTUALIZAR_COMPLEMENTOS', payload: state.response.productosApi.complementoList });
    }

    // Crear un complemento y agregarlo directamente a la base de datos
    const agregarCrearComplemento = async () => {

        // Objeto con información del complemento a crear
        let complementoCrear = {
            CodigoEmpresa: valoresLogin.codigoEmpresa,
            CodigoComplemento: state.formComplemento.CodigoComplemento,
            Descripcion: state.formComplemento.Descripcion,
            Posicion: state.formComplemento.Posicion,
            CantidadPorDefecto: state.formComplemento.CantidadDefecto,
            CantidadMaxima: state.formComplemento.CantidadMaxima,
            CantidadMinima: state.formComplemento.CantidadMinima,
            Estado: 'ALTA',
        }

        // Formulario complemento auxiliar
        let formAux = { ...state.formComplemento };

        // Valores actuales del formulario
        const valoresForm = Object.values(formAux);

        let vacio = false;

        // Se revisa si alguno de los campos está vacío
        for (let i = 0; i < valoresForm.length; i++) {
            if (valoresForm[i] === '') {
                vacio = true;
                break;
            }
        }

        // Si hay campos vacíos, no se permite la creación del complemento
        if (vacio === true) {
            Swal.fire({
                title: 'Formulario incompleto',
                text: 'Debe completar el formulario antes de crear el complemento',
                icon: 'error',
                customClass: {
                    confirmButton: 'ui orange button',
                }
            });
            return;
        }

        // Consumo de servicio para crear complemento
        productoServices.producto.agregarComplemento(complementoCrear)
            .then(async (response) => {
                if (response.data.SUCCESS === false) {
                    Swal.fire({
                        title: 'No se ha podido agregar el complemento',
                        text: JSON.stringify(response.data.MESSAGE),
                        icon: 'error',
                        customClass: {
                            confirmButton: 'ui orange button',
                        }
                    });
                    return;
                }
                else {
                    // Se crea el complemento y se añade directamente al lista de complementos disponibles
                    dispatch({ type: 'ACTUALIZAR_COMP_PRODUCTO', payload: [...state.productoDetalles.compProducto, complementoCrear] });
                    Swal.fire({
                        title: 'Complemento agregado con éxito',
                        icon: 'success',
                        customClass: {
                            confirmButton: 'ui orange button',
                        }
                    })
                    const comp = await getComplementos(valoresLogin.codigoEmpresa);
                    //dispatch({ type: 'ACTUALIZAR_COMPLEMENTOS', payload: comp });
                    dispatch({ type: 'AGREGAR_NUEVO_COMPLEMENTO', payload: comp });
                    reiniciarEstadosComplemento();
                }
            })
    }

    // Eliminar complemento de la tabla de complementos de un producto
    const eliminarComplemento = (complemento) => {
        // Complementos actualmente agregados al producto
        const comps = [...state.productoDetalles.compProducto];

        // Se elimina el complemento deseado del listado de agregados
        const nuevosComplementos = comps.filter(comp => comp.CodigoComplemento !== complemento.CodigoComplemento);
        dispatch({ type: 'ACTUALIZAR_COMP_PRODUCTO', payload: nuevosComplementos });

        // El complemento eliminado de la tabla, puede regresar al listado de disponibles, por si se quiere
        // agregar en el futuro
        const varAuxComp = [...state.response.productosApi.complementos, complemento];
        const varAuxList = [...state.response.productosApi.complementoList, complemento];

        const payload = {
            comps: varAuxComp,
            list: varAuxList,
        }

        dispatch({ type: 'ACTUALIZAR_COMP_LIST', payload })
    }

    // Guardar la edición de un complemento en BD
    const guardarComplementoEditado = async () => {

        // Array para guardar todas las opciones del complemento con el formato de objetoOpcion
        let opcionesArray = [];

        // Se agregan todas las opciones de la tabla con el formato deseado al array
        state.compDetalle.Opciones.forEach(element => {
            const objetoOpcion = {
                CodigoEmpresa: valoresLogin.codigoEmpresa,
                CodigoComplemento: state.compDetalle.CodigoComplemento,
                CodigoOpcion: element.CodigoOpcion,
            }
            opcionesArray.push(objetoOpcion);
        });

        // Objeto con la información del complemento editado
        const complementoEditado = {
            CodigoEmpresa: valoresLogin.codigoEmpresa,
            CodigoComplemento: state.formComplemento.CodigoComplemento,
            Descripcion: state.formComplemento.Descripcion,
            Posicion: state.formComplemento.Posicion,
            CantidadPorDefecto: state.formComplemento.CantidadDefecto,
            CantidadMaxima: state.formComplemento.CantidadMaxima,
            CantidadMinima: state.formComplemento.CantidadMinima,
            Estado: 'ALTA',
            Opciones: opcionesArray,
        }

        // Alerta para confirmar que el usuario quiere editar el complemento
        Swal.fire({
            title: 'Editar complemento',
            text: `¿Desea editar el complemento ${state.formComplemento.CodigoComplemento}?`,
            showDenyButton: true,
            confirmButtonText: 'Editar',
            denyButtonText: 'Regresar',
            customClass: {
                confirmButton: 'ui orange button',
                denyButton: 'ui grey button',
            },
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                // Consumo de servicio para editar complemento
                productoServices.producto.editarComplemento(complementoEditado)
                    .then(async (response) => {
                        if (response.data.SUCCESS === false) {
                            Swal.fire({
                                title: 'No se pudo editar el complemento',
                                text: response.data.MESSAGE,
                                icon: 'error',
                                customClass: {
                                    confirmButton: 'ui orange button',
                                }
                            })
                            return;
                        }
                        else {
                            // Se actualizan los estados
                            Swal.fire({
                                title: 'Complemento editado con éxito',
                                text: response.data.MESSAGE,
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'ui orange button',
                                }
                            })
                            dispatch({ type: 'CERRAR_MODAL' });
                            const comp = await getComplementos(valoresLogin.codigoEmpresa);
                            const opc = await getOpciones(valoresLogin.codigoEmpresa);

                            const payload = {
                                comp,
                                opc,
                            }

                            dispatch({ type: 'ACTUALIZAR_COMP_OPC', payload });
                            reiniciarEstadosComplemento();
                        }
                    });
            }
            else if (result.isDenied) return;
        });
    }

    // Esta función cierra el formulario de edición de complementos y regresa al listado de complementos
    const cancelarEdicionComplemento = () => {
        reiniciarEstadosComplemento();
        dispatch({ type: 'ACTUALIZAR_COMPLEMENTOS', payload: state.response.productosApi.complementoList });

    }

    // Reinicio de los estados que se usan en los complementos
    const reiniciarEstadosComplemento = () => {
        dispatch({ type: 'REINICIAR_ESTADO_COMPLEMENTO' });
    }

    // Cerrar el modal de complementos y reiniciar estados
    const cerrarModalComplemento = () => {
        reiniciarEstadosComplemento();

        dispatch({ type: 'ACTUALIZAR_COMPLEMENTOS', payload: state.response.productosApi.complementoList });
        dispatch({ type: 'ACTUALIZAR_COMP_PRODUCTO', payload: [] });
        dispatch({ type: 'CERRAR_MODAL' });
    }

    // Función para pasar del detalle de complementos a la tab de edición
    const editarComplementoExistente = (objComp) => {

        // Listado de opciones disponbile
        let nuevoListado = [...state.response.productosApi.opciones];

        // Se eliminan del listado todas las opciones que pertenzcan al complemento actualmente
        state.compDetalle.Opciones.forEach(element => {
            nuevoListado = nuevoListado.filter(op => op.CodigoOpcion !== element.CodigoOpcion);
        });

        const payload = {
            nuevoListado,
            objComp,
        }

        dispatch({ type: 'EDITAR_COMP_EXISTENTE', payload });
    }


    /*******************************************************************************************/
    /************************************ OPCIONES *********************************************/
    /*******************************************************************************************/

    // Esta función va del lado de complementos y opciones porque es para agregar una opción
    // al listado de opciones de un complemento
    const agregarOpcionTabla = (codigoOpcion) => {

        // Usando el código de opción del parámetro, se busca la opción completa en el listado de opciones
        const opcionNueva = state.response.productosApi.opcionList.filter(op => op.CodigoOpcion === codigoOpcion)[0];

        // Se carga a compDetalle
        dispatch({ type: 'OPCIONES_COMP_DETALLE', payload: [...state.compDetalle.Opciones, opcionNueva] });

        // Se elimina la opción agregada a la tabla del listado de disponibles
        const nuevoListado = state.response.productosApi.opcionList.filter(op => op.CodigoOpcion !== codigoOpcion);

        const payload = {
            opc: nuevoListado,
            list: nuevoListado,
        }
        dispatch({ type: 'ACTUALIZAR_LISTA_OPCIONES', payload })

        dispatch({ type: 'ACTUALIZAR_OPCION_SELECCIONADA', payload: '' });
        dispatch({ type: 'ACTUALIZAR_ESTADO_OPCION', payload: 'buscando' });

    }

    // Eliminar opciones de la tabla de asociación con complementos
    const eliminarOpcionTabla = (opcion) => {

        // Opciones actualmente agreagadas al complemento
        const ops = [...state.compDetalle.Opciones];

        // Se elimina la opción del listado de agregadas
        const nuevasOpciones = ops.filter(op => op.CodigoOpcion !== opcion.CodigoOpcion);

        dispatch({ type: 'OPCIONES_COMP_DETALLE', payload: [...nuevasOpciones] })

        // Se añade la opción eliminada al listado de disponibles
        const varAuxOp = [...state.response.productosApi.opciones, opcion];
        const varAuxList = [...state.response.productosApi.opcionList, opcion];

        const payload = {
            opc: varAuxOp,
            list: varAuxList,
        }

        dispatch({ type: 'ACTUALIZAR_LISTA_OPCIONES', payload });
    }

    // Buscador de opciones
    const changeBusquedaOpcion = (e) => {

        // Se actualiza el valor del buscador
        dispatch({ type: 'ACTUALIZAR_OPCION_SELECCIONADA', payload: e.target.value });

        // Si el buscador está vacío, se regresa al listado original de opciones
        if (e.target.value === '') {
            dispatch({ type: 'ACTUALIZAR_OPCIONES', payload: state.response.productosApi.opcionList });
        }
        else {
            // Se utiliza el mismo concepto que el buscador de complementos
            let listaAux = [];
            let listaTrues = [];

            state.response.productosApi.opcionList.forEach(element => {
                if (element.Descripcion.toString().toUpperCase().includes(e.target.value.toUpperCase())) {
                    listaAux.push(element)
                    listaTrues.push('si');
                }
                else if (element.CodigoOpcion.toString().toUpperCase().includes(e.target.value.toUpperCase())) {
                    listaAux.push(element)
                    listaTrues.push('si');
                }
                else listaTrues.push('no');
            });

            if (listaTrues.includes('si') && e.target.value !== '') {
                dispatch({ type: 'ACTUALIZAR_ESTADO_OPCION', payload: 'buscando' });
            }
            else dispatch({ type: 'ACTUALIZAR_ESTADO_OPCION', payload: 'no-encontrado' });

            dispatch({ type: 'ACTUALIZAR_OPCIONES', payload: listaAux });
        }
    }

    // Handlder del formulario de opciones
    const changeFormOpcion = (e) => {

        const payload = {
            name: e.target.name,
            value: e.target.value,
        }

        dispatch({ type: 'ACTUALIZAR_FORM_OPCION', payload })

        // ESTO SOLO SE DEBE VALIDAR AL TRATAR DE INGRESAR LA OPCION
        /*let formAux = { ...state.formOpcion };

        formAux = {
            ...formAux,
            [e.target.name]: e.target.value
        }

        const valoresForm = Object.values(formAux);

        let vacio = false;

        for (let i = 0; i < valoresForm.length; i++) {
            if (valoresForm[i] === '') {
                vacio = true;
                break;
            }
        }

        if (state.estados.estadoOpcion !== 'editar') {
            if (vacio !== true) {
                dispatch({ type: 'ACTUALIZAR_ESTADO_OPCION', payload: 'form-lleno' })
            }
        }*/
    }

    // Función para cerrar el modal y reiniciar estados
    const cerrarModalOpcion = () => {
        reiniciarEstadosOpcion();
        dispatch({ type: 'CERRAR_MODAL' });
    }

    // Crear opción y su respectivo PLU
    const crearPluOpcion = () => {

        // Objeto con la información del nuevo Plu
        const nuevoPlu = {
            CodigoEmpresa: valoresLogin.codigoEmpresa,
            CodigoPlu: state.formPluProducto.CodigoPlu,
            AreaProduccion: state.formPluProducto.AreaProduccion,
            CodigoFamilia: state.formPluProducto.CodigoFamilia,
            DescripcionImpresion: state.formPluProducto.DescripcionImpresion,
            DescripcionPantalla: state.formPluProducto.DescripcionPantalla,
            Estado: 'ALTA',
        }

        // Complementos agregados en el modal
        const complementosProducto = [...state.productoDetalles.compProducto];

        // Objeto con la información de la nueva opción
        const nuevaOpcion = {
            CodigoEmpresa: valoresLogin.codigoEmpresa,
            CodigoOpcion: state.formPluProducto.CodigoPlu,
            Descripcion: state.formOpcion.Descripcion,
            CantidadPorDefecto: state.formOpcion.CantidadDefecto,
            CantidadMinima: state.formOpcion.CantidadMinima,
            CantidadMaxima: state.formOpcion.CantidadMaxima,
            CodigoPlu: state.formPluProducto.CodigoPlu,
            Posicion: state.formOpcion.Posicion,
            ReportarPrecio: state.formOpcion.ReportarPrecio,
            Estado: "ALTA",
            Complementos: complementosProducto,
        }

        // Array auxiliar de precios
        const preciosAux = [...state.productoDetalles.productoPrecios];

        // Las opciones pueden no tener precios asociados. Esto lo define un campo del formulario
        // Si se requiere que reporte precio, se debe verificar que se hayan agregado precios
        if (state.formOpcion.ReportarPrecio === 1) {
            if (preciosAux.length === 0) {
                Swal.fire({
                    title: 'No se puede crear producto sin precio',
                    text: 'Debe agregar al menos un precio al producto para poder crearlo',
                    icon: 'warning',
                    confirmButtonText: 'Regresar',
                    customClass: {
                        confirmButton: 'ui orange button',
                    }
                });
                return;
            }
        }

        // Se cierra el modal y se muestra el loader
        dispatch({ type: 'CERRAR_MODAL' });
        dispatch({ type: 'NUMERO_MODAL', payload: 2 });

        // Consumo de servicio para crear plus
        productoServices.plu.agregarPlu(nuevoPlu)
            .then((response) => {
                if (response.data.SUCCESS === false) {
                    dispatch({ type: 'CERRAR_MODAL' });
                    Swal.fire({
                        title: 'No se ha podido agregar el PLU',
                        text: response.data.MESSAGE,
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        customClass: {
                            confirmButton: 'ui orange button'
                        },
                    })
                    return;
                }
                else if (response.data.SUCCESS === true) {
                    // Si se pudo crear el Plu, se procede a crear la opción
                    productoServices.producto.agregarOpcion(nuevaOpcion)
                        .then(async (response) => {
                            if (response.data.SUCCESS === false) {
                                dispatch({ type: 'CERRAR_MODAL' });
                                Swal.fire({
                                    title: 'No se ha podido agregar la opción',
                                    text: JSON.stringify(response.data.MESSAGE),
                                    icon: 'error',
                                    confirmButtonText: 'Cerrar',
                                    customClass: {
                                        confirmButton: 'ui orange button'
                                    },
                                })
                                // Si la opción no se pudo crear, hay que eliminar el nuevo plu creado
                                // para poder utilizarlo de nuevo en otro intento de creación
                                productoServices.plu.eliminarPlu(nuevoPlu);
                                return;
                            }
                            else if (response.data.SUCCESS === true) {
                                // Primero hay que verificar si la opción reporta precio, si no, no tiene sentido 
                                // que se publique el array de precios vacío
                                if (state.formOpcion.ReportarPrecio == 1) {
                                    productoServices.plu.agregarPrecioPlu(preciosAux)
                                        .then(async (result) => {
                                            if (result.data.SUCCESS === false) {
                                                dispatch({ type: 'CERRAR_MODAL' });
                                                Swal.fire({
                                                    title: 'Se ha creado el PLU pero no se han actualizado sus precios',
                                                    text: result.data.MESSAGE,
                                                    icon: 'error',
                                                    confirmButtonText: 'Cerrar',
                                                    customClass: {
                                                        confirmButton: 'ui orange button'
                                                    },
                                                });

                                                const plu = await getPlus(valoresLogin.codigoEmpresa);
                                                const opc = await getOpciones(valoresLogin.codigoEmpresa);
                                                const comp = await getComplementos(valoresLogin.codigoEmpresa);

                                                const payload = {
                                                    plu,
                                                    opc,
                                                    comp,
                                                }

                                                dispatch({ type: 'CREAR_OPCION', payload });
                                                reiniciarEstadosOpcion();
                                            }
                                            else {
                                                dispatch({ type: 'CERRAR_MODAL' });
                                                Swal.fire({
                                                    title: 'PLU creado con éxito',
                                                    text: 'Se han agregado los precios correspondientes',
                                                    icon: 'success',
                                                    confirmButtonText: 'Cerrar',
                                                    customClass: {
                                                        confirmButton: 'ui orange button'
                                                    },
                                                });
                                                const plu = await getPlus(valoresLogin.codigoEmpresa);
                                                const opc = await getOpciones(valoresLogin.codigoEmpresa);
                                                const comp = await getComplementos(valoresLogin.codigoEmpresa);

                                                const payload = {
                                                    plu,
                                                    opc,
                                                    comp,
                                                }

                                                dispatch({ type: 'CREAR_OPCION', payload });
                                                reiniciarEstadosOpcion();
                                            }
                                        }, (error) => {
                                            console.error(error);
                                        })
                                }
                                else { // La opción no reporta precio, no hay que llamar al servicio
                                    dispatch({ type: 'CERRAR_MODAL' });
                                    Swal.fire({
                                        title: 'PLU creado con éxito',
                                        text: 'PLU creado sin precios asignados',
                                        icon: 'success',
                                        confirmButtonText: 'Cerrar',
                                        customClass: {
                                            confirmButton: 'ui orange button'
                                        },
                                    });
                                    const plu = await getPlus(valoresLogin.codigoEmpresa);
                                    const opc = await getOpciones(valoresLogin.codigoEmpresa);
                                    const comp = await getComplementos(valoresLogin.codigoEmpresa);
                                    const payload = {
                                        plu,
                                        opc,
                                        comp,
                                    }

                                    dispatch({ type: 'CREAR_OPCION', payload });
                                    reiniciarEstadosOpcion();
                                }
                            }
                        }, (error) => {
                            console.error(error);
                        }
                        )
                }
            }, (error) => {
                console.error(error);
            }
            )
    }

    // Función para reinicar todos los estados de opciones
    const reiniciarEstadosOpcion = () => {
        dispatch({ type: 'REINICIAR_ESTADO_OPCION' });
    }

    // Función para abrir el modal para editar opción y cargar toda la información
    const abrirModalEditarOpcion = (opcion) => {

        dispatch({ type: 'NUMERO_MODAL', payload: 4 });

        let preciosAux = [...opcion.ListaPrecios];
        let preciosTransformados = [];

        preciosAux.forEach(element => {
            const fechaIn = element.FechaInicio.split('T')[0];
            const fechaF = element.FechaFin.split('T')[0];

            element.FechaInicio = fechaIn;
            element.FechaFin = fechaF;
            element.idUnico = uuidv4();

        });

        preciosAux.forEach(element => {
            let precioTransformado = transformarArray(element);
            preciosTransformados.push(...precioTransformado);
        });

        const preciosAg = {
            Lunes: [],
            Martes: [],
            Miercoles: [],
            Jueves: [],
            Viernes: [],
            Sabado: [],
            Domingo: [],
        }

        preciosAux.forEach(element => {
            for (let j = 1; j < 8; j++) {
                if (element.DiasSemana.includes(j)) {
                    element.DescripcionCanal = state.response.productosApi.canales.filter(can => can.CodigoCanal === element.CodigoCanal)[0].Descripcion;
                    Object.values(preciosAg)[j - 1].push(element);
                }
            }
        })

        let listadoComplementos = [...state.response.productosApi.complementoList];

        // Al igual que en los productos, es esto necesario?
        //let compAux = [...state.productoDetalles.compProducto];
        let compAux = [];

        opcion.Complementos.forEach(element => {
            compAux.push(element);
            listadoComplementos = listadoComplementos.filter(comp => comp.CodigoComplemento !== element.CodigoComplemento);
        });

        const pluOpcion = state.response.productosApi.pluList.filter(plu => plu.CodigoPlu === opcion.CodigoPlu)[0];

        const payload = {
            opcion,
            pluOpcion,
            listadoComplementos,
            preciosTransformados,
            preciosAg,
            compAux,
            preciosAux,

        }

        dispatch({ type: 'MODAL_EDITAR_OPCION', payload })

    }

    // Función para editar una opción
    const editarOpcionExistente = async () => {

        const preciosAux = [...state.productoDetalles.productoPrecios];

        dispatch({ type: 'CERRAR_MODAL' });
        dispatch({ type: 'NUMERO_MODAL', payload: 2 });

        let complementosOpcion = [...state.productoDetalles.compProducto];

        const opcion = {
            CodigoEmpresa: valoresLogin.codigoEmpresa,
            CodigoOpcion: state.formOpcion.CodigoOpcion,
            Descripcion: state.formOpcion.Descripcion,
            CantidadPorDefecto: state.formOpcion.CantidadDefecto,
            CantidadMinima: state.formOpcion.CantidadMinima,
            CantidadMaxima: state.formOpcion.CantidadMaxima,
            CodigoPlu: state.formOpcion.CodigoPlu,
            Posicion: state.formOpcion.Posicion,
            ReportarPrecio: state.formOpcion.ReportarPrecio,
            Estado: state.formOpcion.Estado,
            Complementos: complementosOpcion,
        }

        productoServices.producto.editarOpcion(opcion)
            .then((result) => {
                if (result.data.SUCCESS === true) {
                    productoServices.plu.agregarPrecioPlu(preciosAux)
                        .then(async (result) => {
                            if (result.data.SUCCESS === true) {
                                dispatch({ type: 'CERRAR_MODAL' });
                                Swal.fire({
                                    title: 'Opción actualizada con éxito',
                                    icon: 'success',
                                    customClass: {
                                        confirmButton: 'ui orange button'
                                    }
                                })
                                const opc = await getOpciones(valoresLogin.codigoEmpresa);
                                dispatch({ type: 'EDITAR_ELIMINAR_OPCION', payload: opc });
                                dispatch({ type: 'ACTUALIZAR_EVENTO_PRECIOS', payload: [] });

                                reiniciarEstadosOpcion();
                            }
                            else {
                                dispatch({ type: 'CERRAR_MODAL' });
                                Swal.fire({
                                    title: 'No se actualizó la opción',
                                    text: result.data.MESSAGE,
                                    icon: 'error',
                                    customClass: {
                                        confirmButton: 'ui orange button'
                                    }
                                })
                            }

                        }, (error) => {
                            console.error(error);
                        })
                }
                else {
                    dispatch({ type: 'CERRAR_MODAL' });
                    Swal.fire({
                        title: 'No se actualizó el producto',
                        text: result.data.MESSAGE,
                        icon: 'error',
                        customClass: {
                            confirmButton: 'ui orange button'
                        }
                    })
                }
            }, (error) => {
                console.error(error);
            })

    }

    // Función para dar de baja una opción
    const eliminarOpcion = (opcion) => {
        const opcionBaja = {
            CodigoEmpresa: valoresLogin.codigoEmpresa,
            CodigoOpcion: opcion.CodigoOpcion,
            CodigoPlu: opcion.CodigoPlu,
            Descripcion: opcion.Descripcion,
            Estado: 'BAJA',
            Posicion: opcion.Posicion,
            CantidadPorDefecto: opcion.CantidadPorDefecto,
            CantidadMinima: opcion.CantidadMinima,
            CantidadMaxima: opcion.CantidadMaxima,
        }

        Swal.fire({
            title: 'Dar opción de baja',
            icon: 'question',
            text: `¿Desea dar de baja la opción ${opcionBaja.CodigoOpcion}?`,
            showCancelButton: true,
            confirmButtonText: 'Dar de baja',
            cancelButtonText: 'Regresar',
            customClass: {
                confirmButton: 'ui orange button',
            },
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                productoServices.producto.editarOpcion(opcionBaja)
                    .then(async (result) => {
                        if (result.data.SUCCESS === true) {
                            Swal.fire({
                                title: 'Opción dada de baja con éxito.',
                                icon: 'success',
                            });
                            const opc = await getOpciones(valoresLogin.codigoEmpresa);
                            dispatch({ type: 'EDITAR_ELIMINAR_OPCION', payload: opc });
                        }
                        else {
                            Swal.fire({
                                title: 'No se ha podido dar de baja la opción.',
                                text: JSON.stringify(result.data.MESSAGE),
                                icon: 'error',
                                customClass: {
                                    confirmButton: 'ui orange button',
                                },
                            });
                        }
                    }, (error) => {
                        console.error(error);
                    })
            }
        });

        dispatch({ type: 'ACTUALIZAR_PLU_SELECCIONADO', payload: '' });
    }


    /*******************************************************************************************/
    /**************************** PRECIOS  *****************************************************/
    /*******************************************************************************************/

    // Handler del formulario de precios
    const changeFormPrecio = (e) => {
        const payload = {
            name: e.target.name,
            value: e.target.value,
        }
        dispatch({ type: 'ACTUALIZAR_FORM_PRECIO', payload });
    }

    // Función para el elemento MultiSelect 
    // Cada vez que se agrega un elemento al MultiSelect se llama esta función
    const onSelectCanal = (selectedList, selectedItem) => {
        // selectedList incluye la lista de todos los valores seleccionados actualmente en el MultiSelect
        // Esta lista se puede colocar directamente en CodigoCanal y después iterar sobre esos canales al querer
        // modificar o añadir precios
        const payload = {
            name: 'CodigoCanal',
            value: [...selectedList],
        }
        dispatch({ type: 'ACTUALIZAR_FORM_PRECIO', payload });
    }

    // Función para el elemento MultiSelect 
    // Cada vez que elimina un elemento del MultiSelect se llama esta función
    const onRemoveCanal = (selectedList, selectedItem) => {
        // selectedList siempre tiene todos los elementos actualmente seleccionados
        // Solo es necesario cargarla en CodigoCanal para tener todos los canales actualizados
        const payload = {
            name: 'CodigoCanal',
            value: [...selectedList],
        }
        dispatch({ type: 'ACTUALIZAR_FORM_PRECIO', payload });
    }

    // Función para agregar un precio a todos los canales selccionados
    const submitFormPrecioCanales = () => {

        // Array para calendario
        let preciosTransformadosArray = [];
        // Array para agenda
        let preciosGuardados = { ...state.preciosAgenda };
        // Precios que actualmente tiene el PLU
        let preciosActuales = [...state.productoDetalles.productoPrecios];

        // CodigoCanal puede ser un string o un array con todos los canales a los que se quiere agregar el precio
        let arrayCanales = state.formPrecio.CodigoCanal;

        // Si está seleccionada la opción para todos los canales, el precio se crea para todos los canales disponibles
        if (state.canalModificarUnico === 'TODOS') arrayCanales = [...state.response.productosApi.canales];
        else arrayCanales = state.formPrecio.CodigoCanal;

        // Verificación de selección de canales
        if (arrayCanales.length === 0) {
            Swal.fire({
                title: 'No se puede agregar el precio',
                text: 'Por favor seleccione al menos un canal',
                icon: 'error',
                customClass: {
                    confirmButton: 'ui orange button',
                },
            })
            return;
        }

        // Verificación de form, para revisar si hay campos vacíos
        let formAux = { ...state.formPrecio };
        formAux = {
            ...formAux,
            CodigoCanal: 'Todos',
        }
        const formDiasAux = { ...state.diasSemana };

        const valoresForm = Object.values(formAux);
        const llavesForm = Object.keys(formAux);
        const valoresDias = Object.values(formDiasAux);

        let diasAux = [];

        let vacio = false;
        let sinDias = false;


        for (let i = 0; i < valoresForm.length; i++) {
            if (llavesForm[i] !== 'idUnico') {
                if (valoresForm[i] === '') {
                    vacio = true;
                    break;
                }
            }
        }

        valoresDias.forEach(element => {
            diasAux.push(element);
        });

        if (!diasAux.includes(true)) sinDias = true;

        if (vacio === true) {
            Swal.fire({
                title: 'No se puede agregar el precio',
                text: 'Por favor llene el formulario completo antes de continuar',
                icon: 'error',
                customClass: {
                    confirmButton: 'ui orange button',
                },
            })
            return;
        }

        if (sinDias === true) {
            Swal.fire({
                title: 'No se puede agregar el precio',
                text: 'Debe agregar al menos un día para el precio',
                icon: 'error',
                customClass: {
                    confirmButton: 'ui orange button',
                },
            })
            return;
        }

        // Por cada canal al que se quiere agregar el precio
        arrayCanales.forEach(element => {
            // Objeto para guardar los precios que van a la mostrarse en la agenda
            const preciosAg = {
                Lunes: [],
                Martes: [],
                Miercoles: [],
                Jueves: [],
                Viernes: [],
                Sabado: [],
                Domingo: [],
            }

            // Plu al que se van a agregar los precios
            let pluAgregar = state.formPluProducto.CodigoPlu;

            // Tomar el CodigoPlu del form de creación o de edición, dependiendo de cuál esté en uso
            if (state.formPluProducto.CodigoPlu === "") pluAgregar = state.formEditarProducto.CodigoPlu;
            else pluAgregar = state.formPluProducto.CodigoPlu;

            // Se agrega cada uno de los días seleccionados como un string '1234567'
            let dias = '';
            if (state.diasSemana.Lunes === true) dias += '1';
            if (state.diasSemana.Martes === true) dias += '2';
            if (state.diasSemana.Miercoles === true) dias += '3';
            if (state.diasSemana.Jueves === true) dias += '4';
            if (state.diasSemana.Viernes === true) dias += '5';
            if (state.diasSemana.Sabado === true) dias += '6';
            if (state.diasSemana.Domingo === true) dias += '7';

            // Objeto del nuevo precio
            const precioAux = {
                CodigoEmpresa: valoresLogin.codigoEmpresa,
                CodigoPlu: pluAgregar,
                HoraInicio: state.formPrecio.HoraInicio + ':00',
                HoraFin: state.formPrecio.HoraFin + ':59',
                FechaInicio: state.formPrecio.FechaInicio,
                FechaFin: state.formPrecio.FechaFin,
                DiasSemana: dias,
                CodigoCanal: element.CodigoCanal,
                Precio: state.formPrecio.Precio,
                idUnico: uuidv4(),
            }

            // Por cada día de la semana
            for (let j = 1; j < 8; j++) {
                // Si el string de precios incluye el día actual
                if (precioAux.DiasSemana.includes(j)) {
                    // Se agrega la descripción del canal al precio actual (para ser utilizado después)
                    precioAux.DescripcionCanal = state.response.productosApi.canales.filter(can => can.CodigoCanal == precioAux.CodigoCanal)[0].Descripcion;
                    // Se agrega el precio al día correspondiente en la agenda
                    Object.values(preciosAg)[j - 1].push(precioAux);
                }
            }

            // Validación para ver si un precio en el mimso horario ya existe

            // Primero hay que revisar si ya existe un precio con ese canal para el producto actual
            // Bandera para verificar si un precio en el canal actual existe
            let banderaEncontrado = false;

            // Se busca en todos los precios actuales si los CodigoCanal coinciden
            preciosActuales.forEach(element => {
                if (element.CodigoCanal == precioAux.CodigoCanal) {
                    // Si los canales coinciden, se activa la bandera
                    banderaEncontrado = true;
                }
            });

            // A menos que exista un conflicto de canales, días y horas, el precio se agrega y se actualizan
            // los estados de la misma manera
            // Es inútil tener tanto else para realizar el mismo procedimiento
            // Solo es de validar los conflictos, si no exiten, mismo procedimiento para crear precio

            // Si ya existen precios en el mismo canal
            if (banderaEncontrado === true) {

                // Primero obtener todos los precios asociados al canal 
                const preciosCanal = preciosActuales.filter(precio => precio.CodigoCanal === precioAux.CodigoCanal);

                // Cuántos días tienen ese precio
                const diasLength = precioAux.DiasSemana.length;

                // Bandera para saber si los días de los precios coinciden
                let banderaDiaEncontrado = false;
                // Precios que pueden dar problema
                let arrayPosibles = [];

                // Por cada precio del canal
                preciosCanal.forEach(element => {
                    // Buscar si coincide con cualquiera de los días que tenga agregado ese precio
                    for (let k = 0; k < diasLength; k++) {
                        // Si los días coinciden
                        if (element.DiasSemana.includes(precioAux.DiasSemana[k])) {
                            // Se activa la bandera y se agrega el precio al array de posibles problemas
                            banderaDiaEncontrado = true;
                            arrayPosibles.push(element);
                        }
                    }
                });

                // Si los días coinciden
                if (banderaDiaEncontrado === true) {

                    // Bandera para indicar que existe un traslape, por lo tanto hay problema
                    let banderaTraslape = false;

                    // Se busca en todos los precios posibles si las horas se traslapan
                    arrayPosibles.forEach(element => {
                        // Horario del precio a revisar
                        const elHoraIn = element.HoraInicio.split(':')[0];
                        const elHoraFin = element.HoraFin.split(':')[0];

                        // Horario del precio a crear
                        const auxIn = precioAux.HoraInicio.split(':')[0];
                        const auxFin = precioAux.HoraFin.split(':')[0];

                        // Si el nuevo horario inicia cuando termina el otro, hay que validar que los minutos
                        // Los de el nuevo tienen que ser mayor a los del ya existente
                        if (auxIn == elHoraFin) {
                            // Minuto en el que termina el precio actual
                            const elMinFin = element.HoraFin.split(':')[1];
                            // Minuto en el que inicio el precio a crear
                            const auxMinIn = precioAux.HoraInicio.split(':')[1];

                            // Si el minuto del precio a crear es menor al del precio actual o si los minutos
                            // son iguales, existe traslape
                            if (auxMinIn < elMinFin || auxMinIn == elMinFin) banderaTraslape = true;
                        }
                        // Misma situación pero al comenzar el precio actual y terminar el nuevo
                        else if (auxFin == elHoraIn) {
                            // Minutos de ambos precios
                            const elMinIn = element.HoraInicio.split(':')[1];
                            const auxMinFin = precioAux.HoraFin.split(':')[1];

                            // Si el minuto a terminar del precio nuevo es mayor que el precio actual
                            // o si son iguales, existe traslape
                            if (auxMinFin > elMinIn || auxMinFin == elMinIn) banderaTraslape = true;
                        }
                        // Otras opciones que no toman en cuenta los minutos
                        else {
                            // Si la hora de inicio del nuevo es mayor a la hora de inicio o si son iguales
                            // Y la hora incial del nuevo es menor a la hora final del actual
                            // El nuevo precio está dentro del actual, y por lo tanto existe traslape
                            if ((auxIn > elHoraIn || auxIn == elHoraIn) && auxIn < elHoraFin) banderaTraslape = true; // verificar intervalos cuando sean iguales

                            // Si la hora final del nuevo es mayor a la hora inicial del actual
                            // Y la hora final del nuevo es menor o igual a la hora final del actual
                            // Existe traslape
                            if (auxFin > elHoraIn && (auxFin < elHoraFin || auxFin == elHoraFin)) banderaTraslape = true;
                        }

                    });

                    // Si existe traslape, no se permite agregar el precio
                    if (banderaTraslape === true) {
                        Swal.fire({
                            title: 'No se puede agregar el precio',
                            text: 'Ya existe un precio en ese canal dentro del intervalo de tiempo establecido',
                            icon: 'error',
                            customClass: {
                                confirmButton: 'ui orange button',
                            }
                        })
                        return;
                    }
                }
            }

            // No hay traslape de precios, se puede proceder a agregar el precio

            // Se transforma el precio para el calendario y se agrega al array
            const precioTransformado = transformarPrecioUnico(precioAux);
            preciosTransformadosArray.push(precioTransformado);

            // Esto tiene sentido cuando el precio que se agrega es el primero
            // Ya que preciosGuardados estará completamente vacío
            if (Object.keys(preciosGuardados).length == 0) {
                // Se agregan los precios a cada día de la agenda
                if (preciosAg.Lunes.length > 0) {
                    preciosGuardados = {
                        ...preciosGuardados,
                        Lunes: [...preciosAg.Lunes],
                    }
                }

                if (preciosAg.Martes.length > 0) {
                    preciosGuardados = {
                        ...preciosGuardados,
                        Martes: [...preciosAg.Martes],
                    }
                }

                if (preciosAg.Miercoles.length > 0) {
                    preciosGuardados = {
                        ...preciosGuardados,
                        Miercoles: [...preciosAg.Miercoles],
                    }
                }

                if (preciosAg.Jueves.length > 0) {
                    preciosGuardados = {
                        ...preciosGuardados,
                        Jueves: [...preciosAg.Jueves],
                    }
                }

                if (preciosAg.Viernes.length > 0) {
                    preciosGuardados = {
                        ...preciosGuardados,
                        Viernes: [...preciosAg.Viernes],
                    }
                }

                if (preciosAg.Sabado.length > 0) {
                    preciosGuardados = {
                        ...preciosGuardados,
                        Sabado: [...preciosAg.Sabado],
                    }
                }

                if (preciosAg.Domingo.length > 0) {
                    preciosGuardados = {
                        ...preciosGuardados,
                        Domingo: [...preciosAg.Domingo],
                    }
                }
            }
            // Sin embargo, hay ocasiones en las que hay precios que solo se crean para
            // ciertos días
            // En esos casos preciosGuardados no es iterable para los días que no tienen precios
            // asignados. Hay que poner otra validación para ver si se puede iterar
            else {
                if (preciosAg.Lunes.length > 0) {
                    preciosGuardados = {
                        ...preciosGuardados,
                        // Si no se han creado precios para Lunes, es posible que la llave 'Lunes' no exista, y por lo tanto
                        // se debe validar su existencia para crearla y agregar precios o solo agregar los nuevos precios
                        Lunes: (preciosGuardados.Lunes !== undefined) ? [...preciosGuardados.Lunes, ...preciosAg.Lunes] : [...preciosAg.Lunes],
                    }
                }

                if (preciosAg.Martes.length > 0) {
                    preciosGuardados = {
                        ...preciosGuardados,
                        Martes: (preciosGuardados.Martes !== undefined) ? [...preciosGuardados.Martes, ...preciosAg.Martes] : [...preciosAg.Martes],
                    }
                }

                if (preciosAg.Miercoles.length > 0) {
                    preciosGuardados = {
                        ...preciosGuardados,
                        Miercoles: (preciosGuardados.Miercoles !== undefined) ? [...preciosGuardados.Miercoles, ...preciosAg.Miercoles] : [...preciosAg.Miercoles],
                    }
                }

                if (preciosAg.Jueves.length > 0) {
                    preciosGuardados = {
                        ...preciosGuardados,
                        Jueves: (preciosGuardados.Jueves !== undefined) ? [...preciosGuardados.Jueves, ...preciosAg.Jueves] : [...preciosAg.Jueves],
                    }
                }

                if (preciosAg.Viernes.length > 0) {
                    preciosGuardados = {
                        ...preciosGuardados,
                        Viernes: (preciosGuardados.Viernes !== undefined) ? [...preciosGuardados.Viernes, ...preciosAg.Viernes] : [...preciosAg.Viernes],
                    }
                }

                if (preciosAg.Sabado.length > 0) {
                    preciosGuardados = {
                        ...preciosGuardados,
                        Sabado: (preciosGuardados.Sabado !== undefined) ? [...preciosGuardados.Sabado, ...preciosAg.Sabado] : [...preciosAg.Sabado],
                    }
                }

                if (preciosAg.Domingo.length > 0) {
                    preciosGuardados = {
                        ...preciosGuardados,
                        Domingo: (preciosGuardados.Domingo !== undefined) ? [...preciosGuardados.Domingo, ...preciosAg.Domingo] : [...preciosAg.Domingo],
                    }
                }
            }
            // Se agrega el precio nuevo a los actuales
            preciosActuales.push(precioAux);

        });

        let eventosActuales = [];
        let transformadosSpread = [];

        // Se van agregando todos los eventos 'esparcidos' para que no hayan arrays dentro de un array
        for (let i = 0; i < preciosTransformadosArray.length; i++) {
            transformadosSpread = [...transformadosSpread, ...preciosTransformadosArray[i]];
        }

        // Se agregan todos los nuevos eventos a los ya existentes si ya existen
        if (state.eventoPrecios.length > 0) {
            eventosActuales = [...state.eventoPrecios, ...transformadosSpread];
        }
        else {
            eventosActuales = [...transformadosSpread];
        }

        // Se actualiza el estado
        const payload = {
            preciosActuales,
            preciosGuardados,
            eventosActuales,
        }

        dispatch({
            type: 'SUBMIT_PRECIO_CANALES',
            payload,
        })

    }

    // Acción del botón 'Modificar' en cada precio de la agenda. Carga el form para modificar el precio
    const modificarPrecioUnico = (precio) => {
        // Canal actual al que perteneces ese precio
        const descripcionCanal = state.response.productosApi.canales.filter(canal => canal.CodigoCanal === precio.CodigoCanal)[0].Descripcion;

        // Variables para determinar si el precio existe en x día
        let lunes, martes, miercoles, jueves, viernes, sabado, domingo = false;

        // Se activan las variables dependiendo de los días del precio
        if (precio.DiasSemana.includes('1')) lunes = true;
        if (precio.DiasSemana.includes('2')) martes = true;
        if (precio.DiasSemana.includes('3')) miercoles = true;
        if (precio.DiasSemana.includes('4')) jueves = true;
        if (precio.DiasSemana.includes('5')) viernes = true;
        if (precio.DiasSemana.includes('6')) sabado = true;
        if (precio.DiasSemana.includes('7')) domingo = true;

        // Se carga el estado
        const payload = {
            descripcionCanal,
            precio,
            lunes,
            martes,
            miercoles,
            jueves,
            viernes,
            sabado,
            domingo,
        }

        dispatch({ type: 'MODIFICAR_PRECIO_UNICO', payload });
    }

    // Muestra el formulario para modificar todos los precios de uno o varios canales
    // Este form está vacío por lo que solo se cambia el estado de la tab de precios
    const modificarPrecioPorCanal = () => {
        dispatch({ type: 'ACTUALIZAR_ESTADO_PRECIO', payload: 'editar-canal' })
    }

    // Editar un precio que se quiere editar desde la agenda, afectando únicamente a un precio
    const editarPrecioUnico = () => {

        // Validación formulario vacío
        let formAux = { ...state.formPrecio };
        formAux = {
            ...formAux,
            CodigoCanal: 'Todos',
        }
        const formDiasAux = { ...state.diasSemana };

        const valoresForm = Object.values(formAux);
        const llavesForm = Object.keys(formAux);
        const valoresDias = Object.values(formDiasAux);

        let diasAux = [];

        let vacio = false;
        let sinDias = false;

        for (let i = 0; i < valoresForm.length; i++) {
            if (llavesForm[i] !== 'idUnico') {
                if (valoresForm[i] === '') {
                    vacio = true;
                    break;
                }
            }
        }

        valoresDias.forEach(element => {
            diasAux.push(element);
        });

        if (!diasAux.includes(true)) sinDias = true;

        if (vacio === true) {
            Swal.fire({
                title: 'No se puede agregar el precio',
                text: 'Por favor llene el formulario completo antes de continuar',
                icon: 'error',
                customClass: {
                    confirmButton: 'ui orange button',
                },
            })
            return;
        }

        if (sinDias === true) {
            Swal.fire({
                title: 'No se puede agregar el precio',
                text: 'Debe agregar al menos un día para el precio',
                icon: 'error',
                customClass: {
                    confirmButton: 'ui orange button',
                },
            })
            return;
        }

        // id del precio, para identificar el precio que hay que cambiar del array de precios
        const idPrecio = state.formPrecio.idUnico;
        // Index del precio en el array de precios del producto
        const indexEditado = state.productoDetalles.productoPrecios.findIndex(precio => precio.idUnico === idPrecio);

        const preciosAg = { // Objeto para almacenar los precios que van a la agenda
            Lunes: [],
            Martes: [],
            Miercoles: [],
            Jueves: [],
            Viernes: [],
            Sabado: [],
            Domingo: [],
        }

        // Precios actualmente desplegados en la agenda
        let preciosGuardados = { ...state.preciosAgenda };

        // Se eliminan todos los días del precio
        // Esto se hace para limpiar todos los precios del canal y, si ocurren cambios a los días, poder agregarlos
        // como si fueran precios nuevos solo a los días correspondientes

        // Esto solo borra los precios que tuvieran ese id, ya que solo se está editando un único precio
        preciosGuardados.Lunes = preciosGuardados.Lunes.filter(precio => precio.idUnico !== idPrecio);
        preciosGuardados.Martes = preciosGuardados.Martes.filter(precio => precio.idUnico !== idPrecio);
        preciosGuardados.Miercoles = preciosGuardados.Miercoles.filter(precio => precio.idUnico !== idPrecio);
        preciosGuardados.Jueves = preciosGuardados.Jueves.filter(precio => precio.idUnico !== idPrecio);
        preciosGuardados.Viernes = preciosGuardados.Viernes.filter(precio => precio.idUnico !== idPrecio);
        preciosGuardados.Sabado = preciosGuardados.Sabado.filter(precio => precio.idUnico !== idPrecio);
        preciosGuardados.Domingo = preciosGuardados.Domingo.filter(precio => precio.idUnico !== idPrecio);

        let dias = ''; // String de días

        // Asignación de cada día en el string si el precio existe ese día
        if (state.diasSemana.Lunes === true) dias += '1';
        if (state.diasSemana.Martes === true) dias += '2';
        if (state.diasSemana.Miercoles === true) dias += '3';
        if (state.diasSemana.Jueves === true) dias += '4';
        if (state.diasSemana.Viernes === true) dias += '5';
        if (state.diasSemana.Sabado === true) dias += '6';
        if (state.diasSemana.Domingo === true) dias += '7';

        let pluAgregar = state.formPluProducto.CodigoPlu; // Plu del producto/opción que se está modificando

        // Se toma el código de Plu que exista actualmente
        if (state.formPluProducto.CodigoPlu === "") pluAgregar = state.formEditarProducto.CodigoPlu;
        else pluAgregar = state.formPluProducto.CodigoPlu;

        // Hora de inicio y fin del precio
        let horaInicio = state.formPrecio.HoraInicio;
        let horaFinal = state.formPrecio.HoraFin;

        // Por la forma en la que se trabajan los tiempos en la base de datos, es necesario que se envíen los
        // segundos para mantener el mismo formato y evitar problemas
        // Si el precio ya tiene los segundos, no es necesario modificarlo
        // Si no los tiene, hay que agregarselos, dependiendo de los minutos

        // Si la hora fin no incluye segundos
        if (state.formPrecio.HoraFin.length < 8) {
            // Se separan las horas de los minutos
            const horaFinSplit = state.formPrecio.HoraFin.split(':');
            let segundos = '';
            // Si los minutos son 59, se agrega el tiempo como 59:59
            // Si no, se agrega el tiempo como x:00, para que sea el minuto exacto
            if (horaFinSplit[1] == '59') segundos = '59';
            else segundos = '00';

            horaFinal = horaFinal + ':' + segundos;
        }

        // Mismo caso con la hora de inicio
        if (state.formPrecio.HoraInicio.length < 8) {
            const horaInicioSplit = state.formPrecio.HoraInicio.split(':');
            let segundos = '';
            if (horaInicioSplit[1] == '59') segundos = '59';
            else segundos = '00';

            horaInicio = horaInicio + ':' + segundos;
        }

        // Objeto del precio con valores editados
        const precioEditado = {
            CodigoEmpresa: valoresLogin.codigoEmpresa,
            CodigoPlu: pluAgregar,
            HoraInicio: horaInicio,
            HoraFin: horaFinal,
            FechaInicio: state.formPrecio.FechaInicio,
            FechaFin: state.formPrecio.FechaFin,
            DiasSemana: dias,
            CodigoCanal: state.formPrecio.CodigoCanal,
            Precio: state.formPrecio.Precio,
        }

        // Eventos del calendario
        const eventosActuales = [...state.eventoPrecios];
        // Al igual que con los precios de la agenda, es más fácil eliminar los precios del calendario
        // para luego agregar los editados
        const eventosNuevos = eventosActuales.filter(evento => evento.idPrecio !== idPrecio);
        // Se transforma el precio en uno o varios eventos
        const precioTransformado = transformarPrecioUnico(precioEditado);

        // Se agrega cada el precio a cada día que se quiera agregar
        for (let j = 1; j < 8; j++) {
            if (precioEditado.DiasSemana.includes(j)) {
                precioEditado.DescripcionCanal = state.response.productosApi.canales.filter(can => can.CodigoCanal == precioEditado.CodigoCanal)[0].Descripcion;
                Object.values(preciosAg)[j - 1].push(precioEditado);
            }
        }

        // Se asignan los precios a sus días correspondientes
        if (preciosAg.Lunes.length > 0) {
            preciosGuardados = {
                ...preciosGuardados,
                Lunes: [...preciosGuardados.Lunes, ...preciosAg.Lunes],
            }
        }

        if (preciosAg.Martes.length > 0) {
            preciosGuardados = {
                ...preciosGuardados,
                Martes: [...preciosGuardados.Martes, ...preciosAg.Martes],
            }
        }

        if (preciosAg.Miercoles.length > 0) {
            preciosGuardados = {
                ...preciosGuardados,
                Miercoles: [...preciosGuardados.Miercoles, ...preciosAg.Miercoles],
            }
        }

        if (preciosAg.Jueves.length > 0) {
            preciosGuardados = {
                ...preciosGuardados,
                Jueves: [...preciosGuardados.Jueves, ...preciosAg.Jueves],
            }
        }

        if (preciosAg.Viernes.length > 0) {
            preciosGuardados = {
                ...preciosGuardados,
                Viernes: [...preciosGuardados.Viernes, ...preciosAg.Viernes],
            }
        }

        if (preciosAg.Sabado.length > 0) {
            preciosGuardados = {
                ...preciosGuardados,
                Sabado: [...preciosGuardados.Sabado, ...preciosAg.Sabado],
            }
        }

        if (preciosAg.Domingo.length > 0) {
            preciosGuardados = {
                ...preciosGuardados,
                Domingo: [...preciosGuardados.Domingo, ...preciosAg.Domingo],
            }
        }

        // Precios actuales del plu
        let preciosActuales = state.productoDetalles.productoPrecios;
        // En el index donde se encuentra el precio a editar, se coloca el nuevo precio editado
        preciosActuales[indexEditado] = precioEditado;

        // Se actualiza el estado
        const payload = {
            eventosNuevos,
            precioTransformado,
            preciosGuardados,
            preciosActuales,
        }

        dispatch({ type: 'EDITAR_PRECIO_UNICO', payload });
    }

    // Función para editar TODOS los precios de uno o varios canales
    const editarPrecioPorCanal = () => {
        let preciosTransformadosArray = []; // Array para almacenar los precios transformados modificados
        let preciosGuardados = { ...state.preciosAgenda };
        let preciosActuales = state.productoDetalles.productoPrecios;

        // arrayCanales almacena todos los canales a los que se les modificará el precio
        // Si TODOS está activado, se agrega a todos los canales
        let arrayCanales = state.formPrecio.CodigoCanal;
        if (state.canalModificarUnico === 'TODOS') arrayCanales = [...state.response.productosApi.canales];
        else arrayCanales = state.formPrecio.CodigoCanal;

        // Validación de formulario lleno
        if (arrayCanales.length === 0) {
            Swal.fire({
                title: 'No se puede agregar el precio',
                text: 'Por favor seleccione al menos un canal',
                icon: 'error',
                customClass: {
                    confirmButton: 'ui orange button',
                },
            })
            return;
        }

        let formAux = { ...state.formPrecio };
        formAux = {
            ...formAux,
            CodigoCanal: 'Todos',
        }

        const formDiasAux = { ...state.diasSemana };

        const valoresForm = Object.values(formAux);
        const llavesForm = Object.keys(formAux);
        const valoresDias = Object.values(formDiasAux);

        let diasAux = [];

        let vacio = false;
        let sinDias = false;

        for (let i = 0; i < valoresForm.length; i++) {
            if (llavesForm[i] !== 'idUnico') {
                if (valoresForm[i] === '') {
                    vacio = true;
                    break;
                }
            }
        }

        valoresDias.forEach(element => {
            diasAux.push(element);
        });

        if (!diasAux.includes(true)) sinDias = true;

        if (vacio === true) {
            Swal.fire({
                title: 'No se puede agregar el precio',
                text: 'Por favor llene el formulario completo antes de continuar',
                icon: 'error',
                customClass: {
                    confirmButton: 'ui orange button',
                },
            })
            return;
        }

        if (sinDias === true) {
            Swal.fire({
                title: 'No se puede agregar el precio',
                text: 'Debe agregar al menos un día para el precio',
                icon: 'error',
                customClass: {
                    confirmButton: 'ui orange button',
                },
            })
            return;
        }

        // Se debe hacer todo el flujo de modificación por cada canal seleccionado
        arrayCanales.forEach(element => {
            // En este caso, se están modificando todos los precios de un canal
            // No es necesario el idUnico de cada precio, sino que el CodigoCanal para afectar a todo el canal
            const canalEditado = element.CodigoCanal; // Código del canal a modificar

            // Se crea la propiedad multiIndexOf en los arrays, para poder obtener múltiples índices de una
            // ocurrencia
            Array.prototype.multiIndexOf = function (el) {
                let indxs = [];
                for (let i = this.length - 1; i >= 0; i--) {
                    if (this[i].CodigoCanal === el) {
                        indxs.unshift(i);
                    }
                }
                return indxs;
            }

            // Array de índices en los que hay un precio pertenciente a canalEditado
            let indexes = state.productoDetalles.productoPrecios.multiIndexOf(canalEditado);

            const preciosAg = { // Objeto para almacenar los precios que van a la agenda
                Lunes: [],
                Martes: [],
                Miercoles: [],
                Jueves: [],
                Viernes: [],
                Sabado: [],
                Domingo: [],
            }

            // Se eliminan todos los días del precio
            // Esto se hace para limpiar todos los precios del canal y, si ocurren cambios a los días, poder agregarlos
            // como si fueran precios nuevos solo a los días correspondientes

            // Antes de hacer esto, es necesario revisar si el cambio afecta a todos los días o no
            // Por ejemplo, si se tienen precios martes y jueves y el cambio solo es para lunes y miércoles,
            // no se pueden borrar los precios de martes y jueves, porque se borrarían para siempre, sin ser afectados
            // por el código que sigue

            if (preciosGuardados.Lunes !== undefined && state.diasSemana.Lunes === true) {
                preciosGuardados.Lunes = preciosGuardados.Lunes.filter(precio => precio.CodigoCanal !== canalEditado);
            }
            else if (preciosGuardados.Lunes === undefined && state.diasSemana.Lunes === true) {
                preciosGuardados.Lunes = [];
            }

            if (preciosGuardados.Martes !== undefined && state.diasSemana.Martes === true) {
                preciosGuardados.Martes = preciosGuardados.Martes.filter(precio => precio.CodigoCanal !== canalEditado);
            }
            else if (preciosGuardados.Martes === undefined && state.diasSemana.Martes === true) {
                preciosGuardados.Martes = [];
            }

            if (preciosGuardados.Miercoles !== undefined && state.diasSemana.Miercoles === true) {
                preciosGuardados.Miercoles = preciosGuardados.Miercoles.filter(precio => precio.CodigoCanal !== canalEditado);
            }
            else if (preciosGuardados.Miercoles === undefined && state.diasSemana.Miercoles === true) {
                preciosGuardados.Miercoles = [];
            }

            if (preciosGuardados.Jueves !== undefined && state.diasSemana.Jueves === true) {
                preciosGuardados.Jueves = preciosGuardados.Jueves.filter(precio => precio.CodigoCanal !== canalEditado);
            }
            else if (preciosGuardados.Jueves === undefined && state.diasSemana.Jueves === true) {
                preciosGuardados.Jueves = [];
            }

            if (preciosGuardados.Viernes !== undefined && state.diasSemana.Viernes === true) {
                preciosGuardados.Viernes = preciosGuardados.Viernes.filter(precio => precio.CodigoCanal !== canalEditado);
            }
            else if (preciosGuardados.Viernes === undefined && state.diasSemana.Viernes === true) {
                preciosGuardados.Viernes = [];
            }

            if (preciosGuardados.Sabado !== undefined && state.diasSemana.Sabado === true) {
                preciosGuardados.Sabado = preciosGuardados.Sabado.filter(precio => precio.CodigoCanal !== canalEditado);
            }
            else if (preciosGuardados.Sabado === undefined && state.diasSemana.Sabado === true) {
                preciosGuardados.Sabado = [];
            }

            if (preciosGuardados.Domingo !== undefined && state.diasSemana.Domingo === true) {
                preciosGuardados.Domingo = preciosGuardados.Domingo.filter(precio => precio.CodigoCanal !== canalEditado);
            }
            else if (preciosGuardados.Domingo === undefined && state.diasSemana.Domingo === true) {
                preciosGuardados.Domingo = [];
            }

            let dias = ''; // String de días

            // Asignación de cada día en el string si el precio existe ese día
            if (state.diasSemana.Lunes === true) dias += '1';
            if (state.diasSemana.Martes === true) dias += '2';
            if (state.diasSemana.Miercoles === true) dias += '3';
            if (state.diasSemana.Jueves === true) dias += '4';
            if (state.diasSemana.Viernes === true) dias += '5';
            if (state.diasSemana.Sabado === true) dias += '6';
            if (state.diasSemana.Domingo === true) dias += '7'

            // Plu del producto/opción que se está modificando
            let pluAgregar = state.formPluProducto.CodigoPlu;

            // Se toma el código de Plu que exista actualmente
            if (state.formPluProducto.CodigoPlu === '') pluAgregar = state.formEditarProducto.CodigoPlu;
            else pluAgregar = state.formPluProducto.CodigoPlu;

            // Este caso es igual al de edición única, es necesario validar si contiene segundos
            // para no agregar más de lo necesario

            let horaInicio = state.formPrecio.HoraInicio;
            let horaFinal = state.formPrecio.HoraFin;

            // Si el precio ya tiene los segundos, no es necesario modificarlo
            // Si no los tiene, hay que agregarselos, dependiendo de los minutos
            if (state.formPrecio.HoraFin.length < 8) {
                const horaFinSplit = state.formPrecio.HoraFin.split(':');
                let segundos = '';
                if (horaFinSplit[1] == '59') segundos = '59';
                else segundos = '00';

                horaFinal = horaFinal + ':' + segundos;
            }

            if (state.formPrecio.HoraInicio.length < 8) {
                const horaInicioSplit = state.formPrecio.HoraInicio.split(':');
                let segundos = '';
                if (horaInicioSplit[1] == '59') segundos = '59';
                else segundos = '00';

                horaInicio = horaInicio + ':' + segundos;
            }

            // Precio con sus características editadas
            const precioEditado = {
                CodigoEmpresa: valoresLogin.codigoEmpresa,
                CodigoPlu: pluAgregar,
                HoraInicio: horaInicio,
                HoraFin: horaFinal,
                FechaInicio: state.formPrecio.FechaInicio,
                FechaFin: state.formPrecio.FechaFin,
                DiasSemana: dias,
                CodigoCanal: element.CodigoCanal,
                Precio: state.formPrecio.Precio,
                idUnico: uuidv4(),
            }

            // Objeto precio transformado a un array de eventos que pueda leer el calendario
            const precioTransformado = transformarPrecioUnico(precioEditado);

            // Se agrega el precio transformado al array
            preciosTransformadosArray.push(precioTransformado);

            // Se agrega cada el precio a cada día que se quiera agregar
            for (let j = 1; j < 8; j++) {
                if (precioEditado.DiasSemana.includes(j)) {
                    precioEditado.DescripcionCanal = state.response.productosApi.canales.filter(can => can.CodigoCanal == precioEditado.CodigoCanal)[0].Descripcion;
                    Object.values(preciosAg)[j - 1].push(precioEditado);
                }
            }

            // Se asignan los precios a sus días correspondientes
            if (preciosAg.Lunes.length > 0) {
                preciosGuardados = {
                    ...preciosGuardados,
                    Lunes: [...preciosGuardados.Lunes, ...preciosAg.Lunes],
                }
            }

            if (preciosAg.Martes.length > 0) {
                preciosGuardados = {
                    ...preciosGuardados,
                    Martes: [...preciosGuardados.Martes, ...preciosAg.Martes],
                }
            }

            if (preciosAg.Miercoles.length > 0) {
                preciosGuardados = {
                    ...preciosGuardados,
                    Miercoles: [...preciosGuardados.Miercoles, ...preciosAg.Miercoles],
                }
            }

            if (preciosAg.Jueves.length > 0) {
                preciosGuardados = {
                    ...preciosGuardados,
                    Jueves: [...preciosGuardados.Jueves, ...preciosAg.Jueves],
                }
            }

            if (preciosAg.Viernes.length > 0) {
                preciosGuardados = {
                    ...preciosGuardados,
                    Viernes: [...preciosGuardados.Viernes, ...preciosAg.Viernes],
                }
            }

            if (preciosAg.Sabado.length > 0) {
                preciosGuardados = {
                    ...preciosGuardados,
                    Sabado: [...preciosGuardados.Sabado, ...preciosAg.Sabado],
                }
            }

            if (preciosAg.Domingo.length > 0) {
                preciosGuardados = {
                    ...preciosGuardados,
                    Domingo: [...preciosGuardados.Domingo, ...preciosAg.Domingo],
                }
            }

            // Se modifica el precio editado en el array de precios actuales
            // Si no hay ocurrencias de precios en canalEditado
            if (indexes.length === 0) {
                // Se tiene que agregar en lugar de modificar
                preciosActuales.push(precioEditado);
            }
            // Si el canal ya tenía precios
            else {
                // Se tiene que agregar a la primera casilla de los precios actuales
                // y eliminar de todas las otras casillas, para que no se dupliquen o traslapen los precios
                for (let k = 0; k < indexes.length; k++) {
                    const indice = indexes[k]
                    if (k === 0) {
                        preciosActuales[indice] = precioEditado;
                    }
                    else {
                        preciosActuales.splice(indice, 1);
                    }
                }
            }
        });

        let eventosActuales = [...state.eventoPrecios];
        let transformadosSpread = []; // Array para los precios transformados todos juntos 
        // El array de precios transformados es un array de arrays, esta variable sirve para que solo sea un 
        // array de objetos

        // Hay que crear una variable para almacenar todos los eventos actuales que no se vean afectados 
        // en el cambio que se está realizando
        let guardarEventos = [];
        eventosActuales.forEach(element => {

            // Fecha del evento, para poder saber de qué día es
            const diaString = String(element.start);

            // Se revisa el día de la semana de cada evento
            if (diaString.includes('Mon')) {
                // Si ese día no va a ser modificado, es necesario guardar este evento para que no sea borrado
                if (state.diasSemana.Lunes === false) guardarEventos.push(element);
            }
            if (diaString.includes('Tue')) {
                if (state.diasSemana.Martes === false) guardarEventos.push(element);
            }
            if (diaString.includes('Wed')) {
                if (state.diasSemana.Miercoles === false) guardarEventos.push(element);
            }
            if (diaString.includes('Thu')) {
                if (state.diasSemana.Jueves === false) guardarEventos.push(element);
            }
            if (diaString.includes('Fri')) {
                if (state.diasSemana.Viernes === false) guardarEventos.push(element);
            }
            if (diaString.includes('Sat')) {
                if (state.diasSemana.Sabado === false) guardarEventos.push(element);
            }
            if (diaString.includes('Sun')) {
                if (state.diasSemana.Domingo === false) guardarEventos.push(element);
            }
        });


        // Ciclo para eliminar los eventos a modificar de los eventos
        for (let i = 0; i < preciosTransformadosArray.length; i++) {

            // Los eventos no tienen la descripción del canal como tal, tienen un título que contiene la
            // descripción del canal, y por eso hay que hacer un filtro para eliminar los eventos necesarios
            // Forma 'CANAL - PRECIO' (TEST - Q 1.00)
            const filtroCompleto = preciosTransformadosArray[i][0].title;
            const filtro = filtroCompleto.split(' ')[0];

            // IMPORTANTE
            // EL FILTRO PUEDE QUE NO FUNCIONE BIEN PARA LAS TRES APPS, ES POSIBLE QUE AFECTA A TODAS
            // DIRECTAMENTE

            // Se eliminan los eventos modificados de todos los eventos
            // Al igual que en la situación con los precios de la agenda, no se deben eliminar los precios
            // de los días que no se van a afectar en la modificación
            // Esto fue resuelto un poco atrás, guardando todos los precios de los días que no se modifican
            // en otra variable

            eventosActuales = eventosActuales.filter(evento => !evento.title.includes(filtro));
            // Se carga el array con todos los precios transformados sin división
            transformadosSpread = [...transformadosSpread, ...preciosTransformadosArray[i]];
        }

        // Actualización del estado
        const payload = {
            eventosActuales,
            transformadosSpread,
            guardarEventos,
            preciosGuardados,
            preciosActuales,
        }

        dispatch({ type: 'EDITAR_PRECIO_CANALES', payload });
    }

    // Eliminar un precio del PLU
    const eliminarPrecio = (precio) => {
        // Precios del PLU
        const precios = [...state.productoDetalles.productoPrecios];

        // Listado de precios sin el precio que se desea eliminar
        const nuevosPrecios = precios.filter(pre => pre.idUnico !== precio.idUnico);

        // Variables para precios del calendario y de la agenda
        let preciosTransformados = [];

        const preciosAg = {
            Lunes: [],
            Martes: [],
            Miercoles: [],
            Jueves: [],
            Viernes: [],
            Sabado: [],
            Domingo: [],
        }

        // No puede haber un forEach si no hay precios restantes
        if (nuevosPrecios.length > 0) {
            nuevosPrecios.forEach(element => {
                // Color del precio en el calendario
                const color = colorRandom();
                const bgColor = '#' + color;

                // Canal al que pertenece el precio
                let canalPrecio = state.response.productosApi.canales.filter(canal => canal.CodigoCanal == element.CodigoCanal)[0];

                // Horas para el evento en el calendario
                let horaIn = element.HoraInicio.split(':')[0];
                let minIn = element.HoraInicio.split(':')[1];
                if (horaIn[0] === '0') horaIn = horaIn[1];
                if (minIn[0] === '0') minIn = minIn[1];

                let horaF = element.HoraFin.split(':')[0];
                let minF = element.HoraFin.split(':')[1];
                if (horaF[0] === '0') horaF = horaF[1];
                if (minF[0] === '0') minF = minF[1];

                // Se agregan todos los eventos al calendario
                for (let i = 1; i < 8; i++) {
                    if (element.DiasSemana.includes(i.toString())) {
                        const evento = {
                            title: `${canalPrecio.Descripcion} - Q${element.Precio}`,
                            start: new Date(2022, 10, 6 + i, horaIn, minIn),
                            end: new Date(2022, 10, 6 + i, horaF, minF),
                            color: bgColor,
                            idPrecio: element.idUnico,
                        }
                        preciosTransformados.push(evento);
                    }
                }

            });

            // Convertir precios a un array que pueda entender la agenda
            nuevosPrecios.forEach(element => {
                for (let j = 1; j < 8; j++) {
                    if (element.DiasSemana.includes(j)) {
                        element.DescripcionCanal = state.response.productosApi.canales.filter(can => can.CodigoCanal == element.CodigoCanal)[0].Descripcion;
                        Object.values(preciosAg)[j - 1].push(element);
                    }
                }
            })
        }

        if (nuevosPrecios.length === 0) {
            Swal.fire({
                title: 'Producto sin precios',
                text: 'Si se elimina el último precio, el producto no se podrá crear/actualizar. ¿Desea eliminarlo?',
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: 'ui orange button',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    const payload = {
                        nuevosPrecios,
                        preciosAg,
                        preciosTransformados,
                    }
                    dispatch({ type: 'ELIMINAR_PRECIO', payload });
                }
                else {
                    return;
                }
            })
        }
        else {
            const payload = {
                nuevosPrecios,
                preciosAg,
                preciosTransformados,
            }
            dispatch({ type: 'ELIMINAR_PRECIO', payload });
        }
    }

    // Transforma un precio en un evento para el calendario
    const transformarArray = (precio) => {
        // Canal del precio
        let canalPrecio = state.response.productosApi.canales.filter(canal => canal.CodigoCanal == precio.CodigoCanal)[0];

        // Color del evento
        const color = colorRandom();
        const bgColor = '#' + color;

        // Horario
        let horaIn = precio.HoraInicio.split(':')[0];
        let minIn = precio.HoraInicio.split(':')[1];
        if (horaIn[0] === '0') horaIn = horaIn[1];
        if (minIn[0] === '0') minIn = minIn[1];

        let horaF = precio.HoraFin.split(':')[0];
        let minF = precio.HoraFin.split(':')[1];
        if (horaF[0] === '0') horaF = horaF[1];
        if (horaF[0] === '0') minF = minF[1];

        // Eventos que ya se encuentran en el calendario
        let arrayEventos = [...state.eventoPrecios];

        // Transformación del precio a evento todos los días que se requiera
        for (let i = 1; i < 8; i++) {
            if (precio.DiasSemana.includes(i.toString())) {
                const evento = {
                    title: `${canalPrecio.Descripcion} - Q${precio.Precio}`,
                    start: new Date(2022, 10, 6 + i, horaIn, minIn),
                    end: new Date(2022, 10, 6 + i, horaF, minF),
                    color: bgColor,
                    idPrecio: precio.idUnico,
                }
                arrayEventos.push(evento);
            }
        }

        // Retorna el nuevo array de todos los eventos actuales
        return arrayEventos;

    }

    // Esto sirve para transformar un precioUnico en lugar de un array
    // La única diferencia entre esta función y la anterior es que el arrayEventos empieza desde 0 y no con
    // los eventos ya agregados
    const transformarPrecioUnico = (precio) => {
        // Canal del precio
        let canalPrecio = state.response.productosApi.canales.filter(canal => canal.CodigoCanal == precio.CodigoCanal)[0];

        // Color del evento
        const color = colorRandom();
        const bgColor = '#' + color;

        // Horario
        let horaIn = precio.HoraInicio.split(':')[0];
        let minIn = precio.HoraInicio.split(':')[1];
        if (horaIn[0] === '0') horaIn = horaIn[1];
        if (minIn[0] === '0') minIn = minIn[1];

        let horaF = precio.HoraFin.split(':')[0];
        let minF = precio.HoraFin.split(':')[1];
        if (horaF[0] === '0') horaF = horaF[1];
        if (horaF[0] === '0') minF = minF[1];

        let arrayEventos = [];

        for (let i = 1; i < 8; i++) {
            if (precio.DiasSemana.includes(i.toString())) {
                const evento = {
                    title: `${canalPrecio.Descripcion} - Q${precio.Precio}`,
                    start: new Date(2022, 10, 6 + i, horaIn, minIn),
                    end: new Date(2022, 10, 6 + i, horaF, minF),
                    color: bgColor,
                    idPrecio: precio.idUnico,
                }
                arrayEventos.push(evento);
            }
        }

        return arrayEventos;
    }

    // Función para seleccionar todos los canales disponibles al agregar/modificar precios
    const todosCanales = () => {

        // TODOS es un switch, cuando se cambia, se niega el valor
        const valorTodos = !state.formPrecio.TodosCanales;

        // Estado anterior de el switch
        const estadoAnterior = state.estadoTodosAnterior;

        dispatch({ type: 'ACTUALIZAR_ESTADO_TODOS', payload: state.estados.estadoPrecio });

        // Si seleccionaron todos los canales
        if (valorTodos) {

            let estadoNuevo = '';
            if (state.estados.estadoPrecio === 'crear') estadoNuevo = 'todos-canales-crear';
            else estadoNuevo = 'todos-canales-editar';

            const payload = {
                canal: 'TODOS',
                estado: estadoNuevo,
                valorTodos,
            }

            dispatch({ type: 'TODOS_CANALES', payload });

        }
        else {

            const payload = {
                canal: 'Nada',
                estado: estadoAnterior,
                valorTodos,
            }

            dispatch({ type: 'TODOS_CANALES', payload });

        }
    }

    // Función para seleccionar todos los días de la semana
    const todosDias = () => {
        const estadoTodos = !state.diasSemana.Todos;

        if (estadoTodos === true) {
            dispatch({ type: 'TODOS_DIAS', payload: true });
        }
        else {
            dispatch({ type: 'TODOS_DIAS', payload: false });
        }
    }

    // Función para seleccionar uno de los tres horarios predeterminados para un precio
    const todoElDia = (opcion) => {

        // Se cambia el horario del producto dependiendo del switch seleccionado
        if (opcion === 'TodoDia') {
            const estadoDia = !state.formPrecio.TodoDia;

            if (estadoDia === true) {
                const payload = {
                    HoraInicio: '00:00',
                    HoraFin: '23:59',
                    Desayuno: false,
                    TodoDia: true,
                    AlmuerzoCena: false,
                }

                dispatch({ type: 'FORM_TODO_DIA', payload });
            }
            else {
                const payload = {
                    HoraInicio: '',
                    HoraFin: '',
                    TodoDia: false,
                }

                dispatch({ type: 'FORM_TODO_DIA', payload });
            }
        }
        else if (opcion === 'Desayuno') {
            const estadoDia = !state.formPrecio.Desayuno;

            if (estadoDia === true) {
                const payload = {
                    HoraInicio: '00:00',
                    HoraFin: '10:59',
                    Desayuno: true,
                    TodoDia: false,
                    AlmuerzoCena: false,
                }

                dispatch({ type: 'FORM_TODO_DIA', payload });
            }
            else {
                const payload = {
                    HoraInicio: '',
                    HoraFin: '',
                    Desayuno: false,
                }

                dispatch({ type: 'FORM_TODO_DIA', payload });
            }
        }
        else if (opcion === 'AlmuerzoCena') {
            const estadoDia = !state.formPrecio.AlmuerzoCena;

            if (estadoDia === true) {
                const payload = {
                    HoraInicio: '11:00',
                    HoraFin: '23:59',
                    Desayuno: false,
                    TodoDia: false,
                    AlmuerzoCena: true,
                }

                dispatch({ type: 'FORM_TODO_DIA', payload });
            }
            else {
                const payload = {
                    HoraInicio: '',
                    HoraFin: '',
                    AlmuerzoCena: false,
                }

                dispatch({ type: 'FORM_TODO_DIA', payload });
            }
        }
    }

    // Actualizaciones de estado
    const cambiarEstadoPrecio = (estadoNuevo) => {
        dispatch({ type: 'ACTUALIZAR_ESTADO_PRECIO', payload: estadoNuevo });
    }

    const cambiarEstadoEditarComplemento = (estadoNuevo) => {
        dispatch({ type: 'ACTUALIZAR_ESTADO_EDITAR_COMP', payload: estadoNuevo });
    }

    const cambiarEstadoComplemento = (estadoNuevo) => {
        dispatch({ type: 'ACTUALIZAR_ESTADO_COMP', payload: estadoNuevo });
    }

    const cambiarEstadoMenu = (estadoNuevo) => {
        dispatch({ type: 'ACTUALIZAR_ESTADO_MENU', payload: estadoNuevo });
    }

    // Función para cambiar los valores de los días de la semana en el formulario
    const cambiarEstadoDiaSemana = (dia) => {

        let payload = {}

        if (dia === 'Lunes') {
            payload = {
                name: dia,
                value: !state.diasSemana.Lunes,
            }
            dispatch({ type: 'SET_DIA_SEMANA', payload });
        }
        else if (dia === 'Martes') {
            payload = {
                name: dia,
                value: !state.diasSemana.Martes,
            }
            dispatch({ type: 'SET_DIA_SEMANA', payload });
        }
        else if (dia === 'Miercoles') {
            payload = {
                name: dia,
                value: !state.diasSemana.Miercoles,
            }
            dispatch({ type: 'SET_DIA_SEMANA', payload });
        }
        else if (dia === 'Jueves') {
            payload = {
                name: dia,
                value: !state.diasSemana.Jueves,
            }
            dispatch({ type: 'SET_DIA_SEMANA', payload });
        }
        else if (dia === 'Viernes') {
            payload = {
                name: dia,
                value: !state.diasSemana.Viernes,
            }
            dispatch({ type: 'SET_DIA_SEMANA', payload });
        }
        else if (dia === 'Sabado') {
            payload = {
                name: dia,
                value: !state.diasSemana.Sabado,
            }
            dispatch({ type: 'SET_DIA_SEMANA', payload });
        }
        else if (dia === 'Domingo') {
            payload = {
                name: dia,
                value: !state.diasSemana.Domingo,
            }
            dispatch({ type: 'SET_DIA_SEMANA', payload });
        }
    }

    /*************************************************************************************************************/
    /******************************************** MENUS **********************************************************/
    /*************************************************************************************************************/

    // Handler para busqueda de menus
    const changeBusquedaMenu = (e) => {

        dispatch({ type: 'ACTUALIZAR_MENU_SELECCIONADO', payload: e.target.value });

        if (e.target.value === '') {
            dispatch({ type: 'ACTUALIZAR_MENUS', payload: state.response.productosApi.menuList });
        }
        else {
            let listaAux = [];
            let listaTrues = [];

            // Se busca en el código de menú o en su descripción
            state.response.productosApi.menuList.forEach(element => {
                if (element.node.CodigoMenu.toString().toUpperCase().includes(e.target.value.toUpperCase())) {
                    listaAux.push(element);
                    listaTrues.push('si');
                }
                else if (element.node.Descripcion.toString().toUpperCase().includes(e.target.value.toUpperCase())) {
                    listaAux.push(element);
                    listaTrues.push('si');
                }
                else listaTrues.push('no');
            });

            if (listaTrues.includes('si') && e.target.value !== '') {
                dispatch({ type: 'ACTUALIZAR_ESTADO_MENU', payload: 'buscando' });
            }
            else dispatch({ type: 'ACTUALIZAR_ESTADO_MENU', payload: 'no-encontrado' });

            dispatch({ type: 'ACTUALIZAR_MENUS', payload: listaAux });

        }

    }

    // Botón para seleccionar un menú
    const seleccionarMenu = (menu) => {
        // Todos los menús disponibles
        let varNueva = [...state.response.productosApi.menus];
        // Menú anterior más todos los disponibles
        const combinado = [...state.menus.menuAnterior, varNueva];

        // Si el menú seleccionado tiene hijos
        if (menu.childs.length > 0) {
            let childsAux = [...menu.childs];

            // Se agrega a cada hijo la propiedadd DescripcionPadre
            for (let i = 0; i < childsAux.length; i++) {
                childsAux[i].node = {
                    ...childsAux[i].node,
                    DescripcionPadre: menu.node.Descripcion,
                }
            }

            // Se actualiza el estado y se muestra el listado de hijos
            const payload = {
                combinado,
                childs: menu.childs,
            }
            dispatch({ type: 'SELECCIONAR_MENU_CON_HIJOS', payload });
        }
        else {
            // Si el menú seleccionado no tiene hijos, se proced a mostrar otra pantalla
            dispatch({ type: 'SELECCIONAR_MENU_FINAL', payload: menu.node });
        }
    }

    // Función para regresar al menú padre en el listado de menús
    const regresarMenu = () => {

        // Listado de menús anterior (listado donde se encuentra el padre del menú seleccionado)
        let varAnterior = [...state.menus.menuAnterior];
        const n = varAnterior.length;
        // Se elimina el último valor
        const varNueva = varAnterior[n - 1];
        varAnterior.splice(-1);

        // Se actualiza el estado
        const payload = {
            varNueva,
            varAnterior,
        }

        dispatch({ type: 'REGRESAR_MENU', payload });

    }

    // Agregar menú a la tabla de menús
    const agregarMenu = (menu) => {
        dispatch({ type: 'AGREGAR_MENU', payload: menu });
    }

    // Eliminar menú de la tabla
    const eliminarMenu = (menu) => {
        const menus = [...state.productoDetalles.menuProducto];
        const nuevosMenus = menus.filter(men => men.CodigoMenu !== menu.CodigoMenu);

        dispatch({ type: 'ELIMINAR_MENU', payload: nuevosMenus });
    }

    /******************************************************************************************/

    // Generador de color aleatorio para cuando se agreguen precios a un producto
    const colorRandom = () => {
        let color = Math.floor(Math.random() * 16777215).toString(16);
        return color;
    }

    const onOpenModal = (modalOption) => {
        dispatch({ type: 'NUMERO_MODAL', payload: modalOption });
    }

    // Funcion para poder buscar productos por distintas propiedades en la pantalla principal
    const buscarPropiedades = (elemento, filtro) => {
        let listFind = [];

        for (const property of Object.keys(elemento)) {
            let valorActual = elemento[property];

            if (property == 'CodigoProducto' || property == 'Producto' || property == 'Descripcion' || property == 'CodigoOpcion') {
                if (valorActual.toUpperCase().includes(filtro)) listFind.push('si');
                else listFind.push('no');
            }
        }

        return listFind;
    }

    const regresarAlCalendario = () => {
        dispatch({ type: 'REGRESAR_AL_CALENDARIO' });
    }

    if (state.loading) return <Loader />

    //console.log(state.error);
    if (state.error !== undefined) return <PageError message={state.error.message} source={'productos'} />

    return (
        <ProductosContext.Provider
            value={{
                // General
                state,
                crearPluProdOp,
                changeBusquedaTodosPlus,
                colorRandom,

                // Productos
                cerrarModalCrear,
                crearPluProducto,
                changeFormPluProducto,
                abrirModalEditar,
                changeEditProducto,
                guardarProductoEditado,
                eliminarProducto,
                todosPlus,

                // Complementos
                changeBusquedaComplemento,
                seleccionarComplemento,
                agregarComplemento,
                changeFormComplemento,
                mostrarDetallesComp,
                eliminarComplemento,
                agregarCrearComplemento,
                editarComplementoExistente,
                guardarComplementoEditado,
                cancelarEdicionComplemento,
                cerrarFormComplemento,
                cerrarModalComplemento,
                cambiarEstadoEditarComplemento,
                cambiarEstadoComplemento,

                // Opciones
                changeBusquedaOpcion,
                agregarOpcionTabla,
                eliminarOpcionTabla,
                changeFormOpcion,
                editarOpcionExistente,
                cerrarModalOpcion,
                crearPluOpcion,
                eliminarOpcion,
                abrirModalEditarOpcion,

                // Precios
                changeFormPrecio,
                eliminarPrecio,
                modificarPrecioUnico,
                editarPrecioUnico,
                onSelectCanal,
                onRemoveCanal,
                editarPrecioPorCanal,
                modificarPrecioPorCanal,
                submitFormPrecioCanales,
                todosCanales,
                todosDias,
                todoElDia,
                cambiarEstadoPrecio,
                regresarAlCalendario,
                cambiarEstadoDiaSemana,

                // Menus
                seleccionarMenu,
                changeBusquedaMenu,
                regresarMenu,
                agregarMenu,
                eliminarMenu,
                cambiarEstadoMenu,

                onOpenModal,
            }}
        >
            {children}
        </ProductosContext.Provider>
    )
}

export { ProductosContext, ProductosProvider };