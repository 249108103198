import React, { useEffect } from 'react';
import './style.css';


const ModalCanales = ({ state, dispatch, actions}) => {

  let gruposCanales = []

  state.response.canales.map(item => (
    gruposCanales.map(e => {
      return e.nombreGrupoCanal
    }).indexOf(item.TipoCanal) < 0 ?
    gruposCanales.push({
      nombreGrupoCanal: item.TipoCanal,
      listaCanales: state.response.canales.filter(itemCanal => itemCanal.TipoCanal === item.TipoCanal)
    }) :
    null
  ));
  
  const onCancel = () => {
    dispatch({ type: actions.CERRAR_MODAL });
  };

  const onSelectCanal = (canal) => {
    //NOTA: La comida personal debe facturarse con el nit de la tienda
    if(canal.Descripcion.toUpperCase() === 'COMIDA PERSONAL') {
      dispatch({ type: actions.MODIFICAR_DATO_FISCAL, payload: {...state.datosFiscales, nit: state.response.otrosValores.nitEmpresa} });
    }  

    if(state.orderOptions.codigoCanal !== canal.CodigoCanal) dispatch({ type: actions.LISTA_PRODUCTOS, payload: [] });
    
    dispatch({ type: actions.MODIFICAR_ORDEN, payload: {...state.orderOptions, codigoCanal: canal.CodigoCanal} });
    dispatch({ type: actions.CERRAR_MODAL });
  }

  useEffect(() => {
    window.$('.tabular.menu .item').tab();
  }, []);
  
  return (
    <div className="ui tiny longer modal md-disp">
      <div className="header">
        <h1>Seleccione el canal</h1>
      </div>
      <div className="scrolling content contenedorScroll"  tab>
        <div className="ui top attached tabular menu">
          {gruposCanales.map((item,index) => (
            <a 
              className={index > 0 ? "item" : "active item"}
              key={item.nombreGrupoCanal} 
              data-tab={item.nombreGrupoCanal} 
            >  
              {item.nombreGrupoCanal}
            </a>
          ))}
        </div>  

        {gruposCanales.map((item,index) => (
          <div 
            className={index > 0 ? "ui bottom attached tab segment" : "ui bottom attached active tab segment"} 
            key={item.nombreGrupoCanal+index} 
            data-tab={item.nombreGrupoCanal}
          >
            <div className="ui one column center aligned grid">
              {item.listaCanales.map((itemCanal)=> (
                <div className="column element-height">
                  <button 
                    className="ui fluid inverted orange button btn-modal-height" 
                    key={itemCanal.CodigoCanal}
                    style={{ marginTop: "5px", marginBottom: "5px" }}
                    onClick={() => onSelectCanal(itemCanal)}
                  >
                    <h1>{itemCanal.Descripcion}</h1>
                  </button>
                </div>  
              ))}
          </div>
        </div>
        ))}
      </div>
      <div className="actions">
        <div 
          className="ui large negative right labeled icon button"
          onClick={onCancel}
        >
          Cancelar
          <i className="x icon"></i>
        </div>
      </div>
    </div>
  );
};

export { ModalCanales };