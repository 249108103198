import React, { useEffect, useState, useReducer } from 'react';
import { OrderLogUI } from './OrderLogUI';
import { ordersLogServices } from '../../../api/services/ordersLogServices';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { ACTION_TYPES } from './bitacoraConstants';
import { INITIAL_STATE, bitacoraReducer } from './bitacoraReducer';
import { PageError } from '../../PageError';

const OrderLog = () => {
  const [usuario, guardarUsuario] = useLocalStorage('LOGIN_BETA');

  let inicio = {
    ...INITIAL_STATE,
    formFiltros: {
      ...INITIAL_STATE.formFiltros,
      codigoEmpresa: usuario.Tienda.CodigoEmpresa,
      codigoTienda: usuario.Tienda.CodigoTienda,
    }

  }

  const [state, dispatch] = useReducer(bitacoraReducer, inicio);

  const [error, setError] = useState(undefined);

  const [openModal, setOpenModal] = useState(false);
  const [numberModal, setNumberModal] = useState(undefined);

  const fetchFiltros = async () => {
    try {
      //let errorResponse = '';
      dispatch({ type: ACTION_TYPES.FETCH_START });

      const canalesResponse = await ordersLogServices.ordersLog.getAllCanales(state.formFiltros.codigoEmpresa);
      //console.log('hola');
      const formasDePagoResponse = await ordersLogServices.ordersLog.getFormasDePago(state.formFiltros.codigoEmpresa);

      if (!canalesResponse.data.SUCCESS) {
        // ERROR EN SERVICIO CANALES
        dispatch({ type: ACTION_TYPES.FETCH_START_ERROR, payload: 'ERROR EN SERVICIO DE CANALES' });
      } else if (!formasDePagoResponse.data.SUCCESS) {
        // ERROR EN SERVICIO FORMAS DE PAGO
        dispatch({ type: ACTION_TYPES.FETCH_START_ERROR, payload: 'ERROR EN SERVICIO DE FORMAS DE PAGO' });
      } else {
        // TODO BIEN
        let fetchStart = {
          canales: canalesResponse.data.MESSAGE,
          formasPago: formasDePagoResponse.data.MESSAGE
        }
        dispatch({ type: ACTION_TYPES.FETCH_START_SUCCESS, payload: fetchStart })
      }

    } catch (error) {
      console.error(error);
      setError(error.message);
      dispatch({ type: ACTION_TYPES.FETCH_START_ERROR, payload: error });
    }
  }

  const handleChange = (e) => {
    dispatch({
      type: ACTION_TYPES.CHANGE_FORM,
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      dispatch({ type: ACTION_TYPES.FETCH_DATA });
      //setMostrarMiniLoader(true);
      onOpenModal(1);

      //const ordersLogs = await ordersLogServices.ordersLog.getDocumentoBitacora(state.formFiltros);

      let filtros = { ...state.formFiltros }
      filtros = {
        ...filtros,
        codigoEmpresa: usuario.Tienda.CodigoEmpresa,
        codigoTienda: usuario.Tienda.CodigoTienda,
      }

      //console.log('estado: ', state.formFiltros)
      //const ordersLogs = await ordersLogServices.ordersLog.getBitacoraCompleta(state.formFiltros);
      const ordersLogs = await ordersLogServices.ordersLog.getBitacoraCompleta(filtros);

      if (!ordersLogs.data.SUCCESS) {
        dispatch({ type: ACTION_TYPES.FETCH_DATA_ERROR, payload: 'ERROR EN SERVICIO DOCUMENTO BITACORA' });
        //setMostrarMiniLoader(false);
        onCancelModal();
      } else {
        dispatch({ type: ACTION_TYPES.FETCH_DATA_SUCCESS, payload: ordersLogs.data.MESSAGE });
        //setMostrarMiniLoader(false);
        onCancelModal();
      }

    } catch (error) {
      console.error(error);
      setError(error.message);
      dispatch({ type: ACTION_TYPES.FETCH_DATA_ERROR, payload: error.message });
    }
  }

  const limpiarFiltros = (e) => {
    e.preventDefault();
    dispatch({ type: ACTION_TYPES.LIMPIAR_FILTROS });
  }

  const handleModalLog = (order) => {
    dispatch({ type: ACTION_TYPES.DOCUMENTO_BITACORA, payload: order });
    dispatch({ type: ACTION_TYPES.ABRIR_MODAL });
  };

  const handleCerrarModal = () => {
    dispatch({ type: ACTION_TYPES.CERRAR_MODAL });
  }

  const onOpenModal = (modalOption) => {
    setOpenModal(prevState => !prevState);
    setNumberModal(modalOption);
  }

  const onCancelModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    fetchFiltros();
  }, []);

  if (error !== undefined) return <PageError message={error} source={'bitacora'} />

  return (
    <OrderLogUI
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleModalLog={handleModalLog}
      handleCerrarModal={handleCerrarModal}
      state={state}
      onOpenModal={onOpenModal}
      openModal={openModal}
      onCancelModal={onCancelModal}
      numberModal={numberModal}
      limpiarFiltros={limpiarFiltros}
    />
  );
};

export { OrderLog };