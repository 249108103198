export const gridTeclado = [
  { id: "1", valor: "1", size: "normal", icon: "no" },
  { id: "2", valor: "2", size: "normal", icon: "no" },
  { id: "3", valor: "3", size: "normal", icon: "no" },
  { id: "4", valor: "4", size: "normal", icon: "no" },
  { id: "5", valor: "5", size: "normal", icon: "no" },
  { id: "6", valor: "6", size: "normal", icon: "no" },
  { id: "7", valor: "7", size: "normal", icon: "no" },
  { id: "8", valor: "8", size: "normal", icon: "no" },
  { id: "9", valor: "9", size: "normal", icon: "no" },
  { id: "0", valor: "0", size: "normal", icon: "no" },
  { id: "bckspc", valor: "backspace", size: "wide", icon: "backward icon" },
  { id: "dolar", valor: "$", size: "normal", icon: "no" },
  { id: "percent", valor: "%", size: "normal", icon: "no" },
  { id: "q", valor: "q", size: "normal", icon: "no" },
  { id: "w", valor: "w", size: "normal", icon: "no" },
  { id: "e", valor: "e", size: "normal", icon: "no" },
  { id: "r", valor: "r", size: "normal", icon: "no" },
  { id: "t", valor: "t", size: "normal", icon: "no" },
  { id: "y", valor: "y", size: "normal", icon: "no" },
  { id: "u", valor: "u", size: "normal", icon: "no" },
  { id: "i", valor: "i", size: "normal", icon: "no" },
  { id: "o", valor: "o", size: "normal", icon: "no" },
  { id: "p", valor: "p", size: "normal", icon: "no" },
  { id: "asterisk", valor: "*", size: "normal", icon: "no" },
  { id: "ampersand", valor: "&", size: "normal", icon: "no" },
  {
    id: "cps",
    valor: "caps",
    size: "wide",
    icon: "arrow alternate circle up icon",
  },
  { id: "a", valor: "a", size: "normal", icon: "no" },
  { id: "s", valor: "s", size: "normal", icon: "no" },
  { id: "d", valor: "d", size: "normal", icon: "no" },
  { id: "f", valor: "f", size: "normal", icon: "no" },
  { id: "g", valor: "g", size: "normal", icon: "no" },
  { id: "h", valor: "h", size: "normal", icon: "no" },
  { id: "j", valor: "j", size: "normal", icon: "no" },
  { id: "k", valor: "k", size: "normal", icon: "no" },
  { id: "l", valor: "l", size: "normal", icon: "no" },
  {
    id: "ntr",
    valor: "",
    size: "wide",
    icon: "long arrow alternate left icon",
  },
  { id: "z", valor: "z", size: "normal", icon: "no" },
  { id: "x", valor: "x", size: "normal", icon: "no" },
  { id: "c", valor: "c", size: "normal", icon: "no" },
  { id: "v", valor: "v", size: "normal", icon: "no" },
  { id: "b", valor: "b", size: "normal", icon: "no" },
  { id: "n", valor: "n", size: "normal", icon: "no" },
  { id: "m", valor: "m", size: "normal", icon: "no" },
  { id: "sharp", valor: "#", size: "normal", icon: "no" },
  { id: "at", valor: "@", size: "normal", icon: "no" },
  { id: "qsmark", valor: "?", size: "normal", icon: "no" },
  { id: "exmark", valor: "!", size: "normal", icon: "no" },
  { id: "spc", valor: " ", size: "extra-wide", icon: "space shuttle icon" },
];

/*const grid = [
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'backspace',
    '$', '%', 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '*', '&',
    'caps', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'enter',
    'z', 'x', 'c', 'v', 'b', 'n', 'm', '#', '@', '?', '!',
    'space'
]*/
