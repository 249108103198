import React from "react";
import { BuscarMenu } from "./BuscarMenu";
import { TablaMenus } from "./TablaMenus";

const TabMenus = () => {
    return (
        <div className="ui center aligned two column grid">
            <div className="nine wide column">
                <BuscarMenu />

            </div>
            <div className="seven wide column">
                <TablaMenus />
            </div>

        </div>
    )
}

export { TabMenus }