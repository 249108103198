import React from 'react';

import { PosUi } from './PosUi';
import { PosProvider } from '../PosContext';

import './style.css';

const Pos = () => {
  return (
    <PosProvider>
      <PosUi />
    </PosProvider>
  );
};

export { Pos };