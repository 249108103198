import { useContext } from "react"
import { ProductosContext } from "../ProductosContext"

const AgendaPrecios = () => {

    const {
        state,
        eliminarPrecio,
        modificarPrecioUnico,
        modificarPrecioPorCanal,
        cambiarEstadoPrecio,
    } = useContext(ProductosContext);

    let spanResto = {}; // Objeto para almacenar toda la información de los precios de cada día que no sean
    // de la primera fila

    // Si hay precios para la agenda
    if (Object.keys(state.preciosAgenda).length !== 0) {

        // Todo lo realizado a continuación es necesario para desplegar la información de la mejor forma en la
        // tabla (agenda)

        let lunesSpan = 0; // Cantidad de precios
        let restoLunes = {}; // Objeto para precios de Lunes que no sean el primero

        // Si no existen precios para Lunes
        if (state.preciosAgenda.Lunes === undefined) {
            lunesSpan = 0;
            restoLunes = {};
        }
        // Si existen
        else {
            // Cantidad de precios de Lunes
            lunesSpan = state.preciosAgenda.Lunes.length;
            // Todos los precios de Lunes sin incluir el primero
            restoLunes = state.preciosAgenda.Lunes.slice(1);
        }

        let martesSpan = 0;
        let restoMartes = {};
        if (state.preciosAgenda.Martes === undefined) {
            martesSpan = 0;
            restoMartes = {};
        }
        else {
            martesSpan = state.preciosAgenda.Martes.length;
            restoMartes = state.preciosAgenda.Martes.slice(1);
        }

        let miercolesSpan = 0;
        let restoMiercoles = {};
        if (state.preciosAgenda.Miercoles === undefined) {
            miercolesSpan = 0;
            restoMiercoles = {};
        }
        else {
            miercolesSpan = state.preciosAgenda.Miercoles.length;
            restoMiercoles = state.preciosAgenda.Miercoles.slice(1);
        }

        let juevesSpan = 0;
        let restoJueves = {};
        if (state.preciosAgenda.Jueves === undefined) {
            juevesSpan = 0;
            restoJueves = {};
        }
        else {
            juevesSpan = state.preciosAgenda.Jueves.length;
            restoJueves = state.preciosAgenda.Jueves.slice(1);
        }

        let viernesSpan = 0;
        let restoViernes = {};
        if (state.preciosAgenda.Viernes === undefined) {
            viernesSpan = 0;
            restoViernes = {};
        }
        else {
            viernesSpan = state.preciosAgenda.Viernes.length;
            restoViernes = state.preciosAgenda.Viernes.slice(1);
        }

        let sabadoSpan = 0;
        let restoSabado = {};
        if (state.preciosAgenda.Sabado === undefined) {
            sabadoSpan = 0;
            restoSabado = {};
        }
        else {
            sabadoSpan = state.preciosAgenda.Sabado.length;
            restoSabado = state.preciosAgenda.Sabado.slice(1);
        }

        let domingoSpan = 0;
        let restoDomingo = {};
        if (state.preciosAgenda.Domingo === undefined) {
            domingoSpan = 0;
            restoDomingo = {};
        }
        else {
            domingoSpan = state.preciosAgenda.Domingo.length;
            restoDomingo = state.preciosAgenda.Domingo.slice(1);
        }

        // Se carga toda la información en un objeto común 
        spanResto = {
            lunesSpan: lunesSpan,
            restoLunes: restoLunes,

            martesSpan: martesSpan,
            restoMartes: restoMartes,

            miercolesSpan: miercolesSpan,
            restoMiercoles: restoMiercoles,

            juevesSpan: juevesSpan,
            restoJueves: restoJueves,

            viernesSpan: viernesSpan,
            restoViernes: restoViernes,

            sabadoSpan: sabadoSpan,
            restoSabado: restoSabado,

            domingoSpan: domingoSpan,
            restoDomingo: restoDomingo,

        };
    }
    else {
        spanResto = {};
    }

    return (
        <>
            <div className="ui two column grid">
                <div className="row">
                    <div className="column">
                        <button onClick={() => cambiarEstadoPrecio('crear')} className="ui positive fluid icon labeled button">
                            <i className="plus icon"></i>
                            Agregar precio
                        </button>

                    </div>
                    <div className="column">
                        <button onClick={() => cambiarEstadoPrecio('grafico')} className="ui secondary fluid icon labeled button">
                            <i className="calendar outline icon"></i>
                            Ver gráfico
                        </button>
                    </div>
                </div>
                <div className="centered row">
                    <div className="column">
                        <button onClick={modificarPrecioPorCanal} className="ui blue fluid icon labeled button">
                            <i className="edit icon"></i>
                            Modificar precio por canal
                        </button>

                    </div>
                </div>

            </div>

            {
                (Object.keys(spanResto).length > 0)
                    ? (
                        <table className="ui celled structured table">
                            <thead>
                                <tr>
                                    <th>Día</th>
                                    <th>Precio</th>
                                    <th>Modificar precio</th>
                                    <th>Eliminar precio</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (spanResto.lunesSpan === 0)
                                        ? (
                                            <tr>
                                                <td>Lunes</td>
                                                <td>No hay precios para este día </td>
                                                <td>
                                                    <button
                                                        onClick={null}
                                                        className="fluid disabled centered tiny ui icon blue button"
                                                    >
                                                        <i className="edit icon"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        className="fluid disabled centered tiny ui icon red button"
                                                    >
                                                        <i className="minus icon"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                        : (
                                            // Para que la tabla (agenda) muestre de la forma deseada la información
                                            // es necesario dividarla en dos, primero solo la primera fila y después
                                            // se mapean todos los precios restantes
                                            <tr>
                                                <td rowSpan={spanResto.lunesSpan}>Lunes</td>
                                                <td>{state.preciosAgenda.Lunes[0].DescripcionCanal}: Q{(Math.round(state.preciosAgenda.Lunes[0].Precio * 100) / 100).toFixed(2)} | Horario: {state.preciosAgenda.Lunes[0].HoraInicio} - {state.preciosAgenda.Lunes[0].HoraFin}</td>
                                                <td>
                                                    <button
                                                        onClick={() => modificarPrecioUnico(state.preciosAgenda.Lunes[0])}
                                                        className="fluid centered tiny ui icon blue button"
                                                    >
                                                        <i className="edit icon"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        onClick={() => eliminarPrecio(state.preciosAgenda.Lunes[0])}
                                                        className="fluid centered tiny ui icon red button"
                                                    >
                                                        <i className="minus icon"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                }

                                {
                                    (spanResto.restoLunes.length > 0)
                                        ? (
                                            spanResto.restoLunes.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.DescripcionCanal}: Q{(Math.round(item.Precio * 100) / 100).toFixed(2)} | Horario: {item.HoraInicio} - {item.HoraFin}</td>
                                                        <td>
                                                            <button
                                                                onClick={() => modificarPrecioUnico(item)}
                                                                className="fluid centered tiny ui icon blue button"
                                                            >
                                                                <i className="edit icon"></i>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                                onClick={() => eliminarPrecio(item)}
                                                                className="fluid centered tiny ui icon red button"
                                                            >
                                                                <i className="minus icon"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                        : null
                                }

                                {
                                    (spanResto.martesSpan === 0)
                                        ? (
                                            <tr>
                                                <td>Martes</td>
                                                <td>No hay precios en este día</td>
                                                <td>
                                                    <button
                                                        onClick={null}
                                                        className="fluid disabled centered tiny ui icon blue button"
                                                    >
                                                        <i className="edit icon"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        className="fluid disabled centered tiny ui icon red button"
                                                    >
                                                        <i className="minus icon"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                        : (
                                            <tr>
                                                <td rowSpan={spanResto.martesSpan}>Martes</td>
                                                <td>{state.preciosAgenda.Martes[0].DescripcionCanal}: Q{(Math.round(state.preciosAgenda.Martes[0].Precio * 100) / 100).toFixed(2)} | Horario: {state.preciosAgenda.Martes[0].HoraInicio} - {state.preciosAgenda.Martes[0].HoraFin}</td>
                                                <td>
                                                    <button
                                                        onClick={() => modificarPrecioUnico(state.preciosAgenda.Martes[0])}
                                                        className="fluid centered tiny ui icon blue button"
                                                    >
                                                        <i className="edit icon"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        onClick={() => eliminarPrecio(state.preciosAgenda.Martes[0])}
                                                        className="fluid centered tiny ui icon red button"
                                                    >
                                                        <i className="minus icon"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                }

                                {
                                    (spanResto.restoMartes.length > 0)
                                        ? (
                                            spanResto.restoMartes.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.DescripcionCanal}: Q{(Math.round(item.Precio * 100) / 100).toFixed(2)} | Horario: {item.HoraInicio} - {item.HoraFin}</td>
                                                        <td>
                                                            <button
                                                                onClick={() => modificarPrecioUnico(item)}
                                                                className="fluid centered tiny ui icon blue button"
                                                            >
                                                                <i className="edit icon"></i>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                                onClick={() => eliminarPrecio(item)}
                                                                className="fluid centered tiny ui icon red button"
                                                            >
                                                                <i className="minus icon"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                        : null
                                }

                                {
                                    (spanResto.miercolesSpan === 0)
                                        ? (
                                            <tr>
                                                <td>Miércoles</td>
                                                <td>No hay precios para este día</td>
                                                <td>
                                                    <button
                                                        onClick={null}
                                                        className="fluid disabled centered tiny ui icon blue button"
                                                    >
                                                        <i className="edit icon"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        className="fluid disabled centered tiny ui icon red button"
                                                    >
                                                        <i className="minus icon"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                        : (
                                            <tr>
                                                <td rowSpan={spanResto.miercolesSpan}>Miércoles</td>
                                                <td>{state.preciosAgenda.Miercoles[0].DescripcionCanal}: Q{(Math.round(state.preciosAgenda.Miercoles[0].Precio * 100) / 100).toFixed(2)} | Horario: {state.preciosAgenda.Miercoles[0].HoraInicio} - {state.preciosAgenda.Miercoles[0].HoraFin}</td>
                                                <td>
                                                    <button
                                                        onClick={() => modificarPrecioUnico(state.preciosAgenda.Miercoles[0])}
                                                        className="fluid centered tiny ui icon blue button"
                                                    >
                                                        <i className="edit icon"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        onClick={() => eliminarPrecio(state.preciosAgenda.Miercoles[0])}
                                                        className="fluid centered tiny ui icon red button"
                                                    >
                                                        <i className="minus icon"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                }

                                {
                                    (spanResto.restoMiercoles.length > 0)
                                        ? (
                                            spanResto.restoMiercoles.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.DescripcionCanal}: Q{(Math.round(item.Precio * 100) / 100).toFixed(2)} | Horario: {item.HoraInicio} - {item.HoraFin}</td>
                                                        <td>
                                                            <button
                                                                onClick={() => modificarPrecioUnico(item)}
                                                                className="fluid centered tiny ui icon blue button"
                                                            >
                                                                <i className="edit icon"></i>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                                onClick={() => eliminarPrecio(item)}
                                                                className="fluid centered tiny ui icon red button"
                                                            >
                                                                <i className="minus icon"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                        : null
                                }

                                {
                                    (spanResto.juevesSpan === 0)
                                        ? (
                                            <tr>
                                                <td>Jueves</td>
                                                <td>No hay precios para este día</td>
                                                <td>
                                                    <button
                                                        onClick={null}
                                                        className="fluid disabled centered tiny ui icon blue button"
                                                    >
                                                        <i className="edit icon"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        className="fluid disabled centered tiny ui icon red button"
                                                    >
                                                        <i className="minus icon"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                        : (
                                            <tr>
                                                <td rowSpan={spanResto.juevesSpan}>Jueves</td>
                                                <td>{state.preciosAgenda.Jueves[0].DescripcionCanal}: Q{(Math.round(state.preciosAgenda.Jueves[0].Precio * 100) / 100).toFixed(2)} | Horario: {state.preciosAgenda.Jueves[0].HoraInicio} - {state.preciosAgenda.Jueves[0].HoraFin}</td>
                                                <td>
                                                    <button
                                                        onClick={() => modificarPrecioUnico(state.preciosAgenda.Jueves[0])}
                                                        className="fluid centered tiny ui icon blue button"
                                                    >
                                                        <i className="edit icon"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        onClick={() => eliminarPrecio(state.preciosAgenda.Jueves[0])}
                                                        className="fluid centered tiny ui icon red button"
                                                    >
                                                        <i className="minus icon"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                }
                                {
                                    (spanResto.restoJueves.length > 0)
                                        ? (
                                            spanResto.restoJueves.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.DescripcionCanal}: Q{(Math.round(item.Precio * 100) / 100).toFixed(2)} | Horario: {item.HoraInicio} - {item.HoraFin}</td>
                                                        <td>
                                                            <button
                                                                onClick={() => modificarPrecioUnico(item)}
                                                                className="fluid centered tiny ui icon blue button"
                                                            >
                                                                <i className="edit icon"></i>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                                onClick={() => eliminarPrecio(item)}
                                                                className="fluid centered tiny ui icon red button"
                                                            >
                                                                <i className="minus icon"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                        : null
                                }

                                {
                                    (spanResto.viernesSpan === 0)
                                        ? (
                                            <tr>
                                                <td>Viernes</td>
                                                <td>No hay precios para este día</td>
                                                <td>
                                                    <button
                                                        onClick={null}
                                                        className="fluid disabled centered tiny ui icon blue button"
                                                    >
                                                        <i className="edit icon"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        className="fluid disabled centered tiny ui icon red button"
                                                    >
                                                        <i className="minus icon"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                        : (
                                            <tr>
                                                <td rowSpan={spanResto.viernesSpan}>Viernes</td>
                                                <td>{state.preciosAgenda.Viernes[0].DescripcionCanal}: Q{(Math.round(state.preciosAgenda.Viernes[0].Precio * 100) / 100).toFixed(2)} | Horario: {state.preciosAgenda.Viernes[0].HoraInicio} - {state.preciosAgenda.Viernes[0].HoraFin}</td>
                                                <td>
                                                    <button
                                                        onClick={() => modificarPrecioUnico(state.preciosAgenda.Viernes[0])}
                                                        className="fluid centered tiny ui icon blue button"
                                                    >
                                                        <i className="edit icon"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        onClick={() => eliminarPrecio(state.preciosAgenda.Viernes[0])}
                                                        className="fluid centered tiny ui icon red button"
                                                    >
                                                        <i className="minus icon"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                }
                                {
                                    (spanResto.restoViernes.length > 0)
                                        ? (
                                            spanResto.restoViernes.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.DescripcionCanal}: Q{(Math.round(item.Precio * 100) / 100).toFixed(2)} | Horario: {item.HoraInicio} - {item.HoraFin}</td>
                                                        <td>
                                                            <button
                                                                onClick={() => modificarPrecioUnico(item)}
                                                                className="fluid centered tiny ui icon blue button"
                                                            >
                                                                <i className="edit icon"></i>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                                onClick={() => eliminarPrecio(item)}
                                                                className="fluid centered tiny ui icon red button"
                                                            >
                                                                <i className="minus icon"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                        : null
                                }

                                {
                                    (spanResto.sabadoSpan === 0)
                                        ? (
                                            <tr>
                                                <td>Sábado</td>
                                                <td>No hay precios para este día</td>
                                                <td>
                                                    <button
                                                        onClick={null}
                                                        className="fluid disabled centered tiny ui icon blue button"
                                                    >
                                                        <i className="edit icon"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        className="fluid disabled centered tiny ui icon red button"
                                                    >
                                                        <i className="minus icon"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                        : (
                                            <tr>
                                                <td rowSpan={spanResto.sabadoSpan}>Sábado</td>
                                                <td>{state.preciosAgenda.Sabado[0].DescripcionCanal}: Q{(Math.round(state.preciosAgenda.Sabado[0].Precio * 100) / 100).toFixed(2)} | Horario: {state.preciosAgenda.Sabado[0].HoraInicio} - {state.preciosAgenda.Sabado[0].HoraFin}</td>
                                                <td>
                                                    <button
                                                        onClick={() => modificarPrecioUnico(state.preciosAgenda.Sabado[0])}
                                                        className="fluid centered tiny ui icon blue button"
                                                    >
                                                        <i className="edit icon"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        onClick={() => eliminarPrecio(state.preciosAgenda.Sabado[0])}
                                                        className="fluid centered tiny ui icon red button"
                                                    >
                                                        <i className="minus icon"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                }
                                {
                                    (spanResto.restoSabado.length > 0)
                                        ? (
                                            spanResto.restoSabado.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.DescripcionCanal}: Q{(Math.round(item.Precio * 100) / 100).toFixed(2)} | Horario: {item.HoraInicio} - {item.HoraFin}</td>
                                                        <td>
                                                            <button
                                                                onClick={() => modificarPrecioUnico(item)}
                                                                className="fluid centered tiny ui icon blue button"
                                                            >
                                                                <i className="edit icon"></i>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                                onClick={() => eliminarPrecio(item)}
                                                                className="fluid centered tiny ui icon red button"
                                                            >
                                                                <i className="minus icon"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                        : null
                                }

                                {
                                    (spanResto.domingoSpan === 0)
                                        ? (
                                            <tr>
                                                <td>Domingo</td>
                                                <td>No hay precios para este día</td>
                                                <td>
                                                    <button
                                                        onClick={null}
                                                        className="fluid disabled centered tiny ui icon blue button"
                                                    >
                                                        <i className="edit icon"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        className="fluid disabled centered tiny ui icon red button"
                                                    >
                                                        <i className="minus icon"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                        : (
                                            <tr>
                                                <td rowSpan={spanResto.domingoSpan}>Domingo</td>
                                                <td>{state.preciosAgenda.Domingo[0].DescripcionCanal}: Q{(Math.round(state.preciosAgenda.Domingo[0].Precio * 100) / 100).toFixed(2)} | Horario: {state.preciosAgenda.Domingo[0].HoraInicio} - {state.preciosAgenda.Domingo[0].HoraFin}</td>
                                                <td>
                                                    <button
                                                        onClick={() => modificarPrecioUnico(state.preciosAgenda.Domingo[0])}
                                                        className="fluid centered tiny ui icon blue button"
                                                    >
                                                        <i className="edit icon"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        onClick={() => eliminarPrecio(state.preciosAgenda.Domingo[0])}
                                                        className="fluid centered tiny ui icon red button"
                                                    >
                                                        <i className="minus icon"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                }


                                {
                                    (spanResto.restoDomingo.length > 0)
                                        ? (
                                            spanResto.restoDomingo.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.DescripcionCanal}: Q{(Math.round(item.Precio * 100) / 100).toFixed(2)} | Horario: {item.HoraInicio} - {item.HoraFin}</td>
                                                        <td>
                                                            <button
                                                                onClick={() => modificarPrecioUnico(item)}
                                                                className="fluid centered tiny ui icon blue button"
                                                            >
                                                                <i className="edit icon"></i>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                                onClick={() => eliminarPrecio(item)}
                                                                className="fluid centered tiny ui icon red button"
                                                            >
                                                                <i className="minus icon"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                        : null
                                }
                            </tbody>
                        </table>
                    )
                    : (
                        <h3 className="ui center aligned header">
                            No hay precios para mostrar
                        </h3>
                    )
            }

        </>
    )
}

export { AgendaPrecios }