// MODAL 8
import React, {  useState } from "react";
import { TecladoNumerico } from "../../TecladoNumerico";
import "./style.css";

const ModalPagaCon = ({state, dispatch, actions, totalOrder, calcularDescuento, onCreateOrder}) => {

  const totalOrden = (totalOrder + calcularDescuento());
  const formaDePago = state.orderOptions.formaDePago.toUpperCase();
    
  const [pagaCon, setPagaCon] = useState('');

  const inputHandler = (valor) => {
    let valorAcumulado = pagaCon;
    if (typeof valor === "number") {
      valorAcumulado = valorAcumulado + valor;
    } else if (valor === ".") {
      if (pagaCon.replace(/[^.]/g, "").length < 1 && valor === ".") {
        valorAcumulado = pagaCon + valor;
      }
    } else if(valor === '!#') {
      valorAcumulado = valorAcumulado.slice(0, -1);
    } else {
      const valorEvento = valor.target.value;

      if (valorEvento.replace(/[^.]/g, "").length <= 1) {
        if (
          !!Number.isFinite(parseFloat(valorEvento)) ||
          valorEvento === "." ||
          valorEvento[valorEvento.length - 1] == "."
        ) {
          valorAcumulado = valorEvento;
        }
      }
    }

    setPagaCon(valorAcumulado);
  };

  const pagoEntero = (montoPago) => {
    setPagaCon(montoPago + '');
  }

  const terminarOrden = () => {
    let totalPagaCon = pagaCon !== '' ? parseFloat(pagaCon) : 0;
    if(formaDePago === 'EFECTIVO') {
      //como es efectivo se inserta directamente la orden
      dispatch({ type: actions.MODIFICAR_DATO_FISCAL, payload: {...state.datosFiscales, pagaCon: totalPagaCon} });
      dispatch({ type: actions.NUMERO_MODAL, payload: 11 });
      
      onCreateOrder(totalPagaCon, null);
    } else if(formaDePago === 'DIVIDIDA') {
      // que pasa si solo quiere que se le cobre un porcentaje con un billete grande???

      //verificamos con cuanto va a pagar el cliente, si el monto supera el total de la orden
      //si es el caso se cobra como efectivo
      if(totalPagaCon < totalOrden && totalPagaCon > 0) {
        // PAGA SOLO PARTE DEL TOTAL
        // EN ESTE CASO SE REDIRIGE A UN MODAL PARA INDICAR EL TOTAL A PAGAR EN 
        dispatch({ type: actions.MODIFICAR_DATO_FISCAL, payload: {...state.datosFiscales, pagaCon: totalPagaCon} });
        dispatch({ type: actions.NUMERO_MODAL, payload: 19 });
        
      } else if(totalPagaCon >= totalOrden) {
        //PAGA EL TOTAL DE LA ORDEN, POR LO TANTO LA ORDEN SE CANCELA COMO EFECTIVO
        dispatch({ type: actions.MODIFICAR_DATO_FISCAL, payload: {...state.datosFiscales, pagaCon: totalPagaCon} });
        dispatch({ type: actions.NUMERO_MODAL, payload: 11 });
        
        onCreateOrder(totalPagaCon, null);
      }
    }
  }

  let classBtnCalcular = "ui primary let labeled icon button";
  let valorPagaCon = pagaCon === "" ? 0 : parseFloat(pagaCon);
  
  if (
    (valorPagaCon < totalOrden && formaDePago === "EFECTIVO") ||
    (valorPagaCon <= 0 && formaDePago === "DIVIDIDA")
  ) {
    classBtnCalcular += " disabled";
  }
  
  let vuelto = '0.00';
  if(pagaCon !== '') {
    vuelto = (valorPagaCon - totalOrden).toFixed(2);
  }

  return (
    <div className="ui tiny longer modal md-disp" id="panelFondoCaja">
      <div className="header">DATOS DE PAGO EN EFECTIVO</div>

      <div className="header">
        Total: Q. {(totalOrden).toFixed(2)}
        <br />
        <h2 className="m-0">{pagaCon >= (totalOrden) && `Cambio: Q. ${vuelto}`}</h2>
      </div>

      <div className="content">
        <div className="ui grid">
          <div className="row padBot">
            <div className="sixteen wide column padBot">
              <div className="ui fluid action labeled input">
              <div className="ui label">
                Q.
              </div>
                <input
                  onChange={(e) => inputHandler(e)}
                  value={pagaCon !== '' ? parseFloat(pagaCon).toFixed(2) + '' : parseFloat(0).toFixed(2)}
                  type="text"
                  placeholder="PAGA CON"
                />
                <button className="ui teal right labeled icon button" onClick={() => pagoEntero((totalOrden))}>
                  <i className="money bill alternate outline icon"></i>
                  Pago Exacto
                </button>
              </div>
            </div>
          </div>

          <div className="five large ui green buttons mt-10">
            <button onClick={() => pagoEntero('5')} className="ui button">5</button>
            <button onClick={() => pagoEntero('10')} className="ui button">10</button>
            <button onClick={() => pagoEntero('20')} className="ui button">20</button>
            <button onClick={() => pagoEntero('50')} className="ui button">50</button>
            <button onClick={() => pagoEntero('100')} className="ui button">100</button>
          </div>

          <div className="stretched row padBotTop mt-10">
            <TecladoNumerico inputHandler={inputHandler} usarDecimal={true} />
          </div>
        </div>
      </div>

      <div className="actions">
        
      <button
            className={classBtnCalcular}
            onClick={() => terminarOrden()}
          >
            ACEPTAR
            <i className="check icon"></i>
          </button> 
          <button
            className="ui red let labeled icon button"
            onClick={() => dispatch({ type: actions.CERRAR_MODAL })}
          >
            CANCELAR
            <i className="x icon"></i>
          </button>
      </div>
    </div>
  );
};

export { ModalPagaCon };
