const convertirArbolALista = (raiz) => {
  let stack = [],
    array = [],
    hashMap = {};
  stack.push(raiz);

  while (stack.length !== 0) {
    let node = stack.pop();
    if (node.childs === null || node.childs.length === 0) {
      visitarNodo(node, hashMap, array);
    } else {
      for (let i = node.childs.length - 1; i >= 0; i--) {
        stack.push(node.childs[i]);
      }
    }
  }

  if(raiz.childs.length === 0) {
    array = [raiz.node];
  } else {
    array = [...array, raiz.node];
  }

  return array;
};

const visitarNodo = (nodo, hashMap, array) => {
  if (!hashMap[nodo.node.CodigoMenu]) {
    hashMap[nodo.node.CodigoMenu] = true;
    array.push(nodo.node);
  }
};

const buscarMenuProductos = (nodo) => {
  let stack = [], array = [], hashMap = {};
  stack.push(nodo);

  while(stack.length !== 0) {
    let node = stack.pop();
    if(node.childs === null || node.childs.length === 0) {
      visitarNodoParaProducto(node, hashMap, array)
    } else {
      for(let i = node.childs.length - 1; i >= 0; i--){
        stack.push(node.childs[i]);
      }
    }
  }

  nodo.node.ProductoMenu.forEach(item => array.push(item));
  
  return array;
}

const visitarNodoParaProducto = (nodo, hashMap, array) => {
  if(!hashMap[nodo.node.CodigoMenu]) {
    hashMap[nodo.node.CodigoMenu] = true;
    nodo.node.ProductoMenu.forEach(item => array.push(item));
  }
}

const buscarNodoEnArbol = (raiz, idMenu) => {
  let stack = [], array = [], hashMap = {};
  stack.push(raiz);

  while(stack.length !== 0) {
    let node = stack.pop();
    if(node.childs === null || node.childs.length === 0) {
      if(node.node.CodigoMenu == idMenu) return node;
    } else {
      for(let i = node.childs.length - 1; i >= 0; i--){
        stack.push(node.childs[i]);
      }
    }
  }

  if(raiz.node.CodigoMenu == idMenu) return raiz;

  return null;
}

const removerObjetosDuplicados = (arreglo = []) => {
  /*let nuevoArreglo = [];
  let unicoObjeto = {};

  for (let item in arreglo) {

  }*/

  let jsonObject = arreglo.map(JSON.stringify);

  let unicoSet = new Set(jsonObject);
  let unicoArray = Array.from(unicoSet).map(JSON.parse);

  return unicoArray;
}

export { convertirArbolALista, visitarNodo, buscarMenuProductos, visitarNodoParaProducto, buscarNodoEnArbol, removerObjetosDuplicados };
