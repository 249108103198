import { useContext } from "react"
import { ProductosContext } from "../ProductosContext"
import { Registro } from "./Registro"
import { Tabla } from "./Tabla"

const TablaOpciones = ({ compDetalle }) => {

    const { eliminarOpcionTabla } = useContext(ProductosContext);

    return (
        <Tabla>
            <Registro compDetalle={compDetalle} eliminarOpcionTabla={eliminarOpcionTabla} />
        </Tabla>
    )
}

export { TablaOpciones }