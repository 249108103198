import React from "react"
import { RegistroDetalle } from "../RegistroDetalle"
import { TablaDetalle } from "../TablaDetalle"

const ModalDetalles = ({ onCancelModal, detalleDescuento, pais, turnoSeleccionado, descuentoSeleccionado }) => {
    return (
        <div className="ui longer modal md-disp ">

            <div className="header">
                <h1>Detalles {descuentoSeleccionado} - Turno: {turnoSeleccionado}</h1>
            </div>


            <div className="scrolling content color-contenido">

                <TablaDetalle pais={pais}>
                    <RegistroDetalle detalleDescuento={detalleDescuento} />
                </TablaDetalle>

            </div>
            <div className="actions">
                <button
                    type="button"
                    onClick={onCancelModal}
                    className="ui negative right labeled icon button"
                >
                    Cerrar
                    <i className="x icon"></i>
                </button>
            </div>
        </div>
    )
}

export { ModalDetalles }