const DetalleGeneral = ({ orden, estadoOrden, NombreColaborador, ApellidoColaborador }) => {

    let tiempo, hora = ''

    if (estadoOrden === true) {
        tiempo = orden.Orden.TiempoCreacion.split('T')[1];
        hora = tiempo.split('.')[0];
    }

    return (
        <div className="ui segment">
            {
                (estadoOrden == true)
                    ? (
                        <form className="ui form">
                            <div className="two fields">
                                <div className="field">
                                    <div className="ui big orange label">
                                        Restaurante: <div className="detail">{orden.Orden.CodigoTienda}</div>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="ui big label">
                                        Caja: <div className="detail"> {orden.Orden.CodigoCaja} </div>
                                    </div>
                                </div>

                            </div>
                            <div className="two fields">
                                <div className="field">
                                    <div className="ui big orange label">
                                        Forma Pago: <div className="detail"> {orden.Orden.FormaDePago} </div>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="ui big label">
                                        Canal: <div className="detail"> {orden.Orden.DescripcionCanal} </div>
                                    </div>
                                </div>

                            </div>
                            <div className="two fields">
                                <div className="field">
                                    <div className="ui big orange label">
                                        Estado:
                                        {
                                            (orden.Orden.CodigoEtapa === 'ANUL')
                                                ? <div className="detail">ANULADO</div>
                                                : (orden.OrdenFactura === null)
                                                    ? <div className="detail">No facturado</div>
                                                    : <div className="detail">{orden.OrdenFactura.Estado}</div>
                                        }
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="ui big label">
                                        Hora: <div className="detail">
                                            {hora}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {
                                (orden.Orden.FormaDePago === 'EFECTIVO')
                                    ? null
                                    : (
                                        <div className="two fields">
                                            <div className="field">
                                                <div className="ui big label">
                                                    Tarjeta: <div className="detail">Visa</div>
                                                </div>
                                            </div>
                                            <div className="field">
                                                <div className="ui big label">
                                                    POS: <div className="detail">Credomatic</div>
                                                </div>
                                            </div>

                                        </div>
                                    )
                            }

                            <div className="field">
                                <div className="ui big label">
                                    Empleado: <div className="detail"> {NombreColaborador} {ApellidoColaborador} </div>
                                </div>
                            </div>

                        </form>
                    )
                    : null
            }

        </div>
    )
}

export { DetalleGeneral }