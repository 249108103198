import React, { useContext } from "react";
import { ProductosContext } from "../ProductosContext";

const FormCrearComplemento = ({ estado }) => {

    const {
        state,
        changeFormComplemento,
        agregarCrearComplemento,
        cancelarEdicionComplemento,
        cerrarFormComplemento,
        cambiarEstadoEditarComplemento,
    } = useContext(ProductosContext);

    return (
        <>
            <form className="ui form">
                <div className="two fields">
                    <div className="field">
                        <label>Código de Complemento</label>
                        <input onChange={(e) => changeFormComplemento(e)} value={state.formComplemento.CodigoComplemento} name="CodigoComplemento" type="text" />
                    </div>
                    <div className="field">
                        <label>Posición</label>
                        <input onChange={(e) => changeFormComplemento(e)} value={state.formComplemento.Posicion} name="Posicion" type="text" />
                    </div>
                </div>
                <div className="field">
                    <label>Descripción</label>
                    <input onChange={(e) => changeFormComplemento(e)} value={state.formComplemento.Descripcion} name="Descripcion" type="text" />
                </div>
                <div className="three fields">
                    <div className="field">
                        <label>Cant. por defecto</label>
                        <input onChange={(e) => changeFormComplemento(e)} value={state.formComplemento.CantidadDefecto} name="CantidadDefecto" type="text" />
                    </div>
                    <div className="field">
                        <label>Cant. Mínima</label>
                        <input onChange={(e) => changeFormComplemento(e)} value={state.formComplemento.CantidadMinima} name="CantidadMinima" type="text" />
                    </div>
                    <div className="field">
                        <label>Cant. Máxima</label>
                        <input onChange={(e) => changeFormComplemento(e)} value={state.formComplemento.CantidadMaxima} name="CantidadMaxima" type="text" />
                    </div>
                </div>
            </form>

            {
                (estado === 'crear-producto')
                    ? (
                        <div className="ui two column grid">
                            <div className="column">
                                <button onClick={() => cerrarFormComplemento()} className='ui grey fluid icon labeled button'>
                                    <i className="close icon"></i>
                                    Cancelar
                                </button>
                            </div>
                            <div className="column">
                                <button onClick={agregarCrearComplemento} className="ui positive fluid icon labeled button">
                                    <i className="plus icon"></i>
                                    Crear
                                </button>
                            </div>
                        </div>
                    )
                    : (estado === 'crear-complemento')
                        ? (
                            <button onClick={agregarCrearComplemento} className="ui positive fluid icon labeled button">
                                <i className="plus icon"></i>
                                Crear
                            </button>
                        )
                        : (
                            <>
                                <div className="ui two column grid">
                                    <div className="column">
                                        <button onClick={() => cancelarEdicionComplemento()} className='ui grey fluid icon labeled button'>
                                            <i className="close icon"></i>
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="column">
                                        <button onClick={() => cambiarEstadoEditarComplemento('opciones')} className="ui orange fluid icon labeled button">
                                            <i className="plus icon"></i>
                                            Agregar opciones
                                        </button>
                                    </div>
                                </div>
                            </>
                        )
            }
        </>
    )
}

export { FormCrearComplemento }