
const Registro = ({ compAgregados, eliminarComplemento }) => {
    return (
        <>
            {
                compAgregados.map((item, i) => {
                    return (
                        <tr key={i}>
                            <td><button
                                onClick={() => eliminarComplemento(item)}
                                className="circular centered tiny ui icon red button"
                            >
                                <i className="minus circle icon"></i>
                            </button></td>
                            <td>{item.CodigoComplemento}</td>
                            <td>{item.PosicionComplemento || item.Posicion}</td>
                            <td>{item.CantidadPorDefectoComplemento ?? item.CantidadPorDefecto}</td>
                        </tr>
                    )
                })
            }
        </>
    )
}

export { Registro }