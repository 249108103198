const DetalleTotalAnulacion = ({ controlAnimaciones, estadosAnulacion }) => {

    let valorEfectivo, valorCredito, valorDescuento, valorTotal = 0;

    if (controlAnimaciones.activada === true) {
        if (controlAnimaciones.expandirAnulacion === true) {
            document.getElementById("detalleTotales").style.animation = " mostrar 1s forwards";
            document.getElementById("detalleTotales").style.display = " block";
        }
        else {
            document.getElementById("detalleTotales").style.display = " none";
        }
    }

    if (estadosAnulacion.estadoOrden === true) {
        valorEfectivo = (Math.round(estadosAnulacion.ordenAnular.Orden.TotalFisico * 100) / 100).toFixed(2);
        valorCredito = (Math.round(estadosAnulacion.ordenAnular.Orden.TotalVirtual * 100) / 100).toFixed(2);
        valorDescuento = (Math.round(estadosAnulacion.ordenAnular.Orden.TotalDescuento * 100) / 100).toFixed(2);
        valorTotal = (Math.round((parseFloat(valorEfectivo) + parseFloat(valorCredito)) * 100) / 100).toFixed(2);
    }


    return (
        <div className="ui segment" id="detalleTotales">
            {
                (estadosAnulacion.estadoOrden == true)
                    ? (

                        <div className="ui middle aligned divided list">

                            <div className="item">
                                <div className="right floated content">
                                    <div className="content">

                                    </div>
                                </div>
                                <div className="content">
                                    <h1>Detalle Totales</h1>
                                </div>
                            </div>
                            <div className="item">
                                <div className="right floated content">
                                    <div className="content">
                                        <h3>{valorEfectivo}</h3>
                                    </div>
                                </div>
                                <div className="content">
                                    <h3>Valor Efectivo</h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="right floated content">
                                    <div className="content">
                                        <h3>{valorCredito}</h3>
                                    </div>
                                </div>
                                <div className="content">
                                    <h3>Valor Credito</h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="right floated content">
                                    <div className="content">
                                        <h3>{valorDescuento}</h3>
                                    </div>
                                </div>
                                <div className="content">
                                    <h3>Valor Descuentos</h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="right floated content">
                                    <div className="content">
                                        <h3>{valorTotal}</h3>
                                    </div>
                                </div>
                                <div className="content">
                                    <h3>Venta Total</h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="right floated content">
                                    <div className="content">
                                        <h3>0.00</h3>
                                    </div>
                                </div>
                                <div className="content">
                                    <h3>Valor Propina</h3>
                                </div>
                            </div>
                            {/* <div className="item">
                                <div className="right floated content">
                                    <div className="content">
                                        <h3>0</h3>
                                    </div>
                                </div>
                                <div className="content">
                                    <h3>Tiquete devuelto</h3>
                                </div>
                            </div> */}

                        </div>

                    )
                    : null
            }
        </div>


    )
}

export { DetalleTotalAnulacion }