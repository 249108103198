import React, { useContext } from "react"
import { DetallesComplemento } from "../DetallesComplemento"
import { FormCrearComplemento } from "../FormCrearComplemento"
import { ProductosContext } from "../ProductosContext"

import './style.css'

const BuscarCrearComplemento = ({ modalFuente }) => {

    const {
        state,
        changeBusquedaComplemento,
        mostrarDetallesComp,
        agregarComplemento,
    } = useContext(ProductosContext);

    let claseInput = 'ui fluid icon input input-plu';
    if (state.estados.estadoComplemento === 'crear') claseInput = 'ui disabled fluid icon input input-plu';
    else claseInput = 'ui fluid icon input input-plu';

    return (
        <>
            <div className={claseInput}>
                <input onChange={(e) => changeBusquedaComplemento(e)} value={state.elementoSeleccionado.complementoSeleccionado} type="text" placeholder="Buscar complemento..." />
                <i className="search plus icon"></i>
            </div>
            {
                (state.estados.estadoComplemento === 'buscando')
                    ? (
                        <div className="ui segment plu-list">
                            <h4 className="ui centered header">Listado de Complementos</h4>
                            {
                                state.response.productosApi.complementos.map((item) => {
                                    return (
                                        <div key={item.CodigoComplemento} className="plu">
                                            <button onClick={() => mostrarDetallesComp(item.CodigoComplemento)} className="ui fluid button">
                                                {item.CodigoComplemento}: {item.Descripcion ?? item.DescripcionComplemento}
                                            </button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                    : (state.estados.estadoComplemento === 'no-encontrado')
                        ? (
                            <>
                                <div className="ui icon message">
                                    <i className="bullhorn icon"></i>
                                    <div className="content">
                                        <div className="header">Complemento no encontrado</div>
                                        <p>No se encontró el complemento deseado.</p>
                                    </div>
                                </div>
                                {
                                    (modalFuente !== 'editar')
                                        ? (
                                            <button onClick={() => agregarComplemento()} className="fluid ui button">¿Desea crear el complemento?</button>
                                        )
                                        : null
                                }

                            </>
                        )
                        : (state.estados.estadoComplemento === 'crear')
                            ? (
                                <FormCrearComplemento estado={'crear-producto'} />
                            )
                            : (state.estados.estadoComplemento === 'detalles')
                                ? (
                                    <DetallesComplemento modalFuente={modalFuente} />
                                )
                                : (state.estados.estadoComplemento === 'sel-opciones')
                                    ? (
                                        <div className="ui segment plu-list">
                                            <h4 className="ui centered header">Listado de Complementos</h4>
                                            {
                                                state.response.productosApi.opciones.map((item) => {
                                                    return (
                                                        <div key={item.CodigoOpcion} className="plu">
                                                            <button onClick={null} className="ui fluid button">
                                                                {item.Descripcion}
                                                            </button>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                    : null
            }
        </>
    )
}

export { BuscarCrearComplemento }