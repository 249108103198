import { useState } from 'react';
import { DivisionUI } from './DivisionUI';

import Swal from 'sweetalert2';

import initialData from "./initialData";

const DivisionCuentas = () => {

  const [estado, setEstado] = useState(initialData);

  const onDragEnd = (result) => {

    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = estado.columns[source.droppableId];
    const finish = estado.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);

      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newState = {
        ...estado,
        columns: {
          ...estado.columns,
          [newColumn.id]: newColumn,
        }
      }

      setEstado(newState);
      return;
    }

    // De una lista a otra
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...estado,
      columns: {
        ...estado.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    setEstado(newState);
  };

  const eliminarCuenta = (column, index) => {
    if (column.id === 'column-1') {
      Swal.fire('Advertencia', 'No se puede eliminar la cuenta orignal', 'warning');
      return;
    }

    const newState = { ...estado };

    const colTasks = column.taskIds;

    if (colTasks.length > 0) {
      let prinTasks = newState.columns['column-1'].taskIds;

      prinTasks = [...prinTasks, ...colTasks];

      let columnas = newState.columnOrder;

      const columnaEliminada = columnas.slice(index);

      columnas = columnas.filter(item => item !== columnaEliminada[0])

      const updateState = {
        ...newState,
        columns: {
          ...newState.columns,
          'column-1': { id: 'column-1', title: 'Cuenta 1', taskIds: prinTasks },
        },
        columnOrder: columnas,

      }
      delete updateState.columns[column.id];

      setEstado(updateState)
    }
    else {
      let columnas = newState.columnOrder;
      const columnaEliminada = columnas.slice(index);

      columnas = columnas.filter(item => item !== columnaEliminada[0])

      const updateState = {
        ...newState,
        columnOrder: columnas,
      }

      setEstado(updateState)
    }
  }

  const agregarCuenta = () => {
    const newState = estado;

    const lastDiv = newState.columnOrder[newState.columnOrder.length - 1];

    const lastDivNumber = lastDiv.split('-')[1];
    const newDivNumber = parseInt(lastDivNumber) + 1;
    const divName = `column-${newDivNumber}`;
    const divId = `column-${newDivNumber}`;
    const divTitle = `Cuenta ${newDivNumber}`;

    const updateState = {
      ...estado,
      columns: {
        ...estado.columns,
        [divName]: { id: divId, title: divTitle, taskIds: [] }
      },
      columnOrder: [...estado.columnOrder, divName]
    }
    setEstado(updateState);
  }

  return (
    <DivisionUI
      estado={estado}
      setEstado={setEstado}
      onDragEnd={onDragEnd}
      eliminarCuenta={eliminarCuenta}
      agregarCuenta={agregarCuenta}
    />
  );
};

export { DivisionCuentas };