// MODAL 6
import React, { useEffect, useState} from "react";
import { ordenCredito } from "../../../constants/OrdenFiscalizada";
import { TecladoCompleto } from "../../TecladoCompleto";
import { useTecladoAlfanumerico } from "../../../hooks/useTecladoNumerico";
import './style.css';

const ModalPagoCredito = ({ state, dispatch, actions, onCreateOrder }) => {
  
    const {
      claseTeclado,
      claseEspacioBlanco,
      formData,
      capsLock,
      activarTeclado,
      inputHandler,
      setFormData
    } = useTecladoAlfanumerico({autorizacion: ''});

  const [pagoTCredito, setPagoTCredito] = useState({codigoPuntoDeVenta: state.orderOptions.codigoPuntoDeVenta, autorizacion: ''});

  const handleChanges = (e) => {
    setPagoTCredito({...pagoTCredito, [e.target.name]: e.target.value})
  }

  useEffect(() => {
    window.$(".ui.dropdown").dropdown();
  }, []);

  const eliminarEvento = (e) => {
    e.preventDefault();
  }

  const terminarOrden = (e) => {
    let nuevoPagoTC = {
      ...ordenCredito,
      Autorizacion: formData.autorizacion,
    }

    //setDatosFiscales({...datosFiscales, datosCredito: nuevoPagoTC});
    dispatch({ type: actions.MODIFICAR_ORDEN, payload: {...state.orderOptions, codigoPuntoDeVenta: pagoTCredito.codigoPuntoDeVenta} });
    dispatch({ type: actions.NUMERO_MODAL, payload: 11 });
    onCreateOrder(0, nuevoPagoTC);
  }

  let btnAceptar = "ui positive right labeled icon button"
  if(formData.autorizacion === '') {
    btnAceptar += ' disabled';
  }

  return (
    <div className="ui large longer modal md-disp">
      <div className="header">Datos Pago Credito</div>

      <div className="content">
        <form className="ui form" onSubmit={eliminarEvento}>
          <div className="field">
            <label>Autorización</label>
            <input 
              value={formData.autorizacion} 
              onChange={(e) => inputHandler(e, 'alfaNumerico-no')} 
              type="text" 
              name="autorizacion" 
              placeholder="No. autorización"
              onFocus={(e) => activarTeclado(true, e, 'alfaNumerico-no')}
              autoFocus 
            />
          </div>
          <div className="field">
            <label>Punto de venta</label>
            <select value={pagoTCredito.codigoPuntoDeVenta} onChange={handleChanges} name="codigoPuntoDeVenta" className="ui fluid dropdown">
              {
                state.response.puntosDeVenta.map((item) => {
                  return (
                    <option key={item.CodigoPuntoDeVenta} value={item.CodigoPuntoDeVenta}>{item.Descripcion}</option>
                  )
                })
              }
            </select>
          </div>
        </form>
      </div>

      <div className="actions">
      <button
          type="button"
          onClick={terminarOrden}
          className={btnAceptar}
        >
          Aceptar
          <i className="check icon"></i>
        </button>
        <button
          type="button"
          onClick={() => dispatch({ type: actions.CERRAR_MODAL }) }
          className="ui negative right labeled icon button"
        >
          Cancelar
          <i className="x icon"></i>
        </button>
      </div>

      <div className={claseEspacioBlanco}></div>
        <TecladoCompleto 
          claseActivar={claseTeclado}
          handleChange={inputHandler}
          activarTeclado={activarTeclado}
          capsLock={capsLock}
        />
    </div>
  )
};

export { ModalPagoCredito };