import React, { useContext } from "react"
import { ProductosContext } from "../ProductosContext"

const FormCrearPlu = () => {

    const {
        state,
        changeFormPluProducto,
    } = useContext(ProductosContext);

    return (
        <>
            <form className="ui form">
                <div className="field">
                    <label>Código de PLU</label>
                    <input onChange={(e) => changeFormPluProducto(e)} value={state.formPluProducto.CodigoPlu} name="CodigoPlu" type="text" />
                    {
                        (state.errorInput == 'codigoPlu')
                            ? <span className='errorInput'>Máximo 8 caracteres no especiales</span>
                            : null
                    }
                </div>
                <div className="two fields">
                    <div className="field">
                        <label>Area de Producción</label>
                        <select onChange={(e) => changeFormPluProducto(e)} value={state.formPluProducto.AreaProduccion} name="AreaProduccion" className="ui dropdown">
                            <option value="">Ninguno</option>
                            {
                                state.response.productosApi.areasProduccion.map((item) => {
                                    return (
                                        <option key={item.CodigoAreaProduccion} className='dropdown-option' value={item.CodigoAreaProducion}>{item.Descripcion}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="field">
                        <label>Código de Familia</label>
                        <select onChange={(e) => changeFormPluProducto(e)} value={state.formPluProducto.CodigoFamilia} name="CodigoFamilia" className="ui dropdown">
                            <option value="">Ninguno</option>
                            {
                                state.response.productosApi.familias.map((item) => {
                                    return (
                                        <option key={item.CodigoFamilia} className='dropdown-option' value={item.CodigoFamilia}>{item.Descripcion}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="two fields">
                    <div className="field">
                        <label>Descripción Pantalla</label>
                        <input onChange={(e) => changeFormPluProducto(e)} value={state.formPluProducto.DescripcionPantalla} name="DescripcionPantalla" type="text" />
                    </div>
                    <div className="field">
                        <label>Descripción Impresión</label>
                        <input onChange={(e) => changeFormPluProducto(e)} value={state.formPluProducto.DescripcionImpresion} name="DescripcionImpresion" type="text" />
                    </div>
                </div>
            </form>
        </>
    )
}

export { FormCrearPlu }