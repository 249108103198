import { useState, useEffect } from "react";

import { AnulacionUI } from "./AnulacionUI";
import { anulacionServices } from "../../api/services/anulacionServices";
import { Loader } from '../Loader';
import { PageError } from "../PageError";
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useNavigate } from "react-router-dom";
import { turnoServices } from "../../api/services/turnoServices";
import { getConteoContingencia, getEstadoCaja, setConteoContingencia, setEstadoCaja, instanciaAxios, tiempoEnContingencia } from '../../utils/Common';

import Swal from "sweetalert2";
import { swalFire, SWAL_ACTION } from "../../constants/sweetAlert";
import './style.css';
import { getAllAnulacion, getOrdenesTurno } from "./serviciosAnulacion";
import { posBacServices } from "../../api/services/posBacServices";


const Anulacion = () => {

    const [usuario, guardarUsuario] = useLocalStorage('LOGIN_BETA');

    let valoresLogin = {
        codigoEmpresa: usuario.Tienda.CodigoEmpresa,
        codigoTienda: usuario.Tienda.CodigoTienda,
        codigoCaja: usuario.CorrelativoFiscal.CodigoCaja,
        codigoPuntoDeVenta: 1,
        codigoColaborador: usuario.Colaborador.CodigoColaborador,
        cierreNumero: 1,
        EstadoTurno: usuario.Turno.Estado,
        codigoTurno: usuario.Turno.CodigoTurno,
        Nombres: usuario.Colaborador.Nombres,
        Apellidos: usuario.Colaborador.Apellidos,
        estadoTurno: usuario.Turno.Estado,
        IntentosFacturacion: usuario.CajaFiscal.IntentosFacturacion,
        EstadoCaja: usuario.EstadoCaja,
        TiempoContingencia: usuario.CajaFiscal.TiempoContingencia,
    };

    let navigate = useNavigate();

    //const pais = valoresLogin.codigoEmpresa.split('O')[1];
    const pais = 'SV';

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(true);

    const [valoresTeclado, setValoresTeclado] = useState({
        capsLock: false,
        claseTeclado: 'keyboard-hidden',
        claseEspacioBlanco: 'espacio-blanco-off',
        campoSeleccionado: undefined,
    })

    const [anulacionesApi, setAnulacionesApi] = useState({
        ordenes: [],
        motivosDevolucion: [],
        turnoActual: '',
    })

    //const [valueTabIndex, setValueTabIndex] = useState(1);

    const [formData, setFormData] = useState({
        CodigoFormulario: '',
        CodigoMotivo: 0,
        ExpMotivo: '',
    });

    const [formDataFecha, setFormDataFecha] = useState({
        Fecha: '',
        CodigoFormularioFecha: '',
        CodigoMotivoFecha: 0,
        ExpMotivoFecha: ''
    })

    const [botonesAnular, setBotonesAnular] = useState({
        textoAnular: 'ANULAR',
        textoAnularFecha: 'ANULAR',
        claseBotonAnular: 'ui orange left fluid labeled icon disabled',
        claseBotonAnularFecha: 'ui orange left fluid labeled icon disabled',
        claseBotonBuscarAnulacion: 'ui grey left fluid labeled icon disabled',
        claseBotonBuscarFecha: 'ui grey fluid left labeled icon disabled',
    })

    const [estadosAnulacion, setEstadosAnulacion] = useState({
        estadoOrden: false,
        estadoFechaOrden: false,
        ordenAnular: {},
        ordenAnularFecha: {},
    })

    const [controlAnimaciones, setControlAnimaciones] = useState({
        expandirAnulacion: false,
        expandirFecha: false,
        activada: false,
        cerrarAnular: false,
        valueTabIndex: 1,
    })

    const [fecha, setFecha] = useState();

    const [openModal, setOpenModal] = useState(false);
    const [numberModal, setNumberModal] = useState(undefined);

    useEffect(async () => {

        let estadoCaja = getEstadoCaja();

        let estadoTurno = '';

        let codigoTurno = '';

        // Validar el estado del turno
        if (estadoCaja === 'ALTA') {
            try {
                const respTurno = await turnoServices.turno.turnoAbierto(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja);
                if (respTurno.data.SUCCESS === true) {
                    estadoTurno = respTurno.data.MESSAGE.Estado;
                    codigoTurno = respTurno.data.MESSAGE.CodigoTurno;
                }
                else estadoTurno = 'CERRADO';
            } catch (error) {
                setLoading(false);
                setError(error);
            }

        }
        else estadoTurno = valoresLogin.EstadoTurno;

        // Si el turno está cerrado, no se pueden hacer anulaciones
        // Se dan dos opciones en la swal, regresar al menú o abrir el formulario para hacer una anulación de 
        // día anterior
        if (estadoTurno === 'CERRADO') {
            Swal.fire({
                title: 'No hay turno abierto',
                icon: 'info',
                text: '¿Desea regresar al menú o hacer una anulación del día anterior',
                showDenyButton: true,
                showCancelButton: false,
                allowOutsideClick: false,
                confirmButtonText: 'Regresar al menú',
                denyButtonText: 'Anulación de día anterior',
                customClass: {
                    confirmButton: 'ui orange button',
                    denyButton: 'ui grey button',
                },

            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/menu');
                }
                else {
                    // Proceso para cargar pantalla anulación día anterior
                    // Solo en SV
                    setLoading(false);
                }
            })

        }
        else { // Si hay turno abierto, se pueden anular órdenes
            if (estadoCaja === 'CONT') {
                // Cuando se esté en contigencia, es necesario que todas las órdenes que se han hecho en el turno
                // se vayan almacenando en el localStorage para poder tenerlas disponibles
                // Al cerrar el turno, se tienen que limpiar la variable ORDENES del ls

                let ordenesLS = JSON.parse(localStorage.getItem('ORDENES'));
                let motivosDev = JSON.parse(localStorage.getItem('MOTIVOS_DEV'));

                setAnulacionesApi({
                    ...anulacionesApi,
                    ordenes: ordenesLS,
                    motivosDevolucion: motivosDev,
                });

                setLoading(false);

            }
            else {
                // Si la caja está de alta, se pueden consumir los servicios
                try {
                    const todoAnulaciones = await getAllAnulacion(valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja, codigoTurno);
                    if (Object.hasOwn(todoAnulaciones, 'ordenes')) {
                        setAnulacionesApi({
                            ...anulacionesApi,
                            ordenes: todoAnulaciones.ordenes,
                            motivosDevolucion: todoAnulaciones.motivos,
                            turnoActual: codigoTurno,
                        })
                        setLoading(todoAnulaciones.loading);
                    }
                    // Uno de los servicios (órdenes o motivos devolución) devolvió un códig
                    else if (Object.hasOwn(todoAnulaciones, 'mensajeFalse')) {
                        Swal.fire({
                            title: todoAnulaciones.mensajeFalse,
                            icon: 'error',
                            confirmButtonText: 'Regresar al menú',
                            customClass: {
                                confirmButton: 'ui grey button',
                            }
                        }).then(result => {
                            if (result.isConfirmed) navigate('/menu');
                        })
                    }
                    else if (Object.hasOwn(todoAnulaciones, 'error')) {
                        let intentosFallidos = getConteoContingencia();

                        setConteoContingencia(intentosFallidos + 1);
                        setLoading(false);
                        setError(error.message);

                        if (intentosFallidos === valoresLogin.IntentosFacturacion - 1) {
                            setEstadoCaja('CONT');
                        }
                    }
                } catch (error) {
                    // Proceso para entrar en contingencia
                    /*let intentosFallidos = getConteoContingencia();

                    setConteoContingencia(intentosFallidos + 1);*/
                    setLoading(false);
                    setError(error);

                    /*if (intentosFallidos === valoresLogin.IntentosFacturacion - 1) {
                        setEstadoCaja('CONT');
                        return;
                    }*/
                }
            }
        }
    }, []);

    // Handler formulario anulación
    const handleChangeAnulacion = (e) => {

        let claseBotonBuscarAnulacion = 'ui grey left fluid labeled icon disabled';

        let valorActual = undefined;

        // Si e no tiene longitud o su tipo es un número, el input vino del teclado digital
        if (e.length !== undefined || typeof (e) == 'number') {

            // Valor actual del campo en el que se está escribiendo
            valorActual = formData[valoresTeclado.campoSeleccionado];

            // Control para eliminar un caracter (backspace)
            if (e == 'backspace' || e == '!#') {
                // Se elimina el último caracter del valor y se actualiza el estado
                const valorNuevo = valorActual.slice(0, -1)
                setFormData({
                    ...formData,
                    [valoresTeclado.campoSeleccionado]: valorNuevo,
                })
                if (valorNuevo.length === 0 && valoresTeclado.campoSeleccionado === 'CodigoFormulario') {
                    setBotonesAnular({
                        ...botonesAnular,
                        claseBotonBuscarAnulacion,
                    })
                }
                else if (valorNuevo.length === 0 && valoresTeclado.campoSeleccionado === 'ExpMotivo') {
                    setBotonesAnular({
                        ...botonesAnular,
                        claseBotonAnular: 'ui orange left fluid labeled icon disabled'
                    })
                }
                return;
            }

            // Control de mayúsculas
            if (e == 'caps') {
                setValoresTeclado({
                    ...valoresTeclado,
                    capsLock: !valoresTeclado.capsLock,
                })
                return;
            }
        }

        // Si los dos ifs son iguales, deberían estar dentro ambos
        if (e.length !== undefined || typeof (e) == 'number') {

            // e (evento) al ser un click en el teclado digital, no tiene name ni value. Por eso se trabaja directamente
            // con e
            let valor = e;

            // Si es un string (una letra) y está activado el capsLock
            if (typeof (e) == 'string' && valoresTeclado.capsLock == true) {
                // Se hace mayúscula la tecla presionada
                valor = e.toUpperCase();
            }

            // Si el campoSeleccionado es el Documento Numero
            if (valoresTeclado.campoSeleccionado === 'CodigoFormulario') {

                let campo = valorActual + valor;
                if (campo.length > 0) {
                    claseBotonBuscarAnulacion = 'ui grey left fluid labeled icon';
                }

                // Se actualiza el estado tomando el valor actual y agregando el nuevo al final
                setFormData({
                    ...formData,
                    [valoresTeclado.campoSeleccionado]: valorActual + valor,
                })
                setBotonesAnular({
                    ...botonesAnular,
                    claseBotonBuscarAnulacion,
                })
            }
            // Es imposible que se de el caso en el que se use el teclado digital para seleccionar un motivo
            // de devolución, ya que es un dropdown
            // Por eso, solo se maneja el caso del cuadro de texto para ingresar una explicación
            else {
                // Si el valor a agregar es mayor a 0 (no tiene mucho sentido) y ya fue ingresado un CodigoFormulario
                // se puede proceder a activar el botón para anular
                let claseAnular = 'ui orange left fluid labeled icon disabled'
                if (valor.length > 0 && formData.CodigoFormulario.length > 0) {
                    claseAnular = 'ui orange left fluid labeled icon'
                }
                else {
                    claseAnular = 'ui orange left fluid labeled icon disabled'
                }

                setFormData({
                    ...formData,
                    [valoresTeclado.campoSeleccionado]: valorActual + valor,
                });

                setBotonesAnular({
                    ...botonesAnular,
                    claseBotonAnular: claseAnular,
                })
            }
        }
        // Se está usando un teclado físico para ingresar valores
        else {
            // Si se está ingresando un DocumentoNumero/CodigoFormulario
            if (e.target.name === 'CodigoFormulario') {

                if (e.target.value.length === 0) {
                    claseBotonBuscarAnulacion = 'ui grey left fluid labeled icon disabled';
                }
                else {
                    claseBotonBuscarAnulacion = 'ui grey left fluid labeled icon';
                }

                // Se actualiza el estado
                setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                })
                setBotonesAnular({
                    ...botonesAnular,
                    claseBotonBuscarAnulacion,
                })
            }
            // En este caso, sí se maneja el dropdown para seleccionar un motivo de devolución
            else if (e.target.name === 'CodigoMotivo') {
                // Si se selecciona 'Ninguno', no se permite que se active el botón para anular
                if (e.target.value == 0) {
                    setBotonesAnular({
                        ...botonesAnular,
                        claseBotonAnular: 'ui orange left fluid labeled icon disabled',
                    })
                    setFormData({
                        ...formData,
                        [e.target.name]: 0,
                    })

                    // El código 1 se usa para el motivo 'Otro'
                    // Este motivo permite ingresar una explicación distinta a los motivos ya establecidos
                    // Para esto, se habilita un cuadro de texto extra
                } else if (e.target.value == 1) {

                    // ExpMotivo es el campo para agregar la explicación
                    // Si no se ha ingresado nada en el campo, no se activa el botón para anular
                    let claseAnular = 'ui orange left fluid labeled icon disabled';
                    if (formData.ExpMotivo === '') {
                        claseAnular = 'ui orange left fluid labeled icon disabled';
                    }

                    // Esto solo ocurre en casos en los que se haya dejado algo escrito, creo
                    // Si ya hay una orden, una explicación y un CodigoFormulario, se habilita el botón para anular
                    if (estadosAnulacion.estadoOrden === true && formData.ExpMotivo.length > 0 && formData.CodigoFormulario.length > 0) {
                        claseAnular = 'ui orange left fluid labeled icon';
                    }
                    // Se convierte a un entero el motivo para que sea congruente con la BD
                    const codigoMotivoInt = parseInt(e.target.value);
                    setFormData({
                        ...formData,
                        [e.target.name]: codigoMotivoInt,
                    });
                    setBotonesAnular({
                        ...botonesAnular,
                        claseBotonAnular: claseAnular,
                    })
                }
                // Cualquier otro motivo, uno del listado ya establecido
                else {
                    // Si hay una orden, se habilita el botón para anular
                    if (estadosAnulacion.estadoOrden === true) {
                        setBotonesAnular({
                            ...botonesAnular,
                            claseBotonAnular: 'ui orange left fluid labeled icon',
                        })
                    }
                    const codigoMotivoInt = parseInt(e.target.value);
                    setFormData({
                        ...formData,
                        [e.target.name]: codigoMotivoInt,
                    })
                }

            }
            // Manejo del cambio de motivo explicación
            else {
                // Si ya hay una orden y el hay algo escrito en el campo, se activa el botón para anulación
                let claseAnular = 'ui orange left fluid labeled icon disabled';
                if (estadosAnulacion.estadoOrden === true && e.target.value.length > 0) {
                    claseAnular = 'ui orange left fluid labeled icon';
                }
                else {
                    claseAnular = 'ui orange left fluid labeled icon disabled';
                }

                setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                });
                setBotonesAnular({
                    ...botonesAnular,
                    claseBotonAnular: claseAnular,
                })
            }

        }

    }

    // Busca el documento ingresado en las órdenes del día (debería ser turno)
    const buscarOrdenDiaria = () => {
        // Desactiva el teclado digital
        activarTeclado(false);

        // Se filtra la orden con el código de documento buscado
        const indexOrden = anulacionesApi.ordenes.findIndex(orden => orden.Orden.DocumentoNumero == formData.CodigoFormulario);
        // Si no existe la orden, se muestra una alerta
        if (indexOrden === -1) {
            Swal.fire({
                title: 'No se ha encontrado la orden',
                text: `No existe una orden con el Número de Documento ${formData.CodigoFormulario}`,
                icon: 'info',
                customClass: {
                    confirmButton: 'ui orange button',
                },
                confirmButtonText: 'Entendido',
            })
        }
        // Si existe la orden
        else {
            let botonAnular = 'ui orange left fluid labeled icon disabled';
            let textoAnular = '';
            // Para El Salvador, existen varios tipos de documento. El tipo 1 de estos no se puede anular, entonces
            // el botón debe indicar que es una orden no anulable
            if (pais === 'SV') {
                if (anulacionesApi.ordenes[indexOrden].Orden.CodigoDocumento !== 1) {
                    textoAnular = 'NO ANULABLE';
                }
                else {
                    // Si la orden ya fue anulada, se desactiva el botón y se indica al usuario
                    if (anulacionesApi.ordenes[indexOrden].Orden.CodigoEtapa === 'ANUL') {
                        textoAnular = 'ORDEN ANULADA';
                    }
                    else {
                        textoAnular = 'ANULAR';
                    }
                }

            }
            // En Guatemala todas las órdenes se pueden anular, no hay filtro por tipo de documento
            else {
                if (anulacionesApi.ordenes[indexOrden].Orden.CodigoEtapa === 'ANUL') {
                    textoAnular = 'ORDEN ANULADA';
                }
                else {
                    textoAnular = 'ANULAR';
                }
            }

            // Si hay un motivo para anulación seleccionado o una explicación adicional, el botón para anular
            // se debe activar
            if ((formData.CodigoMotivo == 1 && formData.ExpMotivo.length > 0)
                || (formData.CodigoMotivo != 0 && formData.CodigoMotivo != 1)) {
                botonAnular = 'ui orange left fluid labeled icon';
            }
            console.log(botonAnular);
            setBotonesAnular({
                ...botonesAnular,
                claseBotonAnular: botonAnular,
                textoAnular,
            })

            setControlAnimaciones({
                ...controlAnimaciones,
                expandirAnulacion: true,
                cerrarAnular: false,
            })

            setEstadosAnulacion({
                ...estadosAnulacion,
                estadoOrden: true,
                ordenAnular: anulacionesApi.ordenes[indexOrden],
            })
        }
    }

    // Anular la orden
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Se desactiva el teclado digital
        activarTeclado(false);

        // Se obtiene el motivo de devolución. El formulario solo almacena el código de motivo, se necesita todo 
        // el objeto para obtener la descripción y poder mostrarla al usuario
        const motivoDev = anulacionesApi.motivosDevolucion.filter(motivo => motivo.Condicion === formData.CodigoMotivo);

        let motivoText = '';
        // Si el motivo es 'Otro', se muestra la explicación
        if (formData.CodigoMotivo === 1) {
            motivoText = formData.ExpMotivo;
        }
        // Si no, se muestra la descripción del motivo seleccionado
        else {
            motivoText = motivoDev[0].Descripcion;
        }
        console.log(estadosAnulacion.ordenAnular);
        // Alerta para decidir si anular la orden o no
        const btnConfirmarAnulacion = await Swal.fire({
            title: `¿Desea anular la orden con Documento No. ${estadosAnulacion.ordenAnular.Orden.DocumentoNumero}?`,
            text: `Se anulará la orden con Documento No. ${estadosAnulacion.ordenAnular.Orden.DocumentoNumero}. Motivo: ${motivoText}`,
            showDenyButton: true,
            customClass: {
                confirmButton: 'ui orange button',
                denyButton: 'ui grey button',
            },
            confirmButtonText: 'Anular',
            denyButtonText: 'Cancelar',
        });

        if(btnConfirmarAnulacion.isDenied) {
            Swal.fire({
                title: `No se ha anulado la orden con Documento No. ${estadosAnulacion.ordenAnular.Orden.DocumentoNumero}`,
                icon: 'info',
                confirmButtonText: 'Confirmar',
                customClass: {
                    confirmButton: 'ui orange button'
                },
            })
            return;
        }

        if(!btnConfirmarAnulacion.isConfirmed) {
            return;
        }

        // Se muestra miniLoader
        onOpenModal(0)
        // Verificar estado de la caja para saber a qué servidor dirigirse
        const tipoCaja = tiempoEnContingencia(getEstadoCaja(), valoresLogin.TiempoContingencia);
        const axios = instanciaAxios(tipoCaja);

        try {
          // Validamos si la orden fue hecha con pos integrado para revertir transacción
          if(estadosAnulacion.ordenAnular.Orden.FormaDePago.toUpperCase() === "CREDITO") {
            const posIntegradoObj = {
              parameters: `transactionType:VOID;terminalId:${"RVIRTUAL"};referenceNumber:${estadosAnulacion.ordenAnular.OrdenCredito.Referencia}`
            };
            const responsePosIntegrado = await posBacServices.bac.venta('http://localhost:8000/CSPIntegrationServices/Interop/rest/runTransaction', posIntegradoObj);
        
            if(responsePosIntegrado.status !== 200) {
              // MENSAJE DE ERROR YA QUE NO SE PUDO COMPLETAR EL PAGO
              swalFire(SWAL_ACTION.ERROR_POS_INTEGRADO("ERROR DE COMUNICACIÓN CON POS INTEGRADO"));
              onCancelModal(); 
              return;
            }

            const objPosIntegrado = JSON.parse(responsePosIntegrado.data.runTransactionResult);
            console.info(objPosIntegrado);
        
            if(objPosIntegrado.responseCode === "00" || objPosIntegrado.responseCodeDescription === '') {
              // este objeto debe enviarse al backend para revertir transacciones con pos integrado
              // datosCredito = {
              //   ...datosCredito,
              //   CodigoOrden: state.orderOptions,
              //   NumeroTarjeta: objPosIntegrado.maskedCardNumber,
              //   Referencia: objPosIntegrado.referenceNumber,
              //   Autorizacion: objPosIntegrado.authorizationNumber,
              //   Estado: 'ALTA'
              // }
            } else {
              // MENSAJE DE ERROR, HUBO ALGÚN ERROR AL REALIZAR EL COBRO
              swalFire(SWAL_ACTION.ERROR_POS_INTEGRADO(objPosIntegrado.responseCodeDescription));
              ///dispatch({ type: ACTION_TYPES.CERRAR_MODAL });
              return;
            }
          }
          // en este objeto se debe agregar el CreditoOrden que debe actualizarce o agregar uno nuevo 
          // al anular ordenes con pos integrado
          const responseAnularOrden =
            await anulacionServices.anulacion.anularOrden(
              estadosAnulacion.ordenAnular.Orden.CodigoOrden,
              motivoDev[0].Condicion,
              formData.ExpMotivo,
              axios
            );

          onCancelModal(); // Se cierra el miniLoader

          if (responseAnularOrden.data.SUCCESS !== true) {
            Swal.fire({
              title: "No se ha podido anular la orden",
              text: JSON.stringify(responseAnularOrden.data.MESSAGE),
              icon: "error",
              confirmButtonText: "Confirmar",
              customClass: {
                confirmButton: "ui orange button",
              },
            });
            return;
          }

          setBotonesAnular({
            ...botonesAnular,
            claseBotonAnular: 'ui orange left fluid labeled icon disabled',
          })

          const btnSalirAlMenu = await Swal.fire({
            title: `Se ha anulado la orden con Documento Número ${estadosAnulacion.ordenAnular.Orden.DocumentoNumero} con éxito`,
            icon: 'success',
            confirmButtonText: 'Regresar al menú',
            customClass: {
                confirmButton: 'ui orange button'
            },
            allowOutsideClick: false,
          });

          if(btnSalirAlMenu.isConfirmed) {
            navigate('/menu');
          }

          setFormData({
            CodigoFormulario: '',
            CodigoMotivo: 0,
            ExpMotivo: '',
          });

        } catch (error) {
          onCancelModal();
          console.error(error);

          // Se muestra el mensaje de error en la pantalla
          Swal.fire({
            title: "Error",
            icon: "error",
            text: "No se pudo conectar con el servidor. Intente nuevamente.",
          });

          const estadoCaja = getEstadoCaja();

          // Si la caja está en ALTA, es necesario aumentar los intentosFallidos y hacer todo el proceso para
          // que la caja entre en contingencia
          if (estadoCaja === "ALTA") {
            let intentosFallidos = getConteoContingencia();
            setConteoContingencia(intentosFallidos + 1);

            if (intentosFallidos === valoresLogin.IntentosFacturacion - 1) {
              setEstadoCaja("CONT");
              return;
            }
          }
        }



    }

    /*************************************************************************************************************/
    /*************************************** DEVOLUCION DIA ANTERIOR *********************************************/
    /*************************************************************************************************************/
    // Función para buscar todas las órdenes de una fecha específica
    const buscarFecha = async () => {

        activarTeclado(false);
        try {
            const fechaResp = await anulacionServices.orden.getOrdenPorFechaCodigo(fecha, formDataFecha.CodigoFormularioFecha, valoresLogin.codigoEmpresa, valoresLogin.codigoTienda, valoresLogin.codigoCaja);

            if (fechaResp.data.SUCCESS === false) {
                Swal.fire({
                    text: fechaResp.data.MESSAGE,
                    icon: 'error',
                })
                return;
            }

            const ordenesFechaResp = fechaResp.data.MESSAGE;

            if (typeof (ordenesFechaResp) === 'string') {
                Swal.fire({
                    title: 'Orden no encontrada',
                    text: ordenesFechaResp,
                    icon: 'info',
                    customClass: {
                        confirmButton: 'ui orange button',
                    },
                    confirmButtonText: 'Entendido',
                })
            }
            else {
                if (ordenesFechaResp[0].OrdenFactura === null) {
                    Swal.fire({
                        title: 'Orden no encontrada',
                        text: `No se ha encontrado la orden con Documento Número ${formDataFecha.CodigoFormularioFecha}`,
                        icon: 'info',
                        customClass: {
                            confirmButton: 'ui orange button',
                        },
                        confirmButtonText: 'Entendido',
                    });
                    return;
                }
                else {
                    let textoAnularFecha = '';
                    if (pais === 'SV') {
                        if (ordenesFechaResp[0].Orden.CodigoDocumento !== 1) { // Verificar si este tipo de documento es Tiquete
                            textoAnularFecha = 'NO ANULABLE';
                        }
                        else {
                            if (ordenesFechaResp[0].Orden.CodigoEtapa === 'ANUL') {
                                textoAnularFecha = 'ORDEN ANULADA';
                            }
                            else {
                                textoAnularFecha = 'ANULAR';
                            }
                        }

                    }
                    else {
                        if (ordenesFechaResp[0].Orden.CodigoEtapa === 'ANUL') {
                            textoAnularFecha = 'ORDEN ANULADA';
                        }
                        else {
                            textoAnularFecha = 'ANULAR';
                        }
                    }

                    setBotonesAnular({
                        ...botonesAnular,
                        textoAnularFecha,
                    })
                    setEstadosAnulacion({
                        ...estadosAnulacion,
                        estadoFechaOrden: true,
                        ordenAnularFecha: ordenesFechaResp[0],
                    })
                    setControlAnimaciones({
                        ...controlAnimaciones,
                        expandirFecha: true,
                    })
                }

            }

        } catch (error) {

            onCancelModal();
            console.error(error);

            // Se muestra el mensaje de error en la pantalla
            Swal.fire({
                title: 'Error',
                icon: 'error',
                text: 'No se pudo conectar con el servidor. Intente nuevamente.'
            })

            const estadoCaja = getEstadoCaja();

            // Si la caja está en ALTA, es necesario aumentar los intentosFallidos y hacer todo el proceso para
            // que la caja entre en contingencia
            if (estadoCaja === 'ALTA') {

                let intentosFallidos = getConteoContingencia();
                setConteoContingencia(intentosFallidos + 1);

                if (intentosFallidos === valoresLogin.IntentosFacturacion - 1) {
                    setEstadoCaja('CONT');
                    return;
                }

            }
        }

    }

    const handleChangeFecha = (e) => {

        let claseBotonBuscarFecha = 'ui grey left fluid labeled icon disabled';

        let valorActual = undefined;

        // Si e no tiene longitud o su tipo es un número, el input vino del teclado digital
        if (e.length !== undefined || typeof (e) == 'number') {

            // Valor actual del campo en el que se está escribiendo
            valorActual = formDataFecha[valoresTeclado.campoSeleccionado];

            // Control para eliminar un caracter (backspace)
            if (e == 'backspace' || e == '!#') {
                // Se elimina el último caracter del valor y se actualiza el estado
                const valorNuevo = valorActual.slice(0, -1)
                setFormDataFecha({
                    ...formDataFecha,
                    [valoresTeclado.campoSeleccionado]: valorNuevo,
                })
                if (valorNuevo.length === 0 && valoresTeclado.campoSeleccionado === 'CodigoFormularioFecha') {
                    setBotonesAnular({
                        ...botonesAnular,
                        claseBotonBuscarFecha: 'ui grey left fluid labeled icon disabled'
                    })
                }
                else if (valorNuevo.length === 0 && valoresTeclado.campoSeleccionado === 'ExpMotivoFecha') {
                    setBotonesAnular({
                        ...botonesAnular,
                        claseBotonAnularFecha: 'ui orange left fluid labeled icon disabled'
                    })
                }
                return;
            }

            // Control de mayúsculas
            if (e == 'caps') {
                setValoresTeclado({
                    ...valoresTeclado,
                    capsLock: !valoresTeclado.capsLock,
                })
                return;
            }
        }

        // Si los dos ifs son iguales, deberían estar dentro ambos
        if (e.length !== undefined || typeof (e) == 'number') {

            // e (evento) al ser un click en el teclado digital, no tiene name ni value. Por eso se trabaja directamente
            // con e
            let valor = e;

            // Si es un string (una letra) y está activado el capsLock
            if (typeof (e) == 'string' && valoresTeclado.capsLock == true) {
                // Se hace mayúscula la tecla presionada
                valor = e.toUpperCase();
            }


            if (valoresTeclado.campoSeleccionado === 'CodigoFormularioFecha') {

                let campo = valorActual + valor;
                if (campo.length > 0) {
                    claseBotonBuscarFecha = 'ui grey left fluid labeled icon';
                }

                // Se actualiza el estado tomando el valor actual y agregando el nuevo al final
                setFormDataFecha({
                    ...formDataFecha,
                    [valoresTeclado.campoSeleccionado]: valorActual + valor,
                })
                setBotonesAnular({
                    ...botonesAnular,
                    claseBotonBuscarFecha,
                })
            }
            else {
                // Si el valor a agregar es mayor a 0 (no tiene mucho sentido) y ya fue ingresado un CodigoFormulario
                // se puede proceder a activar el botón para anular
                let claseAnular = 'ui orange left fluid labeled icon disabled'
                if (valor.length > 0 && formDataFecha.CodigoFormularioFecha.length > 0) {
                    claseAnular = 'ui orange left fluid labeled icon'
                }
                else {
                    claseAnular = 'ui orange left fluid labeled icon disabled'
                }

                setFormDataFecha({
                    ...formDataFecha,
                    [valoresTeclado.campoSeleccionado]: valorActual + valor,
                });

                setBotonesAnular({
                    ...botonesAnular,
                    claseBotonAnularFecha: claseAnular,
                })
            }
        }
        else {
            if (e.target.name === 'Fecha') {
                const fechaSeparada = e.target.value.split('-');
                const fechaSeleccionada = fechaSeparada[2] + fechaSeparada[1] + fechaSeparada[0];
                setFecha(fechaSeleccionada);

            }
            else if (e.target.name === 'CodigoFormularioFecha') {
                if (e.target.value === '') {
                    setBotonesAnular({
                        ...botonesAnular,
                        claseBotonBuscarFecha: 'ui grey left fluid labeled icon disabled',
                    })
                }
                else {
                    setBotonesAnular({
                        ...botonesAnular,
                        claseBotonBuscarFecha: 'ui grey left fluid labeled icon',
                    })
                }

                setFormDataFecha({
                    ...formDataFecha,
                    [e.target.name]: e.target.value,
                })

            }
            else if (e.target.name === 'CodigoMotivoFecha') {
                if (e.target.value == 0) {
                    //setClaseBotonAnularFecha('ui orange left fluid labeled icon disabled');
                    setBotonesAnular({
                        ...botonesAnular,
                        claseBotonAnularFecha: 'ui orange left fluid labeled icon disabled',
                    })
                    setFormDataFecha({
                        ...formDataFecha,
                        [e.target.name]: 0,
                    })

                }
                else if (e.target.value == 1) {
                    if (estadosAnulacion.estadoFechaOrden === true && formDataFecha.ExpMotivoFecha.length > 0) {
                        //setClaseBotonAnularFecha('ui orange left fluid labeled icon');
                        setBotonesAnular({
                            ...botonesAnular,
                            claseBotonAnularFecha: 'ui orange left fluid labeled icon',
                        })
                    }

                    const codigoMotivoInt = parseInt(e.target.value);
                    setFormDataFecha({
                        ...formDataFecha,
                        [e.target.name]: codigoMotivoInt,
                    })
                }
                else {
                    if (estadosAnulacion.estadoFechaOrden === true) {
                        //setClaseBotonAnularFecha('ui orange left fluid labeled icon');
                        setBotonesAnular({
                            ...botonesAnular,
                            claseBotonAnularFecha: 'ui orange left fluid labeled icon',
                        })
                    }
                    const codigoMotivoInt = parseInt(e.target.value);
                    setFormDataFecha({
                        ...formDataFecha,
                        [e.target.name]: codigoMotivoInt,
                    })
                }
            }
            else {
                let claseAnular
                if (e.target.value.length > 0) {
                    //setClaseBotonAnularFecha('ui orange left fluid labeled icon');
                    claseAnular = 'ui orange left fluid labeled icon';
                }
                else {
                    //setClaseBotonAnularFecha('ui orange left fluid labeled icon disabled');
                    claseAnular = 'ui orange left fluid labeled icon disabled';
                }

                setBotonesAnular({
                    ...botonesAnular,
                    claseBotonAnularFecha: claseAnular,
                })
                setFormDataFecha({
                    ...formDataFecha,
                    [e.target.name]: e.target.value,
                })
            }
        }

    }

    const handleSubmitFecha = async (e) => {
        e.preventDefault();

        activarTeclado(false);

        let explicacionDev = '';
        const motivoDev = anulacionesApi.motivosDevolucion.filter(motivo => motivo.Condicion === formDataFecha.CodigoMotivoFecha)[0];
        if (motivoDev.Condicion == 1) {
            explicacionDev = formDataFecha.ExpMotivoFecha;
        }
        else explicacionDev = motivoDev.Descripcion;
        //console.log(motivoDev);

        const dia = fecha.substring(0, 2);
        const mes = fecha.substring(2, 4);
        const year = fecha.substring(4, 8);

        const fechaMostrar = `${dia}/${mes}/${year}`

        const tipoCaja = tiempoEnContingencia(getEstadoCaja(), valoresLogin.TiempoContingencia);
        const axios = instanciaAxios(tipoCaja);

        const resultadoBotonAnulacion = await Swal.fire({
            title: `¿Desea anular la orden con Documento No. ${estadosAnulacion.ordenAnularFecha.Orden.DocumentoNumero}?`,
            text: `Se anulará la orden con Documento No. ${estadosAnulacion.ordenAnularFecha.Orden.DocumentoNumero} del ${fechaMostrar}. Motivo: ${explicacionDev} `,
            showDenyButton: true,
            customClass: {
                confirmButton: 'ui orange button',
                denyButton: 'ui grey button',
            },
            confirmButtonText: 'Anular',
            denyButtonText: 'Cancelar',
        });
        
        if(resultadoBotonAnulacion.isDenied) {
            Swal.fire({
                title: `No se ha anulado la orden con Documento No. ${estadosAnulacion.ordenAnularFecha.Orden.DocumentoNumero}`,
                icon: 'info',
                confirmButtonText: 'Confirmar',
                customClass: {
                    confirmButton: 'ui orange button'
                },
            })
            return;
        }

        // ESTE CASO DEBE SER VALIDADO PORQUE NO SE SABE EN QUE MOMENTO PUEDE OCURRIR
        if(!resultadoBotonAnulacion.isConfirmed) {
            return;
        }

        onOpenModal(0);


        const anularOrden = await anulacionServices.anulacion.anularOrden(estadosAnulacion.ordenAnularFecha.Orden.CodigoOrden, motivoDev.Condicion, formDataFecha.ExpMotivoFecha, axios);

        if(anularOrden.data.SUCCESS !== true) {
            Swal.fire({
                title: 'No se ha podido anular la orden',
                text: anularOrden.data.MESSAGE,
                icon: 'error',
                confirmButtonText: 'Confirmar',
                customClass: {
                    confirmButton: 'ui orange button'
                },
            });
            return;
        }

        const btnSalirAlMenu = await Swal.fire({
            title: `Se ha anulado la orden con Documento Número ${estadosAnulacion.ordenAnularFecha.Orden.DocumentoNumero} con éxito`,
            icon: 'success',
            confirmButtonText: 'Regresar al menú',
            customClass: {
                confirmButton: 'ui orange button'
            },
        });

        if(btnSalirAlMenu.isConfirmed) {
            navigate("/menu")
        }

        setFormData({
          CodigoFormulario: '',
          CodigoMotivo: 0,
          ExpMotivo: '',
        });
    }

    /*************************************************************************************************************/
    /******************************************* FUNCIONES GENERALES *********************************************/
    /*************************************************************************************************************/

    const onOpenModal = (modalOption) => {
        setOpenModal(prevState => !prevState);
        setNumberModal(modalOption);
    }

    const onCancelModal = () => {
        setOpenModal(false);
        setFecha(undefined);
    };

    const activarTeclado = (focus, e, tipo) => {

        let claseTeclado = '';
        let campoSeleccionado = '';
        let claseEspacioBlanco = '';

        if (focus === true) {

            campoSeleccionado = e.target.name;
            claseEspacioBlanco = 'espacio-blanco-activado';

            if (tipo === 'full') {
                claseTeclado = 'keyboard';
            }
            else if (tipo === 'num') {
                claseTeclado = 'keyboard-num';
            }

        }
        else {
            claseTeclado = 'keyboard-hidden';
            claseEspacioBlanco = 'espacio-blanco-off'
        }

        setValoresTeclado({
            ...valoresTeclado,
            campoSeleccionado,
            claseEspacioBlanco,
            claseTeclado,
        })
    }

    if (loading) return <Loader />

    if (error !== undefined) return <PageError message={error.message} source={'anulacion'} />

    return (
        <AnulacionUI

            anulacionesApi={anulacionesApi}
            botonesAnular={botonesAnular}
            valoresTeclado={valoresTeclado}
            estadosAnulacion={estadosAnulacion}
            controlAnimaciones={controlAnimaciones}
            setControlAnimaciones={setControlAnimaciones}
            pais={pais}

            handleChangeAnulacion={handleChangeAnulacion}
            handleChangeFecha={handleChangeFecha}
            handleSubmit={handleSubmit}
            handleSubmitFecha={handleSubmitFecha}

            buscarFecha={buscarFecha}

            buscarOrdenDiaria={buscarOrdenDiaria}

            formData={formData}
            formDataFecha={formDataFecha}

            setFormData={setFormData}
            setFormDataFecha={setFormDataFecha}

            NombreColaborador={valoresLogin.Nombres}
            ApellidoColaborador={valoresLogin.Apellidos}

            onOpenModal={onOpenModal}
            openModal={openModal}
            onCancelModal={onCancelModal}
            numberModal={numberModal}

            activarTeclado={activarTeclado}
        />
    )


}

export { Anulacion }
