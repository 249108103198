import React from "react";

import { OrderLogList } from "../OrderLogList";
import { OrderLogItem } from "../OrderLogItem";
import { FormOrdersLog } from "../FormOrdersLog";
import { ModalBitacora } from "../ModalBitacora";

import { Loader } from "../../Loader";
import { PageError } from "../../PageError";
import { Modal } from "../../Modal";

import "./style.css";
import { ModalMiniLoader } from "../ModalMiniLoader";

const OrderLogUI = ({
  handleChange,
  handleSubmit,
  limpiarFiltros,
  handleModalLog,
  handleCerrarModal,
  state,
  numberModal,
  openModal,
}) => {

  // state.bitacora no tiene la propiedad length porque es un objeto que contiene las dos bitácoras
  // Hay que hacer una validación por cada array dentro del objeto
  //let condicion = false;
  //console.log(state.buscar);
  //if (state.bitacora.length !== 0) {
  let condicion = (state.bitacora.Orden.length > 0 || state.bitacora.Cierre.length > 0) && state.buscar === false;
  //console.log(condicion);

  if (state.bitacora.Orden !== undefined || state.bitacora.Cierre !== undefined) {
    condicion = (state.bitacora.Orden.length > 0 || state.bitacora.Cierre.length) && state.buscar === false;
  }
  else {
    condicion = state.bitacora.length > 0 && state.buscar === false;
  }
  //}

  //console.log(condicion);


  if (!!state.loading) return <Loader />;

  if (state.error !== undefined && !state.loading) return <PageError message={state.error} source={'visorDigital'} />;
  //console.log(state.bitacora, state.buscar, state.errorBuscar);
  return (
    <div className="ui grid container mt-30">
      <div className="sixteen wide column">
        <div className="ui column center aligned segment segment-color m-0">
          <h1 className="ui-header">Bitácora Electrónica</h1>
        </div>
        <div className="ui segment segment-color m-0">
          <div className="ui two column centered grid">
            <div className="column">
              <FormOrdersLog
                filters={state.filtros}
                formFilter={state.formFiltros}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                limpiarFiltros={limpiarFiltros}
              />
            </div>
          </div>
          {//state.bitacora.length > 0 && state.buscar === false ? (
            condicion ? (
              <div className="ui segment table-container">
                {
                  (state.bitacora.Orden.length > 0)
                    ? (
                      <OrderLogList tipo={'orden'}>
                        {state.bitacora.Orden.map((order, index) => (
                          <OrderLogItem
                            key={order.CodigoOrden + index}
                            order={order}
                            noFila={index + 1}
                            handleModalLog={handleModalLog}
                            tipo={'orden'}
                            canales={state.filtros.canales}
                          />
                        ))}
                      </OrderLogList>
                    )
                    : null
                }
                {

                  (state.bitacora.Cierre.length > 0)
                    ? (
                      <OrderLogList tipo={'cierre'}>
                        {state.bitacora.Cierre.map((cierre, index) => (
                          <OrderLogItem
                            key={cierre.CodigoCierre + index}
                            order={cierre}
                            noFila={index + 1}
                            handleModalLog={handleModalLog}
                            tipo={'cierre'}
                          />
                        ))}
                      </OrderLogList>
                    )
                    : null
                }
              </div>
            ) : (state.bitacora.Orden.length === 0 && state.bitacora.Cierre.length === 0) && state.buscar !== undefined ? (
              <>
                <div className="ui yellow message">No se encontraron resultados</div>
              </>
            ) : state.errorBuscar !== undefined && state.buscar === undefined ? (
              <>
                <div className="ui red message">Error: {state.errorBuscar}</div>
              </>
            ) : null}
        </div>
      </div>
      {!!state.abrirModal && (
        <Modal>
          <ModalBitacora
            closeModal={handleCerrarModal}
            text={state.documentoBitacora.Impresion}
            codigoOrden={state.documentoBitacora.CodigoOrden}
          />
        </Modal>
      )}

      {!!openModal && (
        <Modal>

          {numberModal === 1 ? (
            <ModalMiniLoader />
          ) : null

          }
        </Modal>
      )}
    </div>
  );
};

export { OrderLogUI };
