import React from 'react'
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { format, parse, startOfWeek, getDay } from 'date-fns';
import esES from 'date-fns/esm/locale/es';
import { useContext } from 'react';
import { ProductosContext } from '../ProductosContext';

const locales = {
    'es': esES,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

let formats = {
    dayFormat: 'EEE' // muestra las abreviaturas de los nombres de los días
}

const CalendarioPrecios = () => {

    const {
        state,
        cambiarEstadoPrecio,
    } = useContext(ProductosContext);

    return (
        <>
            <div className="ui two column grid">
                <div className="column">
                    <button onClick={() => cambiarEstadoPrecio('crear')} className="ui positive fluid icon labeled button">
                        <i className="plus icon"></i>
                        Agregar precio
                    </button>

                </div>
                <div className="column">
                    <button onClick={() => cambiarEstadoPrecio('agenda')} className="ui secondary fluid icon labeled button">
                        <i className="calendar outline icon"></i>
                        Ver agenda
                    </button>
                </div>
            </div>


            <Calendar
                culture='es'
                localizer={localizer}
                events={state.eventoPrecios}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                defaultDate={new Date(2022, 10, 7)}
                defaultView={Views.WEEK}
                views={['week']}
                toolbar={false}
                formats={formats}
                eventPropGetter={(eventoPrecios) => {
                    const backgroundColor = eventoPrecios.color;
                    return {
                        style: {
                            backgroundColor
                        }
                    }
                }}
            />
        </>
    )
}

export { CalendarioPrecios }